import { useDataManagementContext } from '../../../context/DataManagementContext';
import {
    FeedbacksFilters,
    PaginationModel,
    ReviewStatus,
    FeedbackType,
    FeedbackValue,
    PopoverRow
} from '../../../types';
import { RESPONSE_FEEDBACK_VALUES, REWRITE_FEEDBACK_VALUES } from './consts';
import { useEffect } from 'react';
import Backend from '../../../backend/Backend';
import { useIntegratedModels } from '../../../AppContext';
import { getModelFilterItems } from '../../../utils/productUtils';
import { getExportFileData, getFeedbackPopulatedRows } from './FeedbacksHelper';
import { DEFAULT_ROWS_LIMIT_PER_PAGE } from '../../Table/consts';
import { t } from 'i18next';
import { onExportFile } from '../../../utils/fileUtils';

export const useFeedbacksRows = () => {
    const {
        areFeedbacksFetching,
        feedbacksRows,
        setFeedbacksRows,
        setAreFeedbacksFetching
    } = useDataManagementContext();

    const updateFeedbacksRow = (
        rowid: string,
        updates: {
            notes?: string;
            review_status?: ReviewStatus;
        }
    ) => {
        const newFeedbacksRows = feedbacksRows.map((row) =>
            row.id === rowid
                ? {
                      ...row,
                      ...updates
                  }
                : row
        );
        Backend.updateFeedback(rowid, updates.notes, updates.review_status);
        setFeedbacksRows(newFeedbacksRows);
    };

    return {
        areFeedbacksFetching,
        feedbacksRows,
        setFeedbacksRows,
        setAreFeedbacksFetching,
        updateFeedbacksRow
    };
};

export const useFeedbacksFilters = () => {
    const {
        feedbacksSearchTerm,
        setFeedbacksSearchTerm,
        feedbacksFilters,
        setFeedbacksFilters,
        feedbackModels,
        setFeedbackModels
    } = useDataManagementContext();
    const { integratedModels } = useIntegratedModels();
    const { setPaginationPage } = useFeedbackPaginationModel();

    const updateFilters = (updaters: FeedbacksFilters) => {
        const hasChanges = Object.keys(updaters).some(
            (key) => feedbacksFilters[key] !== updaters[key]
        );
        if (!hasChanges) {
            return;
        }
        setFeedbacksFilters((prevFilters: FeedbacksFilters) => ({
            ...prevFilters,
            ...updaters
        }));
        setPaginationPage(0);
    };
    const setModelsToFilter = (models: string[]) => {
        updateFilters({ models });
    };

    const setTypeToFilter = (type: FeedbackType) => {
        if (
            type === FeedbackType.Rewrite &&
            !REWRITE_FEEDBACK_VALUES.includes(feedbacksFilters.value)
        ) {
            updateFilters({ type, value: null });
        } else if (
            (type === FeedbackType.Response || type === FeedbackType.Source) &&
            !RESPONSE_FEEDBACK_VALUES.includes(feedbacksFilters.value)
        ) {
            updateFilters({ type, value: null });
        } else {
            updateFilters({ type });
        }
    };
    const setValueToFilter = (value: FeedbackValue) => {
        updateFilters({ value });
    };
    const setStatusToFilter = (status: ReviewStatus) => {
        updateFilters({ status });
    };
    const setCategoryToFilter = (category: string) => {
        updateFilters({ category });
    };
    const setCreateDateToFilter = (
        minCreateDate?: Date,
        maxCreateDate?: Date
    ) => {
        updateFilters({ minCreateDate, maxCreateDate });
    };

    const fetchFeedbackModels = async () => {
        try {
            const { models } = await Backend.fetchFeedbackModels();
            setFeedbackModels(getModelFilterItems(models, integratedModels));
        } catch (ex: any) {
            console.error('Failed to feedback models.', ex);
            window.sentry.log(ex);
        }
    };

    return {
        feedbacksSearchTerm,
        setFeedbacksSearchTerm,
        setModelsToFilter,
        setTypeToFilter,
        setValueToFilter,
        setStatusToFilter,
        setCreateDateToFilter,
        feedbacksFilters,
        fetchFeedbackModels,
        feedbackModels,
        setCategoryToFilter
    };
};

export const useFetchFeedbacksData = () => {
    const { feedbacksSearchTerm, feedbacksFilters } = useFeedbacksFilters();
    const { setFeedbacksRows, setAreFeedbacksFetching } = useFeedbacksRows();
    const { integratedModels } = useIntegratedModels();
    const { paginationModel } = useFeedbackPaginationModel();
    const { setFeedbackRowsCount } = useFeedbackRowsCount();
    const { feedbacksSortModel } = useFeedbackRowsSortModel();

    const fetchFeedbacks = async () => {
        setAreFeedbacksFetching(true);
        try {
            const response: any = await Backend.fetchFeedbacks(
                feedbacksFilters,
                feedbacksSearchTerm,
                feedbacksSortModel[0].sort,
                feedbacksSortModel[0].field,
                paginationModel.page * DEFAULT_ROWS_LIMIT_PER_PAGE,
                DEFAULT_ROWS_LIMIT_PER_PAGE
            );
            const feedbackRows = getFeedbackPopulatedRows(
                response.data,
                integratedModels
            );
            setFeedbacksRows(feedbackRows);
            setFeedbackRowsCount(response.count);
            setAreFeedbacksFetching(false);
        } catch (ex: any) {
            setAreFeedbacksFetching(false);
            console.error('Failed to fetch data.', ex);
            window.sentry.log(ex);
        }
    };
    useEffect(() => {
        fetchFeedbacks();
    }, [
        paginationModel,
        feedbacksFilters,
        feedbacksSearchTerm,
        feedbacksSortModel
    ]);
};

export const useFeedbackPaginationModel = () => {
    const { feedbackPaginationModel, setFeedbackPaginationModel } =
        useDataManagementContext();

    const setPaginationPage = (page: number) => {
        setFeedbackPaginationModel((prevModel: PaginationModel) => ({
            ...prevModel,
            page
        }));
    };
    return {
        paginationModel: feedbackPaginationModel,
        setPaginationPage,
        setPaginationModel: setFeedbackPaginationModel
    };
};

export const useFeedbackRowsCount = () => {
    const { feedbackRowsCount, setFeedbackRowsCount } =
        useDataManagementContext();
    return {
        feedbackRowsCount,
        setFeedbackRowsCount
    };
};

export const useFeedbackRowsSortModel = () => {
    const { feedbacksSortModel, setFeedbacksSortModel } =
        useDataManagementContext();

    return {
        feedbacksSortModel,
        setFeedbacksSortModel
    };
};

export const useEditedResponse = () => {
    const { editedFeedbackRow, setEditedFeedbackRow } =
        useDataManagementContext();

    return {
        editedFeedbackRow,
        setEditedFeedbackRow
    };
};

const useFeedbackExport = () => {
    const { feedbacksSearchTerm, feedbacksFilters } = useFeedbacksFilters();
    const { feedbacksSortModel } = useFeedbackRowsSortModel();
    const { integratedModels } = useIntegratedModels();
    const { setIsFeedbacksExporting } = useDataManagementContext();

    const exportFeedbacks = async () => {
        try {
            setIsFeedbacksExporting(true);

            const response: any = await Backend.fetchFeedbacks(
                feedbacksFilters,
                feedbacksSearchTerm,
                feedbacksSortModel[0].sort,
                feedbacksSortModel[0].field,
                0,
                0
            );
            const [fileData, fileName] = getExportFileData(
                response.data,
                integratedModels
            );
            setIsFeedbacksExporting(false);
            onExportFile(fileData, fileName);
        } catch (ex: any) {
            setIsFeedbacksExporting(false);
            console.error('Failed to fetch data.', ex);
            window.sentry.log(ex);
        }
    };

    return { exportFeedbacks };
};

export const useMoreOptionsRowsFeedbacks = () => {
    const { exportFeedbacks } = useFeedbackExport();
    const { isFeedbacksExporting } = useDataManagementContext();

    const rows: PopoverRow[] = [
        {
            show: true,
            title: t('common.export'),
            onClick: exportFeedbacks,
            dataTestId: 'expert-responses-menu-item-export',
            withSeparator: false,
            isProcessing: isFeedbacksExporting
        }
    ];
    const hasRowsToShow = rows.some((row) => row.show);
    return { rows, hasRowsToShow };
};