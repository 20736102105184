import {
    useEditedResponseVersions,
    useExpertResponsesRows,
    useOpenThread
} from '../useExpertResponsesHooks';
import EditResponseBox from './EditResponseBox';
import { adjustIntegratedModel } from '../../../../utils/productUtils';
import { useEffect, useRef, useState } from 'react';
import Backend from '../../../../backend/Backend';
import EclipseLoader from '../../../../SVG/EclipseLoader';
import EditResponseModalMetadata from '../../../EditResponseModal/EditResponseModalMetadata';

const EditResponsesModalBody = () => {
    const { expertResponsesEditModalData } = useExpertResponsesRows();
    const { editedResponseVersions, setEditedResponseVersions } =
        useEditedResponseVersions();
    const { onOpenThread } = useOpenThread();
    const [isLoading, setIsLoading] = useState(false);
    const [editingIndex, setEditingIndex] = useState(null);

    const editModalContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (expertResponsesEditModalData) {
            setIsLoading(true);
            Backend.getExpertResponsesByVectorId(
                expertResponsesEditModalData.vector_id,
                expertResponsesEditModalData.model
            )
                .then((response) => {
                    setEditedResponseVersions(response);
                    setIsLoading(false);
                })
                .catch((ex: any) => {
                    setIsLoading(false);
                    console.error('Failed to fetch data.', ex);
                    window.sentry.log(ex);
                });
        }
    }, [expertResponsesEditModalData]);

    if (isLoading) {
        return (
            <div className="side-panel__loader">
                <EclipseLoader />
            </div>
        );
    }

    return (
        <div
            className="flex flex-col editResponsesModal__container"
            ref={editModalContainerRef}
        >
            <EditResponseModalMetadata
                modelDisplay={
                    expertResponsesEditModalData &&
                    expertResponsesEditModalData.integratedModel
                        ? adjustIntegratedModel(
                              expertResponsesEditModalData.integratedModel
                          )
                        : expertResponsesEditModalData.model
                }
                initialQuestion={expertResponsesEditModalData.initial_question}
                onQuestionClick={() => {
                    onOpenThread(expertResponsesEditModalData);
                }}
            />
            <div className="flex flex-col editResponsesModal__responses">
                {editedResponseVersions.map((response, index) => {
                    return (
                        <EditResponseBox
                            index={index}
                            data={response}
                            editingIndex={editingIndex}
                            setEditingIndex={setEditingIndex}
                            editModalContainerRef={editModalContainerRef}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default EditResponsesModalBody;
