import { FC } from 'react';
import { editedResponseVersion } from '../../../../types';
import Backend from '../../../../backend/Backend';
import {
    useEditedResponseVersions,
    useExpertResponsesRows
} from '../useExpertResponsesHooks';
import { useSelector } from 'react-redux';
import EditResponse from '../../../EditResponseModal/EditResponse';
import { t } from 'i18next';
import { useScopSettings } from '../../../../AppContext';

interface EditResponseBoxProps {
    data: editedResponseVersion;
    editingIndex: number;
    setEditingIndex: (index: number) => void;
    index: number;
    editModalContainerRef: React.RefObject<HTMLDivElement>;
}
const EditResponseBox: FC<EditResponseBoxProps> = ({
    data,
    index,
    editingIndex,
    setEditingIndex,
    editModalContainerRef
}) => {
    const { activateEditedResponseVersionsRows, updateEditedResponseVersions } =
        useEditedResponseVersions();
    const {
        setActiveRows,
        expertResponsesEditModalData,
        updateExpertResponsesRows
    } = useExpertResponsesRows();
    const { allowUserToEditInExpertEditTable } = useScopSettings();

    const { fullUserName } = useSelector(
        (state: any) => state.userProfileReducer
    );

    const editMode = editingIndex === index;

    const onClickCancelEdit = () => {
        setEditingIndex(null);
    };

    const shouldShowEdit = () => {
        return allowUserToEditInExpertEditTable && editingIndex === null;
    };

    const shouldShowActive = () => {
        return !editMode && allowUserToEditInExpertEditTable;
    };

    const onActivateResponse = (active: boolean) => {
        Backend.updateExpertResponse(
            data.id,
            '',
            '',
            active,
            expertResponsesEditModalData.vector_id,
            expertResponsesEditModalData.model
        );
        activateEditedResponseVersionsRows(index, active);
        setActiveRows(
            data.id,
            active,
            expertResponsesEditModalData.vector_id,
            expertResponsesEditModalData.model
        );
        editModalContainerRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const onClickSaveEdit = (newResponse: string) => {
        Backend.updateExpertResponse(
            data.id,
            newResponse,
            fullUserName,
            data.active ? null : true,
            expertResponsesEditModalData.vector_id,
            expertResponsesEditModalData.model
        );

        updateEditedResponseVersions(
            index,
            newResponse,
            fullUserName,
            !data.active
        );
        updateExpertResponsesRows(
            data.id,
            newResponse,
            fullUserName,
            !data.active,
            expertResponsesEditModalData.vector_id,
            expertResponsesEditModalData.model
        );
        setEditingIndex(null);
        editModalContainerRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <EditResponse
            onCancelEdit={onClickCancelEdit}
            onSaveEdit={onClickSaveEdit}
            isEditMode={editingIndex === index}
            onEditClick={() => setEditingIndex(index)}
            response={data.expert_response}
            sources={data.answer_sources}
            editDate={data.date}
            expertName={data.user_name}
            isResponseActive={data.active}
            setIsResponseActive={onActivateResponse}
            label={
                data.active
                    ? t('expert-responses-dm.displayed-expert-responses')
                    : t('expert-responses-dm.previous-expert-responses')
            }
            isEditAvailable={shouldShowEdit()}
            isActivationChangeAvailable={shouldShowActive()}
        />
    );
};

export default EditResponseBox;
