import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Typography
} from 'aqui';
import {
    useCopyPersonaSourcesModal,
    usePersonasManagementRows
} from '../PersonaManagementHooks';
import { t } from 'i18next';
import PersonaSelect from '../PersonaSelect';
import { useState } from 'react';
import Backend from '../../../../backend/Backend';
import useTaskStatusInterval from '../../../../hooks/useTaskStatusInterval';
import { useTenant } from '../../../../AppContext';
import { shouldShowPersonasProcessPendingModal } from '../utils';
const CopyPersonaSourcesModal = () => {
    const [selectedPersona, setSelectedPersona] = useState<string>('');

    const { copySourcesFromPersonaModal, setCopySourcesFromPersonaModal } =
        useCopyPersonaSourcesModal();
    const {
        personaNameToId,
        setArePersonasManagementDataFetching,
        fetchPersonasManagementRows,
        setIsPersonasProcessPendingModalOpen
    } = usePersonasManagementRows();
    const { tenantId } = useTenant();

    const { setTaskId } = useTaskStatusInterval({
        tenantId: tenantId,
        successCallback: () => fetchPersonasManagementRows(),
        failureCallback: () => setArePersonasManagementDataFetching(false)
    });

    const onClickSubmit = async () => {
        try {
            setCopySourcesFromPersonaModal('');
            setArePersonasManagementDataFetching(true);
            const res = await Backend.copyFromPersona(
                tenantId,
                personaNameToId[copySourcesFromPersonaModal],
                personaNameToId[selectedPersona]
            );
            setTaskId(res.data.task_id);
        } catch (error: any) {
            setIsPersonasProcessPendingModalOpen(shouldShowPersonasProcessPendingModal(error));
        }
    };

    return (
        <Modal
            isOpen={Boolean(copySourcesFromPersonaModal)}
            closeModal={() => setCopySourcesFromPersonaModal('')}
        >
            <ModalHeader>{t('add-persona.copy-sources')}</ModalHeader>
            <ModalBody className="copyPersonaSourcesModal__body">
                <div className="flex align-center">
                    <Typography type="subtitle3">
                        {t('add-persona.copy-sources')}
                    </Typography>
                    <PersonaSelect
                        selectedPersona={selectedPersona}
                        setSelectedPersona={setSelectedPersona}
                    />
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="flex align-center copyPersonaSourcesModal__footer">
                    <Button
                        dataTestId="ok-btn-copy-sources"
                        color="blue"
                        onClick={() => setCopySourcesFromPersonaModal('')}
                        text={t('common.cancel')}
                        variant="outlined"
                    />
                    <Button
                        dataTestId="ok-btn-copy-sources"
                        color="blue"
                        onClick={onClickSubmit}
                        text={t('common.submit')}
                        variant="filled"
                    />
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default CopyPersonaSourcesModal;
