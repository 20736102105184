import { GridSortModel } from '@mui/x-data-grid-pro';
import { SORT_TYPES } from '../../Table/consts';
import { FeedbackValue } from '../../../types';

export const COLUMNS = {
    FEEDBACK_VALUE: 'feedback_value',
    MODEL: 'tenant_model',
    SOURCE: 'current_source',
    INITIAL_QUESTION: 'initial_question',
    QUESTION: 'question',
    RESPONSE: 'response',
    CONCISE: 'is_concise_response',
    PERSONA: 'persona_name',
    FEEDBACK_CATEGORIES: 'categories',
    FEEDBACK_TEXT: 'feedback_text',
    CREATED_AT: 'created_at',
    FEEDBACK_TYPE: 'feedback_type',
    PLATFORM: 'platform',
    USERNAME: 'created_by',
    REVIEW_STATUS: 'review_status',
    // EDITED_BY_EXPERT: '',
    NOTES: 'notes'
};

export const FEEDBACKS_PINNED_COLUMNS = {
    left: [COLUMNS.FEEDBACK_TYPE, COLUMNS.FEEDBACK_VALUE]
};

export const FEEDBACKS_DEFAULT_SORT_MODEL: GridSortModel = [
    {
        field: COLUMNS.CREATED_AT,
        sort: SORT_TYPES.DESC
    }
];

export const NO_PADDING_COLUMNS = [COLUMNS.NOTES];


export const REWRITE_FEEDBACK_VALUES = [
    FeedbackValue.Better,
    FeedbackValue.Worse,
    FeedbackValue.Same
];
export const RESPONSE_FEEDBACK_VALUES = [
    FeedbackValue.Like,
    FeedbackValue.Dislike
];
