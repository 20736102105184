import React, { FunctionComponent, useState } from 'react';
import { Typography } from 'aqui';
import { ChevronIcon } from 'aqui/icons';

type DateFilterProps = {
    date: string;
    disabled: boolean;
    onChange: (date: string) => void;
};

const DateFilter: FunctionComponent<DateFilterProps> = ({
    date,
    disabled,
    onChange
}) => {
    const [selectedDate, setSelectedDate] = useState<string>(date);
    const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false);

    const onClickDatePicker = (e) => {
        e.currentTarget.showPicker();
        setIsDatePickerOpen(true);
    };

    const onChangeDate = (e) => {
        setSelectedDate(e.target.value);
        setIsDatePickerOpen(false);
        onChange(e.target.value);
    };

    return (
        <div
            className={`flex align-center justify-center dateFilter__container ${
                selectedDate ? 'selected' : ''
            } ${disabled ? 'disabled' : ''} ${isDatePickerOpen ? 'open' : ''}`}
        >
            {!disabled && (
                <input
                    className="dateFilter__input"
                    type="date"
                    onChange={onChangeDate}
                    onClick={onClickDatePicker}
                    value={selectedDate}
                    onBlur={() =>
                        isDatePickerOpen && setIsDatePickerOpen(false)
                    }
                />
            )}
            <Typography type="small1">{`${
                selectedDate || 'Date added: All'
            }`}</Typography>
            <ChevronIcon
                direction="down"
                className={`dateFilter__chevron ${
                    isDatePickerOpen ? 'open' : ''
                }`}
            />
        </div>
    );
};

export default DateFilter;
