import { MAX_MODELS_TO_FETCH, REMOTE_ACTIONS } from '../consts';
import { ProductDetailsResponse } from '../types';

const DEFAULT_CONFIG = {
    escape: false,
    buffer: false
};

type CopilotRemoteActions =
    (typeof REMOTE_ACTIONS)[keyof typeof REMOTE_ACTIONS];

export class RemoteActionNotFoundError extends Error {}

class SFBackend {
    static isSandbox: boolean = null;

    public static async fetchProductDetails(models: Array<string>) {
        return SFBackend.invokeVisualForceAction<
            string[],
            ProductDetailsResponse
        >(REMOTE_ACTIONS.VERIFY_SCOP_MODEL, [models]);
    }

    public static async isSandboxOrg() {
        // Support for this feature is available since version 98
        return SFBackend.invokeVisualForceAction<string[], boolean>(
            REMOTE_ACTIONS.IS_SANDBOX_ORG,
            []
        );
    }

    public static async createChatId() {
        try {
            return SFBackend.invokeVisualForceAction<[], string>(
                REMOTE_ACTIONS.CREATE_CHAT_ID,
                []
            );
        } catch (error: any) {
            console.error(error);
            window.sentry.log(error);
        }
    }

    public static async getChatId(investigationId: string) {
        return SFBackend.invokeVisualForceAction<string, string>('getChatId', [
            investigationId
        ]);
    }

    public static async getAdditionalChatHistoryData(
        chatIds: string[],
        userIds: string[]
    ) {
        if (
            !SFBackend.isRemoteActionExists(
                REMOTE_ACTIONS.GET_ADDITIONAL_CHAT_HISTORY_DATA
            )
        ) {
            return Promise.resolve({ chatsData: {}, usersData: {} });
        }
        return SFBackend.invokeVisualForceAction<any, any>(
            REMOTE_ACTIONS.GET_ADDITIONAL_CHAT_HISTORY_DATA,
            [chatIds, userIds]
        );
    }

    public static isRemoteActionExists(actionName: CopilotRemoteActions) {
        return !!window.RemoteActions[actionName];
    }

    private static async invokeVisualForceAction<T, J>(
        actionName: CopilotRemoteActions,
        params: T[],
        options: object = null
    ): Promise<J> {
        if (!SFBackend.isRemoteActionExists(actionName)) {
            throw new RemoteActionNotFoundError(
                `Remote Action not found: ${actionName}`
            );
        }

        return new Promise((resolve, reject) => {
            window.Visualforce.remoting.Manager.invokeAction(
                window.RemoteActions[actionName],
                ...params,
                (result, event) => {
                    if (event.statusCode === 200) {
                        resolve(result);
                    } else {
                        reject(result);
                    }
                },
                {
                    ...DEFAULT_CONFIG,
                    ...options
                }
            );
        });
    }

    public static async fetchCatalogProductType() {
        return new Promise<{ catalog_data: Array<Record<string, any>> }>(
            (resolve) => {
                window.Visualforce.remoting.Manager.invokeAction(
                    window.RemoteActions[
                        REMOTE_ACTIONS.GET_CATALOG_PRODUCTTYPE
                    ],
                    { list: 'list' },
                    (result, event) => {
                        if (event.statusCode === 200) {
                            resolve(result);
                        } else {
                            console.warn('Failed to get producttype catalog');
                            resolve({ catalog_data: [] });
                        }
                    },
                    DEFAULT_CONFIG
                );
            }
        );
    }
    public static async fetchCatalogProduct() {
        return new Promise<{
            catalog_data: Array<Record<string, any>>;
            additional_data: object;
            has_next: boolean;
        }>((resolve) => {
            window.Visualforce.remoting.Manager.invokeAction(
                window.RemoteActions[REMOTE_ACTIONS.GET_CATALOG_PRODUCT],
                {
                    list: 'list',
                    offset: '0',
                    query: '',
                    skip_upsert: '1',
                    limit: MAX_MODELS_TO_FETCH
                },
                (result, event) => {
                    if (event.statusCode === 200) {
                        resolve(result);
                    } else {
                        console.warn('Failed to get producttype catalog');
                        resolve({
                            catalog_data: [],
                            has_next: false,
                            additional_data: {}
                        });
                    }
                },
                DEFAULT_CONFIG
            );
        });
    }

    public static async getExistingUsersFromList(users: string[]) {
        if (
            !SFBackend.isRemoteActionExists(
                REMOTE_ACTIONS.GET_EXISTING_USERS_FROM_COLLECTION
            )
        ) {
            return Promise.resolve(null);
        }
        return SFBackend.invokeVisualForceAction<any, any>(
            REMOTE_ACTIONS.GET_EXISTING_USERS_FROM_COLLECTION,
            [users]
        );
    }
}

export default SFBackend;
