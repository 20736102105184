import { useEffect, useRef, useState } from 'react';
import BasicPDFDocument from './BasicPDFDocument';
import { SourceDocument } from '../../types';
import PreviewBanner from './PreviewBanner';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ANALYTICS_EVENTS } from '../../consts';
import useAnalytics from '../../hooks/useAnalytics';

type BasicPDFViewerProps = {
    sourceDocument: SourceDocument;
};

const BasicPDFViewer = (props: BasicPDFViewerProps) => {
    const { sendEvent } = useAnalytics();
    const [isShowFullDoc, setIsShowFullDoc] = useState<boolean>(false);
    const hasRun = useRef(false);
    const { t } = useTranslation();

    useEffect(() => {
        if (hasRun.current) return;
        if (isShowFullDoc) {
            hasRun.current = true;
            sendEvent(ANALYTICS_EVENTS.SOURCE_FULL_DOCUMENT_CLICKED, {
                file: props.sourceDocument.metadata.filename,
                page: props.sourceDocument.metadata.page
            });
        }
    }, [isShowFullDoc]);

    return (
        <>
            <PreviewBanner show>
                <div className={classNames('pdf-viewer-iframe__banner')}>
                    {t('document.loading-doc-iframe')}
                    <div
                        className={classNames('pdf-viewer-iframe__btn')}
                        onClick={() => setIsShowFullDoc(!isShowFullDoc)}
                    >
                        <span className={classNames('pdf-viewer-iframe__icon')}>
                            &#8635;
                        </span>
                        {isShowFullDoc
                            ? t('document.loading-doc-iframe-btn')
                            : t('document.loading-preview-doc-iframe-btn')}
                    </div>
                </div>
            </PreviewBanner>
            <BasicPDFDocument
                showDoc={!isShowFullDoc}
                sourcePath={
                    props.sourceDocument.metadata?.source_pdf_preview_url || ''
                }
                pageNumber={1}
            />
            <BasicPDFDocument
                showDoc={isShowFullDoc}
                sourcePath={props.sourceDocument.metadata?.source || ''}
                pageNumber={props.sourceDocument.metadata.page}
            />
        </>
    );
};

export default BasicPDFViewer;
