import { FC } from 'react';
import ValueWithTooltip from '../../ValueWithTooltip/ValueWithTooltip';
import styles from './Cells.module.scss';
import classnames from 'classnames/bind';

const cx = classnames.bind(styles);

interface TextCellProps {
    field: string;
    text: string;
    icon?: any;
    className?: string;
    iconPosition?: 'left' | 'right';
    permanentTooltipText?: string;
    maxTooltipRows?: number;
}

const TextCell: FC<TextCellProps> = ({
    field,
    text,
    icon = null,
    className = '',
    iconPosition = 'right',
    permanentTooltipText,
    maxTooltipRows = 0
}) => {
    return (
        <div
            className={cx(
                className,
                'textCell__container',
                `icon-${iconPosition}`
            )}
            data-testid="clickable-cell"
        >
            {iconPosition === 'left' && icon}
            <ValueWithTooltip
                type="subtitle3"
                dataTestId={`${field}-cell`}
                title={text}
                tooltipTitle={permanentTooltipText}
                isPermanentTooltip={!!permanentTooltipText}
                maxTooltipRows={maxTooltipRows}
            />
            {iconPosition === 'right' && icon}
        </div>
    );
};

export default TextCell;
