import { Fade, Paper, Popper } from '@material-ui/core';
import { useState, useRef, useEffect, useCallback } from 'react';
import { Tooltip } from '@material-ui/core';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import CoPilotMagicButtonPopper from './CoPilotMagicButtonPopper';
import { ANALYTICS_EVENTS, ANALYTICS_EVENT_PARAMS_KEYS } from '../../consts';
import Backend from '../../backend/Backend';
import { useModel } from '../../AppContext';
import {
    useSelectedText,
    useShowHighlightedText,
    useCopilotQuestions,
    useCopilotQuestionsInProgress,
    useHighlightedMessageIndex
} from '../../context/ChatContext';
import useAnalytics from '../../hooks/useAnalytics';
import CoPilotButton from '../CoPilotButton/CoPilotButton';
import { HighlightSuggestionPayload, MessageInfo } from '../../types';
import { TooltipProps } from '@mui/material';
import { t } from 'i18next';

type CoPilotMagicButtonProps = {
    message: MessageInfo;
    askedQuestion: string;
    rect;
    className?: string;
    showPreview?: boolean;
    tooltipPosition?: TooltipProps['placement'];
};

const offsetX = 0;
const offsetY = 9;
const CoPilotMagicButton = ({
    message,
    askedQuestion,
    rect,
    className = '',
    showPreview = false,
    tooltipPosition = 'top'
}: CoPilotMagicButtonProps) => {
    const [open, setOpen] = useState(showPreview);
    const [anchor, setAnchor] = useState(null);
    const { selectedText, setSelectedText } = useSelectedText();
    const { setShowHighlightedText } = useShowHighlightedText();
    const { setCopilotQuestions } = useCopilotQuestions();
    const { setCopilotQuestionsInProgress } = useCopilotQuestionsInProgress();
    const { setHighlightedMessageIndex } = useHighlightedMessageIndex();
    const { sendEvent } = useAnalytics();
    const btnRef = useRef();
    const popperRef = useRef();

    const { model } = useModel();

    const handleClickOutside = useCallback(() => {
        if (!showPreview && open) {
            setOpen(false);
        }
        setSelectedText('');
        setShowHighlightedText(false);
        setHighlightedMessageIndex(null);
    }, [showPreview, open]);

    useOnClickOutside(popperRef, handleClickOutside, btnRef);

    useEffect(() => {
        if (!btnRef.current) {
            return;
        }
        const resizeObserver = new ResizeObserver(() => {
            setAnchor(btnRef?.current);
        });
        resizeObserver.observe(btnRef.current);
        return () => resizeObserver.disconnect();
    }, []);

    const onClickIcon = (e) => {
        e.preventDefault();
        setShowHighlightedText(true);
        setOpen(!open);
        setCopilotQuestionsInProgress(true);
        sendEvent(ANALYTICS_EVENTS.CLICKED_MAGIC_BUTTON, {
            [ANALYTICS_EVENT_PARAMS_KEYS.MARKED_TEXT]: selectedText
        });
        const payload: HighlightSuggestionPayload = {
            highlight: selectedText,
            chat_history_line_id: message.rowID,
            stream_in_progress_data: message.rowID
                ? undefined
                : {
                      question: askedQuestion,
                      partial_answer: message.message
                  }
        };
        Backend.highlightSuggestions(model, payload).then((res) => {
            setCopilotQuestions(res?.data?.suggestions || []);
            setCopilotQuestionsInProgress(false);
        });
    };

    return (
        <>
            <Tooltip
                title={t('common.ask-copilot') as string}
                placement={tooltipPosition}
                className="copilot-btn__tooltip"
            >
                <div
                    style={{
                        top: rect?.top || '0px',
                        left: rect?.left || '0px'
                    }}
                    ref={btnRef}
                    onClick={onClickIcon}
                    className={`copilot-btn__container flex align-center justify-center ${
                        open ? 'open' : ''
                    } ${className}`}
                >
                    <CoPilotButton dataTestId="highlight-magic-button" />
                </div>
            </Tooltip>
            <Popper
                disablePortal
                placement="bottom-start"
                open={open}
                anchorEl={anchor}
                transition
                className="copilot-btn__popper"
                modifiers={{
                    offset: {
                        enabled: true,
                        offset: `${offsetX}, ${offsetY}`
                    }
                }}
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper>
                            <CoPilotMagicButtonPopper
                                setOpen={setOpen}
                                popperRef={popperRef}
                            />
                        </Paper>
                    </Fade>
                )}
            </Popper>
        </>
    );
};

export default CoPilotMagicButton;
