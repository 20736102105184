import { t } from 'i18next';
import { COLUMNS } from './consts';

export const getColumnsData = () => [
    {
        field: COLUMNS.MODEL,
        headerName: t('expert-responses-dm.model-column'),
        width: 190
    },
    {
        field: COLUMNS.INITIAL_QUESTION,
        headerName: t('expert-responses-dm.initial-question-column'),
        width: 250,
        sortable: false
    },
    {
        field: COLUMNS.QUESTION,
        headerName: t('expert-responses-dm.question-column'),
        width: 260,
        sortable: false
    },
    {
        field: COLUMNS.ORIGINAL_RESPONSE,
        headerName: t('expert-responses-dm.original-response-column'),
        width: 410,
        sortable: false
    },
    {
        field: COLUMNS.EXPERT_RESPONSE,
        headerName: t('expert-responses-dm.expert-response-column'),
        width: 410,
        sortable: false
    },
    {
        field: COLUMNS.ACTIVE,
        headerName: t('expert-responses-dm.display-response-column'),
        minWidth: 200,
        maxWidth: 200,
        sortable: false
    },
    {
        field: COLUMNS.DATE,
        headerName: t('expert-responses-dm.date-column'),
        minWidth: 140,
        maxWidth: 140
    },
    {
        field: COLUMNS.EXPERT_MAIL,
        headerName: t('expert-responses-dm.expert-mail-column'),
        minWidth: 190,
        maxWidth: 250,
        flex: 1
    },
    {
        field: COLUMNS.ACTIONS,
        headerName: t('data-management.actions-column'),
        minWidth: 85,
        maxWidth: 85,
        sortable: false
    }
];
