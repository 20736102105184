import SidePanel from '../../../SidePanel/SidePanel';
import { t } from 'i18next';
import { useExpertResponsesRows } from '../useExpertResponsesHooks';
import EditResponsesModalBody from './EditResponsesModalBody';

const EditResponsesModal = () => {
    const { setExpertResponsesEditModalData, expertResponsesEditModalData } =
        useExpertResponsesRows();
    const handleClose = () => {
        setExpertResponsesEditModalData(null);
    };

    if (!expertResponsesEditModalData) {
        return <></>;
    }

    return (
        <SidePanel
            open={Boolean(expertResponsesEditModalData)}
            onClose={handleClose}
            classes={{
                container: 'editResponsesModal__side-panel',
                body: 'editResponsesModal__body editResponsesModal__upload-body flex-col',
                header: 'editResponsesModal__side-panel__header'
            }}
            title={t('expert-responses-dm.expert-response-header') as string}
        >
            <EditResponsesModalBody />
        </SidePanel>
    );
};

export default EditResponsesModal;
