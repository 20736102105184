import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import ValueWithTooltip from '../../ValueWithTooltip/ValueWithTooltip';
import classnames from 'classnames/bind';
import { Button } from 'aqui';

import styles from './Cells.module.scss';
import { Popover } from '@mui/material';
import { t } from 'i18next';
import { doesContainHebrew } from '../../../utils/utils';

const cx = classnames.bind(styles);
const EditableCell = ({
    field,
    row,
    id,
    width = '250px',
    onSubmit,
    placeholder = ''
}) => {
    const [newValue, setNewValue] = useState(row[field]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [textareaElm, setTextareaElm] = useState(null);
    useEffect(() => {
        if (textareaElm && anchorEl) {
            const length = textareaElm.value.length;
            textareaElm.focus();
            textareaElm.setSelectionRange(length, length);
        }
    }, [anchorEl, textareaElm]);

    useLayoutEffect(() => {
        if (textareaElm) {
            if (textareaElm.scrollHeight > textareaElm.clientHeight) {
                textareaElm.style.height = 25 + textareaElm.scrollHeight + 'px';
            }
        }
    }, [textareaElm?.scrollHeight]);

    const textArea = useCallback(
        (node) => {
            if (node !== null && anchorEl) {
                const textArea = document.getElementById('textArea');
                textArea.style.height = 25 + textArea.scrollHeight + 'px';
            }
        },
        [anchorEl]
    );

    const onClickEdit = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const onClickSubmit = () => {
        const trimmedValue = newValue?.trim();
        if (row[field] === trimmedValue) {
            return;
        }
        setNewValue(trimmedValue);
        onSubmit(trimmedValue);
        onClose();
    };

    const onClickCancel = () => {
        setNewValue(row[field]);
        onClose();
    };

    const onClose = () => {
        setAnchorEl(null);
    };

    const returnCellView = () => {
        return (
            <ValueWithTooltip
                type="subtitle3"
                dataTestId={`${field}-cell`}
                title={row[field] || placeholder}
                className={cx('editable-cell__text', {
                    '--placeholder': !row[field] && placeholder
                })}
            />
        );
    };

    return (
        <>
            <div
                onClick={onClickEdit}
                className={cx('editable-cell__container')}
            >
                {returnCellView()}
            </div>
            <Popover
                id={id}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
            >
                <div
                    className={cx('editable-cell__wrapper')}
                    style={{ width }}
                    ref={textArea}
                >
                    <textarea
                        id="textArea"
                        onKeyDown={(e) => e.stopPropagation()}
                        ref={setTextareaElm}
                        data-testid="editable-cell"
                        value={newValue}
                        className={cx('editable-cell__text-area', {
                            rtl: doesContainHebrew(newValue)
                        })}
                        onChange={(e) => {
                            setNewValue(e.target.value);
                        }}
                        placeholder={placeholder}
                    />
                    <div className={cx('editable-cell__btns')}>
                        <Button
                            dataTestId="ok-btn-import-failed-modal"
                            color="blue"
                            onClick={onClickCancel}
                            text={t('common.cancel')}
                            variant="outlined"
                        />
                        <Button
                            dataTestId="ok-btn-import-failed-modal"
                            color="blue"
                            onClick={onClickSubmit}
                            text={t('common.apply')}
                            variant="filled"
                            disabled={row[field] === newValue}
                        />
                    </div>
                </div>
            </Popover>
        </>
    );
};

export default EditableCell;
