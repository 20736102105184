import styles from './Cells.module.scss';
import classnames from 'classnames/bind';
import { SourceDocument } from '../../../types';
import ValueWithTooltip from '../../ValueWithTooltip/ValueWithTooltip';
import { getPageIndication } from '../../KASection/KASectionHelpers';
import { FC } from 'react';
import { useKAModal } from '../../../AppContext';

const cx = classnames.bind(styles);
type SourceDocumentCellProps = {
    field: string;
    sourceDocument: SourceDocument;
    onClick?: () => void;
    isSelected?: boolean;
    hidePageIndication?: boolean;
};

const SourceDocumentCell: FC<SourceDocumentCellProps> = ({
    field,
    sourceDocument,
    onClick,
    isSelected,
    hidePageIndication
}) => {
    const { handleShowSourceDocumentDetails } = useKAModal();

    const handleClick = () => {
        if (sourceDocument) {
            handleShowSourceDocumentDetails(
                0,
                [sourceDocument],
                sourceDocument.metadata.media_type
            );
            onClick?.();
        }
    };

    const title = sourceDocument
        ? `${sourceDocument.metadata.filename}${
              !hidePageIndication
                  ? ', ' + getPageIndication(sourceDocument)
                  : ''
          }`
        : '';

    return (
        <ValueWithTooltip
            type="subtitle3"
            title={title}
            className={cx('source-document-cell', {
                '--no-data': !sourceDocument,
                '--selected': isSelected
            })}
            dataTestId={`${field}-cell`}
            onClick={handleClick}
        />
    );
};

export default SourceDocumentCell;
