import { FunctionComponent } from 'react';
import DateFilter from '../../DateFilter/DateFilter';
import {
    usePersonaManagenentFilters,
    usePersonasManagementRows
} from './PersonaManagementHooks';
import { FiltersRow } from 'aqui';

const PersonasManagementFilters: FunctionComponent = () => {
    const { arePersonasManagementDataFetching } = usePersonasManagementRows();

    const { setFilter, personaManagementFilters } =
        usePersonaManagenentFilters();

    const onChangeFilterDate = (date: string) => {
        setFilter({ date });
    };

    return (
        <FiltersRow withFilterIcon={false}>
            <DateFilter
                date={personaManagementFilters.date}
                disabled={arePersonasManagementDataFetching}
                onChange={onChangeFilterDate}
            />
        </FiltersRow>
    );
};

export default PersonasManagementFilters;
