type BasicPDFDocumentProps = {
    sourcePath: string;
    showDoc: boolean;
    pageNumber: number;
};

const BasicPDFDocument = (props: BasicPDFDocumentProps) => {
    return (
        <div
            style={{
                height: '100vh',
                width: '100%',
                display: !props.showDoc ? 'none' : ''
            }}
        >
            <iframe
                src={props.sourcePath + `#page=${props.pageNumber}`}
                title="PDF Viewer"
                style={{ height: '100%', width: '100%', border: 'none' }}
            />
        </div>
    );
};

export default BasicPDFDocument;
