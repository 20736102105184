import DateCell from '../../Table/Cells/DateCell';
import { COLUMNS } from './consts';
import TextCell from '../../Table/Cells/TextCell';
import { t } from 'i18next';
import ActionsCell from '../../Table/Cells/ActionsCell';
import ToggleCell from '../../Table/Cells/ToggleCell';
import ClickableCell from '../../Table/Cells/ClickableCell';
import { DoubleChevronIcon } from 'aqui/icons';
import {
    useExpertResponsesRows,
    useOpenThread
} from './useExpertResponsesHooks';
import {
    adjustIntegratedModel,
    getIntegratedModelDisplay
} from '../../../utils/productUtils';
import { Tooltip } from '@material-ui/core';
import { ExpertResponsesRow } from '../../../types';
import Backend from '../../../backend/Backend';
import {
    removeIndicatorsFromText,
    splitTextByIndicators
} from '../../../utils/utils';
import { getResponseText } from '../Feedbacks/FeedbacksHelper';
import { useScopSettings } from '../../../AppContext';

export const useExpertResponsesTable = () => {
    const { setExpertResponsesEditModalData, setActiveRows } =
        useExpertResponsesRows();
    const { onOpenThread } = useOpenThread();
    const { allowUserToEditInExpertEditTable } = useScopSettings();

    const getActionsPopoverRows = (row) => {
        return [
            {
                show: true,
                title: t('chat.edit-response'),
                onClick: () => setExpertResponsesEditModalData(row),
                isDisabled: false
            },
            {
                show: true,
                title: t('chat.open-chat'),
                onClick: () => onOpenThread(row),
                isDisabled: false
            }
        ];
    };

    const onClickToggle = (data: ExpertResponsesRow) => {
        const active = !data.active;
        Backend.updateExpertResponse(
            data.id,
            '',
            '',
            active,
            data.vector_id,
            data.model
        );
        setActiveRows(data.id, active, data.vector_id, data.model);
    };

    const columnComponents = {
        [COLUMNS.MODEL]: (params) => (
            <TextCell
                field={params.field}
                text={
                    getIntegratedModelDisplay(params.row.integratedModel) ||
                    params.row.model
                }
                permanentTooltipText={
                    params.row.integratedModel
                        ? adjustIntegratedModel(params.row.integratedModel)
                        : params.row.model
                }
            />
        ),
        [COLUMNS.INITIAL_QUESTION]: (params) => (
            <ClickableCell
                {...params}
                onClick={() => onOpenThread(params.row)}
                withArrow
                arrowTooltip={t('expert-responses-dm.open-chat')}
            />
        ),
        [COLUMNS.QUESTION]: (params) => (
            <TextCell field={params.field} text={params.row[params.field]} />
        ),
        [COLUMNS.ORIGINAL_RESPONSE]: (params) => (
            <TextCell
                field={params.field}
                text={getResponseText(
                    params.row[params.field],
                    params.row.no_response
                )}
                className={
                    params.row.no_response ? 'expertResponses__no-answer' : ''
                }
            />
        ),
        [COLUMNS.EXPERT_RESPONSE]: (params) => (
            <TextCell
                className="expertResponses__clickable-text-cell"
                onClick={() => setExpertResponsesEditModalData(params.row)}
                field={params.field}
                text={`${removeIndicatorsFromText(
                    splitTextByIndicators(params.row[params.field])
                )}`}
                maxTooltipRows={5}
                icon={
                    <Tooltip
                        title={
                            t('expert-responses-dm.view-full-details') as string
                        }
                        placement="top"
                        classes={{
                            tooltip: 'tooltip'
                        }}
                    >
                        <div className="expertResponses__inline-icon">
                            <DoubleChevronIcon direction="right" />
                        </div>
                    </Tooltip>
                }
            />
        ),
        [COLUMNS.ACTIVE]: (params) => (
            <ToggleCell
                {...params}
                onClick={() => onClickToggle(params.row)}
                tooltip={t('expert-responses-dm.activate-response')}
                disabled={!allowUserToEditInExpertEditTable}
            />
        ),
        [COLUMNS.DATE]: (params) => <DateCell {...params} />,
        [COLUMNS.EXPERT_MAIL]: (params) => (
            <TextCell field={params.field} text={params.row[params.field]} />
        ),
        [COLUMNS.ACTIONS]: (params) => (
            <ActionsCell rows={getActionsPopoverRows(params.row)} />
        )
    };

    return {
        columnComponents
    };
};
