import { SearchInput } from 'aqui';
import { FunctionComponent, useEffect, useState } from 'react';
import {
    useFeedbacksFilters,
    useFeedbacksRows,
    useMoreOptionsRowsFeedbacks
} from './useFeedbacksHooks';
import FeedbacksFilters from './FeedbacksFilters';
import MoreOptionsButton from '../../MainHeader/MoreOptionsButton';
import { DATA_MANAGEMENT_SEARCH_DELAY } from '../consts';
import { debounce } from 'lodash';

const FeedbacksHeader: FunctionComponent = () => {
    const { setFeedbacksSearchTerm } = useFeedbacksFilters();
    const { areFeedbacksFetching } = useFeedbacksRows();
    const { rows, hasRowsToShow } = useMoreOptionsRowsFeedbacks();

    const [searchTerm, setSearchTerm] = useState<string>('');

    useEffect(() => {
        debounce(
            () => setFeedbacksSearchTerm(searchTerm),
            DATA_MANAGEMENT_SEARCH_DELAY
        )();
    }, [searchTerm]);

    return (
        <div className="data-management-table__header">
            <FeedbacksFilters />
            <div className="data-management-table__header__section">
                <MoreOptionsButton
                    rows={rows}
                    hasRowsToShow={hasRowsToShow}
                    disabled={areFeedbacksFetching}
                />
                <SearchInput
                    className={'data-management-table__search'}
                    placeholder="Search"
                    value={searchTerm}
                    setValue={setSearchTerm}
                    dataTestId="feedbacks-search"
                    disabled={areFeedbacksFetching}
                />
            </div>
        </div>
    );
};

export default FeedbacksHeader;
