import { FC } from 'react';
import { Toggle } from 'aqui';
import styles from './Cells.module.scss';
import classnames from 'classnames/bind';
import { Tooltip } from '@mui/material';

const cx = classnames.bind(styles);

interface ToggleCellProps {
    field: string;
    className?: string;
    row: any;
    onClick?: () => void;
    tooltip: string;
    disabled?: boolean;
}

const ToggleCell: FC<ToggleCellProps> = ({
    row,
    field,
    className = '',
    onClick,
    tooltip = '',
    disabled = false
}) => {
    return (
        <div
            className={cx(className, 'toggleCell__container')}
            data-testid="toggle-cell"
        >
            <Tooltip
                title={tooltip}
                placement="top"
                classes={{
                    tooltip: 'tooltip'
                }}
            >
                <Toggle
                    checked={row[field]}
                    onClick={onClick}
                    disabled={disabled}
                />
            </Tooltip>
        </div>
    );
};

export default ToggleCell;
