import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'aqui';
import {
    useNotValidEmails,
    usePersonasManagementRows
} from '../PersonaManagementHooks';
import { t } from 'i18next';
import EmailValidationModalBody from './EmailValidationModalBody';
import { useState } from 'react';
import { CreatePersonaData, UpdatePersonaData } from '../../../../types';

export const enum EMAIL_VALIDATION_TYPES {
    NotValid = 'notValid',
    NotExisting = 'notExisting',
    InUse = 'inUse'
}

const EmailValidationModal = () => {
    const [showMoreType, setShowMoreType] =
        useState<null | EMAIL_VALIDATION_TYPES>(null);
    const [selectedEmails, setSelectedEmails] = useState<string[]>([]);

    const { notValidEmails, setNotValidEmails } = useNotValidEmails();
    const {
        personaUpdateOrCreateData,
        setPersonaUpdateOrCreateData,
        updatePersona,
        createPersona
    } = usePersonasManagementRows();
    const persona = Object.keys(notValidEmails)[0];
    const isNotValidEmails = notValidEmails[persona]?.notValidEmails.length > 0;
    const isNotExistingEmails =
        notValidEmails[persona]?.notExistingEmails.length > 0;
    const isEmailsInUse =
        notValidEmails[persona]?.emailsInUse &&
        Object.keys(notValidEmails[persona]?.emailsInUse).length > 0;

    const onClose = () => {
        setNotValidEmails({});
        setShowMoreType(null);
        setSelectedEmails([]);
    };

    const onClickReAssign = () => {
        if (personaUpdateOrCreateData.type === 'update') {
            const newData = {
                ...personaUpdateOrCreateData.data
            } as UpdatePersonaData;
            newData.personas[0].users = [
                ...newData.personas[0].users,
                ...selectedEmails
            ];
            updatePersona(newData.personas, newData.replace);
        } else if (personaUpdateOrCreateData.type === 'create') {
            const newData = {
                ...personaUpdateOrCreateData.data
            } as CreatePersonaData;
            newData.users = [...newData.users, ...selectedEmails];
            createPersona(
                newData.name,
                newData.description,
                newData.users,
                newData.copyFromPersonaId
            );
        }
        setPersonaUpdateOrCreateData(null);
        onClose();
    };

    const onClickLeave = () => {
        if (personaUpdateOrCreateData.type === 'update') {
            const newData = {
                ...personaUpdateOrCreateData.data
            } as UpdatePersonaData;
            updatePersona(newData.personas, newData.replace);
        } else if (personaUpdateOrCreateData.type === 'create') {
            const newData = {
                ...personaUpdateOrCreateData.data
            } as CreatePersonaData;
            createPersona(
                newData.name,
                newData.description,
                newData.users,
                newData.copyFromPersonaId
            );
        }
        setPersonaUpdateOrCreateData(null);
        onClose();
    };

    const footerToShow = () => {
        if (isEmailsInUse) {
            return (
                <div className="flex align-center emailValidationModal__footer">
                    <Button
                        dataTestId="leave-as-previous-btn-email-validation-modal"
                        color="blue"
                        onClick={onClickLeave}
                        text={t('common.leave-as-previously-assigned')}
                        variant="outlined"
                    />
                    <Button
                        dataTestId="re-assign-btn-email-validation-modal"
                        color="blue"
                        onClick={onClickReAssign}
                        text={t('common.re-assign-selected')}
                        variant="filled"
                    />
                </div>
            );
        }
        return (
            <Button
                dataTestId="ok-btn-email-validation-modal"
                color="blue"
                onClick={onClickLeave}
                text={t('common.ok')}
                variant="filled"
            />
        );
    };

    return (
        <Modal
            isOpen={Boolean(Object.keys(notValidEmails).length)}
            closeModal={onClickLeave}
        >
            <ModalHeader>{t('emails-validation.incorrect-format')}</ModalHeader>
            <ModalBody className="emailValidationModal__body">
                <div className="flex flex-col emailValidationModal__container">
                    {isNotValidEmails && (
                        <EmailValidationModalBody
                            emails={notValidEmails[persona].notValidEmails}
                            type={EMAIL_VALIDATION_TYPES.NotValid}
                            persona={persona}
                            showMoreType={showMoreType}
                            setShowMoreType={setShowMoreType}
                        />
                    )}
                    {isNotExistingEmails && (
                        <EmailValidationModalBody
                            emails={notValidEmails[persona].notExistingEmails}
                            type={EMAIL_VALIDATION_TYPES.NotExisting}
                            showMoreType={showMoreType}
                            setShowMoreType={setShowMoreType}
                        />
                    )}
                    {isEmailsInUse && (
                        <EmailValidationModalBody
                            type={EMAIL_VALIDATION_TYPES.InUse}
                            inUseEmails={notValidEmails[persona].emailsInUse}
                            persona={persona}
                            showMoreType={showMoreType}
                            setShowMoreType={setShowMoreType}
                            selectedEmails={selectedEmails}
                            setSelectedEmails={setSelectedEmails}
                        />
                    )}
                </div>
            </ModalBody>
            <ModalFooter>{footerToShow()}</ModalFooter>
        </Modal>
    );
};

export default EmailValidationModal;
