import { FC } from 'react';
import TextButton from '../TextButton/TextButton';
import { DownloadIcon } from 'aqui/icons';
import { t } from 'i18next';

interface SummaryModalFooterProps {
    onExportClick: () => void;
}

const SummaryModalFooter: FC<SummaryModalFooterProps> = ({ onExportClick }) => (
    <TextButton
        dataTestId="exportBtn"
        color="blue"
        text={t('common.export')}
        IconComponent={DownloadIcon}
        onClick={onExportClick}
        typographyType="small1"
    />
);

export default SummaryModalFooter;
