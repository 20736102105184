import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { ChevronIcon } from 'aqui/icons';
import { Typography } from 'aqui';
import InnerThread from './InnerThread';
import { ChatHistoryData } from '../../types';
import { useModel, useThreadsHandling } from '../../AppContext';
import SkeletonContainer from '../SkeletonContainer/SkeletonContainer';
import { useSelector } from 'react-redux';
import ValueWithTooltip from '../ValueWithTooltip/ValueWithTooltip';
import { t } from 'i18next';

type SingleModelThreadProps = {
    modelData: ChatHistoryData;
    modelIndex: number;
};

const SingleModelThread: FunctionComponent<SingleModelThreadProps> = ({
    modelData,
    modelIndex
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [loadingThreads, setLoadingThreads] = useState<boolean>(false);
    const [selectedIndex, setSelectedIndex] = useState<number>(null);

    const {
        selectedThread,
        onSelectedThread,
        getAnotherChunkOfChatHistoryByModel
    } = useThreadsHandling();

    const { isRangeEnabled } = useModel();

    const { chatId } = useSelector((state: any) => state.coPilotReducer);

    const chatIdsForModel = useMemo(() => {
        if (modelData.data.length) {
            return modelData.data.map((chat) => chat.chat_id);
        }
        return [];
    }, [modelData.data.length]);

    useEffect(() => {
        if (!chatIdsForModel.includes(selectedThread) || !selectedThread) {
            setSelectedIndex(null);
        }
    }, [selectedThread]);

    const onClickInnerThread = (
        chatId: string,
        model: string,
        modelSerialNumber: string = '',
        investigationIdFromThread: string = '',
        goToTriage: boolean = false
    ) => {
        onSelectedThread(
            chatId,
            model,
            modelSerialNumber,
            investigationIdFromThread,
            goToTriage
        );
    };

    const onClickShowMore = () => {
        setLoadingThreads(true);
        getAnotherChunkOfChatHistoryByModel(
            modelData.tenant_model,
            modelData.data.length,
            setLoadingThreads
        );
    };

    const getTitle = () => {
        return isRangeEnabled
            ? modelData.data[0].model_serial_number
            : modelData.tenant_model;
    };

    return (
        <div className="singleModelThread__parent-container">
            <div
                className={`flex flex-start singleModelThread__parent ${
                    isOpen ? 'open' : ''
                }`}
                onClick={() => setIsOpen(!isOpen)}
            >
                <ChevronIcon direction="right" />
                <div>
                    <ValueWithTooltip
                        index={modelIndex}
                        title={getTitle()}
                        className="singleModelThread__main-header"
                        type="small1Bold"
                        maxLines={2}
                        key={getTitle()}
                    />
                </div>
            </div>
            {isOpen && (
                <>
                    <div
                        className="flex flex-col singleModelThread__children-container"
                        data-testid="single-model-threads"
                    >
                        {modelData.data.map((thread, index) => {
                            return (
                                <InnerThread
                                    key={thread.chat_id}
                                    index={index}
                                    model={thread.tenant_model}
                                    serialNumber={thread.model_serial_number}
                                    isSelected={
                                        selectedThread === thread.chat_id ||
                                        chatId === thread.chat_id
                                    }
                                    onClickThread={onClickInnerThread}
                                    question={thread.question}
                                    date={thread.created_at}
                                    threadInvestigationId={
                                        thread.investigation_id
                                    }
                                    chatId={thread.chat_id}
                                    modelIndex={modelIndex}
                                    selectedIndex={selectedIndex}
                                    setSelectedIndex={setSelectedIndex}
                                    productId={thread.triage_product_id}
                                    chatSummary={thread.summary}
                                />
                            );
                        })}
                    </div>
                    {loadingThreads && (
                        <SkeletonContainer
                            dots
                            dotsClassName="dateThread__loading"
                        />
                    )}
                    {modelData.has_more && !loadingThreads && (
                        <div
                            className="flex align-center singleModelThread__show-more"
                            onClick={onClickShowMore}
                        >
                            <Typography type="small1">
                                {t('common.show-more')}
                            </Typography>
                            <ChevronIcon direction="down" />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default SingleModelThread;
