import { AxiosError } from 'axios';

export const shouldShowPersonasProcessPendingModal = (error: AxiosError) => {
    const statusCode: number = error?.response?.status;
    const responseDetails: string = error?.response?.data?.detail;
    if (statusCode && responseDetails) {
        return statusCode === 500 && responseDetails.includes('already pending/in-process');
    }
    return false;
};
