import { t } from 'i18next';
import { COLUMNS, IMPORT_COLUMNS } from './consts';
import { CircleInfoIcon, PencilEditIcon } from 'aqui/icons';
import { Tooltip } from '@mui/material';
import {
    ImportedFileDataPersonaManagementRow,
    PersonasManagementFilters,
    PersonasManagementRow
} from '../../../types';
import { prepareDate } from '../../../utils/utils';
import { validateFileColumns } from '../IngestedSources/IngestedSourcesHelpers';

export const getColumnsData = () => [
    {
        field: COLUMNS.PERSONA_NAME,
        headerName: t('add-persona.column-persona-name'),
        minWidth: 190,
        maxWidth: 250,
        flex: 1,
        sortable: false
    },
    {
        field: COLUMNS.DESCRIPTION,
        headerName: t('add-persona.column-description'),
        minWidth: 310,
        flex: 1,
        sortable: false
    },
    {
        field: COLUMNS.SOURCES,
        headerName: t('add-persona.column-sources'),
        minWidth: 140,
        maxWidth: 140,
        sortable: false
    },
    {
        field: COLUMNS.USERS,
        headerName: t('add-persona.column-users'),
        minWidth: 140,
        maxWidth: 230,
        flex: 1,
        sortable: false
    },
    {
        field: COLUMNS.LAST_UPDATED,
        headerName: t('add-persona.column-last-updated'),
        minWidth: 140,
        maxWidth: 140,
        sortable: false
    }
];

export const getUsersCellIcon = (isDefault = false) => {
    if (isDefault) {
        return (
            <Tooltip
                placement="top"
                title={t('add-persona.default-persona-tooltip') as string}
                classes={{
                    tooltip: 'tooltip'
                }}
            >
                <CircleInfoIcon color="#2468ff" />
            </Tooltip>
        );
    }
    return <PencilEditIcon />;
};

export const getFilteredPersonasManagementRows = (
    rows: PersonasManagementRow[],
    searchTerm: string,
    filters: PersonasManagementFilters
) => {
    const filteredRows = rows.filter((row) => {
        const isSearchMatch =
            row.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            row.description.toLowerCase().includes(searchTerm.toLowerCase());
        const isFilterMatch =
            filters.date === '' ||
            prepareDate(filters.date) === prepareDate(row.updated_at);
        return isSearchMatch && isFilterMatch;
    });
    return filteredRows;
};

export const sortPersonaRows = (
    a: PersonasManagementRow,
    b: PersonasManagementRow
) => {
    const personas = ['Default', 'All sources'];

    for (const persona of personas) {
        if (a.name === persona) return -1;
        if (b.name === persona) return 1;
    }

    return a.name.localeCompare(b.name);
};

export const validatePersonaName = (data, personasNames) => {
    const notValidPersonas = new Set();
    const sentencesArr = [];
    data.forEach((row) => {
        const personaName = row['PERSONA NAME'];
        if (!personasNames.includes(personaName)) {
            notValidPersonas.add(`"${personaName}"`);
        }
    });
    if (notValidPersonas.size) {
        sentencesArr.push(
            `${
                notValidPersonas.size > 1 ? 'Personas' : 'Persona'
            }: ${Array.from(notValidPersonas).join(', ')} ${
                notValidPersonas.size > 1
                    ? "are not valid or don't exist."
                    : " is not valid or doesnֿֿֿ't exist"
            }.`
        );
    }
    return sentencesArr;
};

export const validateData = (
    data: ImportedFileDataPersonaManagementRow[],
    personasNames: string[]
) => {
    const coulumsValidation = validateFileColumns(
        IMPORT_COLUMNS,
        Object.keys(data[0])
    );
    if (coulumsValidation.length) {
        return coulumsValidation;
    }
    const sentencesArr = validatePersonaName(data, personasNames);
    if (!sentencesArr.length) {
        return [];
    }
    return sentencesArr;
};
