import { CrossMarkIcon } from 'aqui/icons';
import SendIcon from '../../icons/SendIcon';
import React, {
    FocusEvent,
    FunctionComponent,
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import { doesContainHebrew } from '../../utils/utils';
import { ENTER_KEY_CODE } from '../../consts';
import useAnalytics from '../../hooks/useAnalytics';
import { ANALYTICS_EVENTS } from '../../consts';
import { IntegratedModel } from '../../types';
import SearchSuggestions from '../SearchSuggestions/SearchSuggestions';
import ModelSelect from '../ModelSelect/ModelSelect';
import { debounce } from 'lodash';
import { useSuggestionQuestions } from '../../AppContext';
import Backend from '../../backend/Backend';
import { t } from 'i18next';

const LINE_HEIGHT = 18;

type SearchBarProps = {
    value: string;
    setValue: (filter: string) => void;
    disabled?: boolean;
    submitDisabled?: boolean;
    withSelect?: boolean;
    setSelectedValue?: (filter: IntegratedModel) => void;
    selectedValue?: string;
    placeholder?: string;
    growWithText?: boolean;
    maxRowsToGrow?: number;
    onChange?: (value: string, event: React.ChangeEvent<HTMLElement>) => void;
    onReset?: Function;
    onKeyDown?: (event: React.KeyboardEvent<HTMLElement>) => void;
    onFocus?: (event: React.FocusEvent<HTMLElement>) => void;
    onBlur?: (event: React.FocusEvent<HTMLElement>) => void;
    onGrow?: (chatInputHight: number) => void;
    withClearButton?: boolean;
    onSubmit: (value: string) => void;
    inputMaxLength?: number;
    darkStroke?: boolean;
    dataTestId?: string;
    isSmallMode?: boolean;
    withPresetSuggestions?: boolean;
    modelAutoSelect?: boolean;
};

const SearchBar: FunctionComponent<SearchBarProps> = ({
    value,
    setValue,
    disabled = false,
    submitDisabled = false,
    withSelect = false,
    setSelectedValue,
    selectedValue,
    placeholder = '',
    growWithText = true,
    maxRowsToGrow = 99,
    onChange = null,
    onReset = null,
    onKeyDown = null,
    onFocus = null,
    onBlur = null,
    onGrow = null,
    withClearButton = false,
    onSubmit,
    inputMaxLength = 2000,
    darkStroke = false,
    dataTestId = 'search_bar',
    isSmallMode = false,
    withPresetSuggestions = false,
    modelAutoSelect
}) => {
    const [modelName, setModelName] = useState<string | undefined>(
        selectedValue
    );
    const [suggestionsAnchor, setPrefixSuggestionsAnchor] =
        useState<HTMLInputElement | null>(null);
    const [prefixText, setPrefixText] = useState<string>('');
    const [sendIconWithGradient, setSendIconWithGradient] = useState(false);
    const textInputRefInner = useRef<HTMLTextAreaElement>();
    const inputRef = useRef<HTMLInputElement>();
    const [isSelectOpen, setSelectOpen] = useState(false);
    const { sendEvent } = useAnalytics();
    const { setSuggestionQuestions } = useSuggestionQuestions();
    const sendButtonDisabled = useMemo(
        () => disabled || submitDisabled || !value?.trim(),
        [disabled, submitDisabled, value]
    );

    useLayoutEffect(() => {
        changeTextInputHeight();
    }, [value]);

    useEffect(() => {
        if (!value?.length || disabled) {
            setSendIconWithGradient(false);
        }
    }, [disabled, value]);

    const changeTextInputHeight = () => {
        if (growWithText) {
            if (!value) {
                textInputRefInner.current.style.height = '18px';
                return;
            }
            textInputRefInner.current.style.height = '0px';
            textInputRefInner.current.style.height =
                textInputRefInner.current.scrollHeight + 'px';
            onGrow?.(textInputRefInner.current.scrollHeight);
        }
    };

    const handleOnSelectedValue = (model: IntegratedModel) => {
        setModelName(model.modelName);
        if (setSelectedValue) {
            setSelectedValue(model);
        }
    };

    const debouncedGetSuggestionQuestions = useCallback(
        debounce(async (value, modelName) => {
            try {
                const response = await Backend.getSuggestionQuestions(
                    value,
                    modelName
                );
                const list = response.data.data.map((item) => item.value);
                setSuggestionQuestions(list);
            } catch (error) {
                setSuggestionQuestions([]);
            }
        }, 500),
        []
    );

    const handleOnChange = (e) => {
        let nextValue = e.nativeEvent.target.value;
        if (nextValue.length > inputMaxLength) {
            nextValue = nextValue.slice(0, inputMaxLength);
        }

        if (!nextValue.length || !value?.length) {
            setSuggestionQuestions([]);
        }

        if (nextValue.length > 2) {
            debouncedGetSuggestionQuestions(nextValue, modelName);
        }

        setValue(nextValue);
        onChange?.(nextValue, e.nativeEvent);
    };

    const handleOnReset = () => {
        sendEvent(ANALYTICS_EVENTS.CLICKED_CLEAR_QUESTION);
        setValue('');
        onReset?.();
        setPrefixSuggestionsAnchor(inputRef?.current);
        inputRef?.current.focus();
        if (prefixText) {
            setPrefixText('');
        }
    };

    const handleOnSubmit = () => {
        if (sendButtonDisabled) {
            return;
        }

        onSubmit?.(value?.trim());
        sendEvent(ANALYTICS_EVENTS.SEND_QUESTION_CLICK);
        setPrefixSuggestionsAnchor(null);
    };

    const handleOnSelectClick = () =>
        sendEvent(ANALYTICS_EVENTS.MODEL_DROPDOWN_CLICK);

    const handleOnKeyDown = ({ nativeEvent }) => {
        if (nativeEvent.keyCode === ENTER_KEY_CODE && !nativeEvent.shiftKey) {
            nativeEvent.preventDefault();
            handleOnSubmit();
        }

        onKeyDown?.(nativeEvent);
    };

    const handleOnFocus = (e: FocusEvent<HTMLElement, Element>) => {
        setPrefixSuggestionsAnchor(e.currentTarget as HTMLInputElement);
        onFocus?.(e);
        sendFocusAnalyticsEvent();
    };

    const sendFocusAnalyticsEvent = () => {
        sendEvent(ANALYTICS_EVENTS.SEARCH_FIELD_CLICK);
    };

    const handleOnBlur = (e) => {
        if (onBlur) {
            onBlur(e);
        }
    };

    return (
        <div
            className={`search-bar-border-container ${
                disabled ? '--disabled' : ''
            }`}
        >
            <div
                className={`search-bar ${darkStroke ? '--dark-stroke' : ''} ${
                    disabled ? '--disabled' : ''
                }`}
                data-testid={dataTestId}
            >
                {withSelect && (
                    <div
                        className={`search-bar__action-right ${
                            darkStroke ? '--dark-stroke' : ''
                        } ${isSmallMode ? '--small' : ''} ${
                            isSelectOpen ? '--opened' : ''
                        }`}
                        data-testid={`${dataTestId}_action-right`}
                    >
                        <ModelSelect
                            disabled={disabled}
                            isOpen={isSelectOpen}
                            setIsOpen={setSelectOpen}
                            dataTestId={`${dataTestId}_select`}
                            selectedValue={selectedValue}
                            setSelectedValue={handleOnSelectedValue}
                            transformOrigin={{
                                vertical: -40,
                                horizontal: 24
                            }}
                            onClick={handleOnSelectClick}
                            autoSelect={modelAutoSelect}
                            selectLabel={t('model.model')}
                        />
                    </div>
                )}
                {growWithText ? (
                    <textarea
                        value={value || ''}
                        className={`search-bar__input ${
                            doesContainHebrew(value) ? '--rtl' : ''
                        } ${isSmallMode ? '--small' : ''}`}
                        disabled={disabled}
                        placeholder={placeholder}
                        ref={textInputRefInner}
                        onFocus={handleOnFocus}
                        onKeyDown={handleOnKeyDown}
                        onChange={handleOnChange}
                        onBlur={(e) => onBlur?.(e)}
                        autoComplete="off"
                        data-testid="search_bar_textarea"
                        maxLength={inputMaxLength}
                        style={{
                            maxHeight: `${maxRowsToGrow * LINE_HEIGHT}px`
                        }}
                    />
                ) : (
                    <input
                        ref={inputRef}
                        value={value || ''}
                        className={`search-bar__input ${
                            doesContainHebrew(value) ? '--rtl' : ''
                        } ${isSmallMode ? '--small' : ''}`}
                        disabled={disabled}
                        placeholder={placeholder}
                        onFocus={handleOnFocus}
                        onKeyDown={handleOnKeyDown}
                        onChange={handleOnChange}
                        onBlur={handleOnBlur}
                        autoComplete="off"
                        data-testid="search_bar_input"
                        maxLength={inputMaxLength}
                    />
                )}

                <div
                    className={`search-bar__actions ${
                        isSmallMode ? '--small' : ''
                    }`}
                >
                    {withClearButton && !isSmallMode && (
                        <div
                            className={`search-bar__reset ${
                                !!value?.length ? '--visible' : ''
                            }`}
                            onClick={handleOnReset}
                            data-testid="search_bar_reset_btn"
                        >
                            <CrossMarkIcon />
                        </div>
                    )}
                    <SendIcon
                        onClick={handleOnSubmit}
                        withGradient={sendIconWithGradient}
                        onMouseEnter={() =>
                            !sendButtonDisabled && setSendIconWithGradient(true)
                        }
                        onMouseLeave={() => setSendIconWithGradient(false)}
                        className={`search-bar__send ${
                            sendButtonDisabled ? 'disabled' : ''
                        } ${isSmallMode ? '--small' : ''}`}
                        data-testid="search_bar_send_btn"
                        data-disabled={sendButtonDisabled}
                    />
                    <SearchSuggestions
                        anchorEl={suggestionsAnchor}
                        withPresetSuggestions={withPresetSuggestions}
                        setPrefixText={setPrefixText}
                        value={value}
                        setValue={setValue}
                        setSuggestionsAnchor={setPrefixSuggestionsAnchor}
                    />
                </div>
            </div>
        </div>
    );
};

export default SearchBar;
