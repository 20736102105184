import { FunctionComponent, useMemo } from 'react';
import { Typography } from 'aqui';
import { t } from 'i18next';
import SkeletonContainer from '../SkeletonContainer/SkeletonContainer';
import KAList from './KAList';
import { getNumOfUniqueFiles } from './KASectionHelpers';
import { MAX_KA_ROWS_ON_START_PORTAL } from '../../consts';
import {
    useIntegratedModels,
    useMessages,
    useModel,
    useSelectedMessage
} from '../../AppContext';
import { getRelevantRelatedSources, isEqualStrings } from '../../utils/utils';
import { useSmallChatInPortal } from '../../context/ChatContext';
import { useAskStreamContext } from '../../context/AskStreamContext';

const KASection: FunctionComponent = () => {
    const { messages } = useMessages();
    const { integratedModels } = useIntegratedModels();
    const { isSmallChatInPortal } = useSmallChatInPortal();
    const { model } = useModel();
    const { isStreamInProgress } = useAskStreamContext();
    const { selectedMessage } = useSelectedMessage();

    const { results, relatedResults, searchText, isExpertResponse } =
        useMemo(() => {
            const results = selectedMessage?.sourceDocuments || [];
            const relatedResults = getRelevantRelatedSources(
                selectedMessage?.message || '',
                selectedMessage?.relatedSourceDocuments || []
            );
            const searchText = selectedMessage?.questionId || '';
            const isExpertResponse = selectedMessage?.expertDetails || false;
            return { results, relatedResults, searchText, isExpertResponse };
        }, [selectedMessage]);

    const uniqueFilesNum = useMemo(
        () => getNumOfUniqueFiles(results),
        [results]
    );

    const generatedModel = useMemo(() => model, [results, model]);

    const [resultsPluralSuffix, relatedResultsPluralSuffix] = useMemo(
        () => [
            results?.length > 1 ? 's' : '',
            relatedResults?.length > 1 ? 's' : ''
        ],
        [results, relatedResults]
    );

    if (
        !generatedModel ||
        generatedModel === t('common.all') ||
        !messages.length
    ) {
        return <div className="kaSection__container"></div>;
    }
    if (isStreamInProgress) {
        return (
            <div className="kaSection__container">
                <SkeletonContainer single />
                <div className="withSkeleton">
                    <SkeletonContainer limit={4} />
                </div>
            </div>
        );
    }
    if (
        !model ||
        model === t('common.all') ||
        (!searchText && !results?.length) ||
        isSmallChatInPortal
    ) {
        return <div className="kaSection__container"></div>;
    }

    const getModelName = () => {
        const modelData = integratedModels.find((integratedModel) =>
            isEqualStrings(integratedModel.modelName, generatedModel)
        );
        return modelData?.modelDisplay || modelData?.modelName;
    };

    return (
        <div className="kaSection__container">
            {relatedResults.length ? (
                <div data-testid="ka-section-header">
                    <Typography
                        type="subtitle1"
                        className="kaSection__answer-sources-header"
                    >
                        {`${
                            isExpertResponse
                                ? t('search.original-answer')
                                : t('search.answer')
                        } ${t('search.based-on')} ${relatedResults.length} ${t(
                            `search.source${relatedResultsPluralSuffix}`
                        )}`}
                    </Typography>
                    <Typography type="body3" className="kaSection__header">
                        {`${relatedResults.length} ${t(
                            `search.source${relatedResultsPluralSuffix}-related-to`
                        )}`}
                        <span className="bold">{` "${searchText}" `}</span>
                        {`${t('search.in-model')} `}
                        <span className="bold">{` "${getModelName()}". `}</span>
                    </Typography>
                </div>
            ) : (
                <Typography
                    type="body3"
                    className="kaSection__header"
                    dataTestId="ka-section-header"
                >
                    {`${results.length} ${t(
                        `search.result${resultsPluralSuffix}-from`
                    )} ${uniqueFilesNum} ${t(
                        `search.source${resultsPluralSuffix}-related-to`
                    )}`}
                    <span className="bold">{` "${searchText}" `}</span>
                    {`${t('search.in-model')} `}
                    <span className="bold">{` "${getModelName()}". `}</span>
                    {results?.length ? t('search.sorting-by-relevance') : ''}
                </Typography>
            )}
            <KAList
                sourceDocuments={results}
                relatedSourceDocuments={relatedResults}
                isCoPilotPanel
                maxRowsOnStart={MAX_KA_ROWS_ON_START_PORTAL}
                resultsPluralSuffix={resultsPluralSuffix}
            />
        </div>
    );
};

export default KASection;
