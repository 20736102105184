import { Tooltip } from '@mui/material';
import React, { useLayoutEffect, useMemo, useRef } from 'react';
import { PencilEditIcon } from 'aqui/icons';
import {
    useKAModal,
    useMessages,
    useModel,
    useScopSettings,
    useThreadsHandling
} from '../../../AppContext';
import PersonIconSmall from '../../../SVG/PersonIconSmall';
import MessageEditing from '../MessageEditing/MessageEditing';
import { useSelector } from 'react-redux';
import {
    prepareDate,
    removedEditMarkdownStrings,
    revertChangedMarkdownLinks,
    updateHistoryMessage
} from '../../../utils/utils';
import Backend from '../../../backend/Backend';
import {
    useHistoryMessages,
    useSmallChatInPortal
} from '../../../context/ChatContext';
import { ANALYTICS_EVENTS } from '../../../consts';
import useAnalytics from '../../../hooks/useAnalytics';
import { getIsUserExpertOrAdmin } from '../../../selectors/userPermissionSetsSelectors';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Components } from 'react-markdown';
import { useAskStreamContext } from '../../../context/AskStreamContext';
import { t } from 'i18next';

const MessageChunk = ({
    index,
    segment,
    messageData,
    onRelatedSourceClick,
    clickedEditIndex = false,
    setClickedEditIndex = null,
    messageIndex,
    getTooltipTitle,
    showEditOnNewParagraph = false,
    textArr,
    setExpertEdited = null,
    historyMessageIndex = null
}) => {
    const { messages, setMessages } = useMessages();
    const { model } = useModel();
    const { getIsSourceActive } = useKAModal();
    const { messagesPerSelectedChat, selectedThread } = useThreadsHandling();
    const { isSmallChatInPortal } = useSmallChatInPortal();
    const { sendEvent } = useAnalytics();
    const { historyMessages, setHistoryMessages } = useHistoryMessages();
    const { enableEditResponsesByExperts } = useScopSettings();
    const { isStreamStopped, isStreamInProgress } = useAskStreamContext();

    const { isSmallChat } = useSelector((state: any) => state.coPilotReducer);

    const { fullUserName } = useSelector(
        (state: any) => state.userProfileReducer
    );
    const isUserExpertOrAdmin = useSelector(getIsUserExpertOrAdmin);
    const isEditing = clickedEditIndex === index;
    const textareaRef = useRef<HTMLTextAreaElement>();

    useLayoutEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height =
                textareaRef.current.scrollHeight + 'px';
        }
    }, [textareaRef.current, isEditing]);

    useLayoutEffect(() => {
        if (textareaRef.current) {
            if (
                textareaRef.current.scrollHeight >
                textareaRef.current.clientHeight
            ) {
                textareaRef.current.style.height =
                    25 + textareaRef.current.scrollHeight + 'px';
            }
        }
    }, [textareaRef?.current?.scrollHeight]);

    const updateMessages = (newTextArr) => {
        const message = messages[messageIndex];
        const question = messages[messageIndex - 1].message;

        const textWithoutMarkdownedLinks = revertChangedMarkdownLinks(
            newTextArr.join('')
        );

        Backend.updateResponseByExpert(
            message.chatID,
            message.rowID,
            model,
            question,
            message.condensedQuestion,
            textWithoutMarkdownedLinks,
            message.productDetails?.productIds?.productId,
            fullUserName
        );
        if (historyMessageIndex !== null) {
            updateHistoryMessage(
                historyMessages,
                setHistoryMessages,
                messageIndex,
                historyMessageIndex,
                textWithoutMarkdownedLinks,
                fullUserName
            );
        } else {
            setMessages((prevMessages) => {
                return prevMessages.map((message, index) => {
                    if (index === messageIndex) {
                        return {
                            ...message,
                            message: textWithoutMarkdownedLinks,
                            expertDetails: {
                                name: fullUserName,
                                date: prepareDate(new Date())
                            }
                        };
                    }
                    return message;
                });
            });
        }
    };

    const allowEdit = () => {
        return (
            !isStreamStopped &&
            !isStreamInProgress &&
            enableEditResponsesByExperts &&
            isUserExpertOrAdmin &&
            !isSmallChatInPortal &&
            !isSmallChat &&
            clickedEditIndex === null &&
            (!selectedThread ||
                messagesPerSelectedChat[selectedThread] - 1 < messageIndex)
        );
    };

    const onClickEdit = () => {
        sendEvent(ANALYTICS_EVENTS.CLICKED_EDIT_RESPONSE, {
            'Editing type': 'partial',
            'Editing location': 'UI'
        });
        setClickedEditIndex(index);
    };

    const onClickCancel = () => {
        setClickedEditIndex(null);
    };

    const onClickSave = (newText) => {
        if (!newText) {
            const indexesToDelete = [index];
            for (let i = index + 1; i < textArr.length; i++) {
                if (textArr[i] === '\n\n') {
                    indexesToDelete.push(i);
                } else {
                    break;
                }
            }
            const newTextArr = textArr.filter(
                (_, i) => !indexesToDelete.includes(i)
            );
            updateMessages(newTextArr);
        } else {
            newText += ' EXPERT_EDIT';
            const newTextArr = textArr;
            newTextArr[index] = newText;
            updateMessages(newTextArr);
        }
        setClickedEditIndex(null);
        setExpertEdited(true);
    };

    const sourceHandle = (sourceIndex) => {
        const relatedSource =
            messageData?.relatedSourceDocuments?.[sourceIndex - 1];
        const isTooltipDisabled =
            !messageData?.relatedSourceDocuments?.length || !relatedSource;
        const isActive = getIsSourceActive(relatedSource);
        return (
            <Tooltip
                title={getTooltipTitle(relatedSource)}
                disableHoverListener={isTooltipDisabled}
                disableTouchListener
                disableFocusListener={isTooltipDisabled}
                placement="top"
                classes={{
                    tooltip: 'tooltip'
                }}
                key={relatedSource}
            >
                <span
                    className={`bold chatMessage__source-number ${
                        !relatedSource || !relatedSource.metadata.source
                            ? '--disabled'
                            : ''
                    } ${isActive ? '--active' : ''}`}
                    data-testid="answer-related-source-number"
                    onClick={() => onRelatedSourceClick(relatedSource)}
                >
                    {sourceIndex}
                </span>
            </Tooltip>
        );
    };

    const expertEditHandle = () => {
        return (
            <span className="chatMessage__source-btns__edited-and-edit">
                <Tooltip
                    title={t('chat.paragraph-edited-by-expert') as string}
                    placement="top"
                    classes={{
                        tooltip: 'tooltip tooltip__threadsPanel'
                    }}
                >
                    <span className="chatMessage__source-btns__edited">
                        <PersonIconSmall />
                    </span>
                </Tooltip>
            </span>
        );
    };

    const textSegment = useMemo(() => {
        if (isEditing) {
            return (
                <MessageEditing
                    text={removedEditMarkdownStrings(segment)}
                    onClickCancel={onClickCancel}
                    onClickSave={onClickSave}
                />
            );
        }
        const components: Partial<Components> = {
            a: ({ ...props }) => {
                if (props.href.includes('SOURCE_')) {
                    const sourceIndex = props.href.split('SOURCE_')[1];
                    return sourceHandle(parseInt(sourceIndex));
                }
                if (props.href.includes('EXPERT_EDIT')) {
                    return expertEditHandle();
                }
                if (props.href.includes('EDIT_ICON')) {
                    if (allowEdit()) {
                        return showEditButton();
                    }
                    return null;
                }
                return (
                    <a
                        href={props.href}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="custom-link-class"
                        {...props}
                    >
                        {props.children}
                    </a>
                );
            }
        };
        if (segment === '\n\n') {
            return (
                <div
                    className={`markdown-br ${
                        isSmallChatInPortal || isSmallChat ? 'small-chat' : ''
                    }`}
                ></div>
            );
        }

        return (
            <div
                className={`chatMessage__segemnt markdown-container ${
                    isSmallChatInPortal || isSmallChat ? 'small-chat' : ''
                }`}
                style={{ display: 'inline-block', whiteSpace: 'normal' }}
                key={index}
            >
                <ReactMarkdown
                    components={components}
                    remarkPlugins={[remarkGfm]}
                >
                    {segment}
                </ReactMarkdown>
            </div>
        );
    }, [
        segment,
        clickedEditIndex,
        isSmallChatInPortal,
        isSmallChat,
        messageData.relatedSourceDocuments
    ]);

    const showEditButton = () => {
        return (
            <PencilEditIcon
                className={`chatMessage__source-btns__edit `}
                onClick={() => onClickEdit()}
            />
        );
    };

    return textSegment;
};

export default MessageChunk;
