import { Typography } from 'aqui';
import InnerThread from './InnerThread';
import React, { useEffect, useMemo, useState } from 'react';
import { useThreadsHandling } from '../../AppContext';

import { getDatePeriod } from '../../utils/utils';
import SkeletonContainer from '../SkeletonContainer/SkeletonContainer';
import { useSelector } from 'react-redux';
import { t } from 'i18next';

export const ALL_MODELS_INDEX_KEY = 'ModelsByDate';

const DateThreads = () => {
    const [numOfThreadsToShow, setNumOfThreadsToShow] = useState<number>(10);
    const [loadingThreads, setLoadingThreads] = useState<boolean>(false);
    const [selectedIndex, setSelectedIndex] = useState<number>(null);

    const dateHeaders: string[] = [];

    const {
        chatHistoryData,
        getAnotherChunkOfChatHistory,
        selectedThread,
        onSelectedThread
    } = useThreadsHandling();

    const { chatId } = useSelector((state: any) => state.coPilotReducer);

    useEffect(() => {
        if (!selectedThread) {
            setSelectedIndex(null);
        }
    }, [selectedThread]);

    const limitedChatHistoryData = useMemo(() => {
        if (chatHistoryData[0]?.data.length) {
            return chatHistoryData[0]?.data.slice(0, numOfThreadsToShow);
        }
        return [];
    }, [chatHistoryData[0]?.data?.length, numOfThreadsToShow]);

    const onClickInnerThread = (
        chatId: string,
        model: string,
        serial_number: string = '',
        investigationIdFromThread: string = '',
        goToTriage: boolean = false
    ) => {
        onSelectedThread(chatId, model, serial_number, investigationIdFromThread, goToTriage);
    };

    const handleShowMoreThreads = () => {
        const threadsLeft =
            chatHistoryData[0]?.data.length - numOfThreadsToShow;
        if (threadsLeft >= 10) {
            setNumOfThreadsToShow(numOfThreadsToShow + 10);
        } else if (threadsLeft < 10) {
            setNumOfThreadsToShow(numOfThreadsToShow + 10);
            if (chatHistoryData[0]?.has_more) {
                setLoadingThreads(true);
                getAnotherChunkOfChatHistory(
                    chatHistoryData[0]?.data.length,
                    setLoadingThreads
                );
            }
        }
    };

    const isShowMoreAvailible = () => {
        return (
            (chatHistoryData[0]?.has_more ||
                chatHistoryData[0]?.data.length >= numOfThreadsToShow) &&
            !loadingThreads
        );
    };

    const addDateHeader = (date: string) => {
        return getDatePeriod(date, dateHeaders);
    };

    return (
        <div data-testid="date-threads">
            {limitedChatHistoryData.map((thread, index) => {
                return (
                    <React.Fragment key={index}>
                        {addDateHeader(thread.created_at)}
                        <InnerThread
                            key={thread.chat_id}
                            index={index}
                            isSelected={
                                selectedThread === thread.chat_id ||
                                chatId === thread.chat_id
                            }
                            onClickThread={onClickInnerThread}
                            question={thread.question}
                            model={thread.tenant_model}
                            serialNumber={thread.model_serial_number}
                            date={thread.created_at}
                            threadInvestigationId={thread.investigation_id}
                            chatId={thread.chat_id}
                            selectedIndex={selectedIndex}
                            setSelectedIndex={setSelectedIndex}
                            productId={thread.triage_product_id}
                            chatSummary={thread.summary}
                        />
                    </React.Fragment>
                );
            })}
            {loadingThreads && (
                <SkeletonContainer dots dotsClassName="dateThread__loading" />
            )}
            {isShowMoreAvailible() && (
                <Typography
                    className="dateThread__show-more-btn"
                    onClick={handleShowMoreThreads}
                    type="small1"
                >
                    {t('common.show-more')}
                </Typography>
            )}
        </div>
    );
};

export default DateThreads;
