import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';

import { translations } from './i18n';

translations(window?.__triageStore?.orgLng || 'en');

const Loader = () => <div style={{ display: 'none' }}></div>;
const root = ReactDOM.createRoot(document.getElementById('copilot-root'));

root.render(
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
        <Provider store={window.__triageStore}>
            <React.StrictMode>
                <Suspense fallback={<Loader />}>
                    <App />
                </Suspense>
            </React.StrictMode>
        </Provider>
    </Sentry.ErrorBoundary>
);
reportWebVitals();
