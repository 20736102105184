const SmallCircleDoubleChevronIcon = ({ color = 'currentColor', ...props }) => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <rect width="16" height="16" rx="8" fill={color} />
        <path
            d="M8.84155 5.5L11.5416 8.2L8.84155 10.9"
            stroke="white"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M5.19995 5.5L7.89995 8.2L5.19995 10.9"
            stroke="white"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default SmallCircleDoubleChevronIcon;
