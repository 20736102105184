import DateCell from '../../Table/Cells/DateCell';
import { COLUMNS } from './consts';
import TextCell from '../../Table/Cells/TextCell';
import { t } from 'i18next';
import ClickableCell from '../../Table/Cells/ClickableCell';
import { useScopSettings, useThreadsHandling } from '../../../AppContext';
import { getFeedbackValueIcon, getResponseText } from './FeedbacksHelper';
import DropdownCell from '../../Table/Cells/DropdownCell';
import FeedbackStatusOption from './FedbackStatusOption';
import FeedbackReviewStatusMenu from './FeedbackReviewStatusMenu';
import EditableCell from '../../Table/Cells/EditableCell';
import { useEditedResponse, useFeedbacksRows } from './useFeedbacksHooks';
import ChipsCell from '../../Table/Cells/ChipsCell';
import { PencilEditIcon } from 'aqui/icons';
import { adjustIntegratedModel } from '../../../utils/productUtils';
import TextButton from '../../TextButton/TextButton';
import SourceDocumentCell from '../../Table/Cells/SourceDocumentCell';

export const useFeedbacksTable = () => {
    const { onSelectedThread } = useThreadsHandling();
    const { updateFeedbacksRow } = useFeedbacksRows();
    const { setEditedFeedbackRow } = useEditedResponse();
    const { allowUserToEditInExpertEditTable } = useScopSettings();

    const columnComponents = {
        [COLUMNS.MODEL]: (params) => (
            <TextCell
                field={params.field}
                text={params.row.modelDisplay}
                permanentTooltipText={
                    params.row.integratedModel.productTypeName
                        ? adjustIntegratedModel(params.row.integratedModel)
                        : undefined
                }
            />
        ),
        [COLUMNS.SOURCE]: (params) => (
            <SourceDocumentCell
                {...params}
                sourceDocument={params.row[params.field]}
            />
        ),
        [COLUMNS.INITIAL_QUESTION]: (params) => (
            <ClickableCell
                {...params}
                onClick={() =>
                    onSelectedThread(
                        params.row.chat_id,
                        params.row.tenant_model
                    )
                }
                withArrow
                arrowTooltip={t('expert-responses-dm.open-chat')}
            />
        ),
        [COLUMNS.FEEDBACK_TYPE]: (params) => (
            <TextCell field={params.field} text={params.row[params.field]} />
        ),
        [COLUMNS.FEEDBACK_VALUE]: (params) => (
            <TextCell
                field={params.field}
                text={params.row[params.field]}
                icon={getFeedbackValueIcon(params.row[params.field])}
                iconPosition="left"
            />
        ),
        [COLUMNS.QUESTION]: (params) => (
            <TextCell field={params.field} text={params.row[params.field]} />
        ),
        [COLUMNS.CREATED_AT]: (params) => <DateCell {...params} />,
        [COLUMNS.USERNAME]: (params) => (
            <TextCell field={params.field} text={params.row[params.field]} />
        ),
        [COLUMNS.PLATFORM]: (params) => (
            <TextCell field={params.field} text={params.row[params.field]} />
        ),
        [COLUMNS.RESPONSE]: (params) => (
            <TextCell
                onClick={() => setEditedFeedbackRow(params.row)}
                field={params.field}
                maxTooltipRows={5}
                text={getResponseText(
                    params.row[params.field],
                    params.row.no_response
                )}
                className={`feedback-dm__response
                    ${params.row.no_response ? ' no-answer' : ''}
                    `}
                icon={
                    <TextButton
                        text={
                            allowUserToEditInExpertEditTable
                                ? t('edit-response.view-edit')
                                : t('common.view')
                        }
                        IconComponent={PencilEditIcon}
                        color="blue"
                    />
                }
            />
        ),
        [COLUMNS.CONCISE]: (params) => (
            <TextCell
                text={
                    params.row[params.field] ? t('common.yes') : t('common.no')
                }
                field={params.field}
            />
        ),
        [COLUMNS.PERSONA]: (params) => (
            <TextCell text={params.row[params.field]} field={params.field} />
        ),
        [COLUMNS.FEEDBACK_CATEGORIES]: (params) => (
            <ChipsCell chips={params.row[params.field]} maxChipsToShow={4} />
        ),
        [COLUMNS.FEEDBACK_TEXT]: (params) => (
            <TextCell text={params.row[params.field]} field={params.field} />
        ),
        [COLUMNS.REVIEW_STATUS]: (params) => (
            <DropdownCell
                title={
                    <FeedbackStatusOption status={params.row[params.field]} />
                }
                content={
                    <FeedbackReviewStatusMenu
                        selectedStatus={params.row[params.field]}
                        rowid={params.row.id}
                    />
                }
            />
        ),
        // [COLUMNS.EDITED_BY_EXPERT]: (params) => (
        //     <TextCell
        //         text={params.row[params.field] ? t('common.yes') : t('common.no')}
        //         field={params.field}
        //     />
        // ),
        [COLUMNS.NOTES]: (params) => (
            <EditableCell
                field={params.field}
                row={params.row}
                id={params.row.id}
                onSubmit={(value: string) =>
                    updateFeedbacksRow(params.row.id, { notes: value })
                }
                placeholder={t('feedbacks-dm.note-placeholder')}
                width={params.colDef.width}
            />
        )
    };

    return {
        columnComponents
    };
};
