import { t } from 'i18next';
import CheckboxCell from '../../Table/Cells/CheckboxCell';
import TextCell from '../../Table/Cells/TextCell';
import DateCell from '../../Table/Cells/DateCell';
import IngestStatusCell from '../../Table/Cells/IngestStatusCell';
import ActionsCell from '../../Table/Cells/ActionsCell';
import { useEffect, useRef, useState } from 'react';
import {
    getFilteredIngestedSourceRows,
    getIsDeleteButtonDisabled,
    ingestedSourceRowToSourceDocument
} from './IngestedSourcesHelpers';
import { IngestedSourcesRow } from '../../../types';
import {
    useFilteredIngestedSourcesRows,
    useIngestedSourceRows,
    useIngestedSourcesActions,
    useIngestedSourcesFilters,
    useSelectedIngestedSourceRows
} from './IngestedSourcesHooks';
import {
    useScopSettings,
    useDownloadSource,
    useServerlessIndex
} from '../../../AppContext';
import {
    useDataManagementContext,
    useIsPersonasEnabled
} from '../../../context/DataManagementContext';
import useAnalytics from '../../../hooks/useAnalytics';
import MultiSelectCell from '../../Table/Cells/MultiSelectCell';
import { COLUMNS } from './consts';
import { usePersonasManagementRows } from '../PersonasManagement/PersonaManagementHooks';
import { ANALYTICS_EVENTS, ANALYTICS_EVENT_PARAMS_KEYS } from '../../../consts';
import SourceDocumentCell from '../../Table/Cells/SourceDocumentCell';

export const useIngestedSourcesTable = () => {
    const {
        ingestedSourceRows,
        fetchIngestedSourceRowsAndPersonas,
        areIngestedSourcesFetching
    } = useIngestedSourceRows();

    const [rowToConfirmDeletion, setRowToConfirmDeletion] =
        useState<IngestedSourcesRow>(null);
    const {
        selectedIngestedSourceRows,
        setSelectedIngestedSourceRows,
        selectedIngestedSourceRowIdsSet
    } = useSelectedIngestedSourceRows();

    const { ingestedSourceSearchTerm, ingestedSourcesFilters } =
        useIngestedSourcesFilters();

    const { ingestSource } = useIngestedSourcesActions();
    const { setFilteredIngestSourcesRows } = useFilteredIngestedSourcesRows();
    const { ingestedSourcesSortModel, setIngestedSourcesSortModel } =
        useDataManagementContext();
    const { personasNames, personaNameToId, personaIdToName } =
        usePersonasManagementRows();
    const { isPersonasEnabled } = useIsPersonasEnabled();
    const { enableSourceImportByAdminAndExpert } = useScopSettings();
    const { downloadSource, downloadInProgressFileList } = useDownloadSource();
    const { isServerlessIndex } = useServerlessIndex();
    const { sendEvent } = useAnalytics();

    useEffect(() => {
        fetchIngestedSourceRowsAndPersonas();
    }, []);

    useEffect(() => {
        setFilteredIngestSourcesRows(
            getFilteredIngestedSourceRows(
                ingestedSourceRows,
                ingestedSourcesFilters,
                ingestedSourceSearchTerm,
                personaNameToId,
                personaIdToName
            )
        );
    }, [ingestedSourceSearchTerm, ingestedSourcesFilters, ingestedSourceRows]);

    const downloadInProgressFileListRef = useRef(downloadInProgressFileList);

    useEffect(() => {
        downloadInProgressFileListRef.current = downloadInProgressFileList;
    }, [downloadInProgressFileList]);

    const onClickDownload = (row) => {
        downloadSource(null, row.document_name, row.tenant_model);

        sendEvent(ANALYTICS_EVENTS.DOWNLOAD_SOURCE, {
            'Source name': row.document_name,
            'Download Type':
                ANALYTICS_EVENT_PARAMS_KEYS.SOURCE_PAGE_DOWNLOAD_TYPE
        });
    };

    const getActionsPopoverRows = (row) => {
        return [
            {
                show: true,
                title: t('common.download'),
                onClick: () => {
                    onClickDownload(row);
                },
                isProcessing: downloadInProgressFileListRef.current.has(
                    row.document_name + row.tenant_model
                )
            },
            {
                show: enableSourceImportByAdminAndExpert,
                title: t('common.delete'),
                onClick: () => setRowToConfirmDeletion(row),
                isDisabled:
                    getIsDeleteButtonDisabled(row.status) || isServerlessIndex
            }
        ];
    };

    const columnComponents = {
        [COLUMNS.CHECKBOX]: (params) => (
            <CheckboxCell
                selectedRows={selectedIngestedSourceRows}
                setSelectedRows={setSelectedIngestedSourceRows}
                row={params.row}
            />
        ),
        [COLUMNS.FILE_NAME]: (params) => (
            <SourceDocumentCell
                {...params}
                isSelected={selectedIngestedSourceRowIdsSet.has(
                    params?.row?.id
                )}
                sourceDocument={ingestedSourceRowToSourceDocument(params.row)}
                hidePageIndication
            />
        ),
        [COLUMNS.MODEL_DISPLAY]: (params) => (
            <TextCell field={params.field} text={params.row.modelDisplay} />
        ),
        [COLUMNS.CREATED_AT]: (params) => <DateCell {...params} />,
        [COLUMNS.PERSONAS]: (params) => (
            <MultiSelectCell
                data={personasNames}
                existingData={params.row?.personas?.map(
                    (id: string) => personaIdToName[id]
                )}
                {...params}
            />
        ),
        [COLUMNS.CREATED_BY]: (params) => (
            <TextCell field={params.field} text={params.row.created_by} />
        ),
        [COLUMNS.INGEST_STATUS]: (params) => (
            <IngestStatusCell
                {...params}
                isSelected={selectedIngestedSourceRowIdsSet.has(
                    params?.row?.id
                )}
                onButtonClick={() => ingestSource(params?.row)}
            />
        ),
        [COLUMNS.ACTIONS]: (params) => (
            <ActionsCell rows={getActionsPopoverRows(params.row)} />
        )
    };

    return {
        columnComponents,
        rowToConfirmDeletion,
        setRowToConfirmDeletion,
        areIngestedSourcesFetching,
        ingestedSourcesSortModel,
        setIngestedSourcesSortModel,
        isCheckboxColumnVisible: isPersonasEnabled && !isServerlessIndex
    };
};
