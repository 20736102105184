import { Tooltip } from '@mui/material';
import classnames from 'classnames/bind';
import { Typography } from 'aqui';
import { CrossMarkIcon } from 'aqui/icons';

import styles from './TableFloatingMenu.module.scss';
import { useMemo } from 'react';
import FloatingMenuItem from '../../Common/FloatingMenu/FloatingMenuItem';
import { t } from 'i18next';

const cx = classnames.bind(styles);

interface Props {
    selectedRows: any[];
    viewRows: any[];
    setSelectedRows: (rows: any[]) => void;
}

const LabelsMenuItem: React.FC<Props> = ({
    selectedRows,
    viewRows,
    setSelectedRows
}) => {
    const numberOfSelectedItems = selectedRows?.length;
    const viewRowIds = useMemo(
        () => new Set(viewRows.map((row) => row.id)),
        [viewRows]
    );
    const hiddenSelectedItems = useMemo(
        () => selectedRows.filter((row) => !viewRowIds.has(row.id)),
        [selectedRows, viewRowIds]
    );
    const numberOfHiddenSelectedItems = hiddenSelectedItems.length;
    const shouldShowHiddenItemsSection = !!numberOfHiddenSelectedItems;

    const handleClear = () => {
        setSelectedRows([]);
    };

    return (
        <FloatingMenuItem dataTestId="floatingMenuActionSelection">
            <Tooltip
                classes={{ tooltip: 'tooltip' }}
                title={t('data-management.deselect-all') as string}
            >
                <CrossMarkIcon
                    dataTestId="floatingMenuActionRemoveSelection"
                    onClick={handleClear}
                    className={cx('view-floating-menu__close-button')}
                />
            </Tooltip>
            <Typography
                type="small1"
                className={cx('view-floating-menu__label')}
            >
                {`${numberOfSelectedItems} ${t('data-management.selected')}`}
            </Typography>
            {shouldShowHiddenItemsSection ? (
                <Typography
                    type="small1Bold"
                    className={cx('view-floating-menu__label')}
                >
                    {`${numberOfHiddenSelectedItems} ${t(
                        'data-management.hidden'
                    )}`}
                </Typography>
            ) : null}
        </FloatingMenuItem>
    );
};

export default LabelsMenuItem;
