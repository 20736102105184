export const ENTER_KEY_CODE = 13;
export const MAX_KA_ROWS_ON_START_TRIAGE = 5;
export const MAX_KA_ROWS_ON_START_PORTAL = 10;
export const MAX_MODELS_IN_PICKLIST = 200;
export const MAX_MODELS_TO_FETCH = 4000;
export const SHOW_MODEL_SEARCH_THRESHOLD = 5;
export const NO_MODEL_MESSAGE_MAX_MODELS_TO_SHOW = 6;
export const SMALL_SCREEN_WIDTH = 1440;

// Custom logos
export const SIDE_PANEL_LOGO_MAX_HEIGHT = 30;
export const MAIN_LOGO_MAX_HEIGHT = 125;
export const SMALL_CHAT_LOGO_MAX_HEIGHT = 28;
export const SIDE_PANEL_LOGO_MAX_WIDTH = 282;
export const MAIN_LOGO_MAX_WIDTH = 700;
export const SMALL_CHAT_LOGO_MAX_WIDTH = 192;

// Stream markers
export const HEADER_MARKER_BEGIN = '___SCOP_RESPONSE_HEADER_BEGIN___';
export const HEADER_MARKER_END = '___SCOP_RESPONSE_HEADER_END___';
export const METADATA_MARKER_BEGIN = '___SCOP_RESPONSE_METADATA_BEGIN___';
export const METADATA_MARKER_END = '___SCOP_RESPONSE_METADATA_END___';
export const SCOP_ERROR_RESPONSE_MARKER = '___SCOP_RESPONSE_ERROR___';

export const ANALYTICS_EVENTS = {
    SEARCH_FIELD_CLICK: 'Searched field click',
    SEND_QUESTION_CLICK: 'Sent question',
    KNOWLEDGE_SOURCE_CLICK: 'Knowledge source click',
    SHOW_SOURCES_CLICK: 'Clicked results from sources',
    MODEL_DROPDOWN_CLICK: 'Clicked model dropdown',
    SELECT_DIFFERENT_MODEL_CLICK: 'Select different model click',
    LIKE_ANSWER_CLICK: 'Clicked like',
    DISLIKE_ANSWER_CLICK: 'Clicked dislike',
    COPY_TO_CLIPBOARD_CLICK: 'Clicked copy to clipboard',
    TIME_SPENT_PORTAL: 'Time spent on portal',
    TIME_SPENT_PORTAL_CHAT: 'Time spent on a portal chat',
    CLICKED_MENU_ITEM: 'Clicked menu item',
    EXPAND_ICON_SCOP_MINIMIZED_CLICK:
        'Expand icon in SCOP minimized panel click',
    MINIMIZE_ICON_SCOP_PANEL_CLICK: 'Minimize icon in SCOP panel click',
    SOURCE_TRIAGE_CLICK: 'Source click',
    RECOMMENDED_QUESTION_CLICK: 'Clicked recommended question',
    EDIT_SUGGESTION_QUESTION: 'Clicked edit on hyperlink suggestions',
    SOURCE_PANEL_CLICK_SOURCE_EXPAND: 'Source panel - Click source expand',
    SOURCE_PANEL_CLICK_PAGE_NUMBER: 'Source panel - Click page number',
    SOURCE_WINDOW_TIME_SPENT_ON_SOURCE: 'Source window - Time spent on source',
    SOURCE_PANEL_CLICK_SHOW_MORE: 'Source Panel - Click Show More',
    STOP_GENERATING: 'Click Stop Generating',
    RESUME_GENERATING: 'Click Resume Generating',
    RESPONSE_DISPLAYED: 'Response displayed',
    FEEDBACK_MODAL_SUBMIT_CLICK: 'Scop Feedback popup - Submit',
    FEEDBACK_MODAL_SKIP_CLICK: 'Scop Feedback popup - Skip',
    PICKED_Q_PRESET: 'SCOP Pick a q pre-set',
    CLEAR_Q_PRESET: 'Click clear after picking a q pre-set ',
    PORTAL_LOADED: 'Portal - Page loaded',
    SCOP_ICON_CLICK: 'Clicked Ask Service Co-Pilot',
    CLICKED_INVESTIGATE_IN_TRIAGE: 'Clicked Investigate in Triage',
    SELECT_SPECIFIC_MODEL: 'Selected specific model',
    CLICKED_LOAD_FULL_DOCUMENT: 'Clicked load full document',
    CLICKED_NEXT_DOC_RESULT: 'Clicked next result',
    CLICKED_PREVIOUS_DOC_RESULT: 'Clicked previous result',
    CLICKED_THREE_DOT_MENU: 'Clicked three dot menu',
    CLICKED_CLOSE_KNOWLEDGE_SOURCE: 'Clicked close knowledge source',
    CLICKED_COMMENT_FIELD: 'Clicked comment field',
    CLICKED_ALTERNATIVE_QUESTION: 'Clicked alternative question',
    CLICKED_KNOWLEGDE_BAR: 'Clicked knowledge bar',
    CLICKED_FEEDBACK_TAG: 'Clicked feedback tag',
    CHECKED_DONT_ASK_AGAIN: "Checked don't ask again",
    CLICKED_CLEAR_FEEDBACK: 'Clicked clear feedback',
    SOURCE_PANEL_CLICK_SHOW_LESS: 'Source Panel - Click Show Less',
    CLICKED_CLEAR_QUESTION: 'Clicked clear question',
    CLICKED_MAGIC_BUTTON: 'Clicked magic icon on response',
    MAGIC_BUTTON_OPTION: 'Magic icon picking an option',
    NEW_CHAT_CLICK: 'Clicking on new chat',
    ON_SORT_THREADS: 'Order threads by dates or models',
    CLICKED_THREAD: 'Previous chat threads navigation',
    CLICKED_INVESTIGATION_ON_THREAD: 'Triage navigation',
    CLICKED_EDIT_RESPONSE: 'Clicked edit response',
    CLICKED_CANCEL_ON_EDIT_MODE: 'Clicked X edit mode',
    CLICKED_SAVE_ON_EDIT_MODE: 'Clicked V edit mode',
    DISCLAIMER_VIEWED: 'Disclaimer Viewed',
    DISCLAIMER_EXPANDED: 'Disclaimer Expanded',
    DISCLAIMER_COLLAPSED: 'Disclaimer Collapsed',
    DISCLAIMER_LINK_CLICKED: 'Clicked disclaimer Link',
    SELECTED_RESPONSE_LENGTH: 'Selected response length',
    DOWNLOAD_SOURCE: 'Source downloaded',
    RESPONSE_TEXT_MARKED: 'Response text marked'
};

export const ANALYTICS_EVENT_PARAMS_KEYS = {
    QUESTION_VALUE: 'Question Value',
    MARKED_TEXT: 'Marked text',
    SOURCE_PAGE_DOWNLOAD_TYPE: 'source page',
    SOURCE_TABLE_DOWNLOAD_TYPE: 'source table',
    RESPONSE_MARK_TYPE: 'response',
    SOURCE_MARK_TYPE: 'source'
};

export const SPECIAL_DIV_IDS = {
    CHAT: 'chat__conversation'
};
export const REMOTE_ACTIONS = {
    VERIFY_SCOP_MODEL: 'verifyScopModel',
    CREATE_CHAT_INVESTIGATION_MAPPING: 'createChatInvestigationMapping',
    GET_CHAT_ID: 'getChatId',
    CREATE_CHAT_ID: 'createChatId',
    GET_CATALOG_PRODUCTTYPE: 'getCatalogProductType',
    GET_ADDITIONAL_CHAT_HISTORY_DATA: 'getAdditionalChatHistoryData',
    GET_CATALOG_PRODUCT: 'getCatalogProduct',
    GET_EXISTING_USERS_FROM_COLLECTION: 'getExistingUsersFromCollection'
} as const;

export const NON_PROD_SUBSTRINGS = ['-dev', '-qa', '-staging'];

// Feedback:
export const MAX_FREE_TEXT_FEEDBACK_LENGTH = 1000;
export const FEEDBACK_BUTTONS_ANIMATION_DELAY = [500, 1200, 1900];
export const FEEDBACK_BUTTONS_SHOW_TOOLTIP_DELAY = 800;
export const FEEDBACK_BUTTONS_HIDE_TOOLTIP_DELAY = 3000;

//Upload files
export const MAX_NUMBER_OF_FILES_TO_INGEST = 20;
export const MAX_FILE_SIZE_TO_INGEST_IN_MB = 200;
export const VALID_FILE_TYPES_TO_INGEST = ['PDF', 'MP4'];
export const MAX_MODEL_NAME_LENGTH = 80;

// Disclaimers
export const LINK_REGEX = /\[([^\]]+)\]\(([^)]+)\)/g;

// TODO: Temporary code - Needs to be removed once 115 is released
export const RESTRICTED_ORG_IDS = ['00Di0000000KFvAEAW', '00D8N000000u35WUAQ'];
