import SidePanel from '../../../SidePanel/SidePanel';
import {
    useAddUsersModal,
    useNotValidEmails,
    usePersonasManagementRows
} from '../PersonaManagementHooks';
import { t } from 'i18next';
import AddUsersModalFooter from './AddUsersModalFooter';
import AddUsersModalBody from './AddUsersModalBody';
import { useEffect, useState } from 'react';
import {
    handleUsersEmailsExistence,
    returnValdinAndNotValidMails
} from '../../../../utils/utils';
import Backend from '../../../../backend/Backend';
import { useTenant } from '../../../../AppContext';
import EclipseLoader from '../../../../SVG/EclipseLoader';
import { handleUsersMappedByPersona } from '../PersonasManagementHelpers';

const AddUsersModal = () => {
    const { addUsersModalOpen, setAddUsersModalOpen } = useAddUsersModal();
    const { setNotValidEmails } = useNotValidEmails();
    const {
        handleUsersAssignment,
        setPersonaUpdateOrCreateData,
        updatePersona
    } = usePersonasManagementRows();
    const { tenantId } = useTenant();

    const [users, setUsers] = useState<string>('');
    const [personaHadUsers, setPersonaHadUsers] = useState<boolean>(false);
    const [selectedPersona, setSelectedPersona] = useState<string>(
        addUsersModalOpen?.name
    );
    const [personaSearchText, setPersonaSearchText] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (addUsersModalOpen?.name) {
            setSelectedPersona(addUsersModalOpen?.name);
            setPersonaSearchText(addUsersModalOpen?.name);
            Backend.getUsersByPersona(tenantId, [addUsersModalOpen?.id]).then(
                (response) => {
                    const personaIdToUsers = response.data.persona_id_to_users;
                    const rearrengedUsers = personaIdToUsers[
                        addUsersModalOpen.id
                    ]
                        ?.sort()
                        .join('\n');
                    setUsers(rearrengedUsers || '');
                    setIsLoading(false);
                    setPersonaHadUsers(Boolean(rearrengedUsers));
                }
            );
            return;
        }
        return () => resetState();
    }, [addUsersModalOpen?.name]);

    const handleSave = async () => {
        const { notValidEmails, validEmails } = returnValdinAndNotValidMails(
            users.split('\n').filter((email) => Boolean(email))
        );
        const { existingEmails, notExistingEmails } =
            await handleUsersEmailsExistence(validEmails);
        const assignedUsersMap = await handleUsersAssignment(existingEmails);
        const {
            usersFromSamePersonaOrNonePersona,
            usersAssignedToAnotherPersona
        } = handleUsersMappedByPersona(assignedUsersMap, selectedPersona);
        if (
            notValidEmails.length ||
            notExistingEmails.length ||
            Object.keys(usersAssignedToAnotherPersona).length
        ) {
            setPersonaUpdateOrCreateData({
                type: 'update',
                data: {
                    personas: [
                        {
                            id: addUsersModalOpen?.id,
                            users: usersFromSamePersonaOrNonePersona
                        }
                    ],
                    replace: true
                }
            });
            setNotValidEmails({
                [selectedPersona]: {
                    notValidEmails,
                    notExistingEmails,
                    emailsInUse: usersAssignedToAnotherPersona
                }
            });
        } else {
            updatePersona(
                [
                    {
                        id: addUsersModalOpen?.id,
                        users: usersFromSamePersonaOrNonePersona
                    }
                ],
                true
            );
        }
        setAddUsersModalOpen(null);
        resetState();
    };

    const handleCancel = () => {
        resetState();
        setAddUsersModalOpen(null);
    };

    const handleDisableSave = () => {
        return (!users && !personaHadUsers) || !selectedPersona || isLoading;
    };

    const resetState = () => {
        setUsers('');
        setSelectedPersona('');
        setPersonaSearchText('');
        setIsLoading(true);
        setPersonaHadUsers(false);
    };

    return (
        <SidePanel
            open={Boolean(addUsersModalOpen?.name)}
            onClose={handleCancel}
            classes={{
                container: 'side-panel__upload-container',
                body: 'side-panel__body side-panel__upload-body flex-col',
                header: 'ingestModal__side-panel__header',
                footer: 'side-panel__footer'
            }}
            title={t('add-persona.add-users-modal-title') as string}
            footer={
                <AddUsersModalFooter
                    disableSave={handleDisableSave()}
                    handleSave={handleSave}
                    handleCancel={handleCancel}
                />
            }
        >
            {isLoading ? (
                <div className="side-panel__loader">
                    <EclipseLoader />
                </div>
            ) : (
                <AddUsersModalBody
                    users={users}
                    setUsers={setUsers}
                    selectedPersona={selectedPersona}
                    setSelectedPersona={setSelectedPersona}
                    personaSearchText={personaSearchText}
                    setPersonaSearchText={setPersonaSearchText}
                />
            )}
        </SidePanel>
    );
};

export default AddUsersModal;
