import DateCell from '../../Table/Cells/DateCell';
import { useEffect } from 'react';
import { PersonasManagementRow, SourcesByPersona } from '../../../types';
import {
    useAddUsersModal,
    useFilteredPersonaManagementRows,
    usePersonaManagenentFilters,
    usePersonaSourcesModal,
    usePersonasManagementRows,
    useSourcesByPersona
} from './PersonaManagementHooks';
import { COLUMNS, PERSONA_CONSTANT_NAMES } from './consts';
import EditableCell from '../../Table/Cells/EditableCell';
import ClickableCell from '../../Table/Cells/ClickableCell';
import {
    getFilteredPersonasManagementRows,
    getUsersCellIcon
} from './PersonasManagementHelpers';
import TextCell from '../../Table/Cells/TextCell';
import { useIsPersonasEnabled } from '../../../context/DataManagementContext';
import { t } from 'i18next';
import { useTenant } from '../../../AppContext';
import ToggleCell from '../../Table/Cells/ToggleCell';
import Backend from '../../../backend/Backend';

export const usePersonasManagementTable = () => {
    const {
        personasManagementRows,
        arePersonasManagementDataFetching,
        setArePersonasManagementDataFetching,
        updatePersona
    } = usePersonasManagementRows();
    const { setAddUsersModalOpen } = useAddUsersModal();
    const { setSelectedPersonaSources } = usePersonaSourcesModal();
    const { setSourcesByPersona } = useSourcesByPersona();
    const { personaManagemantSearchTerm, personaManagementFilters } =
        usePersonaManagenentFilters();
    const { setFilteredPersonaManagementRows } =
        useFilteredPersonaManagementRows();
    const { isPersonasEnabled } = useIsPersonasEnabled();
    const { tenantId } = useTenant();

    useEffect(() => {
        setFilteredPersonaManagementRows(
            getFilteredPersonasManagementRows(
                personasManagementRows,
                personaManagemantSearchTerm,
                personaManagementFilters
            )
        );
    }, [
        personasManagementRows,
        personaManagemantSearchTerm,
        personaManagementFilters
    ]);

    const onClickSource = async (row: PersonasManagementRow) => {
        setArePersonasManagementDataFetching(true);
        const response = await Backend.getDocumentsByPersona(tenantId, row.id);
        setSourcesByPersona(response.data);
        setSelectedPersonaSources(row.name);
        setArePersonasManagementDataFetching(false);
    };

    const onChangeEditableCell = (value: string, field: string, id: string) => {
        updatePersona([
            {
                id,
                [field]: value
            }
        ]);
    };

    const onClickToggle = (row: PersonasManagementRow) => {
        updatePersona([{ id: row.id, active: !row.active }]);
    };

    const sourcesCell = (params) => {
        const newPersonasCreated = personasManagementRows.length > 2;
        const isAllSources =
            params.row.name === PERSONA_CONSTANT_NAMES.ALL_SOURCES;
        const isPersonaDeactivated = !params.row.active;

        if (isAllSources) {
            return (
                <TextCell
                    field={params.field}
                    text={PERSONA_CONSTANT_NAMES.ALL_SOURCES}
                />
            );
        }
        if (
            (!isPersonasEnabled && params.row.source_count === 0) ||
            isPersonaDeactivated
        ) {
            return (
                <TextCell
                    field={params.field}
                    text={`${params.row.source_count}  ${
                        params.row.name === PERSONA_CONSTANT_NAMES.DEFAULT &&
                        !newPersonasCreated
                            ? '(All)'
                            : 'sources'
                    }`}
                />
            );
        }
        return (
            <ClickableCell
                {...params}
                text={
                    params.row.name === PERSONA_CONSTANT_NAMES.DEFAULT &&
                    !newPersonasCreated
                        ? '(All)'
                        : 'sources'
                }
                tooltip="View sources list"
                onClick={() => onClickSource(params.row)}
            />
        );
    };

    const usersCell = (params) => {
        const isDefault = params.row.name === PERSONA_CONSTANT_NAMES.DEFAULT;
        const isPersonaDeactivated = !params.row.active;
        if (isDefault) {
            return (
                <TextCell
                    field={params.field}
                    text={`${params.row[params.field]}`}
                    icon={getUsersCellIcon(isDefault)}
                />
            );
        }
        if (!isPersonasEnabled || isPersonaDeactivated) {
            return (
                <TextCell
                    field={params.field}
                    text={`${params.row.user_count} ${t(
                        'add-persona.mapped-users'
                    )}`}
                />
            );
        }
        return (
            <ClickableCell
                {...params}
                text={t('add-persona.mapped-users')}
                tooltip={t('add-persona.add-or-remove-users')}
                icon={getUsersCellIcon(false)}
                onClick={() => setAddUsersModalOpen(params.row)}
            />
        );
    };

    const columnComponents = {
        [COLUMNS.PERSONA_NAME]: (params) => {
            if (
                params.row.name === PERSONA_CONSTANT_NAMES.DEFAULT ||
                params.row.name === PERSONA_CONSTANT_NAMES.ALL_SOURCES ||
                !isPersonasEnabled
            ) {
                return (
                    <TextCell
                        field={params.field}
                        text={params.row.name}
                        className={
                            params.row.name === PERSONA_CONSTANT_NAMES.DEFAULT
                                ? 'defaultCell'
                                : ''
                        }
                    />
                );
            }
            return (
                <EditableCell
                    width="250px"
                    {...params}
                    onSubmit={(value) =>
                        onChangeEditableCell(value, params.field, params.row.id)
                    }
                />
            );
        },

        [COLUMNS.DESCRIPTION]: (params) => {
            if (
                params.row.name === PERSONA_CONSTANT_NAMES.DEFAULT ||
                params.row.name === PERSONA_CONSTANT_NAMES.ALL_SOURCES ||
                !isPersonasEnabled
            ) {
                return (
                    <TextCell
                        field={params.field}
                        text={params.row.description}
                    />
                );
            }
            return (
                <EditableCell
                    width="520px"
                    {...params}
                    onSubmit={(value: string) =>
                        onChangeEditableCell(value, params.field, params.row.id)
                    }
                />
            );
        },
        [COLUMNS.SOURCES]: (params) => {
            return sourcesCell(params);
        },
        [COLUMNS.USERS]: (params) => {
            return usersCell(params);
        },
        [COLUMNS.LAST_UPDATED]: (params) => <DateCell {...params} />,
        [COLUMNS.ACTIVE]: (params) => (
            <ToggleCell
                {...params}
                onClick={() => onClickToggle(params.row)}
                disabled={
                    params.row.name === PERSONA_CONSTANT_NAMES.DEFAULT ||
                    params.row.name === PERSONA_CONSTANT_NAMES.ALL_SOURCES
                }
            />
        )
    };
    return {
        columnComponents,
        arePersonasManagementDataFetching
    };
};
