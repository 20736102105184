import { FunctionComponent, useEffect, useState } from 'react';
import { SourceDocument } from '../../types';
import VideoPlayer from '../Common/VideoPlayer';
import PDFViewer from '../PDFViewer/PDFViewer';
import EclipseLoader from '../../SVG/EclipseLoader';
import Backend from '../../backend/Backend';
import { useScopSettings } from '../../AppContext';
import BasicPDFViewer from '../PDFViewer/BasicPDFViewer';

type SourceDocumentDetailsProps = {
    sourceDocument: SourceDocument;
    setIsDocPreparingToLoad: Function;
};

const SourceDocumentDetails: FunctionComponent<SourceDocumentDetailsProps> = (
    props: SourceDocumentDetailsProps
) => {
    const { sourceDocument, setIsDocPreparingToLoad } = props;
    const mediaType = sourceDocument.metadata.media_type;
    const [isSourceReady, setIsSourceReady] = useState(false);
    const { enableBasicPdfViewer } = useScopSettings();
    const getSourceUrl = async () => {
        try {
            const res = await Backend.getSourceUrl(
                sourceDocument.tenantModel,
                sourceDocument.metadata.filename,
                sourceDocument.metadata.page
            );
            sourceDocument.metadata.source = res.data.source_url;
            sourceDocument.metadata.source_pdf_preview_url =
                res.data.source_pdf_preview_url;
        } catch (ex: any) {
            console.error('Failed to fetch document url data.', ex);
            window.sentry.log(ex);
        } finally {
            setIsSourceReady(true);
        }
    };

    useEffect(() => {
        if (sourceDocument.metadata.source) {
            setIsSourceReady(true);
        } else {
            getSourceUrl();
        }
    }, [sourceDocument]);

    if (!isSourceReady) {
        return (
            <div className="flex align-center justify-center">
                <EclipseLoader height="90vh" />
            </div>
        );
    }

    return mediaType === 'video' ? (
        <VideoPlayer
            videoUrl={sourceDocument.metadata.source}
            currentTime={sourceDocument.metadata.start}
        />
    ) : enableBasicPdfViewer ? (
        <BasicPDFViewer sourceDocument={sourceDocument} />
    ) : (
        <PDFViewer
            sourceDocument={sourceDocument}
            setIsDocPreparingToLoad={setIsDocPreparingToLoad}
        />
    );
};

export default SourceDocumentDetails;
