import { FC, useMemo } from 'react';
import { Disclaimer, DisclaimerType } from '../../types';
import SmallCircleInfoIcon from '../../SVG/SmallCircleInfoIcon';
import SmallWarningIcon from '../../SVG/SmallWarningIcon';
import { Typography } from 'aqui';
import { getLinkType, parseTextWithLinks } from '../../utils/utils';
import useAnalytics from '../../hooks/useAnalytics';
import { ANALYTICS_EVENTS } from '../../consts';
import { getDisclaimersAnalyticsData } from './MessageDisclaimersHelpers';

type MessageSingleTypeDisclaimerProps = {
    disclaimers: Disclaimer[];
    className?: string;
};

const MessageSingleTypeDisclaimer: FC<MessageSingleTypeDisclaimerProps> = ({
    disclaimers,
    className = ''
}) => {
    const { sendEvent } = useAnalytics();
    const type = useMemo(
        () => disclaimers?.[0]?.disclaimer_type,
        [disclaimers]
    );
    const icon = useMemo(() => {
        if (type === DisclaimerType.Notice) {
            return <SmallCircleInfoIcon />;
        }

        return <SmallWarningIcon filled={type !== DisclaimerType.Caution} />;
    }, [type]);

    const onLinkClick = (disclaimer: Disclaimer, url: string) => {
        window.open(url);
        sendEvent(ANALYTICS_EVENTS.DISCLAIMER_LINK_CLICKED, {
            ...getDisclaimersAnalyticsData(disclaimer),
            Link: url,
            'Link Type': getLinkType(url)
        });
    };

    return (
        <div className={`message-disclaimers__items-container ${className} --${type}`}>
            {disclaimers.map((disclaimer, index) => (
                <div
                    className="message-disclaimers__item"
                    key={disclaimer.disclaimer_text + index}
                >
                    {icon}
                    <Typography type="small1">
                        {parseTextWithLinks(
                            disclaimer.disclaimer_text,
                            'message-disclaimer-link',
                            'message-disclaimers__link',
                            (url) => onLinkClick(disclaimer, url)
                        )}
                    </Typography>
                </div>
            ))}
        </div>
    );
};

export default MessageSingleTypeDisclaimer;
