import { Typography, Tabs } from 'aqui';
import { t } from 'i18next';
import { FunctionComponent } from 'react';
import { ChevronIcon } from 'aqui/icons';
import useSwitchPanel from '../../hooks/useSwitchPanel';
import { DataManagementTab } from '../../types';
import { useDataManagementTab } from '../../context/DataManagementContext';
import IngestedSources from './IngestedSources/IngestedSources';
import PersonasManagement from './PersonasManagement/PersonasManagement';
import FailedImportModal from './FailedImportModal/FailedImportModal';
import ExpertResponses from './ExpertResponses/ExpertResponses';
import { useUpdateFilePersonas } from './IngestedSources/IngestedSourcesHooks';
import { Tooltip } from '@mui/material';
import PersonaLoadingTooltip from './IngestedSources/PersonaLoadingTooltip';
import FeedbackManagement from './Feedbacks/FeedbackManagement';

const TABS = [
    DataManagementTab.IngestedSources,
    DataManagementTab.PersonasManagement,
    DataManagementTab.ExpertResponses,
    DataManagementTab.FeedbackManagement
];

const DataManagementPanel: FunctionComponent = () => {
    const { currentTab, setCurrentTab } = useDataManagementTab();
    const { backToPrevPanel } = useSwitchPanel();
    const { updatingPersonas } = useUpdateFilePersonas();

    const showLoadingPersonas = () => {
        return (
            currentTab === DataManagementTab.IngestedSources &&
            updatingPersonas.size > 0
        );
    };

    return (
        <div className="full-width flex flex-col">
            <div className="data-management__header">
                <ChevronIcon
                    direction="left"
                    className="data-management__back-btn"
                    onClick={backToPrevPanel}
                    dataTestId="data-management-back-btn"
                />
                <Typography type="h6">
                    {t('app-menu.data-management')}
                </Typography>
            </div>
            <div className="flex space-between align-flex-end">
                <Tabs
                    tabs={TABS}
                    selectedTab={currentTab}
                    onTabClick={setCurrentTab}
                    dataTestId="data-management-tabs"
                    className="data-management__tabs"
                />
                {showLoadingPersonas() && (
                    <Tooltip
                        title={<PersonaLoadingTooltip />}
                        placement="top"
                        classes={{
                            tooltip: 'tooltip'
                        }}
                    >
                        <div className="flex align-center data-management__tabs__loading">
                            <div className="circle-loader"></div>
                            <Typography
                                type="body3"
                                className="data-management__tabs__loading-text"
                            >
                                {t('ingested-sources.updating-personas')}
                                <span className="dots"></span>
                            </Typography>
                        </div>
                    </Tooltip>
                )}
            </div>
            <div className="data-management__body">
                {currentTab === DataManagementTab.IngestedSources && (
                    <IngestedSources />
                )}
                {currentTab === DataManagementTab.PersonasManagement && (
                    <PersonasManagement />
                )}
                {currentTab === DataManagementTab.ExpertResponses && (
                    <ExpertResponses />
                )}
                {currentTab === DataManagementTab.FeedbackManagement && (
                    <FeedbackManagement />
                )}
            </div>
            <FailedImportModal />
        </div>
    );
};

export default DataManagementPanel;
