import { FunctionComponent } from 'react';
import { t } from 'i18next';
import { Typography } from 'aqui';
import { UploadIcon, CircleInfoIcon } from 'aqui/icons';
import {
    MAX_NUMBER_OF_FILES_TO_INGEST,
    MAX_FILE_SIZE_TO_INGEST_IN_MB,
    VALID_FILE_TYPES_TO_INGEST
} from '../../consts';
import { Tooltip } from '@material-ui/core';
import { FileUploader } from 'react-drag-drop-files';
import {
    useSelectedPersonasForIngest,
    useSourceFiles,
    useUploadModel
} from './UploadFilesModalHooks';
import { useIsPersonasEnabled } from '../../context/DataManagementContext';

const DragAndDropArea: FunctionComponent = () => {
    const { sourceFiles, validSourceFiles, setSourceFilesToValidate } =
        useSourceFiles();
    const { uploadModel } = useUploadModel();
    const { addDefaultPersona } = useSelectedPersonasForIngest();
    const { isPersonasEnabled } = useIsPersonasEnabled();

    const disabled =
        validSourceFiles.length >= MAX_NUMBER_OF_FILES_TO_INGEST ||
        !uploadModel;

    const onDrop = (acceptedFiles: FileList) => {
        if (disabled) {
            return;
        }
        const filteredFiles = [...acceptedFiles].filter(
            (file) =>
                !sourceFiles.some(
                    (sourceFile) => sourceFile.file.name === file.name
                )
        );
        const sourceFilesToAdd = filteredFiles.map((file) => ({
            file
        }));
        setSourceFilesToValidate(sourceFilesToAdd);
        isPersonasEnabled && addDefaultPersona();
    };

    return (
        <FileUploader
            handleChange={onDrop}
            name="file"
            types={VALID_FILE_TYPES_TO_INGEST}
            multiple
            maxSize={MAX_FILE_SIZE_TO_INGEST_IN_MB}
            disabled={disabled}
        >
            <div
                className={`upload-files__drop-area ${
                    disabled ? '--disabled' : ''
                }`}
            >
                <UploadIcon />
                <Typography type="subtitle1">
                    {t('upload-files-modal.click-drag-files')}
                </Typography>
                <Typography
                    type="small1"
                    className="upload-files__drop-area__specifications"
                    tag="div"
                >
                    {t('upload-files-modal.no-spec-char')}
                    <p className="flex align-center">
                        {`${t(
                            'upload-files-modal.max-files'
                        )} ${MAX_NUMBER_OF_FILES_TO_INGEST} | ${t(
                            'upload-files-modal.max-size'
                        )} ${MAX_FILE_SIZE_TO_INGEST_IN_MB}${t(
                            'upload-files-modal.mb'
                        )}`}
                        <Tooltip
                            title={
                                disabled
                                    ? ''
                                    : (t(
                                          'upload-files-modal.larger-files-tooltip-text'
                                      ) as string)
                            }
                            classes={{ tooltip: 'tooltip tooltip__close-top' }}
                            placement="top"
                        >
                            <CircleInfoIcon className="upload-files__drop-area__info-icon" />
                        </Tooltip>
                    </p>
                </Typography>
            </div>
        </FileUploader>
    );
};

export default DragAndDropArea;
