import { Typography } from 'aqui';
import { t } from 'i18next';
import { prepareDate } from '../../utils/utils';

const SummaryModalTitle = () => (
    <div className="flex flex-col">
        <Typography type="h3">{t('chat.summary')}</Typography>
        <Typography type="subtitle1" className="summary-modal__subtitle">
            {`${t('summary-modal.last-modified')} ${prepareDate(new Date())}`}
        </Typography>
    </div>
);

export default SummaryModalTitle;
