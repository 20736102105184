import { FunctionComponent, useState } from 'react';
import {
    useEditedResponse,
    useFeedbacksRows
} from '../DataManagementPanel/Feedbacks/useFeedbacksHooks';
import EditResponseModalMetadata from './EditResponseModalMetadata';
import { adjustIntegratedModel } from '../../utils/productUtils';
import { useScopSettings, useThreadsHandling } from '../../AppContext';
import EditResponse from './EditResponse';
import Backend from '../../backend/Backend';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import { MessageInfo, ReviewStatus, SourceDocument } from '../../types';
import GetUpdatedResponseDisclaimer from './GetUpdatedResponseDisclaimer';
import EclipseLoader from '../../SVG/EclipseLoader';

type EditResponseModalBodyProps = {
    relatedMessage: any;
    generateUpdatedMessage: () => void;
    updatedMessage: MessageInfo;
    isStreamInProgress: boolean;
    setIsFixed: (isFixed: boolean) => void;
    isFixed: boolean;
    closeModal: () => void;
};

const EditResponseModalBody: FunctionComponent<EditResponseModalBodyProps> = ({
    relatedMessage,
    generateUpdatedMessage,
    updatedMessage,
    isStreamInProgress,
    setIsFixed,
    isFixed,
    closeModal
}) => {
    const { editedFeedbackRow } = useEditedResponse();
    const { onSelectedThread } = useThreadsHandling();
    const [editedResponse, setEditedResponse] = useState();
    const { fullUserName } = useSelector(
        (state: any) => state.userProfileReducer
    );
    const [expertData, setExpertData] = useState<{
        expertName?: string;
        editDate?: string;
    }>({});
    const [isEditMode, setIsEditMode] = useState(false);
    const { updateFeedbacksRow } = useFeedbacksRows();
    const { enableEditResponsesByExperts } = useScopSettings();
    const [isCreatingExpertResponse, setIsCreatingExpertResponse] =
        useState(false);

    const onQuestionClick = () => {
        if (!editedFeedbackRow.chat_id) {
            return;
        }
        closeModal();
        onSelectedThread(
            editedFeedbackRow.chat_id,
            editedFeedbackRow.tenant_model
        );
    };

    const onSaveEdited = async (newResponse) => {
        setIsCreatingExpertResponse(true);
        try {
            Backend.updateResponseByExpert(
                editedFeedbackRow.chat_id,
                editedFeedbackRow.message_id,
                editedFeedbackRow.tenant_model,
                editedFeedbackRow.question,
                relatedMessage.condensed_question,
                newResponse,
                editedFeedbackRow.triage_product_id,
                fullUserName
            );
            setIsEditMode(false);
            setEditedResponse(newResponse);
            setExpertData({
                editDate: new Date().toISOString(),
                expertName: fullUserName
            });
            onResponseIsGoodClick();
        } catch (ex: any) {
            console.error('Failed to edit response response', ex);
            window.sentry.log(ex);
        } finally {
            setIsCreatingExpertResponse(false);
        }
    };

    const onResponseIsGoodClick = () => {
        setIsFixed(true);
        updateFeedbacksRow(editedFeedbackRow.rowid, {
            review_status: ReviewStatus.Fixed
        });
    };

    const isLoading =
        (updatedMessage && !updatedMessage.message) || isCreatingExpertResponse;
    const isEditAvailable =
        !isStreamInProgress &&
        enableEditResponsesByExperts &&
        !editedFeedbackRow.is_concise_response;

    const showRelatedExpertRes =
        relatedMessage.expert_details && !updatedMessage?.message;

    const getUpdatedResEditResponseBox = (
        response: string,
        sources: SourceDocument[],
        expertName: string,
        expertDate: string,
        withDisclaimer?: boolean
    ) => (
        <EditResponse
            response={editedResponse || response}
            isEditAvailable={isEditAvailable && !withDisclaimer}
            isEditMode={isEditMode}
            sources={sources}
            expertName={expertData.expertName || expertName}
            editDate={expertData.editDate || expertDate}
            label={t('edit-response.updated-response')}
            onCancelEdit={() => setIsEditMode(false)}
            onSaveEdit={onSaveEdited}
            onEditClick={() => setIsEditMode(true)}
            shouldShowCustomButton={!isStreamInProgress && !isFixed}
            onCustomButtonClick={onResponseIsGoodClick}
            customButtonText={t('edit-response.approve-response')}
            customButtonDataTestId="edit-response-modal-approve-button"
            disclaimer={
                withDisclaimer ? (
                    <GetUpdatedResponseDisclaimer
                        onClick={generateUpdatedMessage}
                    />
                ) : null
            }
        />
    );
    return (
        <div className="edit-response-modal__body">
            <EditResponseModalMetadata
                chatId={editedFeedbackRow.chat_id}
                modelDisplay={adjustIntegratedModel(
                    editedFeedbackRow.integratedModel
                )}
                initialQuestion={editedFeedbackRow.initial_question}
                onQuestionClick={onQuestionClick}
                persona={editedFeedbackRow.persona_name}
                isConcise={editedFeedbackRow.is_concise_response}
            />
            {updatedMessage?.message &&
                getUpdatedResEditResponseBox(
                    updatedMessage.message,
                    updatedMessage.relatedSourceDocuments,
                    updatedMessage.expertDetails?.name,
                    updatedMessage.expertDetails?.date
                )}
            {showRelatedExpertRes &&
                getUpdatedResEditResponseBox(
                    relatedMessage.expert_details.expert_response,
                    relatedMessage.answer_sources,
                    relatedMessage.expert_details.name,
                    relatedMessage.expert_details.date,
                    !relatedMessage.expert_details.active
                )}
            <EditResponse
                response={relatedMessage.answer}
                sources={relatedMessage.answer_sources}
                label={t('feedbacks-dm.column-response')}
                disclaimer={
                    !updatedMessage?.message && !showRelatedExpertRes ? (
                        <GetUpdatedResponseDisclaimer
                            onClick={generateUpdatedMessage}
                        />
                    ) : null
                }
            />
            {isLoading && (
                <div className="side-panel__loader edit-response-modal__loader">
                    <EclipseLoader />
                </div>
            )}
        </div>
    );
};

export default EditResponseModalBody;
