import { CircularProgress } from '@mui/material';
import { Typography } from 'aqui';
import { t } from 'i18next';
import { useUpdateFilePersonas } from '../IngestedSources/IngestedSourcesHooks';

const PersonaLoadingOverlay = () => {
    const { updatingPersonas } = useUpdateFilePersonas();

    return (
        <div className="flex flex-col align-center justify-center personaLoadingOverlay__container">
            <CircularProgress />
            {updatingPersonas.size > 0 && (
                <div className="flex flex-col personaLoadingOverlay__text">
                    <Typography type="small1" className="bold">
                        {t('ingested-sources.updating-personas')}
                    </Typography>
                    <Typography type="small1">
                        {t('add-persona.loading-wait')}
                    </Typography>
                </div>
            )}
        </div>
    );
};

export default PersonaLoadingOverlay;
