import React from 'react';

const SummaryIcon = ({ color = 'currentColor', ...props }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            {...props}
        >
            <path
                d="M9.33333 19H17.3333C17.6869 19 18.0261 18.8595 18.2761 18.6095C18.5262 18.3594 18.6667 18.0203 18.6667 17.6667V16.3333H10.6667V17.6667C10.6667 18.0203 10.5262 18.3594 10.2761 18.6095C10.0261 18.8595 9.68695 19 9.33333 19ZM9.33333 19C8.97971 19 8.64057 18.8595 8.39052 18.6095C8.14047 18.3594 7.99999 18.0203 7.99999 17.6667V8.33333C7.99999 7.97971 7.85952 7.64057 7.60947 7.39052C7.35942 7.14048 7.02028 7 6.66666 7C6.31304 7 5.9739 7.14048 5.72385 7.39052C5.4738 7.64057 5.33333 7.97971 5.33333 8.33333V10.3333H7.99999"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.6667 16.3333V8.33333C16.6667 7.97971 16.5262 7.64057 16.2761 7.39052C16.0261 7.14048 15.687 7 15.3333 7H6.66667"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default SummaryIcon;
