import { useEffect } from 'react';
import { adjustIntegratedModel } from '../../../utils/productUtils';
import ValueWithTooltip from '../../ValueWithTooltip/ValueWithTooltip';
import { IntegratedModel } from '../../../types';

type ModelListProps = {
    mainModelsList: IntegratedModel[];
    selectedModel: IntegratedModel;
    onModelSelect: (model: IntegratedModel) => void;
};

const ModelList = ({
    mainModelsList,
    selectedModel,
    onModelSelect
}: ModelListProps) => {

    return (
        <div className="NoModelMessage__models flex flex-col">
            {mainModelsList.map((model, modelIndex) => {
                const modelTitle = adjustIntegratedModel(model);
                const isModelSelected = selectedModel === model;

                return (
                    <ValueWithTooltip
                        key={modelTitle}
                        index={modelIndex}
                        title={modelTitle}
                        className={`ModelList__model ${
                            selectedModel
                                ? isModelSelected
                                    ? 'ModelList__model--chosen'
                                    : 'ModelList__model--disabled'
                                : ''
                        }`}
                        type="body2"
                        onClick={() => onModelSelect(model)}
                        maxLines={2}
                    />
                );
            })}
        </div>
    );
};

export default ModelList;
