import {
    useIntegratedModels,
    useScopSettings,
    useServerlessIndex,
    useTenant
} from '../../../AppContext';
import {
    useDataManagementContext,
    useIsPersonasEnabled
} from '../../../context/DataManagementContext';
import {
    EditedPersonasOfFile,
    IngestedSourcesFilters,
    IngestedSourcesRow,
    IngestedSourcesStatus,
    IntegratedModel,
    PopoverRow,
    RunningTask,
    SourceFile,
    TaskTypes
} from '../../../types';
import Backend from '../../../backend/Backend';
import {
    useIngestFiles,
    useSourceFiles,
    useUploadingFiles
} from '../../UploadFilesModal/UploadFilesModalHooks';
import {
    getIngestedSourcePopulatedRows,
    sourceFilesToIngestedSourceRows,
    validateData
} from './IngestedSourcesHelpers';
import { MAX_NUMBER_OF_ROWS_AT_CALL, UPDATING_ALL } from './consts';
import SFBackend from '../../../backend/SFBackend';
import { getIntegratedModels } from '../../../utils/productUtils';
import { t } from 'i18next';
import { onExportFile, onImportFile } from '../../../utils/fileUtils';
import { usePersonasManagementRows } from '../PersonasManagement/PersonaManagementHooks';
import { splitAndCleanStringToArray, prepareDate } from '../../../utils/utils';
import useTaskStatusInterval from '../../../hooks/useTaskStatusInterval';
import { useEffect, useRef, useState } from 'react';
import { shouldShowPersonasProcessPendingModal } from '../PersonasManagement/utils';

export const useIngestedSourcesFilters = () => {
    const {
        ingestedSourcesFilters,
        setIngestedSourcesFilters,
        ingestedSourcesUsers,
        ingestedSourcesModels,
        ingestedSourceSearchTerm,
        ingestedSourcesPersonas,
        setIngestedSourceSearchTerm
    } = useDataManagementContext();
    const { ingestedModels } = useIntegratedModels();
    const updateFilters = (updaters: IngestedSourcesFilters) => {
        setIngestedSourcesFilters((prevFilters: IngestedSourcesFilters) => ({
            ...prevFilters,
            ...updaters
        }));
    };
    const setModelsToFilter = (models: string[]) => {
        updateFilters({ models });
    };
    const setUserToFilter = (user: string) => {
        updateFilters({ user });
    };
    const setStatusToFilter = (status: IngestedSourcesStatus) => {
        updateFilters({ status });
    };
    const setPersonaToFilter = (persona: string) => {
        updateFilters({ persona });
    };
    const setCreateDateToFilter = (
        minCreateDate?: Date,
        maxCreateDate?: Date
    ) => {
        updateFilters({ minCreateDate, maxCreateDate });
    };

    return {
        ingestedSourcesFilters,
        setIngestedSourcesFilters,
        ingestedModels,
        setModelsToFilter,
        setUserToFilter,
        setStatusToFilter,
        ingestedSourcesUsers,
        ingestedSourceSearchTerm,
        setIngestedSourceSearchTerm,
        ingestedSourcesModels,
        setPersonaToFilter,
        ingestedSourcesPersonas,
        setCreateDateToFilter
    };
};

export const useIngestedSourceRows = () => {
    const {
        ingestedSourceRows,
        setIngestedSourceRows,
        areIngestedSourcesFetching,
        setAreIngestedSourcesFetching,
        deletedIngestedRows,
        setUpdatingPersonas
    } = useDataManagementContext();
    const { tenantId } = useTenant();
    const { fetchPersonasManagementRows } = usePersonasManagementRows();
    const { getUploadingFilesAsIngestedSources } = useUploadingFiles();
    const { setIngestedSourcesDisabledRowIds } =
        useIngestedSourcesDisabledRows();
    const { checkForRunningTasks } = useHandleIngestTasks();
    const { disableRows } = useIngestedSourcesDisabledRows();

    const [checkTasks, setCheckTasks] = useState(false);

    useEffect(() => {
        if (checkTasks && ingestedSourceRows.length > 0) {
            checkForRunningTasks();
            setCheckTasks(false);
        }
    }, [checkTasks]);

    const fetchIngestedSourceRowsAndPersonas = async (
        skipTasks: boolean = false
    ) => {
        // TODO: remove \/ after 114 will be deployed to the genai-be prod server
        setUpdatingPersonas(new Set());
        //
        setIngestedSourcesDisabledRowIds(new Set());
        setAreIngestedSourcesFetching(true);
        fetchPersonasManagementRows().then(() => {
            fetchIngestedSourceRows(true, skipTasks);
        });
    };

    const fetchIngestedSourceRows = async (
        isAlreadyFetching: boolean = false,
        skipTasks: boolean = false
    ) => {
        try {
            if (!isAlreadyFetching) {
                setAreIngestedSourcesFetching(true);
            }
            setIngestedSourceRows([]);
            let hasNext = true;
            const documents = [];
            while (hasNext) {
                const response = await Backend.getIngestDocuments(
                    tenantId,
                    MAX_NUMBER_OF_ROWS_AT_CALL,
                    documents.length
                );
                hasNext = response.has_next;
                documents.push(...response.documents);
            }
            const uniqueModelNames = [
                ...new Set(documents.map((doc) => doc.tenant_model))
            ];
            const productDetails = await SFBackend.fetchProductDetails(
                uniqueModelNames
            );
            const ingestedModels = getIntegratedModels(
                productDetails.product,
                uniqueModelNames
            );
            const ingestedRows = getIngestedSourcePopulatedRows(
                documents,
                ingestedModels
            );
            const uploadingSources = getUploadingFilesAsIngestedSources();
            const allRows = [...uploadingSources, ...ingestedRows];
            // TODO: remove \/ after 114 will be deployed to the genai-be prod server
            disableRows(
                allRows.filter(
                    (row) =>
                        row.status === IngestedSourcesStatus.Processing ||
                        row.status === IngestedSourcesStatus.Uploading
                )
            );
            //
            setIngestedSourceRows(allRows);
            if (!skipTasks) {
                setCheckTasks(true);
            } else {
                setAreIngestedSourcesFetching(false);
            }
        } catch (ex: any) {
            console.error(
                'Failed to fetch ingested documents data.',
                ex.message
            );
            window.sentry.log(ex);
            setAreIngestedSourcesFetching(false);
        }
    };

    const updateIngestedSourceRow = (updatedRow: IngestedSourcesRow) => {
        setIngestedSourceRows((prevRows: IngestedSourcesRow[]) =>
            prevRows.map((prevRow) =>
                prevRow.id === updatedRow.id
                    ? { ...prevRow, ...updatedRow }
                    : prevRow
            )
        );
    };

    const addSourceFilesToIngestedSources = (
        sourceFiles: SourceFile[],
        integratedModel: IntegratedModel,
        user: string,
        status: IngestedSourcesStatus,
        personas: string[]
    ) => {
        const newIngestedSourcesRows = sourceFilesToIngestedSourceRows(
            sourceFiles,
            integratedModel,
            user,
            status,
            personas
        );
        const newRowIds = new Set(newIngestedSourcesRows.map((row) => row.id));

        setIngestedSourceRows((prevRows) => [
            ...prevRows.filter((row) => !newRowIds.has(row.id)),
            ...newIngestedSourcesRows
        ]);
    };

    const deleteIngestedSourceRows = (rowIds: string[]) => {
        setIngestedSourceRows((prevRows: IngestedSourcesRow[]) =>
            prevRows.filter((prevRow) => !rowIds.includes(prevRow.id))
        );
    };

    return {
        ingestedSourceRows,
        fetchIngestedSourceRows,
        areIngestedSourcesFetching,
        updateIngestedSourceRow,
        addSourceFilesToIngestedSources,
        deleteIngestedSourceRows,
        fetchIngestedSourceRowsAndPersonas,
        setAreIngestedSourcesFetching,
        deletedIngestedRows
    };
};

export const useSelectedIngestedSourceRows = () => {
    const {
        selectedIngestedSourceRows,
        setSelectedIngestedSourceRows,
        selectedIngestedSourceRowIdsSet
    } = useDataManagementContext();
    const { ingestedSourcesDisabledRowIds } = useIngestedSourcesDisabledRows();

    const disabledRowsIds = useRef<Set<string>>(new Set());
    useEffect(() => {
        disabledRowsIds.current = ingestedSourcesDisabledRowIds;
    }, [ingestedSourcesDisabledRowIds]);

    const selectAllEnabledRows = (rows) => {
        setSelectedIngestedSourceRows((prevRows) => {
            const combinedRows = rows(prevRows);
            const enabledRows = combinedRows.filter((row) => {
                return !disabledRowsIds.current.has(row.id);
            });
            return enabledRows;
        });
    };

    return {
        selectedIngestedSourceRows,
        setSelectedIngestedSourceRows,
        selectedIngestedSourceRowIdsSet,
        selectAllEnabledRows
    };
};

export const useIngestedSourcesActions = () => {
    const {
        selectedIngestedSourceRowIdsSet,
        setSelectedIngestedSourceRows,
        setDeletedIngestedRows
    } = useDataManagementContext();
    const { tenantId } = useTenant();
    const { ingestFiles } = useIngestFiles();
    const { disableRows, enableRows } = useIngestedSourcesDisabledRows();
    const { updateIngestedSourceRow, deleteIngestedSourceRows } =
        useIngestedSourceRows();
    const { deleteSourceFiles } = useSourceFiles();

    const deleteSource = async (row: IngestedSourcesRow) => {
        disableRows([row]);
        setDeletedIngestedRows((prevIds) => new Set([...prevIds, row.id]));
        try {
            await Backend.deleteFiles(tenantId, row.tenant_model, [
                row.document_name
            ]);
            deleteIngestedSourceRows([row.id]);
            if (selectedIngestedSourceRowIdsSet.has(row.id)) {
                setSelectedIngestedSourceRows(
                    (prevRows: IngestedSourcesRow[]) =>
                        prevRows.filter((prevRow) => prevRow.id !== row.id)
                );
            }
            deleteSourceFiles([row.document_name]);
        } catch (ex: any) {
            console.error('Failed to delete document.', ex.message);
            window.sentry.log(ex);
        } finally {
            enableRows([row]);
            setDeletedIngestedRows((prevIds) => {
                const newIds = new Set(prevIds);
                newIds.delete(row.id);
                return newIds;
            });
        }
    };

    const ingestSource = async (row: IngestedSourcesRow) => {
        disableRows([row]);
        try {
            await ingestFiles(
                row.tenant_model,
                [row.document_name],
                row.personas
            );
            updateIngestedSourceRow({
                ...row,
                status: IngestedSourcesStatus.Processing
            });
            deleteSourceFiles([row.document_name]);
        } catch (ex: any) {
            console.error('Failed to send ingest request. ', ex.message);
            window.sentry.log(ex);
        }
    };

    return { deleteSource, ingestSource };
};

export const useIngestedSourcesDisabledRows = () => {
    const { ingestedSourcesDisabledRowIds, setIngestedSourcesDisabledRowIds } =
        useDataManagementContext();
    const disableRows = (rows: IngestedSourcesRow[]) => {
        setIngestedSourcesDisabledRowIds(
            (prevDisabledRowIds: Set<string>) =>
                new Set([...prevDisabledRowIds, ...rows.map((row) => row.id)])
        );
    };

    const enableRows = (rows: IngestedSourcesRow[]) => {
        setIngestedSourcesDisabledRowIds(
            (prevDisabledRowIds: Set<string>) =>
                new Set(
                    [...prevDisabledRowIds].filter(
                        (disabledRowId) =>
                            !rows.some((row) => row.id === disabledRowId)
                    )
                )
        );
    };
    const getIsRowDisabled = (row: IngestedSourcesRow) => {
        return ingestedSourcesDisabledRowIds.has(row.id);
    };
    return {
        getIsRowDisabled,
        disableRows,
        enableRows,
        ingestedSourcesDisabledRowIds,
        setIngestedSourcesDisabledRowIds
    };
};

export const useMoreOptionsRowsIngestedSources = () => {
    const { personaNameToId, personaIdToName, personasNames, defaultPersona } =
        usePersonasManagementRows();
    const { ingestedSourceRows } = useIngestedSourceRows();
    const { filteredIngestSourcesRows } = useFilteredIngestedSourcesRows();
    const { setFailedImportData } = useDataManagementContext();
    const { isPersonasEnabled } = useIsPersonasEnabled();
    const { updateFilePersonas } = useUpdateFilePersonas();
    const { enableSourceImportByAdminAndExpert } = useScopSettings();
    const { isServerlessIndex } = useServerlessIndex();

    const data = [{ 'FILE NAME': '', MODEL: '', PERSONAS: '' }];

    const handleDataFromImport = (importedData) => {
        const newData = importedData.map((row) => {
            const personasArr = splitAndCleanStringToArray(
                row['PERSONAS'],
                /[\n,]+/
            );
            const newPersonas = personasArr.map(
                (persona) => personaNameToId[persona]
            );
            return {
                filename: row['FILE NAME'],
                model: row['MODEL'],
                new_tags: newPersonas.length ? newPersonas : [defaultPersona.id]
            };
        });
        updateFilePersonas(newData);
    };

    const onImport = () => {
        onImportFile((importedData) => {
            const sentences = validateData(
                importedData,
                ingestedSourceRows,
                personasNames
            );
            if (!sentences.length) {
                handleDataFromImport(importedData);
            } else {
                setFailedImportData(sentences);
            }
        });
    };

    const onExport = () => {
        const fileName = `Sources_${new Date().toISOString()}.csv`;
        const fileData = filteredIngestSourcesRows.map((row) => {
            return {
                'FILE NAME': row.document_name,
                MODEL: row.modelDisplay,
                'UPLOAD DATE': prepareDate(row.created_at),
                USERNAME: row.created_by,
                PERSONAS: row.personas
                    .map((personaId) => personaIdToName[personaId])
                    .join(', '),
                'INGEST STATUS': row.status
            };
        });
        onExportFile(fileData, fileName);
    };

    const onExportTemplate = () => {
        const fileName = 'Personas template.csv';
        onExportFile(data, fileName);
    };

    const rows: PopoverRow[] = [
        {
            show: enableSourceImportByAdminAndExpert,
            title: t('common.export'),
            onClick: onExport,
            dataTestId: 'data-management-menu-item-export'
        },
        {
            show: true,
            title: t('common.import'),
            onClick: onImport,
            dataTestId: 'data-management-menu-item-import',
            withSeparator: false,
            isDisabled: !isPersonasEnabled || isServerlessIndex,
            tooltip: !isPersonasEnabled ? t('add-persona.feature-disabled') : ''
        },
        {
            show: true,
            title: t('ingested-sources.download-personas-template'),
            onClick: onExportTemplate,
            dataTestId: 'data-management-menu-item-download-template'
        }
    ];
    const hasRowsToShow = rows.some((row) => row.show);
    return { rows, hasRowsToShow };
};

export const useFilteredIngestedSourcesRows = () => {
    const { filteredIngestSourcesRows, setFilteredIngestSourcesRows } =
        useDataManagementContext();
    return { filteredIngestSourcesRows, setFilteredIngestSourcesRows };
};

export const useDeleteSourcesByModel = () => {
    const { disableRows, enableRows } = useIngestedSourcesDisabledRows();
    const { tenantId } = useTenant();
    const { deleteIngestedSourceRows } = useIngestedSourceRows();
    const { selectedIngestedSourceRowIdsSet, setSelectedIngestedSourceRows } =
        useDataManagementContext();
    const { deleteSourceFiles } = useSourceFiles();

    const deleteSourcesByModel = async (rows: IngestedSourcesRow[]) => {
        disableRows(rows);
        const filesByModel = {};
        const listOfFilesByModel = [];
        rows.forEach((row) => {
            if (!filesByModel[row.tenant_model]) {
                filesByModel[row.tenant_model] = [];
            }
            filesByModel[row.tenant_model].push(row.document_name);
        });
        for (const model in filesByModel) {
            if (filesByModel.hasOwnProperty(model)) {
                listOfFilesByModel.push({
                    model_name: model,
                    document_names: filesByModel[model]
                });
            }
        }
        try {
            await Backend.deleteFileByModels(tenantId, listOfFilesByModel);
            deleteIngestedSourceRows(rows.map((row) => row.id));
            const idsInSelectedRows = new Set();
            rows.forEach((row) => {
                if (selectedIngestedSourceRowIdsSet.has(row.id)) {
                    idsInSelectedRows.add(row.id);
                }
            });
            setSelectedIngestedSourceRows((prevRows: IngestedSourcesRow[]) =>
                prevRows.filter((prevRow) => !idsInSelectedRows.has(prevRow.id))
            );

            deleteSourceFiles(rows.map((row) => row.document_name));
        } catch (ex: any) {
            console.error('Failed to delete document.', ex);
            window.sentry.log(ex);
        } finally {
            enableRows(rows);
        }
    };
    return { deleteSourcesByModel };
};

export const useUpdateFilePersonas = () => {
    const {
        ingestedSourceRows,
        setIngestedSourceRows,
        updatingPersonas,
        setUpdatingPersonas,
        setIsPersonasProcessPendingModalOpen
    } = useDataManagementContext();
    const { tenantId } = useTenant();
    const { disableRows, enableRows } = useIngestedSourcesDisabledRows();

    const { setTaskId } = useTaskStatusInterval({
        tenantId: tenantId,
        successCallback: onSuccessUpdateFilePersonas
    });

    useEffect(() => {
        ingestedSourceRowsRef.current = ingestedSourceRows;
    }, [ingestedSourceRows]);

    const dataRef = useRef<EditedPersonasOfFile[]>([]);
    const rowsRef = useRef<IngestedSourcesRow[]>([]);
    const ingestedSourceRowsRef = useRef<IngestedSourcesRow[]>([]);

    function onSuccessUpdateFilePersonas() {
        const updatedRowsById = {};
        dataRef.current.forEach(
            (row) => (updatedRowsById[row.filename + row.model] = row.new_tags)
        );
        const newRows = ingestedSourceRowsRef.current.map((row) => {
            const newPersonas =
                updatedRowsById[row.document_name + row.tenant_model];
            if (newPersonas) {
                return {
                    ...row,
                    personas: newPersonas
                };
            }
            return row;
        });
        const completedIds = Object.keys(updatedRowsById);
        setUpdatingPersonas(
            (prevIds) =>
                new Set([...prevIds].filter((id) => !completedIds.includes(id)))
        );
        setIngestedSourceRows(newRows);
        enableRows(rowsRef.current);
    }

    const updateFilePersonas = async (
        data: EditedPersonasOfFile[],
        taskId: string = ''
    ) => {
        setUpdatingPersonas(
            (prevIds) =>
                new Set([
                    ...prevIds,
                    ...data.map((row) => row.filename + row.model)
                ])
        );

        const uniqeName = data.map((row) => row.filename + row.model);
        const rows = ingestedSourceRows.filter((row) =>
            uniqeName.includes(row.document_name + row.tenant_model)
        );
        rowsRef.current = rows;
        dataRef.current = data;
        disableRows(rows);
        if (taskId) {
            setTaskId(taskId);
            return;
        }
        try {
            const res = await Backend.updateFilePersonas(tenantId, data);
            setTaskId(res.data.task_id);
        } catch (error: any) {
            setIsPersonasProcessPendingModalOpen(shouldShowPersonasProcessPendingModal(error));
        }
    };

    return { updateFilePersonas, updatingPersonas };
};

export const useHandleIngestTasks = () => {
    const {
        ingestedSourceRows,
        setUpdatingPersonas,
        setTriggerFetchIngestSources,
        areIngestedSourcesFetching,
        setAreIngestedSourcesFetching
    } = useDataManagementContext();
    const { disableRows, setIngestedSourcesDisabledRowIds } =
        useIngestedSourcesDisabledRows();
    const { tenantId } = useTenant();
    const { setTasks } = useHandleMultiplePersonaTasks();

    const { setTaskId } = useTaskStatusInterval({
        tenantId: tenantId,
        successCallback: successCallback,
        failureCallback: () => console.error('Error')
    });

    function successCallback() {
        checkForRunningTasks(true);
    }

    const checkForRunningTasks = async (
        alreadyMadeRun: boolean = false,
        taskType: TaskTypes = null
    ) => {
        const response = await Backend.getRunningTasksData(tenantId, taskType);
        handleTasks(response.data, alreadyMadeRun);
    };

    const handleTasks = (data, alreadyMadeRun: boolean = false) => {
        if (data.length === 0) {
            setIngestedSourcesDisabledRowIds(new Set());
            setAreIngestedSourcesFetching(false);
            setUpdatingPersonas(new Set());
            if (alreadyMadeRun) {
                setTriggerFetchIngestSources(true);
            }
            return;
        }
        let newTaskId = null;
        const specificTasks = [];
        let isPersonaTaskProcessing = false;
        data.some((task) => {
            if (
                [
                    TaskTypes.COPY_PERSONA,
                    TaskTypes.CREATE_PERSONA,
                    TaskTypes.INITIAL_PERSONAS
                ].includes(task.type)
            ) {
                newTaskId = task.id;
                return true;
            } else if (
                [TaskTypes.UPDATE_TAGS, TaskTypes.INGEST].includes(task.type)
            ) {
                if (
                    !isPersonaTaskProcessing &&
                    task.type === TaskTypes.UPDATE_TAGS
                ) {
                    isPersonaTaskProcessing = true;
                }
                specificTasks.push(task);
            }
            return false;
        });

        if (newTaskId) {
            setUpdatingPersonas(new Set([UPDATING_ALL]));
            setTaskId(newTaskId);
            disableRows(ingestedSourceRows);
        } else if (specificTasks.length > 0) {
            if (!isPersonaTaskProcessing) {
                setUpdatingPersonas(new Set());
            }
            setIngestedSourcesDisabledRowIds(new Set());
            const tasks: RunningTask[] = [];
            const disableIngestRows = new Set();
            const disablePersonaUpdatingRows: Set<string> = new Set();
            specificTasks.forEach((task) => {
                const parsedData = JSON.parse(task.task_data);
                if (task.type === TaskTypes.INGEST) {
                    disableIngestRows.add(
                        parsedData.document_name + parsedData.model_name
                    );
                } else {
                    parsedData.files.forEach((file) => {
                        disablePersonaUpdatingRows.add(
                            file.filename + file.model
                        );
                    });
                    tasks.push({
                        id: task.id,
                        files: parsedData.files
                    });
                }
            });
            setTasks(tasks);
            if (disablePersonaUpdatingRows.size > 0) {
                setUpdatingPersonas(
                    (prevIds) =>
                        new Set([...prevIds, ...disablePersonaUpdatingRows])
                );
            }
            disableRows(
                ingestedSourceRows.filter(
                    (row) =>
                        disableIngestRows.has(
                            row.document_name + row.tenant_model
                        ) ||
                        disablePersonaUpdatingRows.has(
                            row.document_name + row.tenant_model
                        )
                )
            );
        } else {
            setIngestedSourcesDisabledRowIds(new Set());
            setUpdatingPersonas(new Set());
            if (alreadyMadeRun) {
                setTriggerFetchIngestSources(true);
            }
        }
        if (areIngestedSourcesFetching) {
            setAreIngestedSourcesFetching(false);
        }
    };

    return { checkForRunningTasks };
};

export const useHandleMultiplePersonaTasks = () => {
    const { tenantId } = useTenant();
    const { ingestedSourceRows, setIngestedSourceRows } =
        useDataManagementContext();
    const { enableRows } = useIngestedSourcesDisabledRows();
    const { setUpdatingPersonas } = useDataManagementContext();
    const [tasks, setTasks] = useState<RunningTask[]>([]);

    useEffect(() => {
        ingestedSourceRowsRef.current = ingestedSourceRows;
    }, [ingestedSourceRows]);

    const ingestedSourceRowsRef = useRef<IngestedSourcesRow[]>([]);

    useEffect(() => {
        const checkTaskStatus = async (taskId: string) => {
            try {
                const response = await Backend.getTaskStatus(tenantId, taskId);
                return response.data.status;
            } catch (error) {
                console.error(
                    `Error checking status for task ${taskId}:`,
                    error
                );
                return null;
            }
        };

        const intervalIds: NodeJS.Timeout[] = [];

        tasks.forEach((task) => {
            const intervalId = setInterval(async () => {
                const status = await checkTaskStatus(task.id);
                if (status === 'Done') {
                    handleCompletedTask(task.id, task.files);
                    clearInterval(intervalId);
                } else if (status === 'Error') {
                    clearInterval(intervalId);
                }
            }, 7500);

            intervalIds.push(intervalId);
        });

        return () => {
            intervalIds.forEach((id) => clearInterval(id));
        };
    }, [tasks]);

    const handleCompletedTask = (
        taskId: string,
        files: EditedPersonasOfFile[]
    ) => {
        const updatedRowsById = {};
        const rowsToEnable = [];
        files.forEach(
            (row) => (updatedRowsById[row.filename + row.model] = row.new_tags)
        );
        const newRows = ingestedSourceRowsRef.current.map((row) => {
            const newPersonas =
                updatedRowsById[row.document_name + row.tenant_model];
            if (newPersonas) {
                rowsToEnable.push(row);
                return {
                    ...row,
                    personas: newPersonas
                };
            }
            return row;
        });
        const completedIds = Object.keys(updatedRowsById);
        setUpdatingPersonas(
            (prevIds) =>
                new Set([...prevIds].filter((id) => !completedIds.includes(id)))
        );
        setIngestedSourceRows(newRows);
        enableRows(rowsToEnable);
    };

    return { setTasks };
};

export const useFetchIngestData = () => {
    const { fetchIngestedSourceRowsAndPersonas } = useIngestedSourceRows();
    const { triggerFetchIngestSources, setTriggerFetchIngestSources } =
        useDataManagementContext();

    useEffect(() => {
        if (triggerFetchIngestSources) {
            fetchIngestedSourceRowsAndPersonas(true);
            setTriggerFetchIngestSources(false);
        }
    }, [triggerFetchIngestSources]);

    return { fetchIngestedSourceRowsAndPersonas, setTriggerFetchIngestSources };
};
