import { FC, useState } from 'react';
import styles from './Cells.module.scss';
import classnames from 'classnames/bind';
import { Popover } from '@mui/material';
import { ChevronIcon } from 'aqui/icons';

const cx = classnames.bind(styles);

interface DropdownCellProps {
    className?: string;
    title: any;
    content: any;
}

const DropdownCell: FC<DropdownCellProps> = ({
    className = '',
    content,
    title
}) => {
    const [anchorEl, setAnchorEl] = useState<Element>(null);
    return (
        <>
            <div
                className={cx(className, 'dropdownCell__container')}
                data-testid="dropdown-cell"
                onClick={(e) => setAnchorEl(e.currentTarget)}
            >
                {title}
                <ChevronIcon
                    direction="down"
                    className={cx('dropdownCell__chevron', {
                        '--open': Boolean(anchorEl)
                    })}
                />
            </div>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                onClick={() => setAnchorEl(null)}
            >
                {content}
            </Popover>
        </>
    );
};

export default DropdownCell;
