import React, {
    FunctionComponent,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import { CheckMarkIcon, CrossMarkIcon } from 'aqui/icons';
import useAnalytics from '../../../hooks/useAnalytics';
import { ANALYTICS_EVENTS } from '../../../consts';

import SimpleMDE from 'react-simplemde-editor';
import 'easymde/dist/easymde.min.css';
import { Typography } from 'aqui';
import { t } from 'i18next';

type MessageEditingProps = {
    text: string;
    onClickCancel: () => void;
    onClickSave: (x: string) => void;
    allowEmptyText?: boolean;
    className?: string;
};

const MessageEditing: FunctionComponent<MessageEditingProps> = ({
    text,
    onClickCancel,
    onClickSave,
    allowEmptyText = true,
    className = ''
}) => {
    const { sendEvent } = useAnalytics();

    const [newText, setNewText] = useState('');

    const textareaRef = useRef<HTMLTextAreaElement>();

    const options = useMemo(
        () => ({
            toolbar: ['bold', 'unordered-list', 'ordered-list'] as const,
            status: false,
            toolbarTips: true,
            spellChecker: false,
            autofocus: true
        }),
        []
    );

    useLayoutEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height =
                textareaRef.current.scrollHeight + 'px';
            textareaRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [textareaRef.current]);

    useLayoutEffect(() => {
        if (textareaRef?.current) {
            if (
                textareaRef.current.scrollHeight >
                textareaRef.current.clientHeight
            ) {
                textareaRef.current.style.height =
                    25 + textareaRef.current.scrollHeight + 'px';
            }
        }
    }, [textareaRef?.current?.scrollHeight]);

    useEffect(() => {
        setNewText(text);
    }, []);

    const shouldDisableSave = () => {
        return newText === text || (!allowEmptyText && !/[^\s]/.test(newText));
    };

    const cancelClick = () => {
        sendEvent(ANALYTICS_EVENTS.CLICKED_CANCEL_ON_EDIT_MODE, {
            location: 'UI'
        });
        onClickCancel();
    };

    const saveClick = () => {
        if (shouldDisableSave()) {
            return;
        }
        sendEvent(ANALYTICS_EVENTS.CLICKED_SAVE_ON_EDIT_MODE, {
            location: 'UI'
        });
        onClickSave(newText);
    };

    return (
        <div className="messageEditing__container">
            <div className="messageEditing__instructions">
                <Typography type="small2SemiBold">
                    {t('chat.edit-box-new-paragraph')}
                </Typography>
            </div>
            <SimpleMDE
                value={newText}
                onChange={setNewText}
                options={options}
            />
            <div className="messageEditing__message-text-area__btns-container">
                <div className="flex align-center messageEditing__message-text-area__btns">
                    <div className="flex align-center messageEditing__message-text-area__btn crossmark">
                        <CrossMarkIcon onClick={cancelClick} />
                    </div>
                    <div
                        className={`flex align-center messageEditing__message-text-area__btn checkmark ${
                            shouldDisableSave() ? 'disabled' : ''
                        }`}
                    >
                        <CheckMarkIcon onClick={saveClick} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MessageEditing;
