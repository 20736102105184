import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Util from './Util';

export const translations = (language) => {
    return i18n
        .use(Backend)
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
            fallbackLng: 'en',
            debug: false,
            backend: {
                loadPath: (lngs, namespaces) => {
                    if (process.env.NODE_ENV !== 'development') {
                        return Util.resolveUri(
                            `/locales/${language}/translation.json`
                        );
                    } else {
                        return `http://localhost:3003/locales/${language}/translation.json`;
                    }
                }
            },
            interpolation: {
                escapeValue: false // not needed for react as it escapes by default
            }
        });
};
