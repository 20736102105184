import { FC, useMemo } from 'react';
import { Typography } from 'aqui';
import classnames from 'classnames/bind';

import styles from './Cells.module.scss';
import { Tooltip } from '@mui/material';
import ValueWithTooltip from '../../ValueWithTooltip/ValueWithTooltip';

interface MultiSelectCellProps2 {
    chips: string[];
    isDisabled?: boolean;
    className?: string;
    maxChipsToShow?: number;
}

const cx = classnames.bind(styles);
const ChipsCell: FC<MultiSelectCellProps2> = ({
    chips = [],
    isDisabled,
    className,
    maxChipsToShow = 3
}) => {
    const [visibleChips, hiddenChips] = useMemo(() => {
        if (chips.length <= maxChipsToShow) {
            return [chips, []];
        }
        return [chips.slice(0, maxChipsToShow), chips.slice(maxChipsToShow)];
    }, [chips]);

    return (
        <div
            className={cx(className, 'ChipsCell__container', {
                '--disabled': isDisabled
            })}
            data-testid="chips-cell"
        >
            {visibleChips.map((item) => (
                <ValueWithTooltip
                    type="small1"
                    dataTestId="chip-item"
                    title={item}
                    className={cx('ChipsCell__item')}
                    key={item}
                />
            ))}
            {hiddenChips.length > 0 && (
                <Tooltip
                    title={hiddenChips.join(', ')}
                    placement="top"
                    classes={{
                        tooltip: 'tooltip'
                    }}
                >
                    <Typography
                        data-testid="chips-show-more"
                        type="small1"
                        className={cx(
                            'ChipsCell__item',
                            'ChipsCell__item__hidden-num'
                        )}
                    >
                        +{hiddenChips.length}
                    </Typography>
                </Tooltip>
            )}
        </div>
    );
};

export default ChipsCell;
