import { useMemo, useState } from 'react';
import { Typography } from 'aqui';
import { t } from 'i18next';
import { adjustIntegratedModel } from '../../../utils/productUtils';
import ModelSelect from '../../ModelSelect/ModelSelect';
import {
    ANALYTICS_EVENTS,
    NO_MODEL_MESSAGE_MAX_MODELS_TO_SHOW
} from '../../../consts';
import useAnalytics from '../../../hooks/useAnalytics';
import { useIntegratedModels } from '../../../AppContext';
import ModelList from '../ModelList/ModelList';
import { IntegratedModel } from '../../../types';

const NoModelMessage = ({ availableModels, setModel }) => {
    const [open, setIsOpen] = useState(false);
    const [selectedModel, setSelectedModel] = useState(null);
    const { sendEvent } = useAnalytics();
    const { integratedModels } = useIntegratedModels();

    const mainModelsList = useMemo(() => {
        const updatedList = [
            ...availableModels.slice(0, NO_MODEL_MESSAGE_MAX_MODELS_TO_SHOW)
        ];

        if (selectedModel && !updatedList.includes(selectedModel)) {
            updatedList.push(selectedModel);
        }

        return updatedList;
    }, [availableModels, selectedModel]);

    const showMessage = () => {
        if (availableModels.length > 0) {
            const singleModel = availableModels.length === 1;
            return (
                <Typography type="subtitle1" className="NoModelMessage__header">
                    {t(
                        `no-models.${
                            singleModel ? 'found-model' : 'found-models'
                        }`
                    )}{' '}
                    <span className="bold">
                        {
                            t(
                                `no-models.${
                                    singleModel
                                        ? 'please-select-it'
                                        : 'please-select'
                                }`
                            ) as string
                        }
                    </span>{' '}
                    {t(`no-models.${'search-alternative'}`)}
                </Typography>
            );
        }
        return (
            <Typography type="subtitle1" className="NoModelMessage__header">
                {t('no-models.no-found')}
            </Typography>
        );
    };

    const showModelSearch = mainModelsList.length < integratedModels.length;

    const onModelSelect = (model: IntegratedModel) => {
        setModel(model);
        setSelectedModel(model);
        sendEvent(ANALYTICS_EVENTS.SELECT_DIFFERENT_MODEL_CLICK, {
            'Model value': adjustIntegratedModel(model)
        });
    };

    return (
        <div className="NoModelMessage__container">
            {showMessage()}
            <ModelList
                mainModelsList={mainModelsList}
                selectedModel={selectedModel}
                onModelSelect={(model) => onModelSelect(model)}
            />
            {showModelSearch && (
                <ModelSelect
                    prioritizedIntegratedModels={availableModels}
                    isOpen={open}
                    setIsOpen={setIsOpen}
                    setSelectedValue={onModelSelect}
                    isSearchSelect
                    disabled={!!selectedModel}
                    searchPlaceholder={t('no-models.search-placeholder')}
                />
            )}
        </div>
    );
};

export default NoModelMessage;
