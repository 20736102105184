const SmallCircleProcessingIcon = ({ color = 'currentColor', ...props }) => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <rect width="16" height="16" rx="8" fill={color} />
        <path
            d="M8.5 3C8.5 2.72386 8.27614 2.5 8 2.5C7.72386 2.5 7.5 2.72386 7.5 3V5C7.5 5.27614 7.72386 5.5 8 5.5C8.27614 5.5 8.5 5.27614 8.5 5V3Z"
            fill="white"
        />
        <path
            d="M8.5 11C8.5 10.7239 8.27614 10.5 8 10.5C7.72386 10.5 7.5 10.7239 7.5 11V13C7.5 13.2761 7.72386 13.5 8 13.5C8.27614 13.5 8.5 13.2761 8.5 13V11Z"
            fill="white"
        />
        <path
            d="M4.11129 4.11144C4.30655 3.91618 4.62314 3.91618 4.8184 4.11144L6.2334 5.52644C6.42866 5.72171 6.42866 6.03829 6.2334 6.23355C6.03814 6.42881 5.72155 6.42881 5.52629 6.23355L4.11129 4.81855C3.91603 4.62329 3.91603 4.30671 4.11129 4.11144Z"
            fill="white"
        />
        <path
            d="M10.4737 9.76644C10.2784 9.57118 9.96183 9.57118 9.76656 9.76644C9.5713 9.9617 9.5713 10.2783 9.76656 10.4735L11.1816 11.8885C11.3768 12.0838 11.6934 12.0838 11.8887 11.8885C12.0839 11.6933 12.0839 11.3767 11.8887 11.1814L10.4737 9.76644Z"
            fill="white"
        />
        <path
            d="M2.5 8C2.5 7.72386 2.72386 7.5 3 7.5H5C5.27614 7.5 5.5 7.72386 5.5 8C5.5 8.27614 5.27614 8.5 5 8.5H3C2.72386 8.5 2.5 8.27614 2.5 8Z"
            fill="white"
        />
        <path
            d="M11 7.5C10.7239 7.5 10.5 7.72386 10.5 8C10.5 8.27614 10.7239 8.5 11 8.5H13C13.2761 8.5 13.5 8.27614 13.5 8C13.5 7.72386 13.2761 7.5 13 7.5H11Z"
            fill="white"
        />
        <path
            d="M6.2334 9.76644C6.42866 9.9617 6.42866 10.2783 6.2334 10.4735L4.8184 11.8885C4.62313 12.0838 4.30655 12.0838 4.11129 11.8885C3.91603 11.6933 3.91603 11.3767 4.11129 11.1814L5.52629 9.76644C5.72155 9.57118 6.03814 9.57118 6.2334 9.76644Z"
            fill="white"
        />
        <path
            d="M11.8887 4.81855C12.0839 4.62329 12.0839 4.3067 11.8887 4.11144C11.6934 3.91618 11.3768 3.91618 11.1816 4.11144L9.76656 5.52644C9.5713 5.72171 9.5713 6.03829 9.76656 6.23355C9.96183 6.42881 10.2784 6.42881 10.4737 6.23355L11.8887 4.81855Z"
            fill="white"
        />
    </svg>
);

export default SmallCircleProcessingIcon;
