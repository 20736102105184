import { ReactNode } from 'react';
import { Typography } from 'aqui';

type PreviewBannerProps = {
    show: boolean;
    children?: ReactNode;
};

const PreviewBanner = (props: PreviewBannerProps) => {
    return (
        <Typography
            type="body3"
            tag="div"
            className={`pdf-viewer__message ${
                props.show ? 'pdf-viewer__message--show' : ''
            }`}
            dataTestId="pdf-viewer-message"
            data-open={props.show}
        >
            {props.children}
        </Typography>
    );
};

export default PreviewBanner;
