import { useEffect, useState } from 'react';
import { Typography, SearchInput } from 'aqui';
import { MagnifyingGlassIcon, CrossMarkIcon, CheckMarkIcon } from 'aqui/icons';
import SortArrows from '../../SVG/SortArrows';
import { Popover, Tooltip } from '@mui/material';
import { useThreadsHandling } from '../../AppContext';
import { SortingType } from '../../types';
import useAnalytics from '../../hooks/useAnalytics';
import { ANALYTICS_EVENTS } from '../../consts';
import { t } from 'i18next';
import { capitalizeFirstLetter } from '../../utils/utils';

const ThreadsPanelHeader = () => {
    const {
        threadsSortingType,
        onChangeSortingType,
        onSearchThread,
        setChatHistoryData
    } = useThreadsHandling();
    const { sendEvent } = useAnalytics();

    const [searchOpen, setSearchOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [searchText, setSearchText] = useState<string | null>(null);

    useEffect(() => {
        if (searchOpen) {
            const timeout = setTimeout(() => {
                if (!searchText || searchText.length > 1) {
                    setChatHistoryData([]);
                    onSearchThread(searchText);
                }
            }, 2000);
            return () => clearTimeout(timeout);
        }
    }, [searchText]);

    const onClickSearchIcon = () => {
        const threadsPanelBody = document.getElementsByClassName(
            'threadsPanel__body'
        ) as HTMLCollectionOf<HTMLElement>;
        if (searchOpen) {
            threadsPanelBody[0].style.height = 'calc(100% - 85px)';
        } else {
            threadsPanelBody[0].style.height = 'calc(100% - 85px - 35px)';
        }
        setSearchOpen(!searchOpen);
    };

    const onClickSort = (sortingType: SortingType) => {
        setChatHistoryData([]);
        onChangeSortingType(sortingType);
        sendEvent(ANALYTICS_EVENTS.ON_SORT_THREADS, {
            'order by': sortingType
        });
    };

    return (
        <div>
            <div
                className={`flex align-items space-between threadsPanel__header ${
                    searchOpen ? 'withSearchInput' : ''
                }`}
            >
                <Typography type="h6">
                    {capitalizeFirstLetter(t('threads.chat-history'))}
                </Typography>
                <div className="flex align-center justify-center threadsPanel__header__icons">
                    <Tooltip
                        title={
                            t(
                                `threads.${
                                    threadsSortingType === SortingType.Date
                                        ? 'sorted-by-date'
                                        : 'sorted-by-model'
                                }`
                            ) as string
                        }
                        placement="top"
                        classes={{
                            tooltip: 'tooltip tooltip__threadsPanel'
                        }}
                    >
                        <div
                            className={`threadsPanel__header__sort ${
                                anchorEl ? 'selected' : ''
                            }`}
                            onClick={(e) => setAnchorEl(e.currentTarget)}
                            data-active={anchorEl}
                            data-testid="threads-sort-button"
                        >
                            <SortArrows color="currentcolor" />
                        </div>
                    </Tooltip>
                    <Popover
                        open={!!anchorEl}
                        anchorEl={anchorEl}
                        onClose={() => setAnchorEl(null)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                    >
                        <SortPopover
                            sortBy={threadsSortingType}
                            setSortBy={onClickSort}
                            setAnchorEl={setAnchorEl}
                        />
                    </Popover>
                    <Tooltip
                        title={
                            searchOpen
                                ? ''
                                : (t('threads.search-chat-history') as string)
                        }
                        placement="top"
                        classes={{
                            tooltip: 'tooltip tooltip__threadsPanel'
                        }}
                    >
                        <div
                            className="threadsPanel__header__search"
                            onClick={onClickSearchIcon}
                            data-testid="threads-search-button"
                            data-active={searchOpen}
                        >
                            {searchOpen ? (
                                <CrossMarkIcon />
                            ) : (
                                <MagnifyingGlassIcon />
                            )}
                        </div>
                    </Tooltip>
                </div>
            </div>
            {searchOpen && (
                <div className="threadsPanel__header__searchInput">
                    <SearchInput
                        className="full-width"
                        placeholder={`${t('threads.search-chat-history')}`}
                        value={searchText}
                        setValue={setSearchText}
                        autoFocus
                        dataTestId="threads-search-input"
                    />
                </div>
            )}
        </div>
    );
};

export default ThreadsPanelHeader;

const SortPopover = ({ sortBy, setSortBy, setAnchorEl }) => {
    const onClickItem = (sort) => {
        setAnchorEl(null);
        setSortBy(sort);
    };
    return (
        <div className="threadsSortPopover__container">
            <div
                className={`flex align-center threadsSortPopover__option ${
                    sortBy === SortingType.Date ? 'selected' : ''
                }`}
                onClick={() => onClickItem(SortingType.Date)}
            >
                {sortBy === SortingType.Date ? (
                    <CheckMarkIcon />
                ) : (
                    <div className="threadsSortPopover__empty-icon"></div>
                )}
                <Typography type="subtitle3">
                    {t('threads.sort-by-date')}
                </Typography>
            </div>
            <div
                className={`flex align-center threadsSortPopover__option ${
                    sortBy === SortingType.Model ? 'selected' : ''
                }`}
                onClick={() => onClickItem(SortingType.Model)}
            >
                {sortBy === SortingType.Model ? (
                    <CheckMarkIcon />
                ) : (
                    <div className="threadsSortPopover__empty-icon"></div>
                )}
                <Typography type="subtitle3">
                    {t('threads.sort-by-model')}
                </Typography>
            </div>
        </div>
    );
};
