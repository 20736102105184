import { Typography, Button } from 'aqui';
import { IngestedSourcesStatus } from '../../../types';
import OctagonWarning from '../../../SVG/OctagonWarning';
import CircleCheckMarkIcon from '../../../SVG/CircleCheckMarkIcon';
import ProcessingIcon from '../../../SVG/ProcessingIcon';
import React, { useMemo } from 'react';
import classnames from 'classnames/bind';

import styles from './Cells.module.scss';
import { CircleInfoIcon } from 'aqui/icons';
import { Tooltip } from '@material-ui/core';
import { t } from 'i18next';
import { useScopSettings } from '../../../AppContext';

const cx = classnames.bind(styles);
const IngestStatusCell = ({ row, isSelected, onButtonClick }) => {
    const { enableSourceImportByAdminAndExpert } = useScopSettings();
    const StatusIcon = useMemo(() => {
        switch (row.status) {
            case IngestedSourcesStatus.Done:
                return CircleCheckMarkIcon;
            case IngestedSourcesStatus.Processing:
            case IngestedSourcesStatus.Uploading:
            case IngestedSourcesStatus.Uploaded:
                return ProcessingIcon;
            case IngestedSourcesStatus.Failed:
                return OctagonWarning;
            default:
                return React.Fragment;
        }
    }, [row.status]);

    const shouldShowIngestButton =
        enableSourceImportByAdminAndExpert &&
        row.status === IngestedSourcesStatus.Uploaded;

    const statusLabel =
        row.status === IngestedSourcesStatus.Uploading
            ? t(`ingested-sources.status-${row.status.toLowerCase()}`) + '...'
            : row.status &&
              t(`ingested-sources.status-${row.status.toLowerCase()}`);
    return (
        <div
            data-testid="ingest-status-cell"
            className={cx('ingest-status', `--${row.status}`, {
                '--selected': isSelected
            })}
        >
            {shouldShowIngestButton ? (
                <Button
                    text={t('upload-files-modal.ingest')}
                    className="full-width"
                    onClick={onButtonClick}
                />
            ) : (
                <>
                    <Typography type="subtitle3" className="flex align-center">
                        <StatusIcon />
                        {statusLabel}
                    </Typography>
                    {row.status === IngestedSourcesStatus.Failed && (
                        <Tooltip
                            title={
                                t('ingested-sources.contact-support') as string
                            }
                            classes={{ tooltip: 'tooltip tooltip__close-top' }}
                            placement="top"
                        >
                            <CircleInfoIcon color="#2468FF" />
                        </Tooltip>
                    )}
                </>
            )}
        </div>
    );
};

export default IngestStatusCell;
