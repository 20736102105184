import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'aqui';
import { useNotValidEmails } from '../PersonaManagementHooks';
import { t } from 'i18next';
import { useState } from 'react';
import EmailValidationModalBody from './EmailValidationModalBody';

export const enum EMAIL_VALIDATION_TYPES {
    NotValid = 'notValid',
    NotExisting = 'notExisting'
}

const EmailValidationModal = () => {
    const [showMore, setShowMore] = useState(false);
    const { notValidEmails, setNotValidEmails } = useNotValidEmails();

    const persona = Object.keys(notValidEmails)[0];
    const isNotValidEmails = notValidEmails[persona]?.notValidEmails.length > 0;
    const isNotExistingEmails =
        notValidEmails[persona]?.notExistingEmails.length > 0;

    const onClickOk = () => {
        setShowMore(false);
        setNotValidEmails({});
    };

    return (
        <Modal
            isOpen={Boolean(Object.keys(notValidEmails).length)}
            closeModal={() => setNotValidEmails({})}
        >
            <ModalHeader>{t('emails-validation.incorrect-format')}</ModalHeader>
            <ModalBody className="emailValidationModal__body">
                <div className="flex flex-col emailValidationModal__container">
                    {isNotValidEmails && (
                        <EmailValidationModalBody
                            emails={notValidEmails[persona].notValidEmails}
                            type={EMAIL_VALIDATION_TYPES.NotValid}
                            persona={persona}
                        />
                    )}
                    {isNotExistingEmails && (
                        <EmailValidationModalBody
                            emails={notValidEmails[persona].notExistingEmails}
                            type={EMAIL_VALIDATION_TYPES.NotExisting}
                        />
                    )}
                </div>
            </ModalBody>
            <ModalFooter>
                <Button
                    dataTestId="ok-btn-email-validation-modal"
                    color="blue"
                    onClick={onClickOk}
                    text={t('common.ok')}
                    variant="filled"
                />
            </ModalFooter>
        </Modal>
    );
};

export default EmailValidationModal;
