import React, { useState } from 'react';
import { Typography } from 'aqui';
import { ChevronIcon } from 'aqui/icons';
import Sparkles from '../../SVG/Sparkles';
import { useRecommendedQuestions } from '../../AppContext';
import ValueWithTooltip from '../ValueWithTooltip/ValueWithTooltip';
import useAnalytics from '../../hooks/useAnalytics';
import { RecommendedQuestionsSource } from '../../types';
import { ANALYTICS_EVENTS } from '../../consts';
import { t } from 'i18next';

const RecommendedQuestions = ({ handleOnSend }) => {
    const { recommendedQuestions, recommendedQuestionsSource } =
        useRecommendedQuestions();
    const [showMore, setShowMore] = useState(false);
    const { sendEvent } = useAnalytics();
    const handleOnQuestionClick = (question) => {
        handleOnSend(question);
        sendEvent(ANALYTICS_EVENTS.RECOMMENDED_QUESTION_CLICK, {
            'Question Value': question,
            Location:
                recommendedQuestionsSource ===
                RecommendedQuestionsSource.Solution
                    ? 'Solution'
                    : 'Follow up question (FUQ)'
        });
    };

    return (
        <div className="recommendedQuestion__container">
            <div
                onClick={() => setShowMore(!showMore)}
                className="recommendedQuestion__header flex align-center"
                data-testid="recommendedQuestionsHeader"
            >
                <ChevronIcon
                    direction="right"
                    className={`recommendedQuestion__chevronIcon ${
                        showMore ? 'open' : ''
                    }`}
                />
                <Typography type="small1">{`${recommendedQuestions.length} ${t(
                    'chat.possible-details-to-ask'
                )}`}</Typography>
                <Sparkles />
            </div>
            <div
                className={`recommendedQuestion__questions flex flex-col ${
                    showMore ? 'show' : ''
                }`}
            >
                {recommendedQuestions.map((question, index) => {
                    return (
                        <div
                            key={index}
                            className="flex align-center recommendedQuestion__question"
                            data-testid={`recommendedQuestions${index}`}
                            onClick={() => handleOnQuestionClick(question)}
                        >
                            <ValueWithTooltip title={question} type="small1" />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default RecommendedQuestions;
