import React, { useState } from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Typography,
    Button,
    TextInput,
    CheckBox
} from 'aqui';
import { PlusIcon } from 'aqui/icons';
import {
    useAddPersonaModal,
    useNotValidEmails,
    usePersonasManagementRows
} from './PersonaManagementHooks';
import { t } from 'i18next';
import { Tooltip } from '@mui/material';
import AddUsersBox from './AddUsersBox';
import PersonaSelect from './PersonaSelect';
import {
    handleUsersEmailsExistence,
    returnValdinAndNotValidMails
} from '../../../utils/utils';
import { handleUsersMappedByPersona } from './PersonasManagementHelpers';

const AddPersonaModal = () => {
    const [users, setUsers] = useState<string>('');
    const [usersBoxOpen, setUsersBoxOpen] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [selectedPersona, setSelectedPersona] = useState<string>('');
    const [checked, setChecked] = useState<boolean>(false);

    const { addPersonaModalOpen, setAddPersonaModalOpen } =
        useAddPersonaModal();
    const {
        personasNames,
        personaNameToId,
        setPersonaUpdateOrCreateData,
        handleUsersAssignment,
        createPersona
    } = usePersonasManagementRows();
    const { setNotValidEmails } = useNotValidEmails();

    const onClickSubmit = async () => {
        let notValidEmails = [];
        let validEmails = [];
        if (usersBoxOpen && users.length) {
            const emails = returnValdinAndNotValidMails(users.split('\n'));
            notValidEmails = emails.notValidEmails;
            validEmails = emails.validEmails;
        }
        const { existingEmails, notExistingEmails } =
            await handleUsersEmailsExistence(validEmails);
        const assignedUsersMap = await handleUsersAssignment(existingEmails);
        const {
            usersFromSamePersonaOrNonePersona,
            usersAssignedToAnotherPersona
        } = handleUsersMappedByPersona(assignedUsersMap, '');
        setAddPersonaModalOpen(false);
        resetState();
        if (
            notValidEmails.length ||
            notExistingEmails.length ||
            Object.keys(usersAssignedToAnotherPersona).length
        ) {
            setPersonaUpdateOrCreateData({
                type: 'create',
                data: {
                    name,
                    description,
                    users: usersFromSamePersonaOrNonePersona,
                    copyFromPersonaId: checked
                        ? personaNameToId[selectedPersona]
                        : ''
                }
            });
            setNotValidEmails({
                [name]: {
                    notValidEmails,
                    notExistingEmails,
                    emailsInUse: usersAssignedToAnotherPersona
                }
            });
        } else {
            createPersona(
                name,
                description,
                usersFromSamePersonaOrNonePersona,
                checked ? personaNameToId[selectedPersona] : ''
            );
        }
    };

    const onClickCancel = () => {
        resetState();
        setAddPersonaModalOpen(false);
    };

    const disableSubmit = () => {
        return (
            name.length === 0 ||
            personasNames.some((personaName) => personaName === name)
        );
    };

    const resetState = () => {
        setName('');
        setDescription('');
        setUsers('');
        setSelectedPersona('');
        setChecked(false);
        setUsersBoxOpen(false);
    };

    const handleTextInput = (value: string) => {
        const regex = /^[\p{L}\p{M}\p{N}\p{Zs}'_-]*$/u;
        if (regex.test(value)) {
            setName(value);
        }
    };

    return (
        <Modal
            isOpen={addPersonaModalOpen}
            closeModal={() => setAddPersonaModalOpen(false)}
        >
            <ModalHeader>{t('add-persona.add-persona-btn')}</ModalHeader>
            <ModalBody className="addPersonaModal__body-container">
                <div className="flex flex-col addPersonaModal__body">
                    <div className="flex flex-col addPersonaModal__add-name">
                        <Typography type="subtitle3">
                            {t('add-persona.new-persona-name-title')}
                        </Typography>
                        <TextInput
                            placeholder={t(
                                'add-persona.new-persona-name-placeholder'
                            )}
                            value={name}
                            dataTestId="add-persona-name-input"
                            setValue={handleTextInput}
                        />
                    </div>
                    <div className="flex flex-col addPersonaModal__add-description">
                        <Typography type="subtitle3">
                            {t('add-persona.new-persona-description-title')}
                        </Typography>
                        <TextInput
                            dataTestId="add-persona-description-input"
                            placeholder={t(
                                'add-persona.new-persona-description-placeholder'
                            )}
                            value={description}
                            setValue={setDescription}
                        />
                    </div>
                    <div>
                        {!usersBoxOpen ? (
                            <Tooltip
                                title={
                                    t('add-persona.add-users-tooltip') as string
                                }
                                placement="top"
                                classes={{ tooltip: 'tooltip' }}
                            >
                                <Button
                                    text={t('add-persona.add-users')}
                                    variant="text"
                                    IconComponent={PlusIcon}
                                    iconPosition="left"
                                    dataTestId="add-users-button"
                                    onClick={() => setUsersBoxOpen(true)}
                                />
                            </Tooltip>
                        ) : (
                            <AddUsersBox
                                dataTestId="add-users-text-area"
                                value={users}
                                setValue={setUsers}
                                className={'addUsersBox__textarea'}
                            />
                        )}
                    </div>
                    <div className="flex align-center">
                        <CheckBox
                            dataTestId="copy-sources-checkbox"
                            setChecked={setChecked}
                            checked={checked}
                        />
                        <Typography
                            type="subtitle3"
                            onClick={() => setChecked(!checked)}
                        >
                            {t('add-persona.copy-sources-from')}
                        </Typography>
                        <PersonaSelect
                            selectedPersona={selectedPersona}
                            setSelectedPersona={setSelectedPersona}
                            menuClassName={usersBoxOpen ? 'height-limit' : ''}
                        />
                    </div>
                </div>
            </ModalBody>

            <ModalFooter>
                <Button
                    dataTestId="cancel-button-add-persona"
                    color="blue"
                    onClick={onClickCancel}
                    text={t('common.cancel')}
                    variant="outlined"
                />
                <Tooltip
                    title={
                        disableSubmit()
                            ? (t('add-persona.name-error') as string)
                            : ''
                    }
                    placement="top"
                >
                    <Button
                        dataTestId="submit-button-add-persona"
                        color="blue"
                        onClick={onClickSubmit}
                        text={t('common.submit')}
                        variant="filled"
                        style={{ marginLeft: '11px' }}
                        disabled={disableSubmit()}
                    />
                </Tooltip>
            </ModalFooter>
        </Modal>
    );
};

export default AddPersonaModal;
