export const COLUMNS = {
    PERSONA_NAME: 'name',
    DESCRIPTION: 'description',
    SOURCES: 'source_count',
    USERS: 'user_count',
    LAST_UPDATED: 'updated_at',
    ACTIVE: 'active'
};

export const PERSONA_MANAGEMENT_PINNED_COLUMNS = {
    left: [COLUMNS.PERSONA_NAME]
};

export const PERSONA_CONSTANT_NAMES = {
    DEFAULT: 'Default',
    ALL_SOURCES: 'All sources'
};

export const IMPORT_COLUMNS = ['PERSONA NAME', 'DESCRIPTION', 'USERS'];

export const MAX_SELECTED_PERSONAS_ALLOWED = 14;
export const MAX_PERSONAS_ALLOWED = 15;
