import { FunctionComponent } from 'react';
import { t } from 'i18next';
import { Typography } from 'aqui';

import { Tooltip } from '@mui/material';

type EditResponseModalMetadataProps = {
    chatId?: string;
    modelDisplay: string;
    initialQuestion: string;
    onQuestionClick: () => void;
    persona?: string;
    isConcise?: boolean;
};

const EditResponseModalMetadata: FunctionComponent<
    EditResponseModalMetadataProps
> = ({
    chatId,
    modelDisplay,
    initialQuestion,
    onQuestionClick,
    persona,
    isConcise
}) => {
    return (
        <div className="edit-response-modal__metadata">
            <div className="edit-response-modal__metadata__item">
                <Typography type="subtitle3">{t('model.model')}</Typography>
                <Typography type="subtitle3">{modelDisplay}</Typography>
            </div>
            <div className="edit-response-modal__metadata__item">
                <Typography type="subtitle3">
                    {t('expert-responses-dm.initial-question')}
                </Typography>
                <Tooltip
                    title={
                        chatId
                            ? (t('expert-responses-dm.open-chat') as string)
                            : ''
                    }
                    classes={{ tooltip: 'tooltip' }}
                    placement="top"
                >
                    <Typography
                        type="subtitle3"
                        className="edit-response-modal__link"
                        onClick={chatId && onQuestionClick}
                    >
                        {initialQuestion}
                    </Typography>
                </Tooltip>
            </div>
            {persona && (
                <div className="edit-response-modal__metadata__item">
                    <Typography type="subtitle3">
                        {t('data-management.persona')}
                    </Typography>
                    <Typography type="body3">{persona}</Typography>
                </div>
            )}
            {isConcise !== undefined && (
                <div className="edit-response-modal__metadata__item">
                    <Typography type="subtitle3">
                        {t('edit-response.concise-response')}
                    </Typography>
                    <Typography type="body3">
                        {isConcise ? t('common.yes') : t('common.no')}
                    </Typography>
                </div>
            )}
        </div>
    );
};

export default EditResponseModalMetadata;
