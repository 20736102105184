import { FC, useMemo } from 'react';
import { Typography } from 'aqui';
import { ReviewStatus } from '../../../types';
import { t } from 'i18next';
import SmallCircleProcessingIcon from '../../../SVG/SmallCircleProcessingIcon';
import SmallCircleDoubleChevronIcon from '../../../SVG/SmallCircleDoubleChevronIcon';
import CheckMarkRoundSmallIcon from '../../../SVG/CheckMarkRoundSmallIcon';

interface FeedbackStatusOptionProps {
    status: ReviewStatus;
    className?: string;
}

const FeedbackStatusOption: FC<FeedbackStatusOptionProps> = ({
    status,
    className = ''
}) => {
    const [icon, translation, statusClassName] = useMemo(() => {
        switch (status) {
            case ReviewStatus.InReview:
                return [
                    <SmallCircleProcessingIcon />,
                    t('feedbacks-dm.status-in-review'),
                    '--in-review'
                ];
            case ReviewStatus.Fixed:
                return [
                    <CheckMarkRoundSmallIcon />,
                    t('feedbacks-dm.status-fixed'),
                    '--fixed'
                ];
            case ReviewStatus.Skipped:
                return [
                    <SmallCircleDoubleChevronIcon />,
                    t('feedbacks-dm.status-skipped'),
                    '--skipped'
                ];
            default:
                return ['', '', ''];
        }
    }, [status]);

    if (status === ReviewStatus.NotReviewed) {
        return (
            <Typography type="subtitle3">
                {t('feedbacks-dm.status-not-reviewed')}
            </Typography>
        );
    }

    return (
        <div
            className={`feedback-dm__status-option ${statusClassName} ${className}`}
        >
            {icon}
            <Typography type="small2SemiBold">{translation}</Typography>
        </div>
    );
};

export default FeedbackStatusOption;
