import { FunctionComponent, useMemo, useState } from 'react';
import { Typography, Bold } from 'aqui';
import { t } from 'i18next';
import { adjustIntegratedModel } from '../../../utils/productUtils';
import { IntegratedModel } from '../../../types';
import { useSelector } from 'react-redux';
import TextButton from '../../TextButton/TextButton';
import SummaryIcon from '../../../SVG/SummaryIcon';
import SummaryModal from '../../SummaryModal/SummaryModal';
import { useModel } from '../../../AppContext';
import useAnalytics from '../../../hooks/useAnalytics';
import { ANALYTICS_EVENTS } from '../../../consts';
// import ChevronIconSmall from '../../../SVG/ChevronIconSmall';
// import PopoverBox from '../../PopoverBox/PopoverBox';

interface ChatModelBarProps {
    productDetails: IntegratedModel;
}

const ChatModelBar: FunctionComponent<ChatModelBarProps> = ({
    productDetails
}) => {
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);
    const [summaryModalOpen, setSummaryModalOpen] = useState<boolean>(false);
    const { sendEvent } = useAnalytics();

    const { scopModelName } = useSelector((state: any) => state.coPilotReducer);

    const { model, isRangeEnabled, modelSerialNumber } = useModel();

    const modelToDisplay = useMemo(() => {
        if (isRangeEnabled) {
            return modelSerialNumber;
        }
        if (!productDetails && model === t('common.all')) {
            return scopModelName;
        }
        return adjustIntegratedModel({
            modelName: model,
            modelDisplay: productDetails?.modelDisplay,
            productTypeName: productDetails?.productTypeName,
            productTypeDisplay: productDetails?.productTypeDisplay,
            manufacturerName: productDetails?.manufacturerName,
            manufacturerDisplay: productDetails?.manufacturerDisplay
        });
    }, [
        model,
        modelSerialNumber,
        productDetails,
        isRangeEnabled,
        scopModelName
    ]);

    const openPopover = (event) => {
        setAnchorEl(event.currentTarget);
    };
    // Commented code applies model actions popover.

    // const rows = [
    //     {
    //         show: true,
    //         title: 'New chat for this model',
    //         onClick: () => {
    //             setAnchorEl(null);
    //         },
    //         dataTestId: 'new-chat-for-current-model'
    //     }
    // ];
    return (
        <div className={`chat__model-actions ${anchorEl ? '--active' : ''}`}>
            <div onClick={openPopover} className="flex align-center">
                <Typography type="small2">
                    {isRangeEnabled
                        ? `${t('model.serial-number')} `
                        : `${t('model.model')} `}
                    <Bold>{modelToDisplay}</Bold>
                </Typography>
                {/* <ChevronIconSmall
                    direction="down"
                    className="chat__model-actions__chevron"
                /> */}
            </div>
            <div className="chat__summary">
                <TextButton
                    dataTestId="SummaryBtn"
                    color="grey"
                    text={t('chat.summary')}
                    IconComponent={SummaryIcon}
                    onClick={() => {
                        sendEvent(ANALYTICS_EVENTS.SUMMARY_PANEL_OPENED);
                        setSummaryModalOpen(true);
                    }}
                />
            </div>
            <SummaryModal
                isOpen={summaryModalOpen}
                onClose={() => setSummaryModalOpen(false)}
            />
            {/* <PopoverBox
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                rows={rows}
                horizontalOrigin="right"
            /> */}
        </div>
    );
};

export default ChatModelBar;
