import {
    GRID_CHECKBOX_SELECTION_FIELD,
    GRID_TREE_DATA_GROUPING_FIELD,
    GridSortDirection
} from '@mui/x-data-grid-pro';

export const SORT_TYPES = {
    ASC: 'asc' as GridSortDirection,
    DESC: 'desc' as GridSortDirection
};

export const SORTING_ORDER = [SORT_TYPES.ASC, SORT_TYPES.DESC];
export const DEFAULT_ROWS_LIMIT_PER_PAGE = 50;

export const ROW_HEIGHT = 42;
export const FIRST_SORT_COLUMN = 0;
export const INITIAL_STATE = {
    columns: {
        columnVisibilityModel: {
            [GRID_CHECKBOX_SELECTION_FIELD]: false,
            [GRID_TREE_DATA_GROUPING_FIELD]: false
        }
    }
};

// const to calculate the min width of the column header
export const COLUMN_PADDINGS = 26;
export const SORT_ICON_WIDTH = 24;
export const COLUMN_HEADER_LETTER_SPACING = 0.1;
export const COLUMN_HEADER_FONT = '500 12px "Noto Sans"';
