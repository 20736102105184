import { useMemo } from 'react';
import {
    FiltersMultiSelect as AquiFilterMultiSelect,
    Typography,
    Bold
} from 'aqui';
import ValueWithTooltip from '../../ValueWithTooltip/ValueWithTooltip';
import { t } from 'i18next';
import cl from 'classnames';
import { capitalizeFirstLetter } from '../../../utils/utils';

interface FiltersMultiSelectProps {
    filters: string[] | Array<{ value: string }>;
    selectedFilters: string[];
    allFilterLabel?: string;
    onApplyClick: (filters: string[]) => void;
    dataTestId?: string;
    disabled?: boolean;
    processing?: boolean;
}

const FiltersMultiSelect: React.FC<FiltersMultiSelectProps> = ({
    filters = [],
    selectedFilters = [],
    allFilterLabel = capitalizeFirstLetter(t('common.all')),
    onApplyClick,
    dataTestId,
    disabled,
    processing
}) => {
    const hasChosenFilters = selectedFilters.length > 0;
    const filterLabel = useMemo(
        () => selectedFilters.join(', ') || allFilterLabel,

        [selectedFilters, allFilterLabel]
    );
    return (
        <AquiFilterMultiSelect
            items={filters}
            selectedItems={selectedFilters}
            onApplyClick={onApplyClick}
            className={cl('data-management__filter', {
                '--active': hasChosenFilters,
                '--disabled': disabled,
                '--processing': processing
            })}
            dataTestId={dataTestId}
            processing={processing}
            disabled={disabled}
        >
            <ValueWithTooltip
                title={filterLabel}
                type="small1"
                className="data-management__filter__label"
            />
            {selectedFilters.length > 1 && (
                <Typography type="small1">
                    <Bold>+{selectedFilters.length - 1}</Bold>
                </Typography>
            )}
        </AquiFilterMultiSelect>
    );
};

export default FiltersMultiSelect;
