import { useState } from 'react';
import BasicPDFDocument from './BasicPDFDocument';
import { SourceDocument } from '../../types';
import PreviewBanner from './PreviewBanner';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

type BasicPDFViewerProps = {
    sourceDocument: SourceDocument;
};

const BasicPDFViewer = (props: BasicPDFViewerProps) => {
    const [isShowFullDoc, setIsShowFullDoc] = useState<boolean>(false);
    const { t } = useTranslation();

    return (
        <>
            <PreviewBanner show>
                <div className={classNames('pdf-viewer-iframe__banner')}>
                    {t('document.loading-doc-iframe')}
                    <div
                        className={classNames('pdf-viewer-iframe__btn')}
                        onClick={() => setIsShowFullDoc(!isShowFullDoc)}
                    >
                        <span className={classNames('pdf-viewer-iframe__icon')}>
                            &#8635;
                        </span>
                        {isShowFullDoc
                            ? t('document.loading-doc-iframe-btn')
                            : t('document.loading-preview-doc-iframe-btn')}
                    </div>
                </div>
            </PreviewBanner>
            <BasicPDFDocument
                showDoc={!isShowFullDoc}
                sourcePath={
                    props.sourceDocument.metadata?.source_pdf_preview_url || ''
                }
                pageNumber={1}
            />
            <BasicPDFDocument
                showDoc={isShowFullDoc}
                sourcePath={props.sourceDocument.metadata?.source || ''}
                pageNumber={props.sourceDocument.metadata.page}
            />
        </>
    );
};

export default BasicPDFViewer;
