import { useState } from 'react';
import { Tooltip } from '@mui/material';
import { Typography } from 'aqui';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    IntegratedModel,
    MessageInfo,
    MessageInvestigationDetails,
    SmallWindowState
} from '../../../types';
import { setObservationToInvestigate } from '../../../actions/coPilotActions';
import { ANALYTICS_EVENTS } from '../../../consts';
import useAnalytics from '../../../hooks/useAnalytics';
import {
    useIntegratedModels,
    useInvestigationDetails,
    useMessages,
    useModel,
    useProductDetails,
    useScopSettings
} from '../../../AppContext';
import Util from '../../../Util';
import ModelList from '../ModelList/ModelList';
import {
    adjustIntegratedModel,
    getIntegratedModelsByName
} from '../../../utils/productUtils';
import useSwitchPanel from '../../../hooks/useSwitchPanel';

type InvestigateInTriageProps = {
    disabled: boolean;
    messageInvestigationDetails: MessageInvestigationDetails;
    message: MessageInfo;
    isTriageModel: boolean;
};

const InvestigateInTriage = (props: InvestigateInTriageProps) => {
    const dispatch = useDispatch();
    const { sendEvent } = useAnalytics();
    const { continueInvestigationInTriage } = useSwitchPanel();
    const { showInvestigateButtonBasedOnObservations } = useScopSettings();
    const { setInvestigationDetails } = useInvestigationDetails();
    const { investigationId } = useSelector(
        (state: any) => state.deviceInspectionReducer
    );

    const { integratedModels } = useIntegratedModels();
    const { model } = useModel();
    const { setProductDetails } = useProductDetails();
    const { messages } = useMessages();

    const [selectedModel, setSelectedModel] = useState(null);

    const isDisabled =
        props.disabled || (!props.isTriageModel && !selectedModel);

    const investigateInTriage = () => {
        if (isDisabled) {
            return;
        }
        sendEvent(ANALYTICS_EVENTS.CLICKED_INVESTIGATE_IN_TRIAGE);
        if (showInvestigateButtonBasedOnObservations) {
            dispatch(
                setObservationToInvestigate(
                    props.messageInvestigationDetails?.observationObject
                )
            );
        }
        continueInvestigationInTriage(SmallWindowState.Maximized);
    };

    const observationName =
        props.messageInvestigationDetails?.observationObject[
            Util.getPropWithPrefix('Display__c')
        ] || props.messageInvestigationDetails?.observationObject.Name;

    const onModelSelect = (model: IntegratedModel) => {
        setSelectedModel(model);
        setProductDetails(model);
        setInvestigationDetails(model, messages[0].message);
        sendEvent(ANALYTICS_EVENTS.SELECT_DIFFERENT_MODEL_CLICK, {
            'Model value': adjustIntegratedModel(model)
        });
    };

    return (
        <div
            className="investigate-in-triage"
            onClick={investigateInTriage}
            data-testid="investigate-in-triage"
            data-disabled={props.disabled}
        >
            <Tooltip
                title={
                    props.disabled
                        ? (t('chat.disabled-triage-link') as string)
                        : ''
                }
                placement="top"
                classes={{
                    tooltip: 'tooltip'
                }}
            >
                <div
                    className={`investigate-in-triage__container ${
                        props.disabled ? 'disabled' : ''
                    }`}
                >
                    {props.messageInvestigationDetails &&
                    observationName &&
                    showInvestigateButtonBasedOnObservations ? (
                        <Typography
                            type="subtitle1"
                            dataTestId="investigate-in-triage-text"
                        >
                            {`${t('chat.use-triage-observation1')} `}
                            <span className="bold">"{observationName}"</span>
                            {` ${t('chat.use-triage-observation2')}`}
                        </Typography>
                    ) : (
                        <Typography
                            type="subtitle1"
                            dataTestId="investigate-in-triage-text"
                        >
                            {`${t('chat.use-triage')}`}
                            <span className="bold">{` ${props.message.model}`}</span>
                            .
                            {!props.isTriageModel && !selectedModel && (
                                <>
                                    <div>
                                        {t('chat.verify-model') as string}:
                                    </div>
                                    <ModelList
                                        mainModelsList={getIntegratedModelsByName(
                                            integratedModels,
                                            model
                                        )}
                                        selectedModel={null}
                                        onModelSelect={(modelString) =>
                                            onModelSelect(modelString)
                                        }
                                    />
                                </>
                            )}
                        </Typography>
                    )}
                    <Typography
                        type="button"
                        className={`investigate-in-triage__button ${
                            isDisabled ? 'disabled' : ''
                        }`}
                        dataTestId="investigate-in-triage-button"
                        data-disabled={isDisabled}
                    >
                        {investigationId && !isDisabled
                            ? t('chat.continue-investigation')
                            : t('chat.triage')}
                    </Typography>
                </div>
            </Tooltip>
        </div>
    );
};

export default InvestigateInTriage;
