import { useRef, useState, useEffect } from 'react';
import { Tooltip } from '@material-ui/core';
import { Typography } from 'aqui';

const ValueWithTooltip = ({
    title,
    tooltipTitle = title,
    className = '',
    onClick = null,
    dataTestId = title,
    type = 'small1',
    maxLines = 1,
    isPermanentTooltip = false,
    maxTooltipRows = 0,
    ...props
}) => {
    const ref = useRef();
    const [isOverflow, setIsOverflow] = useState(false);

    useEffect(() => {
        if (ref.current) {
            setIsOverflow(
                ref.current.scrollWidth > ref.current.clientWidth ||
                ref.current.scrollHeight > ref.current.clientHeight
            );
        }
    }, [title]);

    const tooltipComponent = () => {
        if (maxTooltipRows) {
            return (
                <p
                    style={{
                        maxHeight: `${maxTooltipRows * 1.5}em`,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: maxTooltipRows,
                        WebkitBoxOrient: 'vertical'
                    }}
                >
                    {tooltipTitle}
                </p>
            );
        }
        return tooltipTitle;
    };

    return (
        <Tooltip
            key={`${tooltipTitle}_tooltip`}
            placement="top"
            title={(isOverflow || isPermanentTooltip) ? tooltipComponent() : ''}
            classes={{
                tooltip: 'tooltip'
            }}
            {...props}
        >
            <Typography
                type={type}
                key={`${title}_text`}
                data-testid={dataTestId}
                ref={ref}
                className={`lines-limit-${maxLines} ${className}`}
                onClick={onClick}
            >
                {title}
            </Typography>
        </Tooltip>
    );
};

export default ValueWithTooltip;
