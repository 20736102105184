import { FunctionComponent, useEffect } from 'react';
import { useFeedbacksFilters, useFeedbacksRows } from './useFeedbacksHooks';
import FiltersMultiSelect from '../filters/FiltersMultiSelect';
import { FiltersRow } from 'aqui';
import { t } from 'i18next';
import FilterSelect from '../filters/FilterSelect';
import { FeedbackType, ReviewStatus } from '../../../types';
import DateRangeFilter from '../filters/DateRangeFilter';
import { getFeedbackValuesFilterOptions } from './FeedbacksHelper';
import { useFeedbackCategories } from '../../../AppContext';
import { capitalizeFirstLetter } from '../../../utils/utils';

const FeedbacksFilters: FunctionComponent = () => {
    const { areFeedbacksFetching } = useFeedbacksRows();
    const {
        feedbacksFilters,
        setModelsToFilter,
        setTypeToFilter,
        setValueToFilter,
        setStatusToFilter,
        setCreateDateToFilter,
        fetchFeedbackModels,
        feedbackModels,
        setCategoryToFilter
    } = useFeedbacksFilters();

    const { feedbackCategories } = useFeedbackCategories();

    useEffect(() => {
        if (!feedbackModels.length) {
            fetchFeedbackModels();
        }
    }, []);

    return (
        <FiltersRow withFilterIcon={false}>
            <FilterSelect
                items={Object.values(FeedbackType)}
                selectedItem={feedbacksFilters.type}
                onChange={setTypeToFilter}
                allFilterLabel={`${t(
                    'feedbacks-dm.column-feedback-type'
                )}: ${capitalizeFirstLetter(t('common.all'))}`}
                disabled={areFeedbacksFetching}
                dataTestId="feedback-type-filter"
            />
            <FilterSelect
                items={getFeedbackValuesFilterOptions(feedbacksFilters.type)}
                selectedItem={feedbacksFilters.value}
                onChange={setValueToFilter}
                allFilterLabel={`${t(
                    'feedbacks-dm.column-feedback-value'
                )}: ${capitalizeFirstLetter(t('common.all'))}`}
                disabled={areFeedbacksFetching}
                dataTestId="feedback-value-filter"
            />
            <FiltersMultiSelect
                filters={feedbackModels}
                selectedFilters={feedbacksFilters.models}
                onApplyClick={setModelsToFilter}
                allFilterLabel={`${t('model.models')}: ${capitalizeFirstLetter(
                    t('common.all')
                )}`}
                dataTestId="feedbacks-models-filter"
                disabled={areFeedbacksFetching || !feedbackModels.length}
            />
            <FilterSelect
                items={Object.values(ReviewStatus)}
                selectedItem={feedbacksFilters.status}
                onChange={setStatusToFilter}
                allFilterLabel={`${t(
                    'feedbacks-dm.column-review-status'
                )}: ${capitalizeFirstLetter(t('common.all'))}`}
                disabled={areFeedbacksFetching}
                dataTestId="feedback-status-filter"
            />
            <FilterSelect
                items={[...(feedbackCategories?.chips || []), 'None']}
                selectedItem={feedbacksFilters.category}
                onChange={setCategoryToFilter}
                allFilterLabel={`${t(
                    'feedbacks-dm.column-feedback-category'
                )}: ${t('common.all')}`}
                disabled={areFeedbacksFetching}
                dataTestId="feedback-category-filter"
            />
            <DateRangeFilter
                onChange={setCreateDateToFilter}
                allFilterLabel={`${t(
                    'data-management.create-date'
                )}: ${capitalizeFirstLetter(t('common.all'))}`}
                dataTestId="feedback-create-date-filter"
                disabled={areFeedbacksFetching}
                minDate={feedbacksFilters.minCreateDate}
                maxDate={feedbacksFilters.maxCreateDate}
            />
        </FiltersRow>
    );
};

export default FeedbacksFilters;
