import { Dayjs } from 'dayjs';
import classnames from 'classnames/bind';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';
import { DateRange } from '@mui/x-date-pickers-pro';
import { Typography } from 'aqui';
import { FC } from 'react';
import styles from './DateRangePicker.module.scss';
const cx = classnames.bind(styles);
const { primaryColor, fontFamily } = styles;

interface DateRangePickerProps {
    onChange: (minDate: DateRange<Dayjs>) => void;
    value: DateRange<Dayjs>;
    shortcutItems?: { label: string; onClick: () => void }[];
}
const DateRangePicker: FC<DateRangePickerProps> = ({
    onChange,
    value,
    shortcutItems = []
}) => {
    return (
        <div>
            {!!shortcutItems.length && (
                <div className={cx('date-range-picker__shortcuts')}>
                    {shortcutItems.map((item) => (
                        <div
                            key={item.label}
                            className={cx('date-range-picker__shortcuts__item')}
                            onClick={item.onClick}
                        >
                            <Typography type="small2">{item.label}</Typography>
                        </div>
                    ))}
                </div>
            )}

            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StaticDateRangePicker
                    slotProps={{
                        actionBar: { actions: [] }
                    }}
                    disableFuture
                    onChange={onChange}
                    value={value}
                    sx={{
                        '.MuiPickersSlideTransition-root': {
                            minHeight: '220px'
                        },
                        '.MuiDateRangePickerDay-day.Mui-selected': {
                            '&:hover, &.Mui-selected': {
                                background: primaryColor
                            }
                        },
                        '.MuiTypography-root': {
                            fontFamily
                        },
                        '.MuiButtonBase-root': {
                            background: 'transparent'
                        }
                    }}
                />
            </LocalizationProvider>
        </div>
    );
};

export default DateRangePicker;
