import { useAppContext } from '../../AppContext';
import OctagonWarning from '../../SVG/OctagonWarning';
import { Typography } from 'aqui';
import MessageSingleTypeDisclaimer from '../MessageDisclaimers/MessageSingleTypeDisclaimer';
import { useSelector } from 'react-redux';

const ChatDisclaimers = () => {
    const { scopSettings, modelNameToDisclaimer, model } = useAppContext();
    const { scopModelName } = useSelector((state: any) => state.coPilotReducer);
    const showGeneralDisclaimer =
        scopSettings.displayGeneralDisclaimer &&
        scopSettings.generalDisclaimerMessage;
    const modelDisclaimer = modelNameToDisclaimer[scopModelName || model];
    const showModelDisclaimer =
        scopSettings.displayKeywordDisclaimers &&
        modelDisclaimer?.disclaimer_text;

    return (
        <div className="headerMessage">
            {showGeneralDisclaimer && (
                <div className="flex align-center headerMessage__container">
                    <OctagonWarning className="headerMessage__icon" />
                    <Typography className="headerMessage__text" type="small1">
                        {scopSettings.generalDisclaimerMessage}
                    </Typography>
                </div>
            )}
            {showModelDisclaimer && (
                <MessageSingleTypeDisclaimer
                    className={'headerMessage__model-disclaimer'}
                    disclaimers={[modelDisclaimer]}
                />
            )}
        </div>
    );
};

export default ChatDisclaimers;
