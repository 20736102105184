import { t } from 'i18next';
import { FC } from 'react';
import { Button } from 'aqui';

type GetUpdatedResponseDisclaimerProps = {
    onClick: () => void;
};
const GetUpdatedResponseDisclaimer: FC<GetUpdatedResponseDisclaimerProps> = ({
    onClick
}) => {
    return (
        <div className="flex flex-col" style={{ gap: '10px' }}>
            {t('edit-response.update-response-disclaimer') as string}
            <Button
                text={t('edit-response.get-updated-response')}
                variant="filled"
                onClick={onClick}
                dataTestId="get-updated-response"
                style={{ width: 'fit-content' }}
            />
        </div>
    );
};

export default GetUpdatedResponseDisclaimer;
