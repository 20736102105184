import React, { FunctionComponent } from 'react';
import MessageEditing from '../MessageEditing/MessageEditing';
import { useEditedMessages, useMessages, useModel } from '../../../AppContext';
import { useSelector } from 'react-redux';
import { prepareDate, updateHistoryMessage } from '../../../utils/utils';
import Backend from '../../../backend/Backend';
import { useHistoryMessages } from '../../../context/ChatContext';

type FullMessageEditProps = {
    textArr: string[];
    messageIndex: number;
    historyMessageIndex: number;
    setExpertEdited: (value: boolean) => void;
    noResponse?: boolean;
};

const FullMessageEdit: FunctionComponent<FullMessageEditProps> = ({
    textArr,
    messageIndex,
    setExpertEdited,
    noResponse = false,
    historyMessageIndex
}) => {
    const { setIndexAndHeighOfEditedEntireMessage } = useEditedMessages();
    const { messages, setMessages } = useMessages();
    const { historyMessages, setHistoryMessages } = useHistoryMessages();
    const { model } = useModel();

    const { fullUserName } = useSelector(
        (state: any) => state.userProfileReducer
    );

    const prepareTextForFullEdit = (textArray: string[]) => {
        return textArray.reduce((acc, segment) => {
            if (
                !segment.includes('SOURCE_') &&
                !segment.includes('EXPERT_EDIT')
            ) {
                return acc + segment;
            } else {
                return acc;
            }
        }, '');
    };

    const onClickCancel = () => {
        setIndexAndHeighOfEditedEntireMessage({});
    };

    const onClickSave = (newResponse: string) => {
        const message = messages[messageIndex];
        const question = messages[messageIndex - 1].message;
        Backend.updateResponseByExpert(
            message.chatID,
            message.rowID,
            model,
            question,
            message.condensedQuestion,
            newResponse,
            message.productDetails?.productIds?.productId,
            fullUserName
        );
        if (historyMessageIndex !== null) {
            updateHistoryMessage(
                historyMessages,
                setHistoryMessages,
                messageIndex,
                historyMessageIndex,
                newResponse,
                fullUserName
            );
        } else {
            setMessages((prevMessages) => {
                return prevMessages.map((message, index) => {
                    if (index === messageIndex) {
                        return {
                            ...message,
                            message: newResponse,
                            expertDetails: {
                                name: fullUserName,
                                date: prepareDate(new Date())
                            }
                        };
                    }
                    return message;
                });
            });
        }
        setIndexAndHeighOfEditedEntireMessage({});
        setExpertEdited(true);
    };

    return (
        <MessageEditing
            text={noResponse ? '' : prepareTextForFullEdit(textArr)}
            onClickCancel={onClickCancel}
            onClickSave={onClickSave}
            allowEmptyText={false}
        />
    );
};

export default FullMessageEdit;
