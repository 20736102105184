import {
    useInvestigationDetails,
    useMessages,
    useModel,
    useProductDetails,
    useScopSettings,
    useSelectedMessage,
    useThreadsHandling
} from '../AppContext';
import { MessageSenderType, SmallWindowState } from '../types';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import Backend from '../backend/Backend';
import { setChatWindowState, setTriageChatId } from '../actions/coPilotActions';
import SFBackend from '../backend/SFBackend';
import useSwitchPanel from './useSwitchPanel';
import { t } from 'i18next';

const useChatHistory = () => {
    const [startedTagsOperation, setStartedTagsOperation] = useState(false);
    const tagsToRunRef = useRef(new Set());

    const dispatch = useDispatch();
    const { messages, setMessages } = useMessages();
    const { setCoPilotPanel } = useSwitchPanel();
    const {
        selectedThread,
        setTriageBtnIndexOnThreadClick,
        setSelectedThread
    } = useThreadsHandling();
    const { setSelectedMessage } = useSelectedMessage();
    const { productDetails } = useProductDetails();
    const { showInvestigateButtonBasedOnObservations } = useScopSettings();
    const { setInvestigationDetails } = useInvestigationDetails();
    const isIntegratedInvestigation = useSelector(
        (state: any) => state.deviceInspectionReducer.workOrderData
    );
    const { isSmallChat, chatId } = useSelector(
        (state: any) => state.coPilotReducer
    );
    const { investigationId, customerComplaintActionInProgress } = useSelector(
        (state: any) => state.deviceInspectionReducer
    );
    const { model } = useModel();

    const applyChatHistory = (chatHistory, productDetails = null) => {
        if (!chatHistory.length) {
            return;
        }
        const messagesList = [];
        chatHistory.forEach((msg) => {
            const messageId = msg.chat_history_line_id;
            messagesList.push({
                message: msg.question,
                direction: 'outgoing',
                sender: MessageSenderType.User,
                chatID: msg.chat_id
            });
            messagesList.push({
                message: msg.answer,
                direction: 'incoming',
                sender: MessageSenderType.Aquant,
                sourceDocuments: msg.source_documents.map(
                    (source: any, index: number) => ({
                        id: messageId + index,
                        messageId,
                        content: source.page_content,
                        metadata: source.metadata
                    })
                ),
                relatedSourceDocuments:
                    msg.answer_sources?.map((source: any, index: number) => ({
                        id: messageId + index,
                        messageId,
                        content: source.page_content,
                        metadata: source.metadata
                    })) || [],
                condensedQuestion: msg.condensed_question,
                triageLink: msg.triage_link,
                triageSolutionCount: msg.triage_solution_count,
                rowID: messageId,
                model,
                chatID: msg.chat_id,
                productDetails,
                expertDetails: msg.expert_details,
                questionId: msg.question
            });
        });
        setMessages(messagesList);
        setSelectedMessage(messagesList.length > 1 ? messagesList[1] : null);
        if (isSmallChat) {
            dispatch(setChatWindowState(SmallWindowState.Maximized));
        }
    };

    useEffect(() => {
        // get chat id by investigation id
        // from version 107 implemented in Triage
        if (
            isSmallChat &&
            !chatId &&
            investigationId &&
            isIntegratedInvestigation
        ) {
            SFBackend.getChatId(investigationId).then((chatId) => {
                if (chatId) {
                    dispatch(setTriageChatId(chatId, model));
                }
            });
        }
    }, [investigationId, chatId, isSmallChat]);

    useEffect(() => {
        if (chatId && model && model !== t('common.all') && !messages.length) {
            setCoPilotPanel();
            Backend.getChatHistory(model, chatId).then((response) => {
                applyChatHistory(response.data, productDetails);
                setSelectedThread(chatId);
                if (selectedThread) {
                    if (showInvestigateButtonBasedOnObservations) {
                        setStartedTagsOperation(true);
                        tagsToRunRef.current = new Set(
                            response.data.map((res) => res.question)
                        );
                    } else {
                        setTriageBtnIndexOnThreadClick(
                            response.data.length * 2 - 1
                        );
                        setInvestigationDetails(
                            productDetails,
                            response.data[response.data.length - 1].question
                        );
                    }
                }
            });
        }
    }, [chatId, model, isIntegratedInvestigation]);

    useEffect(() => {
        if (startedTagsOperation && !customerComplaintActionInProgress) {
            if (tagsToRunRef.current.size > 0) {
                const [firstTag] = tagsToRunRef.current;
                setInvestigationDetails(productDetails, firstTag as string);
                tagsToRunRef.current.delete(firstTag);
            } else {
                setStartedTagsOperation(false);
            }
        }
    }, [customerComplaintActionInProgress, startedTagsOperation]);
};

export default useChatHistory;
