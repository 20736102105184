import React from 'react';
import { DoubleChevronIcon } from 'aqui/icons';
import { Tooltip } from '@material-ui/core';
import { t } from 'i18next';

interface CollapseButtonProps {
    className: string;
    onClick: Function;
    dataTestId?: string;
}

const CollapseButton: React.FC<CollapseButtonProps> = ({
    className,
    onClick,
    dataTestId = 'collapse-btn'
}) => {
    const handleClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
        onClick(event);
    };

    return (
        <Tooltip
            title={t('common.collapse') as string}
            classes={{ tooltip: 'tooltip tooltip__select-button-icon' }}
            data-testid={dataTestId}
        >
            <div className={`collapse-btn ${className}`} onClick={handleClick}>
                <div className="collapse-btn__inner">
                    <DoubleChevronIcon direction="right" />
                </div>
            </div>
        </Tooltip>
    );
};

export default CollapseButton;
