import { FC, useState } from 'react';
import { Bold, Typography } from 'aqui';
import { ChatItem, RenderValueParams } from './types';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import ChevronIconSmall from '../../SVG/ChevronIconSmall';
import KASectionRow from '../KASection/KASectionRow';
import { SourceDocument } from '../../types';
import { useKAModal } from '../../AppContext';

interface ChatSummaryProps {
    scopMessages: Array<ChatItem>;
    header: string;
    renderHeader: (header: string) => JSX.Element;
    renderValue: (params: RenderValueParams) => JSX.Element;
    renderSeparator: (marginBottom?: number) => JSX.Element;
}

const ChatSummary: FC<ChatSummaryProps> = ({
    scopMessages,
    header,
    renderHeader,
    renderValue,
    renderSeparator
}) => {
    const { handleShowSourceDocumentDetails } = useKAModal();

    const [openedQuestion, setOpenedQuestions] = useState(new Set());

    const onResultClick = (
        doc: SourceDocument,
        kaType: string,
        allDocuments: Array<SourceDocument>
    ) => {
        handleShowSourceDocumentDetails(
            allDocuments.indexOf(doc),
            allDocuments,
            kaType
        );
    };

    const handleOnClick = (index: number) => () => {
        const newOpenedQuestions = new Set(openedQuestion);
        if (openedQuestion.has(index)) {
            newOpenedQuestions.delete(index);
        } else {
            newOpenedQuestions.add(index);
        }
        setOpenedQuestions(newOpenedQuestions);
    };

    const renderResponse = (response: string) => {
        const textByparagraphs = response.split('\n\n');
        return textByparagraphs.map((segment, index) => {
            if (segment === '\n\n') {
                return (
                    <div key={index} className={'markdown-br small-chat'}></div>
                );
            }
            return (
                <div
                    className={
                        'chatMessage__segemnt markdown-container small-chat'
                    }
                    key={index}
                >
                    <ReactMarkdown remarkPlugins={[remarkGfm]}>
                        {segment}
                    </ReactMarkdown>
                    {textByparagraphs.length !== index + 1 && (
                        <div
                            key={index}
                            className={'markdown-br small-chat'}
                        ></div>
                    )}
                </div>
            );
        });
    };

    return (
        <>
            {!!scopMessages?.length && renderHeader(header)}
            {scopMessages.map(
                (
                    {
                        question = '',
                        responseHeader = '',
                        response = '',
                        relatedSourcesHeader = '',
                        relatedSources = []
                    },
                    index
                ) => (
                    <div key={index}>
                        <div
                            className="chat-summary__title"
                            onClick={handleOnClick(index)}
                        >
                            <ChevronIconSmall
                                direction="right"
                                className={`chat-summary__chevron ${
                                    openedQuestion.has(index) ? '--open' : ''
                                }`}
                            />
                            <Typography type="body3">
                                {<Bold>{question}</Bold>}
                            </Typography>
                        </div>
                        {openedQuestion.has(index) && (
                            <div className="chat-summary__body">
                                {renderSeparator(16)}
                                {renderValue({
                                    value: responseHeader,
                                    bold: true
                                })}
                                {renderValue({
                                    value: renderResponse(response),
                                    bold: false
                                })}
                                {!!relatedSources?.length &&
                                    renderSeparator(16)}
                                {!!relatedSources?.length &&
                                    renderValue({
                                        value: relatedSourcesHeader,
                                        bold: true
                                    })}
                                <div className="ka-list__knowledge-articles flex flex-col">
                                    {relatedSources.map((source, index) => {
                                        return (
                                            <KASectionRow
                                                results={[source]}
                                                isResultSeen={false}
                                                seenResults={new Set()}
                                                onResultClick={(doc, kaType) =>
                                                    onResultClick(
                                                        doc,
                                                        kaType,
                                                        relatedSources
                                                    )
                                                }
                                                key={index}
                                                isCoPilotPanel={true}
                                                resultIndex={index + 1}
                                                isAllResultsDeleted={false}
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                        {scopMessages.length - 1 !== index &&
                            renderSeparator(8)}
                    </div>
                )
            )}
        </>
    );
};

export default ChatSummary;
