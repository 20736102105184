import { FunctionComponent } from 'react';
import Table from '../../Table/Table';
import FeedbacksHeader from './FeedbacksHeader';
import { getColumnsData } from './FeedbacksHelper';
import {
    useFeedbacksRows,
    useFetchFeedbacksData,
    useFeedbackPaginationModel,
    useFeedbackRowsCount,
    useFeedbackRowsSortModel
} from './useFeedbacksHooks';
import { useFeedbacksTable } from './useFeedbacksTable';
import { FEEDBACKS_PINNED_COLUMNS, NO_PADDING_COLUMNS } from './consts';

const FeedbackManagement: FunctionComponent = () => {
    const { areFeedbacksFetching, feedbacksRows } = useFeedbacksRows();
    const { feedbacksSortModel, setFeedbacksSortModel } =
        useFeedbackRowsSortModel();
    const { columnComponents } = useFeedbacksTable();
    const { paginationModel, setPaginationModel } =
        useFeedbackPaginationModel();
    const { feedbackRowsCount } = useFeedbackRowsCount();

    useFetchFeedbacksData();

    return (
        <div className="data-management-table">
            <FeedbacksHeader />
            <Table
                rows={feedbacksRows}
                columnComponents={columnComponents}
                columnsData={getColumnsData()}
                isLoading={areFeedbacksFetching}
                dataTestId="feedbacks-table"
                paginationModel={paginationModel}
                setPaginationModel={setPaginationModel}
                hideFooter={false}
                dataControlSide="server"
                sortModel={feedbacksSortModel}
                onSortModelChange={setFeedbacksSortModel}
                rowCount={feedbackRowsCount}
                pinnedColumns={FEEDBACKS_PINNED_COLUMNS}
                noPaddingColumns={NO_PADDING_COLUMNS}
            />
        </div>
    );
};

export default FeedbackManagement;
