import React, { FunctionComponent } from 'react';
import { WarningIcon, ChevronIcon } from 'aqui/icons';

import { Typography, Bold, CheckBox } from 'aqui';
import { EMAIL_VALIDATION_TYPES } from './EmailValidationModal';
import { t } from 'i18next';

type EmailValidationModalBodyProps = {
    emails?: string[];
    inUseEmails?: { [key: string]: string[] };
    type: EMAIL_VALIDATION_TYPES;
    persona?: string;
    showMoreType: EMAIL_VALIDATION_TYPES;
    setShowMoreType: (type: EMAIL_VALIDATION_TYPES) => void;
    selectedEmails?: string[];
    setSelectedEmails?: (emails: string[]) => void;
};

const EmailValidationModalBody: FunctionComponent<
    EmailValidationModalBodyProps
> = ({
    emails,
    type,
    persona,
    inUseEmails = null,
    showMoreType,
    setShowMoreType,
    selectedEmails,
    setSelectedEmails
}) => {
    const handleEmailsSelection = (email: string, check: boolean) => {
        if (email === 'all') {
            if (check) {
                setSelectedEmails(Object.values(inUseEmails).flat());
            } else {
                setSelectedEmails([]);
            }
        } else {
            if (check) {
                setSelectedEmails([...selectedEmails, email]);
            } else {
                setSelectedEmails(
                    selectedEmails.filter(
                        (selectedEmail) => selectedEmail !== email
                    )
                );
            }
        }
    };

    const handleTextToShow = () => {
        if (type === EMAIL_VALIDATION_TYPES.NotValid) {
            return (
                <>
                    <Bold>
                        {`${emails.length} ${t(
                            'add-persona.user-names'
                        ).toLowerCase()} `}
                    </Bold>
                    {t('emails-validation.not-assigned')}
                    <Bold>{` ${persona} `}</Bold>
                    {t('emails-validation.not-assigned-2')}
                </>
            );
        } else if (type === EMAIL_VALIDATION_TYPES.NotExisting) {
            return (
                <>
                    <Bold>
                        {`${emails.length} ${t(
                            'add-persona.user-names'
                        ).toLowerCase()} `}
                    </Bold>
                    {t('emails-validation.not-found')}
                </>
            );
        } else if (type === EMAIL_VALIDATION_TYPES.InUse) {
            return (
                <>
                    <Bold>{`${t('add-persona.in-use-emails-modal-1')} ${
                        Object.values(inUseEmails).flat().length
                    } ${t('common.usernames')}`}</Bold>{' '}
                    {t('add-persona.in-use-emails-modal-2')}
                    <Bold>{` "${persona}" `}</Bold>
                    {t('data-management.persona').toLowerCase()}:
                </>
            );
        }
    };

    const showExample = () => {
        if (type === EMAIL_VALIDATION_TYPES.NotValid) {
            return (
                <div className="flex flex-col emailValidationModal__example">
                    <Typography type="body3">
                        {t('emails-validation.instructions')}
                    </Typography>
                    <Typography type="body3">
                        <Bold>{t('emails-validation.format')}</Bold>
                    </Typography>
                </div>
            );
        }
    };

    const bodyContent = () => {
        if (type === EMAIL_VALIDATION_TYPES.InUse) {
            return (
                <>
                    <div className="flex align-center emailValidationModal__checkBoxes__single-checkbox">
                        <CheckBox
                            key={'all'}
                            onChange={(value) =>
                                handleEmailsSelection('all', value)
                            }
                            checked={
                                selectedEmails.length ===
                                Object.values(inUseEmails).flat().length
                            }
                        />
                        <Typography type="subtitle3">
                            {t('common.select-all')}
                        </Typography>
                    </div>
                    {Object.keys(inUseEmails).map((usedPersona) => {
                        return inUseEmails[usedPersona].map((email) => {
                            return (
                                <div
                                    key={email}
                                    className="emailValidationModal__checkBoxes__container"
                                >
                                    <div className="flex align-center emailValidationModal__checkBoxes__single-checkbox">
                                        <CheckBox
                                            onChange={(value) =>
                                                handleEmailsSelection(
                                                    email,
                                                    value
                                                )
                                            }
                                            checked={selectedEmails.includes(
                                                email
                                            )}
                                        />
                                        <Typography type="subtitle3">
                                            {email}
                                        </Typography>
                                    </div>
                                    <Typography
                                        type="subtitle3"
                                        className="emailValidationModal__checkBoxes__persona"
                                    >
                                        {usedPersona}
                                    </Typography>
                                </div>
                            );
                        });
                    })}
                </>
            );
        }
        return emails.map((email) => (
            <Typography type="body3">{email}</Typography>
        ));
    };

    return (
        <>
            <div className="flex align-center emailValidationModal__title">
                <WarningIcon className="emailValidationModal__title__icon" />
                <Typography
                    type="body3"
                    className="emailValidationModal__title__text"
                >
                    {handleTextToShow()}
                </Typography>
            </div>
            <div
                className={`emailValidationModal__hidden-data ${
                    showMoreType === type ? 'show' : ''
                }`}
            >
                <div className="flex flex-col emailValidationModal__emails">
                    {bodyContent()}
                </div>
                {showExample()}
            </div>
            <div
                className="flex align-center emailValidationModal__show-more"
                onClick={() =>
                    setShowMoreType(showMoreType === type ? null : type)
                }
            >
                <Typography type="small1">
                    {t('common.more-details')}
                </Typography>
                <ChevronIcon
                    direction="down"
                    className={`emailValidationModal__show-more__icon ${
                        showMoreType === type ? 'open' : ''
                    }`}
                />
            </div>
        </>
    );
};

export default EmailValidationModalBody;
