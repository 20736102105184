import { FC } from 'react';
import { Menu, MenuItem } from 'aqui';
import { ReviewStatus } from '../../../types';
import FeedbackStatusOption from './FedbackStatusOption';
import { useFeedbacksRows } from './useFeedbacksHooks';

interface FeedbackReviewStatusMenuProps {
    selectedStatus: ReviewStatus;
    rowid: string;
}

const FeedbackReviewStatusMenu: FC<FeedbackReviewStatusMenuProps> = ({
    selectedStatus,
    rowid
}) => {
    const statuses = Object.values(ReviewStatus);
    const { updateFeedbacksRow } = useFeedbacksRows();
    return (
        <Menu className="feedback-dm__status-menu">
            {statuses.map((status) => (
                <MenuItem
                    key={status}
                    selected={selectedStatus === status}
                    onClick={() => {
                        updateFeedbacksRow(rowid, { review_status: status });
                    }}
                >
                    {<FeedbackStatusOption status={status} />}
                </MenuItem>
            ))}
        </Menu>
    );
};

export default FeedbackReviewStatusMenu;
