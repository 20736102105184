import { Typography } from 'aqui';
import { t } from 'i18next';

const PersonaLoadingTooltip = () => {
    return (
        <div>
            <Typography type="small2" className="bold">
                {t('ingested-sources.updating-persona-tooltip-header')}
            </Typography>
            <Typography type="small2">
                {t('ingested-sources.updating-persona-tooltip-body')}
            </Typography>
        </div>
    );
};

export default PersonaLoadingTooltip;
