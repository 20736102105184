import {
    SidePanelBody,
    SidePanelFooter,
    SidePanelHeader,
    SidePanelModal
} from 'aqui';
import React, { FunctionComponent } from 'react';

type SidePanelClasses = {
    container?: string;
    header?: string;
    body?: string;
    footer?: string;
};

type SidePanelProps = {
    open: boolean;
    onClose: () => void;
    classes?: SidePanelClasses;
    children: React.ReactNode;
    title: React.ReactNode;
    footer?: React.ReactNode;
    size?: string;
    closeOnBackdropClick?: boolean;
};

const SidePanel: FunctionComponent<SidePanelProps> = ({
    open,
    onClose,
    classes = {},
    children,
    title,
    footer,
    size,
    closeOnBackdropClick = true
}) => {
    return (
        <SidePanelModal
            isOpen={open}
            closeModal={onClose}
            className={classes.container}
            closeOnBackdropClick={closeOnBackdropClick}
            size={size}
        >
            <SidePanelHeader className={classes.header}>
                {title}
            </SidePanelHeader>
            <SidePanelBody className={classes.body}>{children}</SidePanelBody>
            <SidePanelFooter className={classes.footer}>
                {footer}
            </SidePanelFooter>
        </SidePanelModal>
    );
};

export default SidePanel;
