import { FunctionComponent } from 'react';
import SidePanel from '../SidePanel/SidePanel';
import { t } from 'i18next';
import UploadFilesModalFooter from './UploadFilesModalFooter';
import UploadFilesModalBody from './UploadFilesModalBody';
import SourceFilesValidator from './SourceFilesValidator';
import {
    useIngestFiles,
    useUploadFilesModal,
    useUploadModel
} from './UploadFilesModalHooks';
import EclipseLoader from '../../SVG/EclipseLoader';

const UploadFilesModal: FunctionComponent = () => {
    const { closeUploadFilesModal, isUploadFilesModalOpen } =
        useUploadFilesModal();
    const { isIngestRequestInProgress } = useIngestFiles();
    const { isCatalogModelsFetched } = useUploadModel();
    const isLoading = isIngestRequestInProgress || !isCatalogModelsFetched;

    return (
        <SidePanel
            open={isUploadFilesModalOpen}
            onClose={closeUploadFilesModal}
            classes={{
                container: 'side-panel__upload-container',
                body: 'side-panel__body side-panel__upload-body flex-col',
                header: 'side-panel__header',
                footer: 'side-panel__footer'
            }}
            title={t('upload-files-modal.upload-docs') as string}
            footer={<UploadFilesModalFooter />}
        >
            {isLoading && (
                <div
                    className="side-panel__loader"
                    style={{
                        backgroundColor: `rgba(248, 249, 252, ${
                            isIngestRequestInProgress ? '0.6' : '0.1'
                        })`
                    }}
                >
                    <EclipseLoader />
                </div>
            )}
            <UploadFilesModalBody />
            <SourceFilesValidator />
        </SidePanel>
    );
};

export default UploadFilesModal;
