import React from 'react';
const DiagonalArrow = ({ color = 'currentcolor' }) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.4621 5.80861C17.4377 5.74964 17.4015 5.69439 17.3536 5.64645C17.3056 5.59851 17.2504 5.56234 17.1914 5.53794C17.1324 5.51349 17.0678 5.5 17 5.5H16.9999H7C6.72386 5.5 6.5 5.72386 6.5 6C6.5 6.27614 6.72386 6.5 7 6.5H15.7929L6.64645 15.6464C6.45118 15.8417 6.45118 16.1583 6.64645 16.3536C6.84171 16.5488 7.15829 16.5488 7.35355 16.3536L16.5 7.20711V16C16.5 16.2761 16.7239 16.5 17 16.5C17.2761 16.5 17.5 16.2761 17.5 16V6.00049V6C17.5 5.999 17.5 5.998 17.5 5.997C17.4996 5.9303 17.4861 5.86669 17.4621 5.80861Z"
                fill={color}
            />
        </svg>
    );
};

export default DiagonalArrow;
