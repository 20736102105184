import { GridSortModel } from '@mui/x-data-grid-pro';
import { SORT_TYPES } from '../../Table/consts';

export const COLUMNS = {
    MODEL: 'model',
    INITIAL_QUESTION: 'initial_question',
    QUESTION: 'question',
    ORIGINAL_RESPONSE: 'original_response',
    EXPERT_RESPONSE: 'expert_response',
    ACTIVE: 'active',
    DATE: 'date',
    EXPERT_MAIL: 'expert_mail',
    ACTIONS: 'actions'
};

export const EXPERT_RESPONSES_DEFAULT_SORT_MODEL: GridSortModel = [
    {
        field: COLUMNS.DATE,
        sort: SORT_TYPES.DESC
    }
];

export const EXPERT_RESPONSES_PINNED_COLUMNS = {
    left: [COLUMNS.MODEL, COLUMNS.INITIAL_QUESTION]
};
