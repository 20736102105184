import { useStartNewSession, useThreadsHandling } from '../../AppContext';
import { useAskStreamContext } from '../../context/AskStreamContext';
import { useSelector } from 'react-redux';
import CoPilotFooterLogo from '../../SVG/CoPilotFooterLogo';
import {
    SIDE_PANEL_LOGO_MAX_HEIGHT,
    SIDE_PANEL_LOGO_MAX_WIDTH
} from '../../consts';
const ThreadsPanelFooter = () => {
    const { startNewSession } = useStartNewSession();
    const { isStreamInProgress } = useAskStreamContext();
    const { isChatHistoryLoaded } = useThreadsHandling();
    const { isUsingCustomLogo } = useSelector(
        (state: any) => state.globalReducer
    );
    const { darkLogo } = useSelector((state: any) => state.logoReducer);

    const onClickLogo = () => {
        if (!isChatHistoryLoaded) {
            return;
        }
        startNewSession();
    };

    return (
        <div
            className={`flex align-center threadsPanel__footer ${
                isStreamInProgress || !isChatHistoryLoaded ? 'disabled' : ''
            }`}
            onClick={onClickLogo}
        >
            <div className="footer-logo">
                {isUsingCustomLogo && darkLogo ? (
                    <img
                        src={darkLogo}
                        alt="Custom Logo"
                        style={{
                            maxHeight: SIDE_PANEL_LOGO_MAX_HEIGHT,
                            maxWidth: SIDE_PANEL_LOGO_MAX_WIDTH
                        }}
                    />
                ) : (
                    <CoPilotFooterLogo />
                )}
            </div>
        </div>
    );
};

export default ThreadsPanelFooter;
