import { IntegratedModel } from '../../types';
import { adjustIntegratedModels, getIntegratedModelDisplay } from '../../utils/productUtils';

export const filterModelByText = (integratedModel: IntegratedModel, searchText: string) => {
    return [integratedModel.modelName, integratedModel.modelDisplay, integratedModel.manufacturerName, integratedModel.manufacturerDisplay, integratedModel.productTypeName, integratedModel.productTypeDisplay].some(item => item?.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()));
}


export const getFilteredModelsSortedByPriority = (prioritizedIntegratedModelsSet, integratedModels, searchText) => {
    const sortedModels = integratedModels.sort((modelA, modelB) => getIntegratedModelDisplay(modelA).localeCompare(getIntegratedModelDisplay(modelB)));
    let filteredModelsByText = sortedModels.filter((model) => filterModelByText(model, searchText));
    if (prioritizedIntegratedModelsSet.length) {
        filteredModelsByText = filteredModelsByText.filter((model) => !prioritizedIntegratedModelsSet.has(model));
        const filteredPrioritizedModels = prioritizedIntegratedModelsSet.filter((model) => filterModelByText(model, searchText));
        return [
            ...adjustIntegratedModels(filteredPrioritizedModels),
            ...adjustIntegratedModels(filteredModelsByText)
        ];
    }
    return adjustIntegratedModels(filteredModelsByText);
};