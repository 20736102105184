import { ToggleGroup } from 'aqui';
import { t } from 'i18next';
import { FC } from 'react';
import { ResponseDepth } from '../../types';
import useAnalytics from '../../hooks/useAnalytics';
import { ANALYTICS_EVENTS } from '../../consts';
import { useUserData } from '../../AppContext';
import {
    useAskStream,
    useAskStreamContext
} from '../../context/AskStreamContext';

interface ResponseDepthToggleProps {
    closeMenu?: () => void;
}

const ResponseDepthToggle: FC<ResponseDepthToggleProps> = ({ closeMenu }) => {
    const { responseDepth, setResponseDepth } = useUserData();
    const { sendEvent } = useAnalytics();
    const { askAgain } = useAskStream();
    const { isStreamInProgress, cancelStream } = useAskStreamContext();

    const responseDepthTranslations = {
        [ResponseDepth.Concise]: t('app-menu.concise'),
        [ResponseDepth.Detailed]: t('app-menu.detailed')
    };
    const select = (translatedValue: string) => {
        const value = Object.keys(responseDepthTranslations).find(
            (key) => responseDepthTranslations[key] === translatedValue
        ) as ResponseDepth;
        if (responseDepth !== value) {
            sendEvent(ANALYTICS_EVENTS.SELECTED_RESPONSE_LENGTH, {
                'Response length': value
            });
            setResponseDepth(value);
            if (isStreamInProgress) {
                cancelStream();
                askAgain(value);
                closeMenu?.();
            }
        }
    };

    return (
        <ToggleGroup
            options={Object.values(responseDepthTranslations)}
            controlled
            selection={responseDepthTranslations[responseDepth]}
            onClick={select}
        />
    );
};

export default ResponseDepthToggle;
