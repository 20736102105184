import { t } from 'i18next';
import {
    useDataManagementContext,
    useIsPersonasEnabled
} from '../../../context/DataManagementContext';
import {
    PersonasManagementRow,
    PopoverRow,
    TaskTypes,
    UpdatedPersonas
} from '../../../types';
import { onExportFile, onImportFile } from '../../../utils/fileUtils';
import Backend from '../../../backend/Backend';
import { useMemo } from 'react';
import {
    splitAndCleanStringToArray,
    prepareDate,
    returnValdinAndNotValidMails,
    handleUsersEmailsExistence
} from '../../../utils/utils';
import { PERSONA_CONSTANT_NAMES } from './consts';
import { useTenant } from '../../../AppContext';
import { sortPersonaRows, validateData } from './PersonasManagementHelpers';
import useTaskStatusInterval from '../../../hooks/useTaskStatusInterval';
import { UPDATING_ALL } from '../IngestedSources/consts';

export const usePersonaManagenentFilters = () => {
    const {
        personaManagemantSearchTerm,
        setPersonaManagemantSearchTerm,
        personaManagementFilters,
        setPersonaManagementFilters
    } = useDataManagementContext();

    const setFilter = (obj) => {
        setPersonaManagementFilters((prev) => ({ ...prev, ...obj }));
    };

    return {
        personaManagemantSearchTerm,
        setPersonaManagemantSearchTerm,
        setFilter,
        personaManagementFilters
    };
};

export const usePersonasManagementRows = () => {
    const {
        personasManagementRows,
        setPersonasManagementRows,
        arePersonasManagementDataFetching,
        setArePersonasManagementDataFetching,
        setUpdatingPersonas
    } = useDataManagementContext();

    const { tenantId } = useTenant();

    const { setTaskId } = useTaskStatusInterval({
        tenantId: tenantId,
        successCallback: successCallback,
        failureCallback: () => setArePersonasManagementDataFetching(false)
    });

    function successCallback() {
        setUpdatingPersonas(new Set());
        fetchPersonasManagementRows();
    }

    const fetchPersonasManagementRows = async () => {
        try {
            setArePersonasManagementDataFetching(true);
            Backend.getAllPersonas(tenantId).then((response) => {
                setPersonasManagementRows(
                    response.data.data.sort(sortPersonaRows)
                );
                setArePersonasManagementDataFetching(false);
            });
        } catch (ex: any) {
            console.error('Failed to fetch ingested documents data.', ex);
            window.sentry.log(ex);
            setArePersonasManagementDataFetching(false);
        }
    };

    const personaNameToId = useMemo(() => {
        return personasManagementRows.reduce((acc, row) => {
            acc[row.name] = row.id;
            return acc;
        }, {});
    }, [personasManagementRows]);

    const personaIdToName = useMemo(() => {
        return personasManagementRows.reduce((acc, row) => {
            acc[row.id] = row.name;
            return acc;
        }, {});
    }, [personasManagementRows]);

    const personasNames = useMemo(() => {
        return personasManagementRows.map((row) => row.name);
    }, [personasManagementRows]);

    const createPersona = async (
        name: string,
        description: string = '',
        users: string[] = [],
        copyFromPersonaId: string = ''
    ) => {
        try {
            setArePersonasManagementDataFetching(true);
            const res = await Backend.createPersona(
                tenantId,
                name,
                description,
                users,
                copyFromPersonaId
            );
            setUpdatingPersonas(new Set(UPDATING_ALL));
            setTaskId(res.data.task_id);
        } catch (ex: any) {
            setArePersonasManagementDataFetching(false);
            console.error('Failed to create persona.', ex);
            window.sentry.log(ex);
        } finally {
            return;
        }
    };
    const updatePersona = (
        personas: UpdatedPersonas[],
        replace: boolean = true
    ) => {
        setArePersonasManagementDataFetching(true);
        Backend.updatePersona(tenantId, personas, replace)
            .then((res) => {
                if (res.status === 200) {
                    fetchPersonasManagementRows();
                } else {
                    setArePersonasManagementDataFetching(false);
                }
            })
            .catch((ex: any) => {
                setArePersonasManagementDataFetching(false);
                console.error('Failed to update persona.', ex);
                window.sentry.log(ex);
            });
    };

    const defaultPersona = personasManagementRows.find(
        (row) => row.name === PERSONA_CONSTANT_NAMES.DEFAULT
    );

    return {
        personasManagementRows,
        fetchPersonasManagementRows,
        arePersonasManagementDataFetching,
        createPersona,
        personasNames,
        setArePersonasManagementDataFetching,
        personaNameToId,
        personaIdToName,
        updatePersona,
        defaultPersona
    };
};

export const useAddPersonaModal = () => {
    const { addPersonaModalOpen, setAddPersonaModalOpen } =
        useDataManagementContext();
    return { addPersonaModalOpen, setAddPersonaModalOpen };
};

export const useAddUsersModal = () => {
    const { addUsersModalOpen, setAddUsersModalOpen } =
        useDataManagementContext();
    return { addUsersModalOpen, setAddUsersModalOpen };
};

export const usePersonaSourcesModal = () => {
    const { selectedPersonaSources, setSelectedPersonaSources } =
        useDataManagementContext();
    return { selectedPersonaSources, setSelectedPersonaSources };
};

export const useNotValidEmails = () => {
    const { notValidEmails, setNotValidEmails } = useDataManagementContext();
    return { notValidEmails, setNotValidEmails };
};

export const useSourcesByPersona = () => {
    const { sourcesByPersona, setSourcesByPersona } =
        useDataManagementContext();
    return { sourcesByPersona, setSourcesByPersona };
};

export const useMoreOptionsRowsPersonasManagement = () => {
    const { personaNameToId, updatePersona, personasNames } =
        usePersonasManagementRows();
    const { setNotValidEmails } = useNotValidEmails();
    const { filteredPersonaManagementRows } =
        useFilteredPersonaManagementRows();
    const { tenantId } = useTenant();
    const { setFailedImportData } = useDataManagementContext();
    const { isPersonasEnabled } = useIsPersonasEnabled();

    const data = [{ 'PERSONA NAME': '', DESCRIPTION: '', USERS: '' }];

    const handleDataFromImport = async (importedData) => {
        const allNotValidEmails = [];
        const allValidEmailsByPersona = {};
        const personas = importedData.map((row) => {
            const { notValidEmails, validEmails } =
                returnValdinAndNotValidMails(
                    splitAndCleanStringToArray(row['USERS'].toLowerCase())
                );
            allValidEmailsByPersona[row['PERSONA NAME']] = validEmails;
            allNotValidEmails.push(...notValidEmails);
            return {
                id: personaNameToId[row['PERSONA NAME']],
                name: row['PERSONA NAME'],
                description:
                    row['PERSONA NAME'] === PERSONA_CONSTANT_NAMES.ALL_SOURCES
                        ? 'All sources, you may assign users'
                        : row['DESCRIPTION'],
                users: validEmails
            };
        });
        const { existingEmails, notExistingEmails } =
            await handleUsersEmailsExistence(
                Object.values(allValidEmailsByPersona).flat()
            );
        personas.forEach((persona) => {
            persona.users = allValidEmailsByPersona[persona.name].filter(
                (email) => existingEmails.includes(email)
            );
        });
        if (allNotValidEmails.length) {
            setNotValidEmails({
                personas: {
                    notValidEmails: Array.from(new Set(allNotValidEmails)),
                    notExistingEmails
                }
            });
        }
        updatePersona(personas, true);
    };

    const onImport = () => {
        onImportFile((importedData) => {
            const sentences = validateData(importedData, personasNames);
            if (!sentences.length) {
                handleDataFromImport(importedData);
            } else {
                setFailedImportData(sentences);
            }
        });
    };

    const onExport = () => {
        const personaIds = filteredPersonaManagementRows.map((row) => row.id);
        Backend.getUsersByPersona(tenantId, personaIds).then((response) => {
            const personaIdToUsers = response.data.persona_id_to_users;
            const fileName = `Personas_${new Date().toISOString()}.csv`;
            const dataFile = filteredPersonaManagementRows.map((row) => {
                const users = personaIdToUsers[row.id];
                return {
                    'PERSONA NAME': row.name,
                    DESCRIPTION: row.description,
                    SOURCES: row.source_count,
                    USERS: users ? users.join(', ') : '',
                    LAST_UPDATED: prepareDate(row.updated_at)
                };
            });
            onExportFile(dataFile, fileName);
        });
    };

    const onExportTemplate = () => {
        const fileName = 'Personas template.csv';
        onExportFile(data, fileName);
    };

    const rows: PopoverRow[] = [
        {
            show: true,
            title: t('common.export'),
            onClick: onExport,
            dataTestId: 'data-management-menu-item-export',
            withSeparator: false
        },
        {
            show: true,
            title: t('common.import'),
            onClick: onImport,
            dataTestId: 'data-management-menu-item-import',
            withSeparator: false,
            isDisabled: !isPersonasEnabled,
            tooltip: !isPersonasEnabled ? t('add-persona.feature-disabled') : ''
        },
        {
            show: true,
            title: t('common.download-template'),
            onClick: onExportTemplate,
            dataTestId: 'data-management-menu-item-download-template',
            withSeparator: false
        }
    ];
    const hasRowsToShow = rows.some((row) => row.show);
    return { rows, hasRowsToShow };
};

export const useCopyPersonaSourcesModal = () => {
    const { copySourcesFromPersonaModal, setCopySourcesFromPersonaModal } =
        useDataManagementContext();
    return {
        copySourcesFromPersonaModal,
        setCopySourcesFromPersonaModal
    };
};

export const useFilteredPersonaManagementRows = () => {
    const { filteredPersonaManagementRows, setFilteredPersonaManagementRows } =
        useDataManagementContext();
    return { filteredPersonaManagementRows, setFilteredPersonaManagementRows };
};

export const useDisabledPersonaManagementRows = () => {
    const { disabledPersonaManagementRows, setDisabledPersonaManagementRows } =
        useDataManagementContext();
    const getIsRowDisabled = (row: PersonasManagementRow) => {
        return disabledPersonaManagementRows.has(row.id);
    };
    return {
        disabledPersonaManagementRows,
        setDisabledPersonaManagementRows,
        getIsRowDisabled
    };
};

export const useHandlePersonaTasks = () => {
    const {
        fetchPersonasManagementRows,
        setArePersonasManagementDataFetching
    } = usePersonasManagementRows();
    const { tenantId } = useTenant();
    const { setUpdatingPersonas } = useDataManagementContext();

    const { setTaskId } = useTaskStatusInterval({
        tenantId: tenantId,
        successCallback: successCallback,
        failureCallback: () => console.error('Error')
    });

    function successCallback() {
        checkForRunningTasks();
    }

    const checkForRunningTasks = async (taskType: TaskTypes = null) => {
        setArePersonasManagementDataFetching(true);
        const response = await Backend.getRunningTasksData(tenantId, taskType);
        handleTasks(response.data);
    };

    const handleTasks = (data) => {
        if (data.length === 0) {
            setUpdatingPersonas(new Set());
            fetchPersonasManagementRows();
            return;
        }
        let newTaskId = null;
        data.some((task) => {
            if (
                [
                    TaskTypes.COPY_PERSONA,
                    TaskTypes.CREATE_PERSONA,
                    TaskTypes.INITIAL_PERSONAS,
                    TaskTypes.UPDATE_TAGS
                ].includes(task.type)
            ) {
                newTaskId = task.id;
                return true;
            }
            return false;
        });

        if (newTaskId) {
            setTaskId(newTaskId);
            setUpdatingPersonas(new Set(UPDATING_ALL));
        } else {
            setUpdatingPersonas(new Set());
            fetchPersonasManagementRows();
        }
    };

    return { checkForRunningTasks };
};
