const defaultValues = {
    display_disclaimer: false,
    display_disclaimer_message: '',
    navigation_to_triage_enabled: false,
    search_question_suggestion_enabled: true,
    send_email_notifications_for_document_import_status: false,
    documents_import_status_email: '',
    enable_edit_responses_by_experts: false,
    display_keywords_disclaimer: false,
    enable_source_import_by_admin_and_expert: false,
    enable_download_sources_for_all_users: false,
    expert_edit_permissions: false
};

class ScopSettings {
    displayGeneralDisclaimer: boolean;
    generalDisclaimerMessage: string;
    navigationToTriageEnabled: boolean;
    showInvestigateButtonBasedOnObservations: boolean;
    searchQuestionSuggestionEnabled: boolean;
    sendEmailNotificationsForDocumentImportStatus: boolean;
    documentsImportStatusEmail: string;
    enableEditResponsesByExperts: boolean;
    displayKeywordDisclaimers: boolean;
    enableSourceImportByAdminAndExpert: boolean;
    enableDownloadSourcesForAllUsers: boolean;
    allowUserToControlResponseLength: boolean;
    allowUserToEditInExpertEditTable: boolean;

    constructor(settings = defaultValues, packageVersion = 0) {
        const getValue = this.getSettingValueFunc(settings);
        this.displayGeneralDisclaimer = getValue('display_disclaimer');
        this.generalDisclaimerMessage = getValue('display_disclaimer_message');
        this.navigationToTriageEnabled = getValue(
            'navigation_to_triage_enabled'
        );
        this.showInvestigateButtonBasedOnObservations = getValue(
            'show_investigate_button_based_on_observations'
        );
        this.searchQuestionSuggestionEnabled =
            getValue('search_question_suggestion_enabled') &&
            packageVersion > 107;
        this.sendEmailNotificationsForDocumentImportStatus = getValue(
            'send_email_notifications_for_document_import_status'
        );
        this.documentsImportStatusEmail = getValue(
            'documents_import_status_email'
        );
        this.enableEditResponsesByExperts = getValue(
            'enable_edit_responses_by_experts'
        );
        this.allowUserToEditInExpertEditTable = getValue(
            'expert_edit_permissions'
        );
        this.displayKeywordDisclaimers = getValue(
            'display_keywords_disclaimer'
        );
        this.enableSourceImportByAdminAndExpert = getValue(
            'enable_source_import_by_admin_and_expert'
        );
        this.enableDownloadSourcesForAllUsers = getValue(
            'enable_download_sources_for_all_users'
        );
        this.allowUserToControlResponseLength =
            packageVersion > 114
                ? getValue('allow_user_to_control_response_length')
                : true;
    }

    private getSettingValueFunc(settings) {
        return (valueName: string) =>
            settings?.[valueName] ?? defaultValues[valueName];
    }
}

export default ScopSettings;
