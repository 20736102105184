import { FC } from 'react';

import { Typography, Toggle, Button } from 'aqui';
import { t } from 'i18next';
import { PencilEditIcon, CircleInfoIcon } from 'aqui/icons';

import { Tooltip } from '@material-ui/core';
import { SourceDocument } from '../../types';
import SourceTooltipContent from '../Chat/SourceTooltipContent/SourceTooltipContent';
import { getAggregatedResultDetails } from '../KASection/KASectionHelpers';
import {
    combineWithIndex,
    prepareDate,
    removeIndicatorsFromText,
    splitTextByIndicators
} from '../../utils/utils';
import MessageChunk from '../Chat/MessageChunk/MessageChunk';
import PersonIconMedium from '../../SVG/PersonIconMedium';
import MessageEditing from '../Chat/MessageEditing/MessageEditing';
import { useKAModal } from '../../AppContext';

interface EditResponseProps {
    onCancelEdit?: () => void;
    onSaveEdit?: (newResponse: string) => void;
    isEditMode?: boolean;
    onEditClick?: () => void;
    isEditAvailable?: boolean;
    isActivationChangeAvailable?: boolean;
    isResponseActive?: boolean;
    setIsResponseActive?: (active: boolean) => void;
    response: string;
    expertName?: string;
    editDate?: string;
    sources: SourceDocument[];
    label?: string;
    className?: string;
    disclaimer?: string | JSX.Element;
    shouldShowCustomButton?: boolean;
    customButtonText?: string;
    onCustomButtonClick?: () => void;
    customButtonDataTestId?: string;
}
const EditResponse: FC<EditResponseProps> = ({
    onCancelEdit,
    onSaveEdit,
    isEditMode,
    onEditClick,
    isEditAvailable,
    isActivationChangeAvailable,
    isResponseActive,
    setIsResponseActive,
    response,
    expertName,
    editDate,
    sources,
    label,
    className = '',
    disclaimer,
    shouldShowCustomButton,
    customButtonText,
    onCustomButtonClick,
    customButtonDataTestId = 'edit-response-custom-button'
}) => {
    const { handleShowSourceDocumentDetails } = useKAModal();
    const getTooltipTitle = (relatedSource: SourceDocument) => {
        if (!relatedSource || !relatedSource.metadata.source) {
            return t('ingested-sources.source-not-available');
        }
        return <SourceTooltipContent relatedSource={relatedSource} />;
    };

    const onRelatedSourceClick = (selectedSource: SourceDocument) => {
        if (!selectedSource || !selectedSource?.metadata?.source) {
            return;
        }
        const { fileType } = getAggregatedResultDetails([selectedSource]);
        handleShowSourceDocumentDetails(
            sources.indexOf(selectedSource),
            sources,
            fileType
        );
    };

    const shouldShowFooter =
        !isEditMode &&
        (!!isActivationChangeAvailable ||
            isEditAvailable ||
            shouldShowCustomButton);

    const renderTextWithSources = (text: string) => {
        const textArrayWithGroupedSourceIndications =
            splitTextByIndicators(text);
        const combinedTextArray = combineWithIndex(
            textArrayWithGroupedSourceIndications
        );

        if (isEditMode) {
            return (
                <MessageEditing
                    text={removeIndicatorsFromText(
                        textArrayWithGroupedSourceIndications
                    )}
                    onClickCancel={onCancelEdit}
                    onClickSave={onSaveEdit}
                    allowEmptyText={false}
                    className="edit-response-modal__text-area"
                />
            );
        }

        return combinedTextArray.map((segment, index) => {
            let showEditOnNewParagraph = false;
            const lastSegment = combinedTextArray[index - 1];
            if (
                segment === '\n\n' &&
                !lastSegment?.includes('SOURCE_') &&
                !lastSegment?.includes('EXPERT_EDIT')
            ) {
                showEditOnNewParagraph = true;
            }
            const messageData = {
                relatedSourceDocuments: sources
            };
            return (
                <MessageChunk
                    messageIndex={0}
                    key={index}
                    index={index}
                    segment={segment}
                    messageData={messageData}
                    onRelatedSourceClick={onRelatedSourceClick}
                    getTooltipTitle={getTooltipTitle}
                    showEditOnNewParagraph={showEditOnNewParagraph}
                    textArr={combinedTextArray}
                />
            );
        });
    };
    return (
        <div
            className={
                'flex flex-col edit-response-modal__response ' + className
            }
        >
            {!!label && <Typography type="subtitle3">{label}</Typography>}
            <div
                className={`flex flex-col edit-response-modal__response__box ${
                    isEditMode && 'edit-mode'
                }`}
            >
                {!!disclaimer && (
                    <div className="edit-response-modal__response__disclaimer">
                        <CircleInfoIcon className="edit-response-modal__response__disclaimer__icon" />
                        <Typography tag="div" type="small1">
                            {disclaimer}
                        </Typography>
                    </div>
                )}
                {!isEditMode && !!expertName && (
                    <div className="flex space-between edit-response-modal__response__header">
                        <div className="flex align-center edit-response-modal__response__header__expert">
                            <PersonIconMedium />
                            <Typography type="small1">{`Edited by expert ${expertName}`}</Typography>
                        </div>
                        <Typography
                            className="edit-response-modal__response__header__date"
                            type="small1"
                        >
                            {prepareDate(editDate)}
                        </Typography>
                    </div>
                )}
                <div className="edit-response-modal__response__body">
                    {renderTextWithSources(response)}
                </div>
                {shouldShowFooter && (
                    <div className="flex space-between edit-response-modal__response__footer">
                        {isActivationChangeAvailable && (
                            <Tooltip
                                title={
                                    isResponseActive
                                        ? (t(
                                              'expert-responses-dm.disable-response-modal'
                                          ) as string)
                                        : (t(
                                              'expert-responses-dm.activate-response-modal'
                                          ) as string)
                                }
                                placement="top"
                                classes={{
                                    tooltip: 'tooltip'
                                }}
                            >
                                <div
                                    className="flex align-center edit-response-modal__response__footer__toggle"
                                    onClick={() =>
                                        setIsResponseActive(!isResponseActive)
                                    }
                                >
                                    <Toggle checked={isResponseActive} />
                                    <Typography type="small1">
                                        {isResponseActive
                                            ? t(
                                                  'expert-responses-dm.displayed-response'
                                              )
                                            : t(
                                                  'expert-responses-dm.displayed-response-off'
                                              )}
                                    </Typography>
                                </div>
                            </Tooltip>
                        )}
                        {shouldShowCustomButton && (
                            <Button
                                variant="filled"
                                text={customButtonText}
                                dataTestId={customButtonDataTestId}
                                onClick={onCustomButtonClick}
                            />
                        )}

                        {isEditAvailable && (
                            <div
                                className="flex align-center edit-response-modal__response__footer__edit"
                                onClick={onEditClick}
                            >
                                <PencilEditIcon />
                                <Typography type="small1">
                                    {t('chat.edit-response')}
                                </Typography>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default EditResponse;
