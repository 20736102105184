
import React, {
    useState,
    createContext,
    useContext,
    ReactNode,
    useEffect,
    useMemo
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Disclaimer, DisclaimerTriggerBy, MagicButtonRect, SmallWindowState } from '../types';
import { setChatWindowState } from '../actions/coPilotActions';
import Backend from '../backend/Backend';
import { useAskStreamContext } from './AskStreamContext';

export interface ChatContextValue {
    inputMessage: string | null;
    setInputMessage: React.Dispatch<React.SetStateAction<string>>;
    selectedText: string | null;
    setSelectedText: React.Dispatch<React.SetStateAction<string>>;
    showHighlightedText: boolean;
    setShowHighlightedText: React.Dispatch<React.SetStateAction<boolean>>;
    copilotQuestions: string[];
    setCopilotQuestions: React.Dispatch<React.SetStateAction<Array<string>>>;
    copilotQuestionsInProgress: boolean;
    setCopilotQuestionsInProgress: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    magicButtonRect: MagicButtonRect;
    setMagicButtonRect: React.Dispatch<React.SetStateAction<MagicButtonRect>>;
    triggeredMessage: string;
    setTriggeredMessage: React.Dispatch<React.SetStateAction<string>>;
    showMagicButton: boolean;
    setShowMagicButton: React.Dispatch<React.SetStateAction<boolean>>;
    highlightedMessageIndex: number;
    setHighlightedMessageIndex: React.Dispatch<React.SetStateAction<number>>;
    historyMessages: object;
    setHistoryMessages: React.Dispatch<React.SetStateAction<object>>;
    isRegenerateAvailable: boolean;
    setIsRegenerateAvailable: React.Dispatch<React.SetStateAction<boolean>>;
    generationCounter: number;
    setGenerationCounter: React.Dispatch<React.SetStateAction<number>>;
    resetChatContext: Function;
    isSmallChatInPortal: boolean;
    setIsSmallChatInPortal: React.Dispatch<React.SetStateAction<boolean>>;
    isSmallChatInPortalCollapsed: boolean;
    setIsSmallChatInPortalCollapsed: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    messageDisclaimersMap: { [key: string]: Disclaimer[] };
    setMessageDisclaimersMap: React.Dispatch<
        React.SetStateAction<{ [key: string]: Disclaimer[] }>
    >;
    showSuggestedQuestions: boolean;
    setShowSuggestedQuestions: React.Dispatch<React.SetStateAction<boolean>>;
    relevantModels: Record<string, number>;
    setRelevantModels: React.Dispatch<
        React.SetStateAction<Record<string, number>>
    >;
    displayNoModel: boolean;
    setDisplayNoModel: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChatContext = createContext<ChatContextValue | null>(null);

export const ChatContextProvider: React.FC<{ children: ReactNode }> = ({
    children
}) => {
    const [inputMessage, setInputMessage] = useState<string>('');
    const [selectedText, setSelectedText] = useState<string>('');
    const [showHighlightedText, setShowHighlightedText] =
        useState<boolean>(false);
    const [copilotQuestions, setCopilotQuestions] = useState<string[]>([]);
    const [copilotQuestionsInProgress, setCopilotQuestionsInProgress] =
        useState<boolean>(false);
    const [magicButtonRect, setMagicButtonRect] = useState<any>(null);
    const [triggeredMessage, setTriggeredMessage] = useState<string>('');
    const [showMagicButton, setShowMagicButton] = useState<boolean>(false);
    const [highlightedMessageIndex, setHighlightedMessageIndex] =
        useState<number>(null);
    const [historyMessages, setHistoryMessages] = useState<object>({});
    const [isRegenerateAvailable, setIsRegenerateAvailable] =
        useState<boolean>(false);
    const [generationCounter, setGenerationCounter] = useState<number>(0);
    const [isSmallChatInPortal, setIsSmallChatInPortal] =
        useState<boolean>(false);
    const [isSmallChatInPortalCollapsed, setIsSmallChatInPortalCollapsed] =
        useState<boolean>(false);
    const [messageDisclaimersMap, setMessageDisclaimersMap] = useState<{
        [key: string]: Disclaimer[];
    }>({});
    const [showSuggestedQuestions, setShowSuggestedQuestions] =
        useState<boolean>(false);
    const [relevantModels, setRelevantModels] = useState<
        Record<string, number>
    >({});
    const [displayNoModel, setDisplayNoModel] = useState<boolean>(false);

    const { chatInputEdit, resetCopilot } = useSelector(
        (state: any) => state.coPilotReducer
    );

    useEffect(() => {
        if (chatInputEdit != null) {
            setInputMessage(chatInputEdit);
        }
    }, [chatInputEdit]);

    useEffect(() => {
        if (resetCopilot) {
            resetChatContext();
        }
    }, [resetCopilot]);

    const resetChatContext = () => {
        setHistoryMessages({});
        setInputMessage('');
        setSelectedText('');
        setShowHighlightedText(false);
        setCopilotQuestions([]);
        setCopilotQuestionsInProgress(false);
        setMagicButtonRect(null);
        setTriggeredMessage('');
        setShowMagicButton(false);
        setHighlightedMessageIndex(null);
        setIsRegenerateAvailable(false);
        setGenerationCounter(0);
        setIsSmallChatInPortal(false);
        setIsSmallChatInPortalCollapsed(false);
        setMessageDisclaimersMap({});
        setShowSuggestedQuestions(false);
        setRelevantModels({});
        setDisplayNoModel(false);
    };

    const value: ChatContextValue = {
        inputMessage,
        setInputMessage,
        selectedText,
        setSelectedText,
        showHighlightedText,
        setShowHighlightedText,
        copilotQuestions,
        setCopilotQuestions,
        copilotQuestionsInProgress,
        setCopilotQuestionsInProgress,
        magicButtonRect,
        setMagicButtonRect,
        triggeredMessage,
        setTriggeredMessage,
        showMagicButton,
        setShowMagicButton,
        highlightedMessageIndex,
        setHighlightedMessageIndex,
        historyMessages,
        setHistoryMessages,
        isRegenerateAvailable,
        setIsRegenerateAvailable,
        generationCounter,
        setGenerationCounter,
        resetChatContext,
        isSmallChatInPortal,
        setIsSmallChatInPortal,
        isSmallChatInPortalCollapsed,
        setIsSmallChatInPortalCollapsed,
        messageDisclaimersMap,
        setMessageDisclaimersMap,
        showSuggestedQuestions,
        setShowSuggestedQuestions,
        relevantModels,
        setRelevantModels,
        displayNoModel,
        setDisplayNoModel
    };
    return (
        <ChatContext.Provider value={value}>{children}</ChatContext.Provider>
    );
};

export const useChatContext = (): ChatContextValue => {
    const context = useContext(ChatContext);

    if (!context) {
        throw new Error('Did you forget to use the Provider?');
    }

    return context;
};

export const useInputMessage = () => {
    const { inputMessage, setInputMessage } = useChatContext();
    return { inputMessage, setInputMessage };
};

export const useSelectedText = () => {
    const { selectedText, setSelectedText } = useChatContext();
    return { selectedText, setSelectedText };
};

export const useCopilotQuestions = () => {
    const { copilotQuestions, setCopilotQuestions } = useChatContext();
    return { copilotQuestions, setCopilotQuestions };
};

export const useCopilotQuestionsInProgress = () => {
    const { copilotQuestionsInProgress, setCopilotQuestionsInProgress } =
        useChatContext();
    return { copilotQuestionsInProgress, setCopilotQuestionsInProgress };
};

export const useMagicButtonRect = () => {
    const { magicButtonRect, setMagicButtonRect } = useChatContext();
    return { magicButtonRect, setMagicButtonRect };
};

export const useTriggeredMessage = () => {
    const { triggeredMessage, setTriggeredMessage } = useChatContext();
    return { triggeredMessage, setTriggeredMessage };
};

export const useShowMagicButton = () => {
    const { showMagicButton, setShowMagicButton } = useChatContext();
    return { showMagicButton, setShowMagicButton };
};

export const useHighlightedMessageIndex = () => {
    const { highlightedMessageIndex, setHighlightedMessageIndex } =
        useChatContext();
    return { highlightedMessageIndex, setHighlightedMessageIndex };
};

export const useHistoryMessages = () => {
    const { historyMessages, setHistoryMessages } = useChatContext();
    return { historyMessages, setHistoryMessages };
};

export const useRelevantModels = () => {
    const { relevantModels, setRelevantModels } = useChatContext();
    return { relevantModels, setRelevantModels };
};

export const useDisplayNoModel = () => {
    const { displayNoModel, setDisplayNoModel } = useChatContext();
    return { displayNoModel, setDisplayNoModel };
};

export const useRegenerate = () => {
    const {
        isRegenerateAvailable,
        setIsRegenerateAvailable,
        generationCounter,
        setGenerationCounter
    } = useChatContext();
    const { isSmallChat } = useSelector((state: any) => state.coPilotReducer);
    const { chatWindowState } = useChatWindowState();
    const { isStreamStopped, isAskRequestInProgress, typingStarted } =
        useAskStreamContext();
    const isGenerateButtonHidden =
        (!isRegenerateAvailable && !typingStarted && !isStreamStopped) ||
        (isSmallChat && chatWindowState !== SmallWindowState.Maximized) ||
        isAskRequestInProgress;

    return {
        isRegenerateAvailable,
        setIsRegenerateAvailable,
        isGenerateButtonHidden,
        generationCounter,
        setGenerationCounter
    };
};

export const useResetChatContext = () => {
    const { resetChatContext } = useChatContext();
    return { resetChatContext };
};

export const useSmallChatInPortal = () => {
    const {
        isSmallChatInPortal,
        setIsSmallChatInPortal,
        isSmallChatInPortalCollapsed,
        setIsSmallChatInPortalCollapsed
    } = useChatContext();
    return {
        isSmallChatInPortal,
        setIsSmallChatInPortal,
        isSmallChatInPortalCollapsed,
        setIsSmallChatInPortalCollapsed
    };
};

export const useChatWindowState = () => {
    const { chatWindowState, isSmallChat } = useSelector(
        (state: any) => state.coPilotReducer
    );
    const { isSmallChatInPortal } = useSmallChatInPortal();
    const dispatch = useDispatch();
    const chatWindowStateToUse = useMemo(
        () =>
            isSmallChatInPortal && !isSmallChat
                ? SmallWindowState.Maximized
                : chatWindowState,
        [isSmallChatInPortal, isSmallChat, chatWindowState]
    );

    const dispatchWindowState = (chatWindowState: SmallWindowState) => {
        dispatch(setChatWindowState(chatWindowState));
    };

    const handleInitializeChatWindow = () => {
        dispatchWindowState(SmallWindowState.Initial);
    };

    const handleMinimizeChatWindow = () => {
        dispatchWindowState(SmallWindowState.Minimized);
    };

    const handleMaximizeChatWindow = () => {
        dispatchWindowState(SmallWindowState.Maximized);
    };

    const handleHideChatWindow = () => {
        dispatchWindowState(SmallWindowState.Hidden);
    };

    return {
        chatWindowState: chatWindowStateToUse,
        handleInitializeChatWindow,
        handleMinimizeChatWindow,
        handleMaximizeChatWindow,
        handleHideChatWindow
    };
};

export const useShowHighlightedText = () => {
    const { showHighlightedText, setShowHighlightedText } = useChatContext();
    return { showHighlightedText, setShowHighlightedText };
};

export const useMessageDisclaimers = (messageId?: string) => {
    const { locale } = useSelector((state: any) => state.globalReducer);
    const { messageDisclaimersMap, setMessageDisclaimersMap } =
        useChatContext();

    const messageDisclaimers = useMemo(
        () => messageDisclaimersMap[messageId],
        [messageDisclaimersMap, messageId]
    );

    const fetchMessageDisclaimers = async (
        question: string,
        answer: string,
        messageId: string,
        onFinished?: Function
    ) => {
        try {
            const disclaimers = await Backend.fetchMessageDisclaimers(
                locale,
                question,
                answer,
                DisclaimerTriggerBy.keywords
            );
            if (disclaimers.length) {
                setMessageDisclaimersMap((prevState) => ({
                    ...prevState,
                    [messageId]: disclaimers
                }));
            }
        } catch (ex: any) {
            console.error(
                `Failed to get disclaimers for message ${messageId}`,
                ex.message
            );
            window.sentry.log(ex);
        } finally {
            onFinished?.();
        }
    };
    return { fetchMessageDisclaimers, messageDisclaimers };
};

export const useSuggestedQuestions = () => {
    const { showSuggestedQuestions, setShowSuggestedQuestions } =
        useChatContext();
    return { showSuggestedQuestions, setShowSuggestedQuestions };
};