import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Typography } from 'aqui';
import { WarningIcon } from 'aqui/icons';
import { t } from 'i18next';
import { useDataManagementContext } from '../../../../context/DataManagementContext';

const PersonasProcessPendingModal = () => {
    const { isPersonasProcessPendingModalOpen, setIsPersonasProcessPendingModalOpen } =
        useDataManagementContext();

    const handleOnApprove = () => setIsPersonasProcessPendingModalOpen(false);

    return (
        <Modal
            isOpen={isPersonasProcessPendingModalOpen}
            closeModal={() => setIsPersonasProcessPendingModalOpen(false)}
            style={{ width: '807px', height: '432px' }}>
            <ModalHeader showCloseButton>
                <Typography type="h3" className="personasProcessPendingModal__header-text">
                    {t('add-persona.personas-process-pending-header')}
                </Typography>
            </ModalHeader>
            <ModalBody>
                <div className="flex flex-col personasProcessPendingModal__body">
                    <Typography type="body3">
                        {t('add-persona.personas-process-pending-body-message-1')}
                    </Typography>
                    <div className="flex align-center personasProcessPendingModal__warning-frame">
                        <WarningIcon className="personasProcessPendingModal__warning-frame__icon" />
                        <Typography type="body3">
                            {t('add-persona.personas-process-pending-warning')}
                        </Typography>
                    </div>
                    <Typography type="body3" className="personasProcessPendingModal__body__text">
                        {t('add-persona.personas-process-pending-body-message-2')}
                    </Typography>
                </div>
            </ModalBody>
            <div className="personasProcessPendingModal__separator" />
            <ModalFooter>
                <Button
                    dataTestId="i-understand-personas-process-pending-modal"
                    color="blue"
                    onClick={handleOnApprove}
                    text={t('common.i-understand')}
                    variant="filled"
                />
            </ModalFooter>
        </Modal>
    );
};

export default PersonasProcessPendingModal;
