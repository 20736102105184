import { FC } from 'react';
import { useSelector } from 'react-redux';
import LogoCoPilotSmall from '../../SVG/LogoCoPilotSmall';
import {
    SMALL_CHAT_LOGO_MAX_HEIGHT,
    SMALL_CHAT_LOGO_MAX_WIDTH
} from '../../consts';

const SmallChatLogo: FC = () => {
    const { isUsingCustomLogo } = useSelector(
        (state: any) => state.globalReducer
    );
    const { miniChatLogo } = useSelector((state: any) => state.logoReducer);
    return (
        <div>
            {isUsingCustomLogo && miniChatLogo ? (
                <img
                    src={miniChatLogo}
                    alt="logo"
                    style={{
                        maxHeight: SMALL_CHAT_LOGO_MAX_HEIGHT,
                        maxWidth: SMALL_CHAT_LOGO_MAX_WIDTH
                    }}
                />
            ) : (
                <LogoCoPilotSmall />
            )}
        </div>
    );
};

export default SmallChatLogo;
