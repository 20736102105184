import { MessageDirection } from '@chatscope/chat-ui-kit-react/src/types/unions';

export type SourceDocumentMetadata = {
    filename: string;
    source: string;
    page: number;
    model: string;
    media_type?: string;
    start?: number;
    max_page?: number;
    end?: number;
    score?: number;
    source_pdf_preview_url?: string;
    source_thumbnail_preview_url?: string;
    vid?: string;
};

export type SourceDocument = {
    content?: string;
    metadata: SourceDocumentMetadata;
    messageId?: string;
    id?: string;
    isHidden?: boolean;
};

export type ExpertDetails = {
    name: string;
    date: string;
    expert_response?: string;
    active?: boolean;
};

export type Disclaimer = {
    disclaimer_type: DisclaimerType;
    disclaimer_text: string;
    appear_in_question: boolean;
    appear_in_answer: boolean;
    keywords: string;
    rowid: string;
};

export type MessageInfo = {
    chatID: string;
    rowID: string;
    message: string;
    sentTime: string;
    sender: MessageSenderType;
    direction: MessageDirection;
    sourceDocuments?: Array<SourceDocument>;
    relatedSourceDocuments?: Array<SourceDocument>;
    condensedQuestion?: string;
    feedbackId?: string;
    questionId?: string;
    usefulCount?: number;
    userResponded?: boolean;
    canProvideFeedback?: boolean;
    triageLink?: string;
    triageSolutionCount?: number;
    siLink?: string;
    useful?: boolean;
    promptInfo?: PromptInfo | Record<string, never>;
    noResponse?: boolean;
    model?: string;
    productDetails?: IntegratedModel;
    expertDetails?: ExpertDetails;
    disclaimers?: Array<Disclaimer>;
    id?: string;
};

export type PopoverRow = {
    show: Boolean;
    title: string;
    onClick?: Function;
    icon?: JSX.Element;
    dataTestId?: string;
    withSeparator?: boolean;
    isSelected?: boolean;
    isDisabled?: boolean;
    isProcessing?: boolean;
    tooltip?: string;
    RightComponent?: any;
    isNotSelectable?: boolean;
};

export type AvailableModels = {
    name: String;
    probability?: Number;
    productTypeName?: String;
    ProductIds?: ProductIds;
};

export type SfdcIds = {
    sessionId: string;
    userId: string;
    orgId: string;
};

export const enum SmallWindowState {
    Initial = 'initial',
    Hidden = 'hidden',
    Wide = 'wide',
    Minimized = 'minimized',
    Maximized = 'maximized',
    Remove = 'remove'
}

export const enum AppTypes {
    PortalChat = 'portal chat',
    Portal = 'portal',
    Triage = 'triage',
    DataManagement = 'data management'
}

export const enum Panel {
    FirstRequestPanel = AppTypes.Portal,
    CoPilotPanel = AppTypes.PortalChat,
    DataManagementPanel = AppTypes.DataManagement
}

export interface ProductIds {
    productTypeId: string;
    manufacturerId: string;
    productId: string;
}

export interface IntegratedModel {
    modelName: string;
    modelDisplay?: string;
    productTypeName?: string;
    productTypeDisplay?: string;
    manufacturerName?: string;
    manufacturerDisplay?: string;
    productIds?: ProductIds;
    isLinkedProductDetails?: boolean;
}

export interface ProductDetailsResponsePayload {
    producttype: ProductResponse;
    manufacturer: ProductResponse;
    model: ProductResponse;
}

export interface ProductDetailsResponse {
    product: Array<ProductDetailsResponsePayload>;
}

interface ProductResponse {
    name: string;
    id: string;
    display: string | null;
    knowledge_names?: string[];
}

export const enum RecommendedQuestionsSource {
    Observation,
    Solution
}

export type FetchedSuggestions = {
    solutions: string[];
    observations: string[];
};

export type Suggestions = {
    suggestions: string[];
};
export type RespType = {
    data?: Suggestions;
};

export type PromptInfo = {
    default_standalone_prompt?: string;
    is_complete_prompt?: boolean;
    suggested_alternatives?: string[];
};

export enum FeedbackValue {
    Like = 'Like',
    Dislike = 'Dislike',
    Same = 'Same',
    Better = 'Better',
    Worse = 'Worse'
}

export type FeedbackCategory = {
    original_value: string;
    translated_value: string;
    id: string;
    locale: string;
    order: string;
};

export enum FeedbackType {
    Source = 'Source',
    Response = 'Response',
    Rewrite = 'Rewrite'
}

export type QuestionPreset = {
    original_value: string;
    translated_value: string;
    id: string;
    locale: string;
    order: string;
};

export type FeedbackCategories = {
    question: string;
    chips: string[];
};

export type InvestigationDetails = {
    productTypeId: string;
    manufacturerId: string;
    productId: string;
    observation: string;
};

export type MessageInvestigationDetails = {
    numberOfSolutions: number;
    observationObject: Record<string, any>;
    investigationDetails: InvestigationDetails;
};

export type MagicButtonRect = {
    top: number;
    left: number;
    msgLeft: number;
    msgTop: number;
};

export const enum MessageSenderType {
    User = 'User',
    Aquant = 'Aquant',
    UserMock = 'UserMock'
}

export type ChatHistoryPayload = {
    tenant_models: string[];
    search_term: string;
    date: string;
    is_descending: boolean;
    order_by: string;
    page_num: number;
    page_size: number;
};

export type ChatHistorySessionPayload = {
    tenant_model: string;
    search_term: string;
    is_descending: boolean;
    offset: number;
    limit: number;
};

export type ChatHistorySessionPayloadByModels = {
    search_term: string;
    offset: number;
    limit: number;
    limit_per_model: number;
};

export type SingleChatHistoryData = {
    investigation_id: string;
    user_email: string;
    chat_id: string;
    tenant_model: string;
    question: string;
    created_at: string;
    user_external_id: string;
    triage_product_id: string;
};

export type ChatHistoryData = {
    tenant_model?: string | null;
    has_more?: boolean;
    data?: SingleChatHistoryData[];
};

export const enum SortingType {
    Date = 'date',
    Model = 'model'
}

type HighlightInProgressData = {
    question: string;
    partial_answer: string;
};

export type HighlightSuggestionPayload = {
    highlight: string;
    chat_history_line_id: string;
    stream_in_progress_data: HighlightInProgressData;
};

export enum FileValidation {
    Valid = 'valid',
    Corrupt = 'corrupt',
    Protected = 'protected',
    BadFilename = 'bad-filename',
    Exists = 'exists'
}

export type SourceFile = {
    file: File;
    validationType?: FileValidation;
    numPages?: number;
    duration?: number;
    isUploadStarted?: boolean;
    isUploaded?: boolean;
    isUploadFiled?: boolean;
    isDeleting?: boolean;
    isDeletedFailed?: boolean;
    percentLoaded?: number;
    abortUpload?: () => void;
};

export interface IngestPayload {
    document_names?: Array<string>;
    recreate: boolean;
    triage_url: string;
    initial_observation: string;
    enable_copilot: boolean;
    requested_by_email: string;
    send_mail_notification: boolean;
    sf_org_id: string;
    enable_cache: boolean;
    preprocess_prompt: boolean;
    provider: 'OpenAI' | 'Azure OpenAI';
    embedding_model:
        | 'scop-text-embedding-3-large'
        | 'scop-embed'
        | 'text-embedding-ada-002'
        | 'text-embedding-3-large';
    mails_to_notify: string[];
    persona_ids: string[];
}

export type CatalogProduct = {
    name: string;
    display: string;
    id: string;
    productTypeName: string;
    productTypeDisplay: string;
    productTypeId: string;
    manufacturerName: string;
    manufacturerDisplay: string;
    manufacturerId: string;
};

export enum DataManagementTab {
    IngestedSources = 'Uploaded sources',
    PersonasManagement = 'Personas management',
    ExpertResponses = 'Expert responses',
    FeedbackManagement = 'Feedback'
}

export enum IngestedSourcesStatus {
    Done = 'Done',
    Processing = 'Processing',
    Failed = 'Failed',
    Uploaded = 'Uploaded',
    Queued = 'Queued',
    Uploading = 'Uploading'
}

export enum RangeDatePickerPresets {
    LastWeek = 'Last week',
    LastMonth = 'Last month',
    LastYear = 'Last year',
    Custom = 'Custom'
}

export interface IngestedSourcesFilters {
    models?: string[];
    status?: IngestedSourcesStatus;
    minCreateDate?: Date;
    maxCreateDate?: Date;
    user?: string;
    persona?: string;
}

export interface IngestedSourcesRow {
    id: string;
    document_name: string;
    document_type?: MediaType;
    tenant_model: string;
    status: IngestedSourcesStatus;
    created_by: string;
    created_at: Date;
    integratedModel?: IntegratedModel;
    modelDisplay?: string;
    personas?: string[];
}

export type IngestedDocumentsResponse = {
    count: number;
    has_next: boolean;
    documents: IngestedSourcesRow[];
};

export enum MediaType {
    PDF = 'pdf',
    VIDEO = 'video',
    HTML = 'html'
}

export const enum IngestModalTypes {
    addPersona = 'add-persona',
    deleteSources = 'delete-sources'
}

export interface PersonasManagementRow {
    id: string;
    name: string;
    description: string;
    source_count: number;
    user_count: number;
    updated_at: string;
}

export type EditedPersonasOfFile = {
    model: string;
    filename: string;
    new_tags: string[];
};

export type PersonasManagementFilters = {
    date: string;
};

export type UpdatedPersonas = {
    id: string;
    name?: string;
    description?: string;
    users?: string[];
};

export type MultiSelectSearchValuesProps = {
    Id: string;
    value: string;
};

export type DocsByModel = {
    model_name: string;
    document_names: string[];
};

export type ImportedFileDataIngestedSourcesRow = {
    PERSONA: string;
    MODEL: string;
    'FILE NAME': string;
};

export type ImportedFileDataPersonaManagementRow = {
    'PERSONA NAME': string;
    DESCRIPTION: string;
    USERS: string;
};
export enum DisclaimerType {
    Danger = 'Danger',
    Warning = 'Warning',
    Caution = 'Caution',
    Notice = 'Notice'
}

export interface ExpertResponsesRow {
    id: string;
    chat_id: string;
    model: string;
    initial_question: string;
    question: string;
    original_response: string;
    expert_response: string;
    active: boolean;
    date: string;
    expert_mail: string;
    no_response?: boolean;
    integratedModel?: IntegratedModel;
    original_chat_history_line_id?: string;
    triage_product_id: string;
    investigationId: string;
    vector_id: string;
}

export interface editedResponseVersion {
    id: string;
    model: string;
    date: string;
    expert_response: string;
    user_name: string;
    active: boolean;
    condensed_question: string;
    answer_sources?: Array<SourceDocument>;
}

export interface ExpertResponsesFilters {
    models?: string[];
}

export interface PaginationModel {
    page: number;
    pageSize: number;
}
export type CaseInfo = {
    investigation_id: string;
    asset_id: string;
    work_order_id: string;
};

export const enum TaskTypes {
    INGEST = 'Ingest',
    CLONE_TENANT = 'CloneTenant',
    DELETE_MODEL = 'DeleteModel',
    SYNC_STORAGE = 'SyncStorage',
    CREATE_PERSONA = 'CreatePersona',
    COPY_PERSONA = 'CopyPersona',
    UPDATE_TAGS = 'UpdateTags',
    INITIAL_PERSONAS = 'InitialPersonas'
}

export interface SourcesByPersona {
    rowid: string;
    tenant_id: string;
    document_type: string;
    tenant_model: string;
    document_name: string;
    status: string;
}

export interface RunningTask {
    id: string;
    files: EditedPersonasOfFile[];
}
export enum GenerationType {
    SemanticOnly = 'SemanticOnly',
    Interleaved = 'Interleaved',
    LexicalOnly = 'LexicalOnly'
}

export enum ResponseDepth {
    Concise = 'Concise',
    Detailed = 'Detailed'
}

export type AskStreamCustomInstructions = {
    response_depth: ResponseDepth;
};

export interface FeedbackRow {
    id: string;
    feedback_type: FeedbackType;
    feedback_value: FeedbackValue;
    tenant_model: string;
    platform: string;
    created_at: Date;
    created_by: string;
    initial_question: string;
    question: string;
    response: string;
    categories: string[];
    feedback_text: string;
    review_status: ReviewStatus;
    notes: string;
    chat_id: string;
    integratedModel?: IntegratedModel;
    modelDisplay?: string;
    rowid: string;
    message_id: string;
    no_response: boolean;
    triage_product_id: string;
    is_concise_response?: boolean;
    persona_id?: string;
    persona_name?: string;
    current_source?: SourceDocument;
}

export interface FeedbacksFilters {
    models?: string[];
    type?: FeedbackType;
    minCreateDate?: Date;
    maxCreateDate?: Date;
    status?: ReviewStatus;
    category?: string;
    value?: FeedbackValue;
}

export enum ReviewStatus {
    NotReviewed = 'Not reviewed',
    InReview = 'In review',
    Fixed = 'Fixed',
    Skipped = 'Skipped'
}

export type FilterItem = {
    value: string;
    display: string;
};
export interface ErrorEmails {
    notValidEmails: string[];
    notExistingEmails: string[];
}

export type AskStreamHandlers = {
    onAskInit?: (abortController: AbortController) => void;
    onError?: (ex: any) => void;
    onStreamInit?: (reader: ReadableStreamDefaultReader<Uint8Array>) => void;
    handleAnswer?: (answer: string) => void;
};