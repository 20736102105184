import axios from 'axios';

import SFBackend from './SFBackend';
import {
    AskStreamCustomInstructions,
    CaseInfo,
    ChatHistorySessionPayload,
    ChatHistorySessionPayloadByModels,
    DocsByModel,
    EditedPersonasOfFile,
    FeedbackType,
    FeedbackValue,
    FeedbacksFilters,
    GenerationType,
    HighlightSuggestionPayload,
    IngestPayload,
    IngestedDocumentsResponse,
    ReviewStatus,
    TaskTypes,
    UpdatedPersonas
} from '../types';
import { SORT_TYPES } from '../components/Table/consts';

// Prefixes
const permanentUrl = process.env.REACT_APP_API_SERVER as string;
const tempBaseUrl = process.env.REACT_APP_TEMP_API_SERVER as string;
const baseUrl = window?.__triageStore?.is_using_temp_server
    ? tempBaseUrl
    : permanentUrl;
const coreApiPrefix = '/core/v2';
const ingestPrefix = '/ingest';
const filesApiPrefix = '/files';
const adminApiPrefix = '/admin';

// URIs
const askUrl = baseUrl + coreApiPrefix + '/ask';
const sourceUrl = baseUrl + '/ask/source_url';
const askStream = baseUrl + coreApiPrefix + '/ask_stream';
const isPersonasEnabled = baseUrl + coreApiPrefix + '/is_personas_enabled';
const searchUrl = baseUrl + coreApiPrefix + '/search';
const feedbackUrl = baseUrl + coreApiPrefix + '/feedback';
const stateCheckUrl = baseUrl + coreApiPrefix + '/state';
const metadataUrl = baseUrl + adminApiPrefix + '/tenant/metadata';
const observationSuggestions =
    baseUrl + coreApiPrefix + '/observation/suggestions';
const highlightSuggestions = baseUrl + coreApiPrefix + '/highlight/suggestions';
const chatUrl = baseUrl + coreApiPrefix + '/chat';
const feedbackCategoriesUrl = baseUrl + coreApiPrefix + '/feedback_categories';
const questionPresetsUrl = baseUrl + coreApiPrefix + '/question_presets';
const chatHistorySessionUrl = baseUrl + coreApiPrefix + '/chat_history_session';
const chatHistorySessionByModelsUrl =
    baseUrl + coreApiPrefix + '/chat_history_grouped_by_models';
const chatArchiveUrl = baseUrl + coreApiPrefix + '/archive_chat';
const suggestionQuestions = baseUrl + coreApiPrefix + '/suggestions/search';
const updateResponseByExpert =
    baseUrl + coreApiPrefix + '/update_response_by_expert';
const uploadUrls = baseUrl + filesApiPrefix + '/{tenant_id}/upload_urls';
const updateMetadata =
    baseUrl + filesApiPrefix + '/{tenant_id}/update_metadata';
const deleteFiles = baseUrl + ingestPrefix + '/documents/{tenant_id}/delete';
const deleteFilesByModel =
    baseUrl + ingestPrefix + '/documents/{tenant_id}/bulk/delete';
const ingest = baseUrl + ingestPrefix + '/ingest/{tenant_id}/{model_name}';
const getIngestConfig =
    baseUrl + ingestPrefix + '/ingest_info/{tenant_id}/{model_name}/config';
const ingestModels = baseUrl + ingestPrefix + '/models/{tenant_id}/list';
const ingestDocuments = baseUrl + ingestPrefix + '/documents/{tenant_id}/list';
const getPersonas =
    baseUrl + adminApiPrefix + '/tenant/{tenant_id}/personas/list';
const updateFilePersonas =
    baseUrl + ingestPrefix + '/documents/{tenant_id}/tags/update';
const createPersona =
    baseUrl + adminApiPrefix + '/tenant/{tenant_id}/personas/create';
const updatePersona =
    baseUrl + adminApiPrefix + '/tenant/{tenant_id}/personas/update';
const copyFromPersona =
    baseUrl + adminApiPrefix + '/tenant/{tenant_id}/personas/copy';
const getUsersByPersona =
    baseUrl + adminApiPrefix + '/tenant/{tenant_id}/persona-mapping';
const getDocumentsByPersona =
    baseUrl +
    adminApiPrefix +
    '/tenant/{tenant_id}/personas/documents?persona_id=';
const addDocuments = baseUrl + ingestPrefix + '/documents/{tenant_id}/add';
const getTaskStatus =
    baseUrl + adminApiPrefix + '/tenant/{tenant_id}/task/status?task_id=';
const getRunningTasksData =
    baseUrl + adminApiPrefix + '/tenant/{tenant_id}/running_tasks';
const disclaimerList = baseUrl + adminApiPrefix + '/disclaimer/list';
const getExpertResponses = baseUrl + adminApiPrefix + '/expert_responses/list';
const getExpertResponsesModels =
    baseUrl + adminApiPrefix + '/expert_responses/models/list';
const getExpertResponsesByVectorId =
    baseUrl + adminApiPrefix + '/expert_responses/answer';
const updateExpertResponse =
    baseUrl + adminApiPrefix + '/expert_responses/update';
const feedbackList = baseUrl + coreApiPrefix + '/feedback/list';
const updateFeedback = baseUrl + coreApiPrefix + '/update_feedback';
const feedbackModels = baseUrl + coreApiPrefix + '/feedback/models/list';
const chatMessage = baseUrl + coreApiPrefix + '/chat_history_line/{rowid}';

enum ScopInstanceTypeEnum {
    PRODUCTION = 'Production',
    SANDBOX = 'Sandbox'
}

class Backend {
    private static async getCommonHeaders() {
        if (SFBackend.isSandbox === null) {
            try {
                const isSandbox = await SFBackend.isSandboxOrg();
                SFBackend.isSandbox = isSandbox;
            } catch (e: any) {
                console.error(e);
                window.sentry.log(e);
                SFBackend.isSandbox = false;
            }
        }
        return {
            Authorization: `Bearer ${window.__sfdcIds.sessionId}`,
            'X-Scop-Client-Type': 'Salesforce',
            'X-Scop-Instance-Type': SFBackend.isSandbox
                ? ScopInstanceTypeEnum.SANDBOX
                : ScopInstanceTypeEnum.PRODUCTION
        };
    }

    public static async getTaskStatus(tenantId: string, taskId: string) {
        return axios.get(
            getTaskStatus.replace('{tenant_id}', tenantId) + taskId,
            {
                headers: await Backend.getCommonHeaders()
            }
        );
    }

    public static async getRunningTasksData(
        tenantId: string,
        taskType: TaskTypes
    ) {
        const config = {
            headers: await Backend.getCommonHeaders(),
            params: {
                task_type: taskType
            }
        };
        return axios.get(
            getRunningTasksData.replace('{tenant_id}', tenantId),
            config
        );
    }

    public static async ask(
        model_name: string,
        question: string,
        return_all_results: boolean,
        chat_id: string
    ) {
        const req = { question, chat_id, return_all_results };
        return axios.post(`${askUrl}/${model_name}`, req, {
            headers: await Backend.getCommonHeaders()
        });
    }

    public static async getSourceUrl(
        model_name: string,
        file_name: string,
        page_index: number
    ) {
        const req = { model_name, file_name, page_index };
        return axios.post(sourceUrl, req, {
            headers: await Backend.getCommonHeaders()
        });
    }

    public static async askStream(
        model_name: string,
        question: string,
        return_all_results: boolean,
        chat_id: string,
        regenerate: boolean,
        previous_generation_type: GenerationType | null,
        skip_question_pp: boolean = false,
        signal: AbortSignal,
        triage_product_id: string,
        case_info: CaseInfo,
        custom_instructions: AskStreamCustomInstructions,
        prevent_history_save: boolean = false
    ) {
        const req = {
            question,
            chat_id,
            return_all_results,
            regenerate,
            previous_generation_type,
            skip_question_pp,
            triage_product_id,
            case_info,
            custom_instructions,
            prevent_history_save
        };

        return fetch(`${askStream}/${model_name}`, {
            method: 'POST',
            headers: {
                ...(await Backend.getCommonHeaders()),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(req),
            signal
        });
    }

    public static async search(model_name: string, question: string) {
        const req = { question };

        return axios.post(`${searchUrl}/${model_name}`, req, {
            headers: await Backend.getCommonHeaders()
        });
    }

    public static async sendFeedback(
        modelName: string,
        feedbackId: string,
        question: string,
        answer: string,
        isUseful: boolean,
        categories: Array<string>,
        feedbackText: string,
        sourceDocument: any,
        messageRowId: string,
        isFirstQuestion: boolean,
        feedbackType: FeedbackType,
        feedbackValue: FeedbackValue
    ) {
        const req = {
            feedback_id: feedbackId,
            question,
            answer,
            is_useful: isUseful,
            categories,
            feedback_text: feedbackText,
            current_source: sourceDocument,
            chat_history_line_id: messageRowId,
            is_first_question: isFirstQuestion,
            feedback_type: feedbackType,
            feedback_value: feedbackValue
        };

        return axios.post(`${feedbackUrl}/${modelName}`, req, {
            headers: await Backend.getCommonHeaders()
        });
    }

    public static async performStateCheck(model_name: string) {
        const req = {};

        return axios.post(`${stateCheckUrl}/${model_name}`, req, {
            headers: await Backend.getCommonHeaders()
        });
    }

    public static async fetchMetadata() {
        return axios.get(`${metadataUrl}`, {
            headers: await Backend.getCommonHeaders()
        });
    }

    public static async observationSuggestions(
        model_name: string,
        observation: string
    ) {
        const req = {
            observation
        };
        return axios.post(`${observationSuggestions}/${model_name}`, req, {
            headers: await Backend.getCommonHeaders()
        });
    }

    public static async highlightSuggestions(
        model_name: string,
        payload: HighlightSuggestionPayload
    ) {
        return axios.post(`${highlightSuggestions}/${model_name}`, payload, {
            headers: await Backend.getCommonHeaders()
        });
    }

    public static async getChatHistory(modelName: string, chatId: string) {
        return axios.get(`${chatUrl}/${modelName}/${chatId}`, {
            headers: await Backend.getCommonHeaders()
        });
    }
    public static async getFeedbackCategories(locale: string) {
        const params = { locale, return_defaults: true };
        return axios.get(feedbackCategoriesUrl, {
            headers: await Backend.getCommonHeaders(),
            params
        });
    }

    public static async getQuestionPresets(locale: string) {
        const params = { locale, return_defaults: true };
        return axios.get(questionPresetsUrl, {
            headers: await Backend.getCommonHeaders(),
            params
        });
    }

    public static async getChatHistorySession(
        payload: ChatHistorySessionPayload
    ) {
        return axios.get(
            chatHistorySessionUrl +
                `?tenant_model=${payload.tenant_model}` +
                `&search_term=${payload.search_term}` +
                `&is_descending=${payload.is_descending}` +
                `&offset=${payload.offset}` +
                `&limit=${payload.limit}`,
            {
                headers: await Backend.getCommonHeaders()
            }
        );
    }

    public static async getChatHistorySessionByModels(
        payload: ChatHistorySessionPayloadByModels
    ) {
        return axios.get(
            chatHistorySessionByModelsUrl +
                `?search_term=${payload.search_term}` +
                `&offset=${payload.offset}` +
                `&limit=${payload.limit}` +
                `&limit_per_model=${payload.limit_per_model}`,
            {
                headers: await Backend.getCommonHeaders()
            }
        );
    }

    public static async archiveChat(chat_id: string) {
        const req = {
            chat_id
        };
        return axios.post(`${chatArchiveUrl}`, req, {
            headers: await Backend.getCommonHeaders()
        });
    }
    public static async getSuggestionQuestions(
        query: string,
        model_name?: string
    ) {
        return axios.get(suggestionQuestions, {
            headers: await Backend.getCommonHeaders(),
            params: {
                query,
                model_name
            }
        });
    }

    public static async updateResponseByExpert(
        chat_id: string,
        chat_history_line_id: string,
        tenant_model: string,
        question: string,
        condensed_question: string,
        edited_response: string,
        triage_product_id: string,
        user_name: string
    ) {
        const req = {
            chat_id,
            chat_history_line_id,
            tenant_model,
            question,
            condensed_question,
            edited_response,
            triage_product_id,
            user_name
        };
        return axios.post(`${updateResponseByExpert}`, req, {
            headers: await Backend.getCommonHeaders()
        });
    }

    public static async getUploadUrls(
        tenant_id: string,
        filenames: string[],
        requested_by
    ) {
        return axios.post(
            uploadUrls.replace('{tenant_id}', tenant_id),
            filenames,
            {
                headers: await Backend.getCommonHeaders(),
                params: {
                    requested_by
                }
            }
        );
    }

    public static async uploadToPresignedUrl(
        file: File,
        presignedData: { url: string; fields: any },
        onProgress?: (
            position: number,
            total: number,
            abort: () => void
        ) => void
    ) {
        const data = new FormData();
        const controller = new AbortController();

        Object.keys(presignedData.fields).forEach((key) =>
            data.append(key, presignedData.fields[key])
        );
        data.append('file', file, `${file.name}`);

        return axios.request({
            method: 'post',
            url: presignedData.url,
            data: data,
            onUploadProgress: (p) => {
                onProgress?.(p.loaded, p.total, () => controller.abort());
            },
            signal: controller.signal
        });
    }

    public static async updateMetadata(tenant_id: string, filenames: string[]) {
        return axios.post(
            updateMetadata.replace('{tenant_id}', tenant_id),
            filenames,
            {
                headers: await Backend.getCommonHeaders()
            }
        );
    }

    public static async deleteFiles(
        tenantId: string,
        modelName: string,
        fileNames: string[]
    ) {
        const req = {
            document_names: fileNames,
            model_name: modelName
        };
        return axios.post(deleteFiles.replace('{tenant_id}', tenantId), req, {
            headers: await Backend.getCommonHeaders()
        });
    }

    public static async deleteFileByModels(
        tenantId: string,
        docsByModel: DocsByModel[]
    ) {
        return axios.post(
            deleteFilesByModel.replace('{tenant_id}', tenantId),
            docsByModel,
            {
                headers: await Backend.getCommonHeaders()
            }
        );
    }

    public static async ingest(
        tenantId: string,
        modelName: string,
        ingest_payload: IngestPayload
    ) {
        return axios.post(
            ingest
                .replace('{tenant_id}', tenantId)
                .replace('{model_name}', modelName),
            ingest_payload,
            {
                headers: await Backend.getCommonHeaders()
            }
        );
    }

    public static async getIngestConfig(tenantId: string, modelName: string) {
        const response = await axios.get(
            getIngestConfig
                .replace('{tenant_id}', tenantId)
                .replace('{model_name}', modelName),
            {
                headers: await Backend.getCommonHeaders()
            }
        );
        return response.data;
    }

    public static async getIngestModels(tenantId: string): Promise<string[]> {
        const response = await axios.post(
            ingestModels.replace('{tenant_id}', tenantId),
            {},
            {
                headers: await Backend.getCommonHeaders()
            }
        );
        return response.data;
    }

    public static async getIngestDocuments(
        tenantId: string,
        limit?: number,
        offset?: number,
        tenant_models?: string[],
        status?: string,
        created_by?: string,
        search_term?: string,
        min_created_date?: string,
        max_created_date?: string,
        is_descending?: boolean,
        order_by?: string
    ): Promise<IngestedDocumentsResponse> {
        const response = await axios.post(
            ingestDocuments.replace('{tenant_id}', tenantId),
            {
                tenant_models,
                min_created_date,
                max_created_date,
                status,
                created_by,
                limit,
                offset,
                search_term,
                is_descending,
                order_by
            },
            {
                headers: await Backend.getCommonHeaders()
            }
        );
        return response.data;
    }

    public static async getAllPersonas(tenantId: string) {
        return axios.get(getPersonas.replace('{tenant_id}', tenantId), {
            headers: await Backend.getCommonHeaders()
        });
    }

    public static async updateFilePersonas(
        tenantId: string,
        fileData: EditedPersonasOfFile[]
    ) {
        const req = {
            files: fileData
        };
        return axios.post(
            updateFilePersonas.replace('{tenant_id}', tenantId),
            req,
            {
                headers: await Backend.getCommonHeaders()
            }
        );
    }

    public static async createPersona(
        tenantId: string,
        name: string,
        description: string = '',
        users: string[] = [],
        copyFromPersonaId: string = ''
    ) {
        const req = {
            name,
            description,
            users,
            copy_from_persona_id: copyFromPersonaId
        };
        return axios.post(createPersona.replace('{tenant_id}', tenantId), req, {
            headers: await Backend.getCommonHeaders()
        });
    }

    public static async updatePersona(
        tenantId: string,
        personas: UpdatedPersonas[],
        replace: boolean = false
    ) {
        const req = {
            personas,
            replace
        };
        return axios.post(updatePersona.replace('{tenant_id}', tenantId), req, {
            headers: await Backend.getCommonHeaders()
        });
    }

    public static async copyFromPersona(
        tenantId: string,
        personaId: string,
        copyFromPersonaId: string
    ) {
        const req = {
            persona_id: personaId,
            copy_from_persona_id: copyFromPersonaId
        };
        return axios.post(
            copyFromPersona.replace('{tenant_id}', tenantId),
            req,
            {
                headers: await Backend.getCommonHeaders()
            }
        );
    }

    public static async getUsersByPersona(
        tenantId: string,
        personaIds: string[]
    ) {
        const req = {
            persona_ids: personaIds
        };
        return axios.post(
            getUsersByPersona.replace('{tenant_id}', tenantId),
            req,
            {
                headers: await Backend.getCommonHeaders()
            }
        );
    }

    public static async getDocumentsByPersona(
        tenantId: string,
        personaId: string
    ) {
        return axios.get(
            getDocumentsByPersona.replace('{tenant_id}', tenantId) + personaId,
            {
                headers: await Backend.getCommonHeaders()
            }
        );
    }

    public static async addUploadedFiles(
        tenantId: string,
        modelName: string,
        fileNames: string[],
        personasId: string[]
    ) {
        const response = await axios.post(
            addDocuments.replace('{tenant_id}', tenantId),
            {
                model_name: modelName,
                document_names: fileNames,
                persona_ids: personasId
            },
            {
                headers: await Backend.getCommonHeaders()
            }
        );
        return response.data;
    }

    public static async getIsPersonasEnabled() {
        return axios.get(isPersonasEnabled, {
            headers: await Backend.getCommonHeaders()
        });
    }

    public static async fetchMessageDisclaimers(
        locale: string,
        question: string,
        answer: string,
        isActive: boolean = true
    ) {
        const response = await axios.get(
            disclaimerList +
                `?locale=${locale}&is_active=${isActive}&question=${question}&answer=${answer}`,
            {
                headers: await Backend.getCommonHeaders()
            }
        );
        return response.data;
    }

    public static async fetchExpertResponses(
        models: string[],
        searchTerm: string,
        sort: string = 'desc',
        orderBy: string,
        offset: number,
        limit: number
    ) {
        const config = {
            headers: await Backend.getCommonHeaders(),
            params: {
                tenant_models: JSON.stringify(models),
                search_term: searchTerm,
                sort,
                order_by: orderBy,
                offset,
                limit
            }
        };
        const response = await axios.get(getExpertResponses, config);
        return response.data;
    }

    public static async fetchExpertResponsesModels() {
        const response = await axios.get(getExpertResponsesModels, {
            headers: await Backend.getCommonHeaders()
        });
        return response.data;
    }

    public static async getExpertResponsesByVectorId(
        vector_id: string,
        model: string
    ) {
        const config = {
            headers: await Backend.getCommonHeaders(),
            params: {
                vector_id,
                tenant_model: model
            }
        };
        const response = await axios.get(getExpertResponsesByVectorId, config);
        return response.data;
    }

    public static async updateExpertResponse(
        chatId: string,
        expertResponse: string = '',
        userName: string = '',
        active: boolean = null,
        vectorId: string = '',
        tenant_model: string = ''
    ) {
        const payload = {
            id: chatId,
            tenant_model
        };
        if (expertResponse.length > 0) {
            payload['expert_response'] = expertResponse;
            payload['user_name'] = userName;
        }
        if (active !== null) {
            payload['active'] = active;
            payload['vector_id'] = vectorId;
        }
        const response = await axios.post(updateExpertResponse, payload, {
            headers: await Backend.getCommonHeaders()
        });
        return response.data;
    }

    public static async fetchFeedbacks(
        filters: FeedbacksFilters,
        searchTerm: string,
        sort: string = SORT_TYPES.DESC,
        orderBy: string,
        offset: number,
        limit: number
    ) {
        const config = {
            headers: await Backend.getCommonHeaders(),
            params: {
                tenant_models: JSON.stringify(filters.models || []),
                search_term: searchTerm,
                feedback_category:
                    filters.category !== 'None' ? filters.category : '',
                order_by: orderBy,
                feedback_type: filters.type,
                review_status:
                    filters.status === ReviewStatus.NotReviewed
                        ? ''
                        : filters.status,
                min_created_date: filters.minCreateDate,
                max_created_date: filters.maxCreateDate,
                is_descending: sort === SORT_TYPES.DESC,
                offset,
                limit,
                feedback_value: filters.value
            }
        };
        const response = await axios.get(feedbackList, config);
        return response.data;
    }

    public static async updateFeedback(
        rowid: string,
        notes?: string,
        status?: ReviewStatus
    ) {
        const req = {
            [rowid]: {
                notes,
                review_status:
                    status !== ReviewStatus.NotReviewed ? status : null
            }
        };
        return axios.post(updateFeedback, req, {
            headers: await Backend.getCommonHeaders()
        });
    }

    public static async fetchFeedbackModels(
        searchTerm?: string,
        limit?: number
    ) {
        const config = {
            headers: await Backend.getCommonHeaders(),
            params: {
                search_term: searchTerm,
                limit
            }
        };
        const response = await axios.get(feedbackModels, config);
        return response.data;
    }

    public static async fetchChatMessage(model: string, rowid: string) {
        const config = {
            headers: await Backend.getCommonHeaders(),
            params: {
                model_name: model
            }
        };
        const response = await axios.get(
            chatMessage.replace('{rowid}', rowid),
            config
        );
        return response.data;
    }
}

export default Backend;

