import React from 'react';
import classnames from 'classnames/bind';
import styles from '../../Common/FloatingMenu/FloatingMenu.module.scss';
import FloatingMenuItem from '../../Common/FloatingMenu/FloatingMenuItem';
import { PlusIcon } from 'aqui/icons';
import { Typography } from 'aqui';
import { t } from 'i18next';
import { useIngestModalTriggering } from './IngestModal/IngestModalHooks';

const cx = classnames.bind(styles);
const AddPersonasMenuItem = () => {
    const { setIsAddPersonasToSourcesModalOpen } = useIngestModalTriggering();

    return (
        <FloatingMenuItem
            dataTestId="floatingMenuActionAddPersonas"
            onClick={() => setIsAddPersonasToSourcesModalOpen(true)}
            className={cx('view-floating-menu__add-persona')}
            disabled={false}
        >
            <PlusIcon />
            <Typography type="small1">
                {t('ingested-sources.add-personas')}
            </Typography>
        </FloatingMenuItem>
    );
};

export default AddPersonasMenuItem;
