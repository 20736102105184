import { Typography } from 'aqui';
import { LINK_REGEX } from '../consts';
import { remark } from 'remark';
import strip from 'strip-markdown';
import SFBackend from '../backend/SFBackend';
import { t } from 'i18next';

const HEB_REGEX = /[\u0590-\u05FF]/;

export const doesContainHebrew = (text) => {
    return HEB_REGEX.test(text);
};

export const isEqualStrings = (
    string1 = '',
    string2 = '',
    caseSensitive = false
) => {
    if (caseSensitive) {
        return string1 === string2;
    }
    return string1?.toLowerCase() === string2?.toLowerCase();
};

export const findModelByName = (models, name) => models.find(model => isEqualStrings(model.name, name));

export const consoleLog = (...props) => {
    const isDevUser =
        window.__triageStore.scopServerBaseUrl?.includes('-dev') ||
        window.__triageStore.scopServerBaseUrl?.includes('-qa');
    if (isDevUser) {
        console.debug('@@@', ...props);
    }
};

export const inIframe = () => {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
};

export const getDatePeriod = (date, dateHeaders) => {
    const today = new Date().setHours(0, 0, 0, 0);
    const sevenDaysAgo = new Date(today).setDate(new Date(today).getDate() - 7);
    const monthAgo = new Date(today).setMonth(new Date(today).getMonth() - 1);
    const threadsDay = new Date(date).setHours(0, 0, 0, 0);

    const isToday = threadsDay === today;
    const isLast7days = threadsDay < today && threadsDay >= sevenDaysAgo;
    const isLastMonth = threadsDay < sevenDaysAgo && threadsDay > monthAgo;

    if (isToday && !dateHeaders.includes('today')) {
        dateHeaders.push('today');
        return dateHeader(t('common.today'));
    } else if (isLast7days && !dateHeaders.includes('7days')) {
        dateHeaders.push('7days');
        return dateHeader(t('common.last-7-days'));
    } else if (isLastMonth && !dateHeaders.includes('30days')) {
        dateHeaders.push('30days');
        return dateHeader(t('common.last-month'));
    } else if (threadsDay < monthAgo) {
        const year = new Date(date).getFullYear();
        if (!dateHeaders.includes(year + '')) {
            dateHeaders.push(year + '');
            return dateHeader(year + '');
        }
    }
};

const dateHeader = (title) => {
    return (
        <Typography className="dateThread__date-header" type="small2">
            {title}
        </Typography>
    );
};

export const prepareDate = (date) => {
    const inputDate = new Date(date);
    if (!isNaN(inputDate.getTime())) {
        const outputDateString = new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        }).format(inputDate);

        return outputDateString;
    } else {
        console.error('Invalid date string');
    }
};

export const getTodayDateAndTime = () => {
    const now = new Date();

    const year = now.getFullYear().toString().padStart(4, '0');
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const day = now.getDate().toString().padStart(2, '0');
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');

    const formattedDate = `${month}/${day}/${year}, ${hours}:${minutes}:${seconds}`;
    return formattedDate;
};

export const getRelevantRelatedSources = (text, relatedSources) => {
    const relevantReletedSourcesIndexs = [
        ...new Set(
            Array.from(text.matchAll(/SOURCE_(\d+)/g)).map((match) =>
                parseInt(match[1])
            )
        )
    ];
    return relatedSources.map((source, index) => {
        return relevantReletedSourcesIndexs.includes(index + 1)
            ? source
            : {
                  ...source,
                  isHidden: true
              };
    });
};

export const splitTextByIndicators = (text) => {
    const textArray = text
        .split(/(SOURCE_\d+|\n\n|EXPERT_EDIT)/)
        .filter((segment) => /[^\s]/.test(segment) || segment === '\n\n');
    return textArray.reduce((chunks, segment, currentIndex) => {
        if (
            segment === '\n\n' &&
            textArray[currentIndex + 1]?.includes('SOURCE_')
        ) {
            return chunks;
        }
        chunks.push(segment);

        return chunks;
    }, []);
};
export const getIsStringContainsSpecialChar = (string) => {
    const regex = /^[a-zA-Z0-9 !\-_.*'()+]*$/;
    return !!string.match(regex);
};

export const updateHistoryMessage = (
    historyMessages,
    setHistoryMessages,
    messageIndex,
    historyMessageIndex,
    newResponse,
    userName
) => {
    const newMessagesArr = historyMessages[messageIndex];
    const newMessage = historyMessages[messageIndex][historyMessageIndex];
    newMessage.message = newResponse;
    newMessage.expertDetails = {
        name: userName,
        date: prepareDate(new Date())
    };
    newMessagesArr[historyMessageIndex] = newMessage;
    setHistoryMessages({
        ...historyMessages,
        [messageIndex]: newMessagesArr
    });
};

export const arraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
        return false;
    }

    const sortedArr1 = arr1.slice().sort();
    const sortedArr2 = arr2.slice().sort();

    for (let i = 0; i < sortedArr1.length; i++) {
        if (sortedArr1[i] !== sortedArr2[i]) {
            return false;
        }
    }

    return true;
};

export const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

export const returnValdinAndNotValidMails = (emails) => {
    const notValidEmails = [];
    const validEmails = [];
    emails.forEach((email) => {
        if (!validateEmail(email)) {
            notValidEmails.push(email);
        } else {
            validEmails.push(email);
        }
    });
    return { notValidEmails, validEmails };
};

export const handleUsersEmailsExistence = async (emails) => {
    let existingEmails = await SFBackend.getExistingUsersFromList(emails);
    let notExistingEmails = [];
    if (existingEmails === null) {
        existingEmails = [...emails];
    } else {
        existingEmails = existingEmails.map((email) => email.Username);
        notExistingEmails = emails.filter(
            (email) => !existingEmails.includes(email)
        );
    }
    return { existingEmails, notExistingEmails };
};

export const splitAndCleanStringToArray = (value, regex) => {
    if (!regex) {
        // By default, split by comma, space, or new line
        regex = /[\n, ]+/;
    }
    const dataSet = new Set(
        value
            .split(regex)
            .map((instance) => instance.trim())
            .filter(Boolean)
    );
    return Array.from(dataSet);
};
export const parseTextWithLinks = (
    text,
    linkDataTestId,
    linkClassName,
    onLinkClick = (url) => {
        window.open(url);
    }
) => {
    const splittedText = text.split(LINK_REGEX);
    return splittedText
        .map((part, index) => {
            if (index % 3 === 1) {
                const url = splittedText[index + 1];
                return (
                    <span
                        data-url={url}
                        key={part + url + index}
                        onClick={() => onLinkClick(url)}
                        className={linkClassName}
                        data-test-id={linkDataTestId}
                    >
                        {part}
                    </span>
                );
            } else if (index % 3 === 0) {
                return part;
            }
            return null;
        })
        .filter(Boolean);
};

export const getLinkType = (url) =>
    url.startsWith('mailto:') ? 'Email' : 'Website';

export const getLinksFromText = (text) => {
    const splittedText = text.split(LINK_REGEX);
    return splittedText
        .map((part, index) => {
            if (index % 3 === 1) {
                return {
                    url: splittedText[index + 1],
                    type: getLinkType(splittedText[index + 1]),
                    label: part
                };
            }
            return null;
        })
        .filter(Boolean);
};

export const combineWithIndex = (arr) => {
    const result = [];
    let currentString = '';

    for (let i = 0; i < arr.length; i++) {
        const item = arr[i];
        if (item.startsWith('SOURCE_') || item.startsWith('EXPERT_EDIT')) {
            if (currentString) {
                currentString = [currentString, `[${item}](${item})`].join('');
            } else if (i > 0 && !arr[i - 1].startsWith('SOURCE_')) {
                result[result.length - 1] += `[${item}](${item})`;
            } else {
                currentString = item;
            }
        } else if (item === '\n\n') {
            if (currentString) {
                currentString = [currentString, '[EDIT_ICON](EDIT_ICON)'].join(
                    ''
                );
                result.push(currentString);
                result.push(item);
            }
            currentString = '';
        } else {
            if (currentString) {
                currentString = [currentString, '[EDIT_ICON](EDIT_ICON)'].join(
                    ''
                );
                result.push(currentString);
            }
            currentString = item;
        }
    }

    if (currentString) {
        currentString = [currentString, '[EDIT_ICON](EDIT_ICON)'].join('');
        result.push(currentString);
    }

    return result;
};

export const removedEditMarkdownStrings = (text) => {
    const stringsToRemove = [
        '[EDIT_ICON](EDIT_ICON)',
        '[EXPERT_EDIT](EXPERT_EDIT)'
    ];

    return stringsToRemove.reduce((acc, str) => acc.replace(str, ''), text);
};

export const revertChangedMarkdownLinks = (text) => {
    const replacements = {
        '\\[SOURCE_(\\d+)\\]\\(SOURCE_\\d+\\)': ' SOURCE_$1',
        '\\[EXPERT_EDIT\\]\\(EXPERT_EDIT\\)': ' EXPERT_EDIT',
        '\\[EDIT_ICON\\]\\(EDIT_ICON\\)': ''
    };

    return Object.entries(replacements).reduce(
        (acc, [pattern, replacement]) => {
            const regex = new RegExp(pattern, 'g');
            return acc.replace(regex, replacement);
        },
        text
    );
};

export const removeMarkdown = (markdownString) => {
    const result = remark()
        .use(strip, {
            keep: ['list', 'listItem']
        })
        .processSync(markdownString);
    return result.toString();
};
export const removeIndicatorsFromText = (textArray) => {
    return textArray.reduce((acc, segment) => {
        if (!segment.includes('SOURCE_') && !segment.includes('EXPERT_EDIT')) {
            return acc + segment;
        } else {
            return acc;
        }
    }, '');
};

export const calculateTextWidth = (text, font, letterSpacing) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (context) {
        context.font = font;
        const textWidth = context.measureText(text).width;
        const totalLetterSpacing = letterSpacing * (text.length - 1);
        return Math.ceil(textWidth + totalLetterSpacing);
    }
    return 0;
};

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};
