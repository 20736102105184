import { FunctionComponent, useMemo, useState } from 'react';
import SidePanel from '../SidePanel/SidePanel';
import SourceDocumentDetails from '../SourceDocumentDetails/SourceDocumentDetails';
import {
    useDownloadSource,
    useKAModal,
    useScopSettings
} from '../../AppContext';
import { Typography } from 'aqui';

import { ChevronIcon, DownloadIcon } from 'aqui/icons';
import { Tooltip } from '@mui/material';
import FeedbackMessageButtons from '../FeedbackMessageButtons/FeedbackMessageButtons';
import useAnalytics from '../../hooks/useAnalytics';
import { ANALYTICS_EVENTS, ANALYTICS_EVENT_PARAMS_KEYS } from '../../consts';
import { getFileNameAndType } from '../../utils/fileUtils';
import { useSelector } from 'react-redux';
import { useSmallChatInPortal } from '../../context/ChatContext';
import CoPilotButton from '../CoPilotButton/CoPilotButton';
import { t } from 'i18next';
import { debounce } from 'lodash';
import useSwitchPanel from '../../hooks/useSwitchPanel';
import ValueWithTooltip from '../ValueWithTooltip/ValueWithTooltip';

const NEXT = 'next';
const PREV = 'prev';

type NavigatorArrowsTypes = 'next' | 'prev';

const SourceModal: FunctionComponent = () => {
    const { isSmallChat } = useSelector((state: any) => state.coPilotReducer);
    const {
        showSourceDocumentDetailsDialog,
        hideSourceDocumentDetailsDialog,
        selectedSourceDocumentIndex,
        messageSourceDocuments,
        setSelectedSourceDocumentIndex,
        parentMessage
    } = useKAModal();
    const sourceDocument =
        messageSourceDocuments?.[selectedSourceDocumentIndex];
    const { sendEvent } = useAnalytics();
    const { isSmallChatInPortalCollapsed, setIsSmallChatInPortalCollapsed } =
        useSmallChatInPortal();
    const { isDataManagementPanel } = useSwitchPanel();
    const { enableDownloadSourcesForAllUsers, enableBasicPdfViewer } =
        useScopSettings();
    const withChat = !isSmallChatInPortalCollapsed && !isDataManagementPanel;

    const { isUserAllowedToExpertMode, isUserExpertAdmin, isUserAdmin } =
        useSelector((state: any) => state.globalReducer);
    const showDownloadButton =
        (enableDownloadSourcesForAllUsers ||
            isUserAllowedToExpertMode ||
            isUserExpertAdmin ||
            isUserAdmin) &&
        !!sourceDocument?.metadata?.source &&
        !enableBasicPdfViewer;

    const onCloseClick = () => {
        if (!sourceDocument) {
            return;
        }
        const [fileType] = getFileNameAndType(sourceDocument.metadata.filename);
        sendEvent(ANALYTICS_EVENTS.CLICKED_CLOSE_KNOWLEDGE_SOURCE, {
            'Type of source': fileType
        });
        hideSourceDocumentDetailsDialog();
        setIsSmallChatInPortalCollapsed(false);
    };
    const [isDocPreparingToLoad, setIsDocPreparingToLoad] = useState(false);

    const docsLength = useMemo(() => {
        return messageSourceDocuments?.length;
    }, [messageSourceDocuments]);

    const nextDocDisabled =
        isDocPreparingToLoad || selectedSourceDocumentIndex + 1 === docsLength;
    const prevDocDisabled =
        isDocPreparingToLoad || selectedSourceDocumentIndex === 0;

    const { downloadSource, getIsSourceDownloading } = useDownloadSource();
    const isSourceDownloading = getIsSourceDownloading(
        sourceDocument?.metadata.filename,
        sourceDocument?.metadata.model
    );

    const handleArrowClick = (button: NavigatorArrowsTypes) => {
        debounce(() => {
            if (button === NEXT) {
                setSelectedSourceDocumentIndex(selectedSourceDocumentIndex + 1);
                sendEvent(ANALYTICS_EVENTS.CLICKED_NEXT_DOC_RESULT);
            }
            if (button === PREV) {
                setSelectedSourceDocumentIndex(selectedSourceDocumentIndex - 1);
                sendEvent(ANALYTICS_EVENTS.CLICKED_PREVIOUS_DOC_RESULT);
            }
        }, 500)();
    };
    const handleDownload = () => {
        sendEvent(ANALYTICS_EVENTS.DOWNLOAD_SOURCE, {
            'Source name': sourceDocument.metadata.filename,
            'Download type':
                ANALYTICS_EVENT_PARAMS_KEYS.SOURCE_PAGE_DOWNLOAD_TYPE
        });
        downloadSource(
            sourceDocument.metadata.source,
            sourceDocument.metadata.filename,
            sourceDocument.metadata.model
        );
    };

    const getByTitle = () => {
        return (
            <div className="flex align-center space-between full-width">
                <ValueWithTooltip
                    data-testid="source-header"
                    className="side-panel__header source-modal__header"
                    type="h6"
                    title={sourceDocument?.metadata?.filename}
                />
                {showDownloadButton && (
                    <DownloadIcon
                        onClick={handleDownload}
                        className={`source-modal__download-btn ${
                            isSourceDownloading ? '--in-progress' : ''
                        }`}
                    />
                )}
                {!isDataManagementPanel && (
                    <div className="flex align-center source-modal__navigator">
                        <Typography
                            data-testid="source-pages-navigator"
                            type="small1"
                        >
                            {`${t('common.results-of')
                                .replace(
                                    '{current_result}',
                                    selectedSourceDocumentIndex + 1 + ''
                                )
                                .replace('{total_results}', docsLength + '')}`}
                        </Typography>
                        <div
                            data-testid="source-navigator-buttons"
                            className="flex align-center"
                        >
                            <Tooltip
                                classes={{
                                    tooltip: 'tooltip tooltip__sideModal-arrows'
                                }}
                                placement="top"
                                title={
                                    prevDocDisabled
                                        ? ''
                                        : (t(
                                              'common.previous-result'
                                          ) as string)
                                }
                            >
                                <ChevronIcon
                                    data-testid="source-navigator-button-previous"
                                    onClick={() =>
                                        !prevDocDisabled &&
                                        handleArrowClick(PREV)
                                    }
                                    direction="up"
                                    className={`source-modal__arrow ${
                                        prevDocDisabled
                                            ? 'disabled'
                                            : 'clickable'
                                    }`}
                                />
                            </Tooltip>
                            <Tooltip
                                classes={{
                                    tooltip: 'tooltip tooltip__sideModal-arrows'
                                }}
                                placement="top"
                                title={
                                    nextDocDisabled
                                        ? ''
                                        : (t('common.next-result') as string)
                                }
                            >
                                <ChevronIcon
                                    data-testid="source-navigator-button-next"
                                    onClick={() =>
                                        !nextDocDisabled &&
                                        handleArrowClick(NEXT)
                                    }
                                    direction="down"
                                    className={`source-modal__arrow ${
                                        nextDocDisabled
                                            ? 'disabled'
                                            : 'clickable'
                                    }`}
                                />
                            </Tooltip>
                        </div>
                        <FeedbackMessageButtons
                            type="source"
                            message={parentMessage}
                            sourceDocument={sourceDocument}
                            shouldShowTooltip
                        />
                    </div>
                )}
            </div>
        );
    };
    return (
        <SidePanel
            open={showSourceDocumentDetailsDialog}
            onClose={onCloseClick}
            size={'large'}
            classes={{
                body: `side-panel__body side-panel__source-body flex-col ${
                    withChat ? '--with-chat' : ''
                }`,
                container: `side-panel__container ${
                    isSmallChat ? '--smallChat' : ''
                }`
            }}
            title={getByTitle()}
        >
            <>
                {showSourceDocumentDetailsDialog && (
                    <SourceDocumentDetails
                        sourceDocument={
                            messageSourceDocuments[selectedSourceDocumentIndex]
                        }
                        setIsDocPreparingToLoad={setIsDocPreparingToLoad}
                    />
                )}
                {!isDataManagementPanel && (
                    <CoPilotButton
                        onClick={() => setIsSmallChatInPortalCollapsed(false)}
                        tooltipTitle={t('chat.open-chat')}
                        className="source-modal__copilot-btn"
                        isLarge
                        dataTestId="open-chat-button"
                    />
                )}
            </>
        </SidePanel>
    );
};

export default SourceModal;
