import { FunctionComponent } from 'react';
import './Home.scss';
import PanelContainer from './components/PanelContainer/PanelContainer';
import FirstRequestPanel from './components/FirstRequestPanel/FirstRequestPanel';
import useSwitchPanel from './hooks/useSwitchPanel';
import EclipseLoader from './SVG/EclipseLoader';
import useChatHistory from './hooks/useChatHistory';

import ThreadsPanel from './components/ThreadsPanel/ThreadsPanel';
import MainHeader from './components/MainHeader/MainHeader';
import { useSelector } from 'react-redux';
import CoPilotPanel from './components/CoPilotPanel/CoPilotPanel';
import DataManagementPanel from './components/DataManagementPanel/DataManagementPanel';

const Home: FunctionComponent = () => {
    const { isCoPilotPanel, isFirstRequestPanel, isDataManagementPanel } =
        useSwitchPanel();
    const { isSmallChat } = useSelector((state: any) => state.coPilotReducer);
    useChatHistory();

    const isTriageLoading = useSelector(
        (state: any) => state.applicationLoaderReducer.isLoading
    );

    const getClassName = (className: string) => {
        //No need styles for small chat
        return !isSmallChat ? className : '';
    };

    const shouldShowChat = isSmallChat || (!isTriageLoading && isCoPilotPanel);
    const shouldShowFirstRequestPanel =
        !isTriageLoading && isFirstRequestPanel && !isSmallChat;

    return (
        <>
            <div
                className={getClassName(
                    'flex flex-col full-width home-container'
                )}
            >
                {!isSmallChat && <MainHeader />}
                <div className={getClassName('flex full-height')}>
                    {!isSmallChat && <ThreadsPanel />}
                    <div className={getClassName('home')}>
                        {isTriageLoading && !isSmallChat && (
                            <div className="flex align-center justify-center full-height">
                                <EclipseLoader />
                            </div>
                        )}
                        {shouldShowFirstRequestPanel && <FirstRequestPanel />}
                        {(shouldShowChat || isDataManagementPanel) && (
                            <PanelContainer isSmallChat={isSmallChat}>
                                <>
                                    {shouldShowChat && <CoPilotPanel />}
                                    {isDataManagementPanel && (
                                        <DataManagementPanel />
                                    )}
                                </>
                            </PanelContainer>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Home;
