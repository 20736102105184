import { t } from 'i18next';
import {
    COLUMNS,
    RESPONSE_FEEDBACK_VALUES,
    REWRITE_FEEDBACK_VALUES
} from './consts';
import {
    FeedbackRow,
    FeedbackType,
    FeedbackValue,
    IntegratedModel,
    ReviewStatus
} from '../../../types';
import {
    adjustIntegratedModel,
    getIntegratedModelByProductIdMap,
    getIntegratedModelDisplay
} from '../../../utils/productUtils';
import LikeIcon from '../../../SVG/LikeIcon';
import {
    prepareDate,
    removeIndicatorsFromText,
    removeMarkdown,
    splitTextByIndicators
} from '../../../utils/utils';

export const getColumnsData = () => [
    {
        field: COLUMNS.FEEDBACK_TYPE,
        headerName: t('feedbacks-dm.column-feedback-type'),
        width: 98,
        maxWidth: 98
    },
    {
        field: COLUMNS.FEEDBACK_VALUE,
        headerName: t('feedbacks-dm.column-feedback-value'),
        minWidth: 110,
        maxWidth: 110
    },
    {
        field: COLUMNS.MODEL,
        headerName: t('feedbacks-dm.column-model'),
        width: 190
    },
    {
        field: COLUMNS.SOURCE,
        headerName: t('feedbacks-dm.column-source'),
        width: 250,
        sortable: false
    },
    {
        field: COLUMNS.PLATFORM,
        headerName: t('feedbacks-dm.column-platform'),
        minWidth: 115,
        maxWidth: 115
    },
    {
        field: COLUMNS.CREATED_AT,
        headerName: t('feedbacks-dm.column-created-at'),
        minWidth: 140,
        maxWidth: 140
    },
    {
        field: COLUMNS.USERNAME,
        headerName: t('feedbacks-dm.column-username'),
        width: 190
    },
    {
        field: COLUMNS.INITIAL_QUESTION,
        headerName: t('feedbacks-dm.column-initial-question'),
        width: 250,
        sortable: false
    },
    {
        field: COLUMNS.QUESTION,
        headerName: t('feedbacks-dm.column-question'),
        width: 250,
        sortable: false
    },
    {
        field: COLUMNS.RESPONSE,
        headerName: t('feedbacks-dm.column-response'),
        width: 410,
        sortable: false
    },
    {
        field: COLUMNS.CONCISE,
        headerName: t('feedbacks-dm.column-is-concise'),
        width: 100
    },
    {
        field: COLUMNS.PERSONA,
        headerName: t('feedbacks-dm.column-persona'),
        width: 100
    },
    // {
    //     field: COLUMNS.EDITED_BY_EXPERT,
    //     headerName: t('feedbacks-dm.column-edited-by-expert'),
    //     minWidth: 158,
    //     maxWidth: 158
    // },
    {
        field: COLUMNS.FEEDBACK_CATEGORIES,
        headerName: t('feedbacks-dm.column-feedback-category'),
        width: 300,
        sortable: false
    },
    {
        field: COLUMNS.FEEDBACK_TEXT,
        headerName: t('feedbacks-dm.column-feedback-text'),
        minWidth: 410,
        flex: 1
    },
    {
        field: COLUMNS.REVIEW_STATUS,
        headerName: t('feedbacks-dm.column-review-status'),
        minWidth: 167,
        maxWidth: 167
    },
    {
        field: COLUMNS.NOTES,
        headerName: t('feedbacks-dm.column-notes'),
        width: 300,
        sortable: false
    }
];

export const getFeedbackPopulatedRows = (
    rows: FeedbackRow[],
    ingestedModels: IntegratedModel[]
): FeedbackRow[] => {
    const ingestedModelsMap = getIntegratedModelByProductIdMap(ingestedModels);
    return (
        rows?.map((row) => {
            const integratedModel = ingestedModelsMap[
                row.triage_product_id
            ] || { modelName: row.tenant_model };
            if (row.current_source) {
                row.current_source.tenantModel = row.tenant_model;
            }
            row.integratedModel = integratedModel;
            row.modelDisplay = getIntegratedModelDisplay(integratedModel);
            row.created_at = new Date(row.created_at);
            row.id = row.rowid;
            row.categories = row.categories?.sort() || [];
            row.review_status = row.review_status || ReviewStatus.NotReviewed;
            return row;
        }) || []
    );
};

export const getFeedbackValueIcon = (value: FeedbackValue) => {
    const style = {
        color: '#6E768A',
        borderRadius: '100%',
        backgroundColor: '#ECEFF6'
    };
    switch (value) {
        case FeedbackValue.Like:
            return <LikeIcon style={style} />;
        case FeedbackValue.Dislike:
            return <LikeIcon dislike style={style} />;
        default:
            return <div style={{ minWidth: '24px' }} />;
    }
};

export const getFeedbackValuesFilterOptions = (feedbackType: FeedbackType) => {
    if (feedbackType === FeedbackType.Rewrite) {
        return REWRITE_FEEDBACK_VALUES;
    }
    if (
        feedbackType === FeedbackType.Response ||
        feedbackType === FeedbackType.Source
    ) {
        return RESPONSE_FEEDBACK_VALUES;
    }
    return Object.values(FeedbackValue);
};

export const getResponseText = (response: string, isNoResponse: boolean) => {
    if (isNoResponse) {
        return t('expert-responses-dm.no-answer');
    }
    return removeMarkdown(
        removeIndicatorsFromText(splitTextByIndicators(response))
    );
};

export const getExportFileData = (
    feedbackRows: FeedbackRow[],
    ingestedModels: IntegratedModel[]
) => {
    const ingestedModelsMap = getIntegratedModelByProductIdMap(ingestedModels);
    const fileName = `feedbacks_${new Date().toISOString()}.csv`;
    const fileData = feedbackRows.map((row) => {
        const integratedModel = ingestedModelsMap[row.triage_product_id] || {
            modelName: row.tenant_model
        };
        return {
            [t('feedbacks-dm.column-feedback-type')]: row.feedback_type,
            [t('feedbacks-dm.column-feedback-value')]: row.feedback_value,
            [t('feedbacks-dm.column-model')]:
                adjustIntegratedModel(integratedModel),
            [t('feedbacks-dm.column-platform')]: row.platform,
            [t('feedbacks-dm.column-created-at')]: prepareDate(row.created_at),
            [t('feedbacks-dm.column-username')]: row.created_by,
            [t('feedbacks-dm.column-initial-question')]: row.initial_question,
            [t('feedbacks-dm.column-question')]: row.question,
            [t('feedbacks-dm.column-response')]: getResponseText(
                row.response,
                row.no_response
            ),
            [t('feedbacks-dm.column-is-concise')]: row.is_concise_response
                ? t('common.yes')
                : t('common.no'),
            [t('feedbacks-dm.column-persona')]: row.persona_name,
            [t('feedbacks-dm.column-feedback-category')]: row.categories
                ?.sort()
                .join(', '),
            [t('feedbacks-dm.column-feedback-text')]: row.feedback_text,
            [t('feedbacks-dm.column-review-status')]: row.review_status,
            [t('feedbacks-dm.column-notes')]: row.notes
        };
    });
    return [fileData, fileName];
};