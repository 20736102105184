const LikeIcon = ({
    color = 'currentColor',
    filled = false,
    dislike = false,
    style = {},
    ...props
}) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ rotate: dislike ? '180deg' : '0', ...style }}
            {...props}
        >
            <path
                d="M5.5 12C5.5 11.1716 6.17157 10.5 7 10.5H9.5V17.5H7C6.17157 17.5 5.5 16.8284 5.5 16V12Z"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.5 17.4999L9.5 10.6714L11.6518 5.50718C11.9966 5.54217 12.3264 5.70433 12.5816 5.97657C12.8726 6.28702 13.0413 6.71459 13.0413 7.16665L13.0413 9.33331C13.0413 9.60945 13.2651 9.83331 13.5413 9.83331L16.5344 9.83331L16.5344 9.83334L16.5398 9.83328C16.6721 9.83185 16.8042 9.86095 16.9273 9.9199C17.0509 9.97908 17.1637 10.0675 17.2564 10.1813C17.3492 10.2951 17.4193 10.4312 17.46 10.5807C17.5007 10.7301 17.5105 10.8878 17.4886 11.0423C17.4886 11.0424 17.4886 11.0425 17.4886 11.0426L16.6878 16.6133L16.6877 16.614C16.6509 16.872 16.5288 17.1009 16.352 17.2612C16.1762 17.4205 15.9583 17.5023 15.7389 17.4999L15.7389 17.4999L15.7335 17.4999L9.5 17.4999Z"
                stroke={color}
                fill={filled ? color : 'none'}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default LikeIcon;
