import { ResponseDepth } from '../types';

const defaultValues = {
    response_depth: ResponseDepth.Detailed
};

class UserData {
    response_depth: ResponseDepth;

    constructor(data: Record<string, any> = defaultValues) {
        const getValue = this.getDataValueFunc(data);
        this.response_depth = getValue('response_depth');
    }

    private getDataValueFunc(data) {
        return (key: string) => data?.[key] ?? defaultValues[key];
    }

    clone() {
        return new UserData(this);
    }

    get responseDepth() {
        return this.response_depth;
    }
}

export default UserData;
