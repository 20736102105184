import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { MultiSelectSearch, Typography, Bold } from 'aqui';
import { CirclePlusIcon } from 'aqui/icons';
import { t } from 'i18next';
import { debounce } from 'lodash';
import { usePersonasManagementRows } from '../../PersonasManagement/PersonaManagementHooks';
import {
    MAX_PERSONAS_ALLOWED,
    MAX_SELECTED_PERSONAS_ALLOWED,
    PERSONA_CONSTANT_NAMES
} from '../../PersonasManagement/consts';
import EclipseLoader from '../../../../SVG/EclipseLoader';
import { Tooltip } from '@material-ui/core';

type PersonasSelectionProps = {
    selectedItems: { value: string; Id: string }[];
    setSelectedItems: (items: { value: string; Id: string }[]) => void;
    disabled?: boolean;
};

const PersonasSelection: FunctionComponent<PersonasSelectionProps> = ({
    selectedItems,
    setSelectedItems,
    disabled = false
}) => {
    const {
        personasManagementRows,
        createPersona,
        arePersonasManagementDataFetching
    } = usePersonasManagementRows();

    const [search, setSearch] = useState('');
    const [searchReady, setSearchReady] = useState('');

    useEffect(() => {
        debounce(() => setSearchReady(search), 500)();
    }, [search]);

    const ajustedData = useMemo(() => {
        return personasManagementRows
            .filter((row) => row.name !== PERSONA_CONSTANT_NAMES.ALL_SOURCES)
            .map((persona) => {
                return { value: persona.name, Id: persona.id };
            });
    }, [personasManagementRows]);

    const filteredData = useMemo(() => {
        if (!searchReady) {
            return ajustedData;
        }
        return ajustedData.filter((item) =>
            item.value.toLowerCase().includes(searchReady.toLowerCase())
        );
    }, [searchReady, ajustedData]);

    const onClickSelectAll = () => {
        if (selectedItems.length === MAX_SELECTED_PERSONAS_ALLOWED) {
            return;
        }
        const selectedById = selectedItems.map((item) => item.Id);
        setSelectedItems(
            selectedItems.concat(
                filteredData
                    .filter((item) => !selectedById.includes(item.Id))
                    .slice(
                        0,
                        MAX_SELECTED_PERSONAS_ALLOWED - selectedItems.length
                    )
            )
        );
    };

    const getFooter = () => {
        const reachedMax =
            personasManagementRows.length === MAX_PERSONAS_ALLOWED;
        if (
            !search ||
            search === PERSONA_CONSTANT_NAMES.ALL_SOURCES ||
            personasManagementRows.some((persona) => persona.name === search)
        ) {
            return null;
        }
        return (
            <Tooltip
                title={
                    reachedMax ? (t('add-persona.max-reached') as string) : ''
                }
                placement="top"
                classes={{
                    tooltip: 'tooltip'
                }}
            >
                <div
                    data-testid="personas-selection-create-new"
                    className={`flex align-center personasSelection__popper-footer ${
                        arePersonasManagementDataFetching ? 'disabled' : ''
                    } ${reachedMax ? 'max-reached' : ''}`}
                    onClick={() =>
                        !arePersonasManagementDataFetching &&
                        !reachedMax &&
                        createPersona(search)
                    }
                >
                    {arePersonasManagementDataFetching && (
                        <div
                            className="personasSelection__popper-footer__loader"
                            style={{
                                backgroundColor: `rgba(248, 249, 252, '0.1)`
                            }}
                        >
                            <EclipseLoader height="30" width="30" />
                        </div>
                    )}
                    <CirclePlusIcon />
                    <Typography type="button">
                        {t('add-persona.create-new-btn')} "<Bold>{search}</Bold>
                        " {t('add-persona.persona').toLowerCase()}
                    </Typography>
                </div>
            </Tooltip>
        );
    };

    const getHeader = () => {
        if (filteredData.length === 0) {
            return null;
        }
        return (
            <Typography
                onClick={onClickSelectAll}
                type="body3"
                className="personasSelection__popper-header"
                dataTestId="personas-selection-select-all"
            >
                {t('common.select-all')}
            </Typography>
        );
    };

    const handleSearch = (value: string) => {
        const regex = /^[\p{L}\p{M}\p{N}\p{Zs}'_-]*$/u;
        if (regex.test(value)) {
            setSearch(value);
        }
    };

    return (
        <MultiSelectSearch
            outsideSearch
            maxItemsSelected={8}
            search={search}
            setSearch={handleSearch}
            valuesWithTags={filteredData}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            placeholder={t('add-persona.search-placeholder')}
            searchTimeout={1000}
            reachedMaxText={t('add-persona.max-personas-reached')}
            inputClassName={{
                popperMain: 'ingestModalBody__persona-popper'
            }}
            numberOfRows={1}
            wrapperClassName="personasSelection__persona-select-wrapper"
            footerComponent={getFooter()}
            headerComponent={getHeader()}
            disabled={disabled}
        />
    );
};

export default PersonasSelection;
