import styles from './Cells.module.scss';
import classnames from 'classnames/bind';
import { Tooltip } from '@mui/material';
import ValueWithTooltip from '../../ValueWithTooltip/ValueWithTooltip';
import DiagonalArrow from '../../../SVG/DiagonalArrow';

const cx = classnames.bind(styles);

const ClickableCell = ({
    field,
    row,
    onClick,
    tooltip = '',
    text = '',
    icon,
    className = '',
    containerClassName = '',
    withArrow,
    arrowTooltip = ''
}) => {
    return (
        <Tooltip
            title={tooltip}
            placement="top"
            classes={{
                tooltip: 'tooltip'
            }}
        >
            <div
                className={cx(containerClassName, 'clickableCell__container')}
                data-testid="clickable-cell"
                onClick={onClick}
            >
                <ValueWithTooltip
                    type="subtitle3"
                    dataTestId={`${field}-cell`}
                    title={`${row[field]} ${text}`}
                    className={cx(className, 'editable-cell__text')}
                />
                {icon}
                {withArrow && (
                    <Tooltip
                        title={arrowTooltip}
                        placement="top"
                        classes={{
                            tooltip: 'tooltip'
                        }}
                    >
                        <div className={cx('clickableCell__hidden-arrow')}>
                            <DiagonalArrow />
                        </div>
                    </Tooltip>
                )}
            </div>
        </Tooltip>
    );
};

export default ClickableCell;
