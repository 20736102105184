import React, { useEffect, useMemo, useRef } from 'react';
import {
    DataGridPro,
    gridClasses,
    gridColumnsTotalWidthSelector,
    useGridApiRef,
    GridSortModel,
    GridLoadingOverlay
} from '@mui/x-data-grid-pro';
import classnames from 'classnames/bind';
import AscendingIcon from './Columns/SortIcons/AscendingIcon';
import DescendingIcon from './Columns/SortIcons/DescendingIcon';
import { INITIAL_STATE, ROW_HEIGHT, SORTING_ORDER } from './consts';

import styles from './Table.module.scss';
import { getColumns } from './Columns/ColumnsHelper';
import CheckboxColumnHeader from './Columns/CheckboxColumn/CheckboxColumnHeader';
import { COLUMNS } from '../DataManagementPanel/IngestedSources/consts';
import {
    PERSONA_CONSTANT_NAMES,
    COLUMNS as PersonaColumns
} from '../DataManagementPanel/PersonasManagement/consts';
import { getColumnMinWidth } from './helpers';
const cx = classnames.bind(styles);

const { grey50, grey100, grey200, grey600, grey700, grey800 } = styles;

interface TableProps {
    rows: any[];
    selectedRows?: any[];
    setSelectedRows?: React.Dispatch<React.SetStateAction<any[]>>;
    columnComponents: any;
    columnsData: any;
    pinnedColumns?: any;
    getIsRowDisabled?: (row: any) => boolean;
    dataTestId?: string;
    isLoading?: boolean;
    sortModel?: GridSortModel;
    onSortModelChange?: (sortModel: GridSortModel) => void;
    withPaddingsAlways?: boolean;
    disabledRows?: any[];
    paginationModel?: { page: number; pageSize: number };
    setPaginationModel?: (paginationModel: {
        page: number;
        pageSize: number;
    }) => void;
    hideFooter?: boolean;
    dataControlSide?: 'client' | 'server';
    rowCount?: number;
    DataGridLoadingOverlay?: React.FC | null;
    noPaddingColumns?: string[];
}

const Table: React.FC<TableProps> = ({
    rows,
    selectedRows = [],
    setSelectedRows,
    columnComponents,
    columnsData,
    pinnedColumns = null,
    getIsRowDisabled = (row: any) => false,
    dataTestId = 'data-grid-table',
    isLoading = false,
    sortModel = [],
    onSortModelChange = (sortModel) => {},
    withPaddingsAlways = false,
    disabledRows = [],
    paginationModel,
    setPaginationModel,
    hideFooter = true,
    dataControlSide = 'client',
    rowCount,
    DataGridLoadingOverlay = GridLoadingOverlay,
    noPaddingColumns = []
}) => {
    const rootRef = useRef<HTMLDivElement>(null);
    const apiRef = useGridApiRef();
    const columns = useMemo(
        () =>
            getColumns(columnsData).map((columnObject) => {
                return {
                    ...columnObject,
                    renderCell: (params: any) => {
                        return columnComponents[columnObject.field]({
                            ...params,
                            ...columnObject.componentParams
                        });
                    },
                    renderHeader: () =>
                        columnObject.field === COLUMNS.CHECKBOX ? (
                            <CheckboxColumnHeader
                                rows={rows}
                                selectedRows={selectedRows}
                                setSelectedRows={setSelectedRows}
                                disabledRows={disabledRows}
                            />
                        ) : (
                            columnObject.renderHeader()
                        ),
                    // Set minWidth for each column based on the column title width
                    minWidth: getColumnMinWidth(columnObject)
                };
            }),
        [rows, selectedRows]
    );

    const setShadowToPinnedColumnsWhenNeeded = (
        rootRef: React.RefObject<HTMLDivElement>,
        left: number
    ) => {
        if (!rootRef.current) {
            return;
        }

        if (left === 0) {
            rootRef.current.classList.add('hasScrollToLeft');
        } else {
            rootRef.current.classList.remove('hasScrollToLeft');
        }

        const columnsTotalWidth = gridColumnsTotalWidthSelector(apiRef);
        const dimensions = apiRef.current.getRootDimensions();
        if (
            Math.round(left + (dimensions?.viewportOuterSize.width || 0)) >=
            columnsTotalWidth
        ) {
            rootRef.current.classList.add('hasScrollToRight');
        } else {
            rootRef.current.classList.remove('hasScrollToRight');
        }
    };

    useEffect(() => {
        apiRef.current.subscribeEvent('scrollPositionChange', ({ left }) => {
            setShadowToPinnedColumnsWhenNeeded(rootRef, left);
            if (apiRef.current.getScrollPosition().top !== 0) {
                rootRef.current.classList.add('hasScrollToTop');
            } else {
                rootRef.current.classList.remove('hasScrollToTop');
            }
        });
    }, [apiRef]);

    const initialState = useMemo(
        () =>
            pinnedColumns ? { ...INITIAL_STATE, pinnedColumns } : INITIAL_STATE,
        [pinnedColumns]
    );

    useEffect(() => {
        if (rows.length) {
            apiRef.current.updateRows(rows);
        }
    }, [rows]);

    return (
        <div
            className={cx('data-grid-table', {
                no_results: !rows.length
            })}
            data-testid={dataTestId}
        >
            <DataGridPro
                loading={isLoading}
                checkboxSelection={true}
                rowSelectionModel={selectedRows?.map((row) => row.id)}
                ref={rootRef}
                apiRef={apiRef}
                rows={rows}
                columns={columns}
                hideFooter={hideFooter}
                paginationMode={dataControlSide}
                paginationModel={paginationModel}
                rowCount={rowCount}
                pageSizeOptions={[15]}
                onPaginationModelChange={setPaginationModel}
                pagination
                disableColumnMenu
                disableMultipleColumnsSorting
                rowHeight={ROW_HEIGHT}
                columnHeaderHeight={ROW_HEIGHT}
                scrollbarSize={12}
                sortModel={sortModel}
                onSortModelChange={onSortModelChange}
                sortingMode={dataControlSide}
                columnBuffer={2}
                columnThreshold={2}
                initialState={initialState}
                sortingOrder={SORTING_ORDER}
                disableVirtualization={!hideFooter}
                // Slots - dont render components as functions, it will cause rerendering of the whole grid
                slots={{
                    columnSortedDescendingIcon: DescendingIcon,
                    columnSortedAscendingIcon: AscendingIcon,
                    loadingOverlay: DataGridLoadingOverlay
                }}
                slotProps={{
                    pagination: {
                        sx: {
                            '& .MuiTablePagination-actions': {
                                '& .MuiIconButton-root': {
                                    background: 'transparent',
                                    '&:hover': {
                                        background: 'transparent'
                                    }
                                },
                                '& .Mui-disabled': {
                                    background: 'transparent'
                                }
                            }
                        }
                    }
                }}
                classes={{ row: cx('data-grid__row') }}
                sx={{
                    borderColor: grey100,
                    [`&.${gridClasses.columnHeaders}`]: {
                        transition: 'box-shadow 0.3s ease-in-out'
                    },
                    [`&.hasScrollToTop .${gridClasses.columnHeaders}`]: {
                        boxShadow: ' 0px 6px 14px 0px rgba(21, 47, 118, 0.14)'
                    },
                    [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
                        {
                            outline: 'none'
                        },
                    [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
                        {
                            outline: 'none'
                        },
                    [`& .${gridClasses.columnHeader}`]: {
                        paddingLeft: '16px'
                    },
                    [`& .${gridClasses.cell}`]: {
                        padding: '12px 16px',
                        display: 'flex'
                    },
                    [`& .${gridClasses.iconButtonContainer} button`]: {
                        padding: 0,
                        '&:hover': {
                            backgroundColor: 'transparent'
                        }
                    },
                    [`& .${gridClasses.row}`]: {
                        '&.Mui-hovered, &:hover': {
                            backgroundColor: grey50
                        }
                    },
                    [`& .${gridClasses.row}.Mui-selected`]: {
                        color: grey200,
                        backgroundColor: grey700,
                        '&.Mui-hovered, &:hover': {
                            backgroundColor: grey800
                        },
                        [`.${gridClasses['cell--withRightBorder']}, .${gridClasses['columnHeader--withRightBorder']}`]:
                            {
                                borderColor: grey600
                            }
                    },
                    [`& .${gridClasses['cell--withRightBorder']}, & .${gridClasses['columnHeader--withRightBorder']}`]:
                        {
                            borderColor: grey100
                        },
                    '&.hasScrollToLeft .MuiDataGrid-pinnedColumns--left, &.hasScrollToRight .MuiDataGrid-pinnedColumns--right':
                        {
                            boxShadow: 'none'
                        },
                    '&.hasScrollToLeft .MuiDataGrid-pinnedColumnHeaders--left, &.hasScrollToRight .MuiDataGrid-pinnedColumnHeaders--right':
                        {
                            boxShadow: 'none'
                        },
                    [`& .${gridClasses['cell--withRightBorder']}, & .${gridClasses['columnHeader--withRightBorder']}`]:
                        {
                            borderColor: grey100
                        }
                }}
                showColumnVerticalBorder
                showCellVerticalBorder
                getCellClassName={({ row, field, id }) => {
                    return cx('data-grid__cell', {
                        '--disabled': getIsRowDisabled(row),
                        '--noPadding':
                            noPaddingColumns.includes(field) ||
                            (withPaddingsAlways &&
                                (field === PersonaColumns.DESCRIPTION ||
                                    field === PersonaColumns.PERSONA_NAME) &&
                                row.name !== PERSONA_CONSTANT_NAMES.DEFAULT &&
                                row.name !== PERSONA_CONSTANT_NAMES.ALL_SOURCES)
                    });
                }}
                getRowClassName={({ row }) => {
                    return cx('data-grid__row', {
                        '--disabled': getIsRowDisabled(row)
                    });
                }}
            />
        </div>
    );
};

export default Table;
