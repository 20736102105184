import { calculateTextWidth } from '../../utils/utils';
import {
    COLUMN_HEADER_FONT,
    COLUMN_HEADER_LETTER_SPACING,
    COLUMN_PADDINGS,
    SORT_ICON_WIDTH
} from './consts';

export const getColumnMinWidth = (column: any) => {
    if (column.minWidth) {
        return column.minWidth;
    }
    const textWidth = calculateTextWidth(
        column.headerName?.toUpperCase() || '',
        COLUMN_HEADER_FONT,
        COLUMN_HEADER_LETTER_SPACING
    );
    return textWidth + COLUMN_PADDINGS + SORT_ICON_WIDTH;
};
