import { useMemo, useState } from 'react';
import { Button } from 'aqui';
import { MoreIcon } from 'aqui/icons';
import PopoverBox from '../../PopoverBox/PopoverBox';

const ActionsCell = ({ rows }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const onOpen = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const fixedRows = useMemo(
        () =>
            rows
                .filter((row) => row.show)
                .map((row) => ({
                    ...row,
                    onClick: () => {
                        row.onClick();
                        setAnchorEl(null);
                    }
                })),
        [rows]
    );
    return (
        <div className="flex justify-center full-width">
            <Button
                onClick={onOpen}
                dataTestId="row-actions-button"
                IconComponent={MoreIcon}
                color="blue"
                variant="outlined"
                shape="circle"
                disabled={fixedRows.length === 0}
            />
            <PopoverBox
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                rows={fixedRows}
            />
        </div>
    );
};

export default ActionsCell;
