import { FunctionComponent } from 'react';
import Chat from '../Chat/Chat';
import KASection from '../KASection/KASection';
import { useSelector } from 'react-redux';

type CoPilotPanelProps = {};

const CoPilotPanel: FunctionComponent<CoPilotPanelProps> = () => {
    const { isSmallChat } = useSelector((state: any) => state.coPilotReducer);

    return (
        <>
            <div className="home__chat-container">
                <Chat />
            </div>
            {!isSmallChat && <KASection />}
        </>
    );
};

export default CoPilotPanel;
