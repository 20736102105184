import { createSelector } from 'reselect';

const selectModel = ({ deviceInspectionReducer }) =>
    deviceInspectionReducer.deviceInformationValues.model.value;
const selectManufacturer = ({ deviceInspectionReducer }) =>
    deviceInspectionReducer.deviceInformationValues.manufacturer.value;
const selectProductType = ({ deviceInspectionReducer }) =>
    deviceInspectionReducer.deviceInformationValues.productType.value;
const selectScopMessages = ({ coPilotReducer }) => coPilotReducer.messages;
const selectFeedbacksMap = ({ coPilotReducer }) => coPilotReducer.feedbacksMap;

export const getSummaryData = createSelector(
    [
        selectModel,
        selectManufacturer,
        selectProductType,
        selectScopMessages,
        selectFeedbacksMap
    ],
    (model, manufacturer, productType, messages, feedbacksMap) => ({
        model,
        manufacturer,
        productType,
        scopMessages: messages,
        feedbacksMap
    })
);
