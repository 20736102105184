import { FunctionComponent, useEffect, useState } from 'react';
import { t } from 'i18next';
import EclipseLoader from '../../SVG/EclipseLoader';
import SidePanel from '../SidePanel/SidePanel';
import { useEditedResponse } from '../DataManagementPanel/Feedbacks/useFeedbacksHooks';
import EditResponseModalBody from './EditResponseModalBody';
import Backend from '../../backend/Backend';
import { MessageInfo, ResponseDepth, ReviewStatus } from '../../types';
import { useAskStream } from '../../context/AskStreamContext';
import FeedbackStatusOption from '../DataManagementPanel/Feedbacks/FedbackStatusOption';
import { Typography } from 'aqui';
import { useScopSettings } from '../../AppContext';

const EditResponseModal: FunctionComponent = () => {
    const { editedFeedbackRow, setEditedFeedbackRow } = useEditedResponse();
    const [updatedMessage, setUpdatedMessage] = useState<MessageInfo>();
    const { dummyAskStream } = useAskStream();
    const [isStreamInProgress, setIsStreamInProgress] = useState(false);
    const [streamReader, setStreamReader] =
        useState<ReadableStreamDefaultReader<Uint8Array>>();
    const [askAbortFunction, setAskAbortFunction] = useState<AbortController>();
    const { allowUserToEditInExpertEditTable } = useScopSettings();

    const [isOpen, setIsOpen] = useState(false);
    const [relatedMessage, setRelatedMessage] = useState(null);
    const [isFixed, setIsFixed] = useState(false);

    const cleanupStream = () => {
        askAbortFunction?.abort();
        streamReader?.cancel();
        setAskAbortFunction(null);
        setStreamReader(null);
    };

    const generateUpdatedMessage = () => {
        if (isStreamInProgress) {
            return;
        }
        dummyAskStream(
            relatedMessage.condensed_question,
            editedFeedbackRow.tenant_model,
            setUpdatedMessage,
            setIsStreamInProgress,
            editedFeedbackRow.is_concise_response
                ? ResponseDepth.Concise
                : null,
            {
                onAskInit: setAskAbortFunction,
                onStreamInit: setStreamReader
            }
        );
    };

    useEffect(() => {
        if (editedFeedbackRow) {
            setIsFixed(editedFeedbackRow.review_status === ReviewStatus.Fixed);
            setIsOpen(true);
            Backend.fetchChatMessage(
                editedFeedbackRow.tenant_model,
                editedFeedbackRow.message_id
            )
                .then((response) => setRelatedMessage(response))
                .catch((ex) => {
                    handleClose();
                    console.error('Error fetching chat message', ex);
                    window.sentry.log(ex);
                });
        }
    }, [editedFeedbackRow]);

    const handleClose = () => {
        setIsOpen(false);
        cleanupStream();
        // Wait for the close animation to finish
        setTimeout(() => {
            setEditedFeedbackRow(null);
            setRelatedMessage(null);
            setUpdatedMessage(null);
        }, 500);
    };

    if (!editedFeedbackRow) {
        return null;
    }

    return (
        <SidePanel
            open={isOpen}
            onClose={handleClose}
            classes={{
                container:
                    'side-panel__half-screen-container edit-response-modal__side-panel',
                body: 'side-panel__body side-panel__upload-body flex-col',
                header: 'side-panel__header',
                footer: 'side-panel__footer'
            }}
            title={
                <div className="edit-response-modal__header">
                    <Typography type="h3">
                        {allowUserToEditInExpertEditTable
                            ? (t('edit-response.view-edit-response') as string)
                            : (t('edit-response.view-response') as string)}
                    </Typography>
                    {isFixed && (
                        <FeedbackStatusOption
                            status={ReviewStatus.Fixed}
                            className="edit-response-modal__header__status"
                        />
                    )}
                </div>
            }
            closeOnBackdropClick={false}
        >
            {!relatedMessage ? (
                <div className="side-panel__loader">
                    <EclipseLoader />
                </div>
            ) : (
                <EditResponseModalBody
                    relatedMessage={relatedMessage}
                    generateUpdatedMessage={generateUpdatedMessage}
                    updatedMessage={updatedMessage}
                    isStreamInProgress={isStreamInProgress}
                    setIsFixed={setIsFixed}
                    isFixed={isFixed}
                    closeModal={handleClose}
                />
            )}
        </SidePanel>
    );
};

export default EditResponseModal;
