import React, {
    FunctionComponent,
    useCallback,
    useEffect,
    useState
} from 'react';

import {
    IntegratedModel,
    RespType,
    RecommendedQuestionsSource,
    MessageSenderType
} from '../../types';

import Backend from '../../backend/Backend';

import {
    useAppContext,
    useIntegratedModels,
    useModel,
    useRecommendedQuestions,
    useProductDetails,
    useMessages,
    useInitialSearch,
    useFetchedSuggestions,
    useTriageLinkMessageIndexes,
    useThreadsHandling,
    useSelectedMessage,
    useInvestigationDetails,
    useScopSettings
} from '../../AppContext';
import ChatMessage from './ChatMessage/ChatMessage';
import NoModelMessage from './NoModelMessage/NoModelMessage';
import SkeletonContainer from '../SkeletonContainer/SkeletonContainer';
import SearchBar from '../SearchBar/SearchBar';
import SmallChatHeader from '../SmallChatHeader/SmallChatHeader';
import { SmallWindowState } from '../../types';
import RecommendedQuestions from '../RecommendedQuestions/RecommendedQuestions';
import {
    ANALYTICS_EVENTS,
    REMOTE_ACTIONS,
    SPECIAL_DIV_IDS
} from '../../consts';
import useAnalytics from '../../hooks/useAnalytics';
import { adjustIntegratedModel } from '../../utils/productUtils';

import { useDispatch, useSelector } from 'react-redux';
import {
    SUGGESTED_OBSERVATIONS_FOUND,
    SUGGESTED_SOLUTIONS_FOUND,
    setChatWindowState,
    setIsSmallChat,
    suggestionsFound,
    setTriageChatId,
    updateAskingQuery
} from '../../actions/coPilotActions';
import axios from 'axios';
import SFBackend from '../../backend/SFBackend';
import ScrollToButton from './ScrollToButton/ScrollToButton';
import GenerateButton from '../GenerateButton/GenerateButton';
import HeaderMessage from '../HeaderMessage/HeaderMessage';
import { modelsSuggestionByProbability } from './SuggestQuestionMessage/helper';
import useSwitchPanel from '../../hooks/useSwitchPanel';
import {
    useChatWindowState,
    useHistoryMessages,
    useInputMessage,
    useRegenerate,
    useSmallChatInPortal,
    useTriggeredMessage,
    useSuggestedQuestions,
    useRelevantModels,
    useDisplayNoModel
} from '../../context/ChatContext';
import { prepareDate } from '../../utils/utils';
import {
    resetObservationInputs,
    resetState
} from '../../actions/triageActions';
import ChatModelBar from './ChatModelBar/ChatModelBar';
import { Typography } from 'aqui';
import CollapseButton from '../CollapseButton/CollapseButton';
import useScreenSize from '../../hooks/useScreenSize';
import { followAnswer } from './helpers';
import {
    useAskStream,
    useAskStreamContext
} from '../../context/AskStreamContext';
import { performCallWithTimeoutRetry } from '../../utils/backendUtils';
import { t } from 'i18next';

const Chat: FunctionComponent = () => {
    const {
        recommendedQuestions,
        setRecommendedQuestions,
        setRecommendedQuestionsSource
    } = useRecommendedQuestions();
    const { model, setModelData } = useModel();
    const { fetchedSuggestions, setFetchedSuggestions } =
        useFetchedSuggestions();
    const { productDetails, setProductDetails } = useProductDetails();
    const { integratedModels, fetchAndSaveIntegratedModels } =
        useIntegratedModels();
    const { messages, setMessages } = useMessages();
    const { initialSearch, setInitialSearch } = useInitialSearch();
    const { sendEvent } = useAnalytics();
    const { scopSettings } = useAppContext();
    const { addTriageLinkMessageIndex, resetTriageLinkMessageIndexes } =
        useTriageLinkMessageIndexes();
    const { setCoPilotPanel } = useSwitchPanel();
    const { setTriggeredMessage, triggeredMessage } = useTriggeredMessage();
    const {
        setTypingStarted,
        typingStarted,
        isStreamError,
        setIsAskRequestInProgress,
        isStreamInProgress,
        isAskRequestInProgress,
        setIsStreamInProgress,
        setIsStreamStopped,
        cancelStream
    } = useAskStreamContext();
    const {
        firstThreadQuestion,
        selectedThread,
        messagesPerSelectedChat,
        setMessagesPerSelectedChat,
        setIsChatHistoryLoaded,
        isChatHistoryLoaded
    } = useThreadsHandling();
    const { setSelectedMessage } = useSelectedMessage();
    const { isSmallScreen } = useScreenSize();

    const { displayNoModel, setDisplayNoModel } = useDisplayNoModel();
    const {
        setIsRegenerateAvailable,
        isGenerateButtonHidden,
        generationCounter,
        setGenerationCounter
    } = useRegenerate();
    const { inputMessage, setInputMessage } = useInputMessage();
    const [isValidState, setIsValidState] = useState<boolean | null>(null);
    const [typingFocused, setTypingFocused] = useState<boolean>(true);
    const [missedMessagesRelativeHight, setMissedMessagesCounterRelativeHight] =
        useState<number[]>([]);
    const { historyMessages, setHistoryMessages } = useHistoryMessages();
    const { showSuggestedQuestions, setShowSuggestedQuestions } =
        useSuggestedQuestions();
    const { relevantModels, setRelevantModels } = useRelevantModels();
    const { showInvestigateButtonBasedOnObservations } = useScopSettings();
    const [isMinimizedWithInput, setIsMinimizedWithInput] =
        useState<boolean>(isSmallScreen);
    const {
        isSmallChatInPortal,
        isSmallChatInPortalCollapsed,
        setIsSmallChatInPortalCollapsed
    } = useSmallChatInPortal();
    const {
        isCopilotMode,
        askingQuery,
        obsToSuggest,
        solutionsToSuggest,
        chatId,
        isSmallChat
    } = useSelector((state: any) => state.coPilotReducer);
    const {
        chatWindowState,
        handleInitializeChatWindow,
        handleMinimizeChatWindow,
        handleMaximizeChatWindow,
        handleHideChatWindow
    } = useChatWindowState();
    const { setInvestigationDetails } = useInvestigationDetails();

    const shouldShowSmallChat = isSmallChat || isSmallChatInPortal;

    const { askStream } = useAskStream();

    const dispatch = useDispatch();

    const showGeneralDisclaimer =
        !shouldShowSmallChat &&
        scopSettings.displayGeneralDisclaimer &&
        scopSettings.generalDisclaimerMessage;

    useEffect(() => {
        if (selectedThread) {
            setShowSuggestedQuestions(false);
        }
    }, [selectedThread]);

    useEffect(() => {
        performStateCheck();
    }, []);

    useEffect(() => {
        if (initialSearch) {
            handleOnSend(initialSearch);
        }
        if (isSmallChat && inputMessage && chatId) {
            handleOnSend(inputMessage);
        }
    }, [initialSearch]);

    useEffect(() => {
        if (isSmallChat) {
            // Handle the model change in Triage
            if (!model || model === t('common.all')) {
                //The model has't been selected or it has't been ingested
                handleHideChatWindow();
            } else if (
                chatWindowState === SmallWindowState.Hidden ||
                !isCopilotMode
            ) {
                handleInitializeChatWindow();
            }
        }
    }, [model]);

    useEffect(() => {
        if (
            chatId &&
            messages.length === 1 &&
            model &&
            model !== t('common.all')
        ) {
            askStream(
                messages[0].message,
                null,
                [...messages],
                false,
                false,
                null,
                true
            );
        }
    }, [chatId]);

    useEffect(() => {
        if (askingQuery) {
            setInputMessage(askingQuery);
            handleOnSend(askingQuery);
            dispatch(updateAskingQuery(''));
        }
    }, [askingQuery]);

    useEffect(() => {
        if (obsToSuggest.length) {
            const obsToFetch = obsToSuggest.filter(
                (obj) => !fetchedSuggestions.observations.includes(obj.id)
            );
            if (obsToSuggest.length) {
                getSuggestions(obsToFetch, SUGGESTED_OBSERVATIONS_FOUND);
            }
        }
    }, [JSON.stringify(obsToSuggest)]);

    useEffect(() => {
        if (solutionsToSuggest.length) {
            const solutionsToFetch = solutionsToSuggest.filter(
                (obj) => !fetchedSuggestions.solutions.includes(obj.id)
            );
            if (solutionsToSuggest.length) {
                getSuggestions(solutionsToFetch, SUGGESTED_SOLUTIONS_FOUND);
            }
        }
    }, [JSON.stringify(solutionsToSuggest)]);

    useEffect(() => {
        if (triggeredMessage) {
            handleOnSend(triggeredMessage);
            setTriggeredMessage('');
        }
    }, [triggeredMessage]);

    const chatRef = useCallback(
        (node) => {
            if (node !== null && typingFocused && isChatHistoryLoaded) {
                followAnswer();
            }
        },
        [messages.length, chatWindowState]
    );

    const getSuggestions = async (data, type) => {
        if (!data?.length || !model) {
            return;
        }
        const requests = data.map((value) =>
            Backend.observationSuggestions(model, value.name)
        );
        const location =
            type === SUGGESTED_SOLUTIONS_FOUND ? 'solutions' : 'observations';
        const fetching = data.map((obj) => obj.id);
        setFetchedSuggestions({
            ...fetchedSuggestions,
            [location]: [...fetchedSuggestions[location], ...fetching]
        });
        axios.all(requests).then((responses) => {
            const mapIdToData = {};
            responses.forEach((resp: RespType, index) => {
                mapIdToData[data[index].id] = resp.data.suggestions;
            });
            dispatch(suggestionsFound(mapIdToData, type));
            setRecommendedQuestions(mapIdToData[data[0].id]);
            setRecommendedQuestionsSource(
                type === SUGGESTED_SOLUTIONS_FOUND
                    ? RecommendedQuestionsSource.Solution
                    : RecommendedQuestionsSource.Observation
            );
        });
    };

    const handleNewChatId = (newChatId: string, model: string) => {
        dispatch(setTriageChatId(newChatId, model));
    };

    const createNewChatId = async () => {
        const cId = await SFBackend.createChatId();
        dispatch(setTriageChatId(cId, model));
    };

    const ask = async (
        question: string,
        newMessages,
        useOriginalValue: boolean = false,
        askAnyway: boolean = false
    ) => {
        if (isStreamInProgress && !askAnyway) {
            return;
        }
        setIsAskRequestInProgress(true);
        setIsStreamInProgress(true);
        let cId = chatId;
        const isCreateChatInvestigationMapping = SFBackend.isRemoteActionExists(
            REMOTE_ACTIONS.CREATE_CHAT_INVESTIGATION_MAPPING
        ); // if true SF version >= 98
        if (model === t('common.all') || !model) {
            if (!cId && !isCreateChatInvestigationMapping) {
                // version < 98
                createNewChatId();
            }
            const askModel = model === t('common.all') ? 'all' : model;
            const response = await Backend.ask(
                askModel || 'all',
                question,
                true,
                cId
            );
            if (
                !cId &&
                response.data.chat_id &&
                isCreateChatInvestigationMapping // use chat ID from copilot server if version >= 98
            ) {
                handleNewChatId(response.data.chat_id, model);
            }
            if (!response.data.answer) {
                setIsStreamInProgress(false);
                setIsAskRequestInProgress(false);
                if (response.data.models?.length) {
                    await fetchAndSaveIntegratedModels(
                        response.data.models.map((model) => model.name)
                    );
                    const modelsByProbability = {};
                    response.data.models.forEach(
                        (model) =>
                            (modelsByProbability[model.name] =
                                model.probability)
                    );
                    setRelevantModels(modelsByProbability);
                }
                setDisplayNoModel(true);
                return;
            }
        } else if (!cId && !isCreateChatInvestigationMapping) {
            // version < 98
            createNewChatId();
            return;
        } else {
            askStream(
                question,
                null,
                newMessages,
                false,
                useOriginalValue,
                null,
                askAnyway,
                productDetails
            );
        }
    };

    const handleStateOnSend = (
        question,
        newMessages,
        sender = MessageSenderType.User
    ) => {
        setShowSuggestedQuestions(false);
        newMessages.push({
            message: question,
            sentTime: Date.now().toString(),
            direction: 'outgoing',
            sender,
            id: Date.now().toString()
        });
        setInputMessage('');
        setMessages(newMessages);
        setRecommendedQuestions([]);
        if (isSmallChat) {
            handleMaximizeChatWindow();
        }
    };

    const handleOnSend = async (
        textContent: string,
        useOriginalValue: boolean = false
    ) => {
        if (isAskRequestInProgress) {
            return;
        }
        if (
            selectedThread &&
            !Object.keys(messagesPerSelectedChat).includes(selectedThread)
        ) {
            setMessagesPerSelectedChat({
                ...messagesPerSelectedChat,
                [selectedThread]: messages.length
            });
        }
        let askAnyway = false;
        if (isStreamInProgress) {
            cancelStream();
            setTypingStarted(false);
            askAnyway = true;
        }
        if (showInvestigateButtonBasedOnObservations) {
            setInvestigationDetails(productDetails, textContent);
        }
        setGenerationCounter(0);
        setIsStreamStopped(false);
        const newMessages = [...messages];
        let question = textContent;
        if (!question) {
            return;
        }
        if (initialSearch) {
            setInitialSearch('');
        }
        handleStateOnSend(question, newMessages);
        ask(question, newMessages, useOriginalValue, askAnyway);
    };
    const clearAndStartNewSearch = () => {
        setIsRegenerateAvailable(false);
        setMessages([]);
        setTypingStarted(false);
        setIsStreamStopped(false);
        resetTriageLinkMessageIndexes();
        setHistoryMessages({});
        sendEvent(ANALYTICS_EVENTS.CLICKED_MENU_ITEM, {
            'User selection': 'Clear chat'
        });
    };

    const displayCoPilot = () => {
        const rootElement = document.getElementById('stark');
        rootElement.style.display = 'none';
        dispatch(setIsSmallChat(false));
        dispatch(setChatWindowState(null));
        if (messages.length) {
            setCoPilotPanel();
        }
    };

    const performStateCheck = async () => {
        const response: any = await performCallWithTimeoutRetry(async () =>
            Backend.performStateCheck(model || t('common.all'))
        );
        const state = response.data;
        setIsValidState(state.copilot);
        // do this only in popup mode
        if (isSmallChat) {
            if (state.copilot) {
                if (chatWindowState) {
                    return;
                }
                if (isCopilotMode && (!model || model === t('common.all'))) {
                    handleHideChatWindow();
                } else if (isCopilotMode && model) {
                    handleMaximizeChatWindow();
                } else if (model) {
                    handleInitializeChatWindow();
                }
            } else {
                handleHideChatWindow();
            }
        }
    };

    const modelSelected = (integratedModel: IntegratedModel) => {
        setModelData(integratedModel.modelName);
        setProductDetails(integratedModel);
        handleStateOnSend(
            adjustIntegratedModel(integratedModel),
            messages,
            MessageSenderType.UserMock
        );
        sendEvent(ANALYTICS_EVENTS.SELECT_SPECIFIC_MODEL, {
            'Product type': integratedModel.productTypeName,
            Manufacturer: integratedModel.manufacturerName
        });
        setInvestigationDetails(integratedModel, messages[0].message);
        addTriageLinkMessageIndex(messages.length);
        askStream(
            messages[messages.length - 2].message,
            integratedModel.modelName,
            [...messages],
            false,
            false,
            integratedModel?.modelDisplay || integratedModel?.modelName,
            false,
            integratedModel
        );
    };

    const displayRecommended = () => {
        if (
            recommendedQuestions.length &&
            isSmallChat &&
            chatWindowState === SmallWindowState.Maximized
        ) {
            return true;
        }
    };

    const aboveSearchDisplay = () => {
        if (displayRecommended()) {
            return <RecommendedQuestions handleOnSend={handleOnSend} />;
        } else if (
            chatWindowState !== SmallWindowState.Minimized &&
            chatWindowState !== SmallWindowState.Initial
        ) {
            return <div style={{ height: '12px' }}></div>;
        }
    };

    const showInput = () => {
        if (!isSmallChat || chatWindowState === SmallWindowState.Maximized) {
            return true;
        }
        return isMinimizedWithInput;
    };

    if (isValidState === null) {
        return <div className="chat__container"></div>;
    } else if (isValidState === false) {
        return <div>cant proceed</div>;
    }

    const separatingDateLine = () => {
        return (
            <div className="flex align-center justify-center chat__date full-width">
                <div className="flex chat__date__line"></div>
                <Typography
                    className="chat__date__text"
                    type="small2"
                    textTransform="uppercase"
                >
                    {prepareDate(new Date().setHours(0, 0, 0, 0)).toUpperCase()}
                </Typography>
            </div>
        );
    };

    const shouldAddDate = (index) => {
        return (
            index > 0 &&
            messagesPerSelectedChat[messages[index - 1].chatID] &&
            index === messagesPerSelectedChat[messages[index - 1].chatID]
        );
    };

    const isChatLoading = !messages.length && chatId && !isChatHistoryLoaded;

    return (
        <div
            className={
                shouldShowSmallChat
                    ? `aq-genai-root ${
                          isMinimizedWithInput ? '' : 'smallScreenNoInput'
                      } ${
                          isSmallChatInPortal
                              ? `--in-source-modal ${
                                    isSmallChatInPortalCollapsed
                                        ? '--collapse-chat'
                                        : '--show-chat'
                                }`
                              : `--${chatWindowState}`
                      }`
                    : 'chat__wrapper'
            }
        >
            <div
                className={`chat__container flex flex-col ${
                    shouldShowSmallChat ? 'smallChatWidth' : ''
                }`}
            >
                {shouldShowSmallChat && !isSmallChatInPortal && (
                    <SmallChatHeader
                        onMaximizeClick={() => handleMaximizeChatWindow()}
                        onMinimizeClick={() => handleMinimizeChatWindow()}
                        onClearClick={() => clearAndStartNewSearch()}
                        onClickBack={() => displayCoPilot()}
                        chatWindowState={chatWindowState}
                        isMinimizedWithInput={isMinimizedWithInput}
                        setIsMinimizedWithInput={setIsMinimizedWithInput}
                    />
                )}
                {!shouldShowSmallChat && (
                    <ChatModelBar
                        model={model}
                        productDetails={productDetails}
                    />
                )}
                {showGeneralDisclaimer && (
                    <HeaderMessage
                        message={scopSettings.generalDisclaimerMessage}
                    />
                )}
                {isChatLoading && (
                    <div
                        id={SPECIAL_DIV_IDS.CHAT}
                        ref={chatRef}
                        data-testid="chat-conversation"
                        className={`chat__conversation flex flex-col ${
                            shouldShowSmallChat ? '--smallChat' : ''
                        } ${
                            (chatWindowState === SmallWindowState.Minimized ||
                                chatWindowState === SmallWindowState.Initial) &&
                            shouldShowSmallChat
                                ? 'minimized'
                                : ''
                        }`}
                    >
                        <ChatMessage
                            index={0}
                            message={{
                                chatID: '',
                                rowID: '',
                                message: firstThreadQuestion,
                                sentTime: '',
                                sender: MessageSenderType.User,
                                direction: 0
                            }}
                            isSmallChat={shouldShowSmallChat}
                            isTriageModel={!!productDetails?.productIds}
                            typingStarted={typingStarted}
                            typingFocused={typingFocused}
                            missedMessagesRelativeHight={
                                missedMessagesRelativeHight
                            }
                            setMissedMessagesCounterRelativeHight={
                                setMissedMessagesCounterRelativeHight
                            }
                            historyMessages={historyMessages[0]}
                            isMessageFocused={false}
                            showSuggestions={false}
                            isLastMessage={false}
                            askedQuestion={''}
                        />
                        <SkeletonContainer dots />
                    </div>
                )}
                {messages.length > 0 && (
                    <div
                        id={SPECIAL_DIV_IDS.CHAT}
                        ref={chatRef}
                        data-testid="chat-conversation"
                        className={`chat__conversation flex flex-col ${
                            shouldShowSmallChat ? '--smallChat' : ''
                        } ${
                            (chatWindowState === SmallWindowState.Minimized ||
                                chatWindowState === SmallWindowState.Initial) &&
                            shouldShowSmallChat
                                ? 'minimized'
                                : ''
                        }`}
                    >
                        {messages.map((message, index) => {
                            const questionIndex = index === 2 ? 2 : 1;
                            if (
                                index === messages.length - 1 &&
                                !isChatHistoryLoaded
                            ) {
                                setIsChatHistoryLoaded(true);
                            }
                            return (
                                <React.Fragment key={index}>
                                    {shouldAddDate(index) &&
                                        separatingDateLine()}
                                    <ChatMessage
                                        index={index}
                                        message={message}
                                        onClickShowArticles={() =>
                                            setSelectedMessage(message)
                                        }
                                        isSmallChat={shouldShowSmallChat}
                                        isTriageModel={
                                            !!productDetails?.productIds
                                        }
                                        typingStarted={typingStarted}
                                        typingFocused={typingFocused}
                                        missedMessagesRelativeHight={
                                            missedMessagesRelativeHight
                                        }
                                        setMissedMessagesCounterRelativeHight={
                                            setMissedMessagesCounterRelativeHight
                                        }
                                        historyMessages={historyMessages[index]}
                                        isMessageFocused={
                                            typingStarted &&
                                            index === messages.length - 1
                                        }
                                        onClickSuggestion={handleOnSend}
                                        showSuggestions={showSuggestedQuestions}
                                        isLastMessage={
                                            index === messages.length - 1
                                        }
                                        askedQuestion={
                                            message.sender ===
                                            MessageSenderType.Aquant
                                                ? messages[
                                                      index - questionIndex
                                                  ].message
                                                : ''
                                        }
                                        shouldShowError={
                                            index === messages.length - 1 &&
                                            isStreamError
                                        }
                                    />
                                    {!selectedThread &&
                                        displayNoModel &&
                                        index === 0 && (
                                            <NoModelMessage
                                                availableModels={modelsSuggestionByProbability(
                                                    integratedModels,
                                                    relevantModels
                                                )}
                                                setModel={modelSelected}
                                            />
                                        )}
                                </React.Fragment>
                            );
                        })}

                        {isStreamInProgress && !typingStarted && (
                            <SkeletonContainer dots />
                        )}
                    </div>
                )}
                {showInput() && (
                    <div
                        className={`chat__input ${
                            shouldShowSmallChat ? '--smallChat' : ''
                        } ${
                            (chatWindowState === SmallWindowState.Minimized ||
                                chatWindowState === SmallWindowState.Initial) &&
                            shouldShowSmallChat
                                ? 'minimized'
                                : ''
                        }`}
                    >
                        {!!messages.length && (
                            <ScrollToButton
                                typingFocused={typingFocused}
                                setTypingFocused={setTypingFocused}
                                missedMessagesRelativeHight={
                                    missedMessagesRelativeHight
                                }
                                setMissedMessagesCounterRelativeHight={
                                    setMissedMessagesCounterRelativeHight
                                }
                                isSmallChat={shouldShowSmallChat}
                            />
                        )}
                        <GenerateButton
                            isHidden={isGenerateButtonHidden}
                            setTypingStarted={setTypingStarted}
                            generationCounter={generationCounter}
                            setGenerationCounter={setGenerationCounter}
                            isStreamInProgress={isStreamInProgress}
                            chatWindowState={chatWindowState}
                            isSmallChat={shouldShowSmallChat}
                        />
                        {aboveSearchDisplay()}
                        <SearchBar
                            selectedValue={model}
                            placeholder={t('search.ask')}
                            onSubmit={() => handleOnSend(inputMessage)}
                            dataTestId="follow-up_request_search_bar"
                            value={inputMessage}
                            setValue={setInputMessage}
                            isSmallMode={Boolean(shouldShowSmallChat)}
                            maxRowsToGrow={6}
                            darkStroke
                            submitDisabled={isAskRequestInProgress}
                        />
                        {isSmallChatInPortal &&
                            !isSmallChatInPortalCollapsed && (
                                <CollapseButton
                                    className="collapse-btn--in-source-modal"
                                    onClick={() =>
                                        setIsSmallChatInPortalCollapsed(true)
                                    }
                                    dataTestId="collapse-chat-button"
                                />
                            )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Chat;
