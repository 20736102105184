import React, { FunctionComponent, useState } from 'react';
import { WarningIcon, ChevronIcon } from 'aqui/icons';
import { Typography, Bold } from 'aqui';
import { EMAIL_VALIDATION_TYPES } from './EmailValidationModal';
import { t } from 'i18next';

type EmailValidationModalBodyProps = {
    emails: string[];
    type: EMAIL_VALIDATION_TYPES;
    persona?: string;
};

const EmailValidationModalBody: FunctionComponent<
    EmailValidationModalBodyProps
> = ({ emails, type, persona }) => {
    const [showMore, setShowMore] = useState(false);

    const handleTextToShow = () => {
        if (type === EMAIL_VALIDATION_TYPES.NotValid) {
            return (
                <>
                    {t('emails-validation.not-assigned')}
                    <Bold>{` ${persona} `}</Bold>
                    {t('emails-validation.not-assigned-2')}
                </>
            );
        } else if (type === EMAIL_VALIDATION_TYPES.NotExisting) {
            return t('emails-validation.not-found');
        }
    };

    const showExample = () => {
        if (type === EMAIL_VALIDATION_TYPES.NotValid) {
            return (
                <div className="flex flex-col emailValidationModal__example">
                    <Typography type="body3">
                        {t('emails-validation.instructions')}
                    </Typography>
                    <Typography type="body3">
                        <Bold>{t('emails-validation.format')}</Bold>
                    </Typography>
                </div>
            );
        }
    };

    return (
        <>
            <div className="flex align-center emailValidationModal__title">
                <WarningIcon className="emailValidationModal__title__icon" />
                <Typography
                    type="body3"
                    className="emailValidationModal__title__text"
                >
                    <Bold>
                        {`${emails.length} ${t(
                            'add-persona.user-names'
                        ).toLowerCase()} `}
                    </Bold>
                    {handleTextToShow()}
                </Typography>
            </div>
            <div
                className={`emailValidationModal__hidden-data ${
                    showMore ? 'show' : ''
                }`}
            >
                <div className="flex flex-col emailValidationModal__emails">
                    {emails.map((email) => (
                        <Typography type="body3">{email}</Typography>
                    ))}
                </div>
                {showExample()}
            </div>
            <div
                className="flex align-center emailValidationModal__show-more"
                onClick={() => setShowMore(!showMore)}
            >
                <Typography type="small1">
                    {t('common.more-details')}
                </Typography>
                <ChevronIcon
                    direction="down"
                    className={`emailValidationModal__show-more__icon ${
                        showMore ? 'open' : ''
                    }`}
                />
            </div>
        </>
    );
};

export default EmailValidationModalBody;
